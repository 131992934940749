import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    TextField,
    Button
} from '@material-ui/core/';
import IntlMessages from 'util/IntlMessages';
import {
    setValidationFields
} from 'actions/Auth';

const ChangePasswordForm = ({ onClick, resetForm }) => {
    const [passwords, setPasswords] = useState({
        old_password: '',
        password: '',
        password_confirmation: ''
    });
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [isPasswordsIdentical, setPasswordsIdentical] = useState(true);
    const dispatch = useDispatch();
    const {
        validator
    } = useSelector(({ auth }) => auth);

    useEffect(() => {
        if (resetForm) {
            setPasswords((state) => ({
                ...state,
                old_password: '',
                password: '',
                password_confirmation: ''
            }));
            setButtonDisabled(false);
        }
    }, [resetForm]);

    const handleChange = (event) => {
        if (buttonDisabled) {
            setButtonDisabled(false);
        }
        event.preventDefault();
        const { name, value } = event.target;
        if (validator[name]) {
            delete validator[name];
            dispatch(setValidationFields(validator));
        }
        setPasswords((state) => ({ ...state, [name]: value }));
        if (name === 'password_confirmation') {
            setPasswordsIdentical(value === passwords.password);
        }
    };

    return (
        <div className="login-content change-password-content">
            <div className="login-form">
                <form>
                    <fieldset>
                        <TextField
                            type="password"
                            variant="outlined"
                            label={<IntlMessages id="label.user.password.old" />}
                            fullWidth
                            onChange={handleChange}
                            value={passwords.old_password}
                            name="old_password"
                            margin="dense"
                            className="mt-1 my-sm-3"
                            error={!!validator.old_password}
                            helperText={validator.old_password}
                            required
                        />
                        <TextField
                            type="password"
                            variant="outlined"
                            label={<IntlMessages id="label.user.password.new" />}
                            fullWidth
                            onChange={handleChange}
                            value={passwords.password}
                            name="password"
                            margin="dense"
                            className="mt-1 my-sm-3"
                            error={!!validator.password || !isPasswordsIdentical}
                            helperText={validator.password}
                            required
                        />
                        <TextField
                            type="password"
                            variant="outlined"
                            label={<IntlMessages id="label.user.password.new.repeat" />}
                            fullWidth
                            onChange={handleChange}
                            value={passwords.password_confirmation}
                            name="password_confirmation"
                            margin="dense"
                            className="mt-1 my-sm-3"
                            error={!!validator.password_confirmation || !isPasswordsIdentical}
                            helperText={validator.password_confirmation || (!isPasswordsIdentical ? <IntlMessages id="label.user.password.mismatch" /> : '')}
                            required
                        />

                        <div className="mb-3 d-flex flex-column flex-sm-row justify-content-center justify-content-sm-between align-items-center">
                            <Button
                                onClick={(event) => {
                                    event.preventDefault();
                                    dispatch(setValidationFields({}));
                                    setButtonDisabled(true);
                                    onClick(passwords);
                                }}
                                variant="contained"
                                color="primary"
                                disabled={buttonDisabled}
                            >
                                <IntlMessages id="label.user.password.change" />
                            </Button>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    );
};


export default withRouter(ChangePasswordForm);
