import {
    all, call, select, put, takeLatest
} from 'redux-saga/effects';

import {
    UK_FETCH_ADDRESS_LIST,
    UK_FETCH_STREEST_LIST,
    UK_FETCH_CITIES_LIST
} from 'constants/ActionTypes';
import {
    ukAddressListLoaded,
    ukAddressListLoadedStatus
} from 'actions/UK';
// import { showMessage } from 'actions/Messages';
import { getActionForError } from 'util/errors';
import api from 'util/api/api.uk.methods';

export const stateSelector = (state) => state.translate;
export const stateAuthSelector = (state) => state.auth;

function* fetchUKAddressListSaga({ payload }) {
    const { page, limit, city_name, street_name, query } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.getUKAddressList, page, limit, city_name, street_name, query, authUser?.access_token);
        yield put(ukAddressListLoaded(response));
        yield put(ukAddressListLoadedStatus(true));
    } catch (error) {
        yield put(ukAddressListLoaded({ data: [] }));
        yield put(getActionForError(error));
        yield put(ukAddressListLoadedStatus(true));
    }
}

function* fetchUKStreetListSaga({ payload }) {
    const { page, limit, city_name, street_name } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.getUKStreetList, page, limit, city_name, street_name, authUser?.access_token);
        yield put(ukAddressListLoaded(response));
        yield put(ukAddressListLoadedStatus(true));
    } catch (error) {
        yield put(ukAddressListLoaded({ data: [] }));
        yield put(getActionForError(error));
        yield put(ukAddressListLoadedStatus(true));
    }
}

function* fetchUKCitiesListSaga({ payload }) {
    const { page, limit, city_name } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.getUKCitiesList, page, limit, city_name, authUser?.access_token);
        yield put(ukAddressListLoaded(response));
        yield put(ukAddressListLoadedStatus(true));
    } catch (error) {
        yield put(ukAddressListLoaded({ data: [] }));
        yield put(getActionForError(error));
        yield put(ukAddressListLoadedStatus(true));
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(UK_FETCH_ADDRESS_LIST, fetchUKAddressListSaga),
        takeLatest(UK_FETCH_STREEST_LIST, fetchUKStreetListSaga),
        takeLatest(UK_FETCH_CITIES_LIST, fetchUKCitiesListSaga)
    ]);
}
