import {
    API_ZHKU_URLS,
    API_ZHKU_SBERBANK_URLS,
} from 'constants/Config';

const apiUrls = {
    createOrder: () => `${API_ZHKU_URLS}/order`,

    checkOrder: () => `${API_ZHKU_SBERBANK_URLS}/payment/callback/zkh/sberbank`,
};

export default apiUrls;