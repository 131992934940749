import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ListItem, ListItemText, Typography, IconButton } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import { editUser } from 'actions/Admin';
import Info from './Info';
import Form from './EditorForm';
import ModalDialog from 'components/ModalDialog';
import styles from './users.module.scss';

const UserItem = ({ user }) => {
    const dispatch = useDispatch();
    const [modalVisible, setModalVisible] = useState(false);
    const [isOpenInfo, setIsOpenInfo] = useState(false);

    const handleClose = () => {
        setModalVisible(false);
    };

    const handleSuccess = (personal_account) => {
        dispatch(editUser({
            personal_account,
            user_id: user.id
        }));
    };

    return (
        <div className={styles.list_item}>
             {/* модальное окно редактирования юзера */}
             <ModalDialog
                isOpen={modalVisible}
                titleText="Изменение/Добавление лицевого счёта"
                handleClose={handleClose}
            >
                <Form
                    onSuccess={handleSuccess}
                    onClose={handleClose}
                    data={user}
                />
            </ModalDialog>

            <ListItem onClick={() => setIsOpenInfo(!isOpenInfo)}>
                <ListItemText>
                    <span className={styles.title}>{[user?.last_name, user?.first_name, user?.middle_name].join(' ')}</span>
                    {'  '}
                    {user?.roles?.length > 0
                        && <span className={styles.description}>{user?.roles.map((el => el.description)).join(' ')}</span>
                    }
                </ListItemText>
                <IconButton onClick={() => setModalVisible(true)} size="small">
                    <EditIcon fontSize="inherit" />
                </IconButton>
                <IconButton size="small">
                    {isOpenInfo
                        ? <ExpandLess fontSize="inherit" />
                        : <ExpandMore fontSize="inherit" />
                    }
                </IconButton>
            </ListItem>
            {isOpenInfo && <Info isOpen={isOpenInfo} user={user} />}
        </div>
    );
};

export default UserItem;
