import React from 'react';
import { useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import {
    deleteArticle, publishArticle
} from 'actions/Articles';
import ListItem from 'components/ArticleList/ListItem';


const ArticleItem = ({
    item, history, ...props
}) => {
    const { isAdmin, ukList } = useSelector(({ auth }) => auth);

    const actions = {
        edit: {
            onClick: () => history.push(`/article/edit/${item.id}`),
            text: 'appModule.edit',
            tooltipTitle: 'appModule.edit',
            className: 'text-secondary',
            icon: <EditIcon />,
            disabled: [],
            admin: true
        },
        publish: {
            onClick: publishArticle,
            text: 'appModule.publish',
            confirmText: 'article.confirmPublish',
            tooltipTitle: 'appModule.publish',
            disabled: [1],
            className: 'text-info',
            icon: <ThumbUpAltIcon />,
            admin: true
        },
        delete: {
            onClick: deleteArticle,
            confirmText: 'article.confirmArchive',
            text: 'appModule.toArchive',
            tooltipTitle: 'appModule.toArchive',
            disabled: [2],
            className: 'text-danger',
            icon: <span className="iconify" data-icon="ion:archive" data-inline="false" />,
            admin: true
        }
    };

    const statusesForHeader = {
        0: 'new', 1: 'published', 2: 'deleted'
    };
    const statusName = statusesForHeader[item.status];


    return (
        <ListItem
            {...props}
            statusName={statusName}
            actions={actions}
            data={item}
            isAdmin={isAdmin}
            type="article"
            uk={ukList.find((el) => el.value === item.uk_name) || {}}
        />
    );
};

export default ArticleItem;
