import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalDialog from 'components/ModalDialog';
import { messageBody } from 'components/DebtorsList/helper';
import Typography from '@material-ui/core/Typography';
import { Button, CardActions, Grid } from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import {
    clearDebtorsMessageStatus,
    sendDebtorsMessageTelegramUser,
    sendDebtorsMessageEmailUser,
    clearDebtorsUser
} from 'actions/Debtors';
import CheckedList from './CheckedList';
import List from './List';

function PersonalInfo(props) {
    const {
        selectedData,
        onClose = () => {}
    } = props;

    const dispatch = useDispatch();
    const [sendEmailList, setSendEmailList] = useState([]);
    const [sendTelegramList, setSendTelegramList] = useState([]);
    const [visibleForm, setVisibleForm] = useState(false);
    const [disabledForm, setDisabledForm] = useState(false);

    const { user } = useSelector(({ debtors }) => debtors);

    const { user_identifier } = user;

    const onSendClick = (event) => {
        if (visibleForm) {
            setDisabledForm(true);

            if (sendEmailList.length > 0) {
                //user_identifier, emails, subject, body, files=[]
                dispatch(sendDebtorsMessageEmailUser(
                    user_identifier,
                    sendEmailList,
                    'Напоминание о задолжности',
                    messageBody({
                        ...selectedData,
                        ...user
                    })
                ));
            }

            if (sendTelegramList.length > 0) {
                sendTelegramList.forEach((telegramId) => {
                    dispatch(sendDebtorsMessageTelegramUser(
                        user_identifier,
                        telegramId,
                        messageBody({
                            ...selectedData,
                            ...user
                        })
                    ));
                });
            }

            setDisabledForm(false);
            setVisibleForm(false);
            return;
        }

        setVisibleForm(true);
    };

    const handleClose = () => {
        dispatch(clearDebtorsUser());
        onClose();
    };

    useEffect(() => {
        setVisibleForm(false);
    }, [user]);

    useEffect(() => {
        // очищаем статусы
        dispatch(clearDebtorsMessageStatus());
    }, []);

    const flatProps = [
        'house',
        'flat',
        'account_uk_identifier'
    ];

    const FIO = [
        'last_name',
        'first_name',
        'middle_name'
    ];

    const renderList = (list, data) => (
        <>
            {list.map((key) => (
                <React.Fragment key={key}>
                    {(data.hasOwnProperty(key) && data[key]) && (
                        <div key={`${key}modalbody`}>
                            <Typography className="mb-2">
                                <span className="font-weight-bold">
                                    <IntlMessages id={`debtors.label.${key}`} />
                                    {': '}
                                </span>
                                <span>
                                    {key === 'flat' && '№'}
                                    {data[key]}
                                </span>
                            </Typography>
                        </div>
                    )}
                </React.Fragment>
            ))}
        </>
    );

    const emails = user?.emails?.length ? user?.emails?.map((item) => ({ name: item, value: item })) : [];
    const telegram = user?.telegram?.length ? user?.telegram?.filter(({ subscribe }) => subscribe).map((item) => ({ name: item.channel_id, value: item.channel_id })) : [];
    const phones = user?.phones?.length ? user?.phones?.map((item) => ({ name: item, value: item })) : [];
    const isDialogOpen = Object.keys(user).length > 0;

    return (
        <ModalDialog
            isOpen={isDialogOpen}
            handleClose={handleClose}
            headerClasses="pb-0"
            titleText={FIO.map((key) => {
                if (user.hasOwnProperty(key)) {
                    return (
                        <span key={key}>
                            {user[key]}
                            {' '}
                        </span>
                    );
                }
                return null;
            })}
        >
            {renderList(flatProps, selectedData)}

            {phones.length > 0 && (
                <div className="mb-2">
                    <Typography style={{ fontWeight: 700 }}>
                        <IntlMessages id="appModule.phone" />
                        {':'}
                    </Typography>
                    <List
                        list={phones}
                    />
                </div>
            )}

            {emails.length > 0 && (
                <div className="mb-2">
                    <Typography style={{ fontWeight: 700 }}>
                        <IntlMessages id="appModule.email" />
                        {':'}
                    </Typography>

                    {visibleForm ? (
                        <CheckedList
                            className="mb-2"
                            list={emails}
                            checkeds={sendEmailList}
                            defaultChecked
                            onChange={(checkeds) => setSendEmailList(checkeds)}
                            disabled={disabledForm}
                        />
                    ) : (
                        <List
                            list={emails}
                        />
                    )}
                </div>
            )}

            {telegram.length > 0 && (
                <div className="mb-2">
                    <Typography style={{ fontWeight: 700 }}>
                        <IntlMessages id="appModule.telegram" />
                        {':'}
                    </Typography>

                    {visibleForm ? (
                        <CheckedList
                            className="mb-2"
                            list={telegram}
                            checkeds={sendTelegramList}
                            defaultChecked
                            onChange={(checkeds) => setSendTelegramList(checkeds)}
                            disabled={disabledForm}
                        />
                    ) : (
                        <List
                            list={telegram}
                        />
                    )}
                </div>
            )}

            <CardActions disableSpacing classes={{ root: 'justify-content-end' }}>
                <div className="mb-2">
                    {(selectedData?.end_debit > 0 && (emails.length > 0 || telegram.length > 0)) && (
                        <Button
                            className="mr-4"
                            onClick={onSendClick}
                            //disabled={error}
                            variant="contained"
                            color="primary"
                        >
                            {visibleForm ? (
                                <IntlMessages id="appModule.send" />
                            ) : (
                                <IntlMessages id="appModule.sendNotification" />
                            )}
                        </Button>
                    )}

                    <Button onClick={handleClose} variant="contained">
                        <IntlMessages id="appModule.close" />
                    </Button>
                </div>
            </CardActions>

        </ModalDialog>
    );
}

export default PersonalInfo;
