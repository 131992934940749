const headerBackgroundWithStatus = (
    status
) => {
    const titleBg = (st) => {
        switch (st) {
        case 'wait':
            return 'bg-orange lighten-1';
        case 'new':
            return 'bg-primary'; //bg-orange
        case 'started':
            return 'bg-secondary lighten-1';
        case 'published':
            return 'bg-orange lighten-1';//bg-secondary
        case 'finished':
            return 'bg-grey lighten-1';
        case 'deleted':
            return 'bg-grey darken-1';
        default:
            return '';
        }
    };

    return (
        titleBg(status)
    );
};

export default headerBackgroundWithStatus;
