import addErrorHandling from 'util/errors/error.handling';
import urls from './api.urls';
import instance from '../axios.config';

const ApiMethods = {
    createOrder: async (params) => {
        const response = await instance.post(urls.createOrder(), params);
        return response.data;
    },

    checkOrder: async (params) => {
        const response = await instance.get(urls.checkOrder(), {
            params,
            // responseType: 'text'
        });
        return response.data;
    },
};

export default addErrorHandling(ApiMethods);