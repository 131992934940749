import {
    TRANSLATE_LIST_LOADED,
    TRANSLATE_LIST_LOADED_STATUS,
    TRANSLATE_SET_ERROR,
    TRANSLATE_SAVED,
    TRANSLATE_WHITH_ID_LOADED,
    TRANSLATE_SERVICE_LIST_LOADED
} from 'constants/ActionTypes';

const INIT_STATE = {
    list: {
        data: []
    },
    serviceList: [],
    listLoaded: false,
    error: false,
    savedAction: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
    case TRANSLATE_LIST_LOADED: {
        return {
            ...state,
            list: action.payload
        };
    }
    case TRANSLATE_WHITH_ID_LOADED: {
        const { data } = action.payload;
        const currentIndex = state.list.data.findIndex((el) => el.id === data.id);
        if (currentIndex < 0) {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: [
                        ...state.list.data,
                        data
                    ]
                },
            };
        }
        return {
            ...state,
            list: {
                ...state.list,
                data: [
                    ...state.list.data.slice(0, currentIndex),
                    data,
                    ...state.list.data.slice(currentIndex + 1)
                ]
            },
        };
    }
    case TRANSLATE_LIST_LOADED_STATUS: {
        return {
            ...state,
            listLoaded: action.payload
        };
    }
    case TRANSLATE_SET_ERROR: {
        return {
            ...state,
            error: action.payload
        };
    }
    case TRANSLATE_SAVED: {
        const { savedAction } = action.payload;
        return {
            ...state,
            savedAction
        };
    }
    case TRANSLATE_SERVICE_LIST_LOADED: {
        const { data } = action.payload;
        return {
            ...state,
            serviceList: Object.keys(data).map((key) => {
                const item = data[key];
                return {
                    id: item.value,
                    name: item.description
                };
            })
        };
    }
    default:
        return state;
    }
};
