import React from 'react';
import IntlMessages from 'util/IntlMessages';
import ListItem from './ListItem';
import {useSelector} from "react-redux";

const List = ({ list = [], activeId, ...props }) => {
    const { ukList } = useSelector(({ auth }) => auth);

    if (!list?.length) {
        return (
            <div className="h4 pl-4 pr-4">
                <IntlMessages id="appModule.nothingFound" />
            </div>
        );
    }
    return (
        list.map((item) => (
            <ListItem
                key={item.id}
                data={{
                    ...item,
                    uk: ukList.find((el) => el.value === item.uk_name) || null
                }}
                isActive={activeId === item.id}
                {...props}
            />
        ))
    );
};

export default List;
