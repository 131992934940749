import React, { useEffect } from 'react';
import {
    Grid,
    FormControlLabel,
    Checkbox
} from '@material-ui/core/';

function CheckedList(props) {
    const {
        list = [],
        checkeds = [],
        onChange = () => {},
        defaultChecked = false,
        disabled = false
    } = props;

    const handleCheck = (event, value) => {
        const { checked } = event.target;
        if (checked) {
            const newCheckeds = [...checkeds];
            newCheckeds.push(value);
            onChange(newCheckeds);
            return;
        }
        const currentIndex = checkeds.indexOf(value);
        onChange([
            ...checkeds.slice(0, currentIndex),
            ...checkeds.slice(currentIndex + 1)
        ]);
    };

    useEffect(() => {
        if (defaultChecked) {
            onChange(list.map(({ value }) => value));
        }
    }, []);

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
        >
            {Object.keys(list).map((key) => {
                const { name, value } = list[key];
                return (
                    <FormControlLabel
                        key={key}
                        control={(
                            <Checkbox
                                color="primary"
                                checked={checkeds.indexOf(value) >= 0}
                                onChange={(events) => handleCheck(events, value)}
                                disabled={disabled}
                            />
                        )}
                        label={name}
                    />
                );
            })}
        </Grid>
    );
}

export default CheckedList;
