import {
    WS_CONNECT,
    WS_CONNECTED,
    WS_DISCONNECT,
    WS_DISCONNECTED
} from 'constants/ActionTypes';

export const wsConnect = (authUser, isAdmin, history) => ({ type: WS_CONNECT, authUser, isAdmin, history });

export const wsConnected = () => ({ type: WS_CONNECTED });

export const wsDisconnect = () => ({ type: WS_DISCONNECT });

export const wsDisconnected = () => ({ type: WS_DISCONNECTED });
