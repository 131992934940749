import React, { useState, useEffect } from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import ChatHeader from './ChatHeader';
import Conversation from './Conversation';
import ChatFooter from './ChatFooter';

const ChatBox = ({
    onSendMessage, messages = [], authUserId, person, showFooter = true, maxHeight
}) => {
    messages.sort((a, b) => {
        if (a.created_at < b.created_at) {
            return -1;
        }
        if (a.created_at > b.created_at) {
            return 1;
        }
        return 0;
    });

    const [scrollRef, setScrollRef] = useState(null);
    const height = maxHeight > 0 ? `${(maxHeight - 58)}px` : '40vh';

    const scrollToBottom = () => {
        if (scrollRef?.current) {
            scrollRef.current.scrollToBottom();
        }
    };
    // scroll to last message
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <div className="chat-box">
            <div className="chat-box-main">

                <div className="chat-main">
                    <ChatHeader person={person} />

                    <CustomScrollbars
                        className="chat-list-scroll scrollbar"
                        style={{ height, minHeight: '120px', background: '#fcfcfd' }}
                        getScrollbarRef={(ref) => setScrollRef(ref)}
                    >
                        <Conversation
                            messages={messages}
                            authUserId={authUserId}
                            onImageLoaded={scrollToBottom}
                        />
                    </CustomScrollbars>

                    {showFooter && <ChatFooter onSendMessage={onSendMessage} />}
                </div>
            </div>
        </div>
    );
};

export default ChatBox;
