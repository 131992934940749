import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom'
import {
    syncUser,
    changeSyncUserStatus
} from 'actions/User';
import {
    Button,
    LinearProgress,
    Grid
} from '@material-ui/core/';
import {
    fetchUKAddressList,
    ukAddressListLoaded,
    fetchUKStreetList,
    fetchUKCitiesList,
    ukAddressListLoadedStatus
} from 'actions/UK';
import {
    showAuthLoader,
    setValidationFields
} from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle
} from 'reactstrap';
import AuthHeader from 'components/Auth/AuthHeader';
import { showMessage } from 'actions/Messages';
import Step from '@material-ui/core/Step/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Stepper from '@material-ui/core/Stepper';
import FindField from './FindField';
import Form from './form';
import useStyles from './style';

const SyncUserForm = ({ match, history }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const logo = `<img src="${require('assets/images/logo64.png')}" alt="Logo" title="Logo" />`;
    const {
        syncUserStatus, loader
    } = useSelector(({ auth }) => auth);

    const {
        addressListLoaded
    } = useSelector(({ uk }) => uk);

    let timer = null;

    const [formData, setFormData] = useState({});
    const [uk_name, setUkName] = useState('');
    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNextStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleResetStep = () => {
        setActiveStep(0);
        setFormData({});
    };

    const handleSubmitForm = (data) => {
        const newData = {
            ...data,
            uk_name
        };

        dispatch(showAuthLoader());
        dispatch(syncUser(newData));
    };

    const handleSelectItem = (data) => {
        const { name, value } = data;
        setFormData({
            ...formData,
            [name]: value
        });
        dispatch(ukAddressListLoaded({ data: [] }));
        handleNextStep();
    };

    // выбираем ук на этапе выбора дома
    const handleSelectUK = (data, item) => {
        handleSelectItem(data);
        setUkName(item.uk_name);
    };

    const timerWait = (noWait = false, functionHandle = () => {}) => {
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }

        if (noWait) {
            setLoading(true);
            functionHandle();
        } else {
            timer = setTimeout(() => {
                timer = null;
                setLoading(true);
                functionHandle();
            }, 1000);
        }
    };

    useEffect(() => {
        dispatch(setValidationFields({}));
    }, []);

    useEffect(() => {
        if (syncUserStatus) {
            dispatch(showMessage('success', 'syncUser.sync.krasinform.success', null, true));
            handleResetStep();
            dispatch(changeSyncUserStatus());
            history.push('/');
        }
    }, [syncUserStatus]);

    useEffect(() => {
        if (addressListLoaded) {
            setLoading(false);
            dispatch(ukAddressListLoadedStatus());
        }
    }, [addressListLoaded]);

    const getLabelStep = (label, selected) => (
        <>
            {label}
            {!!selected === true && ` (${selected})`}
        </>
    );

    const steps = [
        {
            label: getLabelStep(<IntlMessages id="syncUser.label.selectCity" />, formData.city_name),
            key: 'city_name',
            component: (
                <FindField
                    loading={loading}
                    label={<IntlMessages id="appModule.search" />}
                    name="city_name"
                    onSelectedItem={handleSelectItem}
                    onChangeField={({ value, page, limit }, noWait) => {
                        timerWait(noWait, () => dispatch(fetchUKCitiesList(page, limit, value)));
                    }}
                />
            )
        },
        {
            label: getLabelStep(<IntlMessages id="syncUser.label.selectStreet" />, formData.street_name),
            key: 'street_name',
            component: (
                <FindField
                    loading={loading}
                    label={<IntlMessages id="appModule.search" />}
                    name="street_name"
                    onSelectedItem={handleSelectItem}
                    onChangeField={({ value, page, limit }, noWait) => {
                        timerWait(noWait, () => dispatch(fetchUKStreetList(page, limit, formData.city_name, value)));
                    }}
                />
            )
        },
        {
            label: getLabelStep(<IntlMessages id="syncUser.label.selectHome" />, formData.hnumber),
            key: 'hnumber',
            component: (
                <FindField
                    loading={loading}
                    label={<IntlMessages id="appModule.search" />}
                    name="hnumber"
                    onSelectedItem={handleSelectUK}
                    onChangeField={({ value, page, limit }, noWait) => {
                        timerWait(noWait, () => dispatch(fetchUKAddressList(page, limit, formData.city_name, formData.street_name, value)));
                    }}
                />
            )
        },
        {
            label: <IntlMessages id="syncUser.label.enterYourDetails" />,
            key: 'userinfoform',
            component: (
                <Form
                    onSubmitForm={handleSubmitForm}
                />
            )
        }
    ];

    return (
        <>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Grid item xs={12} md={7} lg={6}>
                    <Card elevation={3}>
                        <div className="logo-lg pt-4">
                            <span dangerouslySetInnerHTML={{ __html: logo }} />
                            <span>
                                <IntlMessages id="syncUser.syncKrasinform" />
                            </span>
                        </div>

                        <CardBody>
                            {/*<CardTitle>*/}
                            {/*    <IntlMessages id={'sidebar.dashboard.sync.krasinform'} />*/}
                            {/*</CardTitle>*/}

                            <div className={classes.root}>
                                <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepper}>
                                    {steps.map((item) => (
                                        <Step key={item.key}>
                                            <StepLabel>
                                                {item.label}
                                            </StepLabel>
                                            <StepContent>

                                                <div>
                                                    {item.component}
                                                </div>

                                                {activeStep > 0 && (
                                                    <div className={classes.actionsContainer}>
                                                        <Button
                                                            onClick={handleResetStep}
                                                            variant="contained"
                                                            color="secondary"
                                                            //fullWidth
                                                        >
                                                            <IntlMessages id="appModule.reset" />
                                                        </Button>
                                                    </div>
                                                )}

                                            </StepContent>
                                        </Step>
                                    ))}
                                </Stepper>
                            </div>
                        </CardBody>

                        {loader && <LinearProgress />}
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default withRouter(SyncUserForm);
