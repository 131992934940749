import api from 'util/api/api.lang.methods';
import messagesRU from '../locales/ru_RU.json';

const ruLang = async () => {
    try {
        const ruMessages = await api.getTranslation('ru');
        return {
            messages: {
                ...ruMessages
            },
            locale: 'ru-RU'
        };
    } catch (e) {
        return {
            messages: {
                ...messagesRU
            },
            locale: 'ru-RU'
        };
    }
};
export default ruLang;
