import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from './Settings';
import Auth from './Auth';
import Menu from './Menu';
import News from './News';
import Meters from './Meters';
import Payments from './Payments';
import Messages from './Messages';
import Articles from './Articles';
import Votes from './Votes';
import Company from './Company';
import Appeal from './Appeal';
import Debtors from './Debtors';
import Translate from './Translate';
import Uk from './UK';
import Validation from './Validation';
import Market from './Market';
import DaData from './DaData';
import Admin from './Admin';
import Indebtedness from './Indebtedness';
import Order from './Order';
import Notifications from './Notifications';
import Ws from './Ws';

export default (history) => combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    menu: Menu,
    news: News,
    meters: Meters,
    payments: Payments,
    messages: Messages,
    articles: Articles,
    votes: Votes,
    company: Company,
    appeal: Appeal,
    debtors: Debtors,
    translate: Translate,
    uk: Uk,
    validation: Validation,
    market: Market,
    dadata: DaData,
    admin: Admin,
    indebtedness: Indebtedness,
    order: Order,
    notifications: Notifications,
    ws: Ws,
});
