import {
    all, call, put, takeLatest, select
} from 'redux-saga/effects';

import {
    INDEBTEDNESS_GET_LIST
} from 'constants/ActionTypes';
import {
    setError,
    listLoaded
} from 'actions/Indebtedness';
import { getActionForError } from 'util/errors';
import api from '../util/api/api.indebtedness.methods';

export const stateSelector = (state) => state.payments;
export const stateAuthSelector = (state) => state.auth;

function* fetchListSaga({ payload: params }) {
    const { authUser } = yield select(stateAuthSelector);
    const { access_token } = authUser;
    try {
        const request = yield call(api.fetchList, access_token, params);
        yield put(listLoaded(request));
    } catch (error) {
        yield put(getActionForError(error));
        yield put(setError(error.message));
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(INDEBTEDNESS_GET_LIST, fetchListSaga),
    ]);
};
