import React from 'react';
import {
    IconButton, Avatar
} from '@material-ui/core';

const ChatHeader = ({ person, onHideChat }) => {
    const avatarName = `${person?.first_name?.[0] || ''}${person?.last_name?.[0] || ''}`;
    return (
        <div className="chat-main-header">
            <div className="chat-main-header-info">

                <div className="chat-avatar mr-2">
                    <div className="chat-avatar-mode">
                        <Avatar
                            className="chat-user text-white bg-primary"
                        >
                            {avatarName}
                        </Avatar>

                        <span className="chat-mode" />
                    </div>
                </div>

                {person && (
                    <div className="chat-contact-name">
                        {person.first_name}
                        {' '}
                        {person.last_name}
                    </div>
                )}
            </div>

        </div>
    );
};

export default ChatHeader;
