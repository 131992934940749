import React, { useState } from 'react';
import { Collapse, List, ListItem, ListItemText, IconButton } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        minWidth: '30px',
        fontSize: '18px',
    },
    children: {
        paddingLeft: 25,
    },
    item: {
        fontSize: '0.875rem',
        lineHeight: 1.43
    },
    selected: {
        backgroundColor: '#bc9131'
    }
});

const CategoryItem = ({ item, handleClick, selected = {}, hasChildren, isChildren }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const onItemClick = (el) => {
        if (hasChildren) {
            setOpen(!open)
        }
        handleClick(el);
    }

    return (
        <div className={isChildren && classes.children}>
            <ListItem
                selected={item.id === selected.id}
                onClick={() => onItemClick(item)}
                button
                dense
            >
                <ListItemText primary={item.name} />
                {hasChildren && (open ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />)}
            </ListItem>
            {hasChildren && (
                <Collapse in={open} timeout="auto">
                    {item.childs.map((child) => (
                        <CategoryItem
                            key={child.id}
                            item={child}
                            hasChildren={child.childs?.length > 0}
                            isChildren={true}
                            handleClick={handleClick}
                        />
                    ))}
                </Collapse>
            )}
        </div>
    );

}

export default CategoryItem;