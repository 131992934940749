import React, { useEffect } from 'react';
//import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import cyrillicToTranslit from 'cyrillic-to-translit-js';
import Typography from '@material-ui/core/Typography';
import {
    ExpansionAccordion,
    ExpansionAccordionDetails,
    ExpansionAccordionSummary
} from './style';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import IntlMessages from 'util/IntlMessages';
import FlatList from '../../FlatList';

const Detail = ({
    list,
    ...props
}) => {
    const [expanded, setExpanded] = React.useState(null);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const expandIcon = (isExpanded) => (
        <div style={{ fontSize: 16 }}>
            <span style={{ fontSize: 16 }}>
                {isExpanded ? <IntlMessages id="appModule.hide" /> : <IntlMessages id="appModule.show" />}
            </span>
            {isExpanded ? <RemoveIcon fontSize="small" /> : <AddIcon fontSize="small" />}
        </div>
    );

    useEffect(() => {
        setExpanded(null);
    }, [list]);

    return (
        <>
            {Object.keys(list).map((key) => {
                //{Object.keys(data).map((key) => {
                const keyProp = cyrillicToTranslit().transform(key, '_');
                const { end_credit, end_debit, flats = [] } = list[key];
                const titles = {
                    key,
                    end_debit,
                    end_credit
                };
                // `${item.account_uk_identifier}${item.period_at}`
                return (
                    <ExpansionAccordion
                        key={keyProp}
                        expanded={expanded === keyProp}
                        onChange={handleChange(keyProp)}
                    >
                        <ExpansionAccordionSummary
                            expandIcon={expandIcon(expanded === keyProp)}
                            classes={{ content: 'justify-content-between' }}
                            //expanded={false}
                        >
                            {Object.keys(titles).map((keyTitle) => (
                                <div key={keyTitle} className="d-flex" style={document.body.clientWidth > 600 ? { width: `${100 / Object.keys(titles).length}%` } : {}}>
                                    <Typography>
                                        {titles[keyTitle]}
                                    </Typography>
                                </div>
                            ))}
                        </ExpansionAccordionSummary>
                        <ExpansionAccordionDetails classes={{ root: 'border-top flex-column' }}>
                            {expanded === keyProp && (<FlatList list={flats} {...props} />)}
                        </ExpansionAccordionDetails>
                    </ExpansionAccordion>
                )}
            )}
        </>
    );
};

export default Detail;
