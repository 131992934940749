import {
    all, call, select, put, takeLatest
} from 'redux-saga/effects';

import {
    TRANSLATE_FETCH_LIST,
    TRANSLATE_CREATE,
    TRANSLATE_EDIT,
    TRANSLATE_DELETE,
    TRANSLATE_FETCH_WITH_ID,
    TRANSLATE_FETCH_SERVICE_LIST
} from 'constants/ActionTypes';
import {
    translateListLoaded,
    translateListLoadedStatus,
    translateWhithIDLoaded,
    setError,
    translateSavedSuccess,
    translateServiceListLoaded
} from 'actions/Translate';
import { showMessage } from 'actions/Messages';
import { getActionForError } from 'util/errors';
import api from 'util/api/api.translate.methods';

export const stateSelector = (state) => state.translate;
export const stateAuthSelector = (state) => state.auth;

function* fetchTranslateListSaga({ payload }) {
    const { page, limit, query, service } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.getTranslateList, page, limit, service, query, authUser?.access_token);
        yield put(translateListLoaded(response));
        yield put(translateListLoadedStatus(true));
    } catch (error) {
        yield put(getActionForError(error));
        yield put(translateListLoadedStatus(true));
    }
}

function* fetchTranslateServiceListSaga() {
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.getTranslateServiceList, authUser?.access_token);
        yield put(translateServiceListLoaded(response));
        //yield put(translateListLoadedStatus(true));
    } catch (error) {
        yield put(getActionForError(error));
        //yield put(translateListLoadedStatus(true));
    }
}

function* fetchTranslateWhithIDSaga({ payload }) {
    yield put(translateListLoadedStatus(true));
    const { id } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.getTranslateWithId, id, authUser?.access_token);
        yield put(translateWhithIDLoaded(response));
    } catch (error) {
        yield put(getActionForError(error));
    }
}

function* createTranslateSaga({ payload }) {
    const { data }  = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.createTranslate, data, authUser.access_token);
        yield put(showMessage('success', 'translate.successfullyCreate', null, true));
        yield put(translateSavedSuccess(true));
    } catch (error) {
        yield put(translateSavedSuccess(false));
        yield put(getActionForError(error));
    }
}

function* editTranslateSaga({ payload }) {
    const { id, data } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.editTranslate, id, data, authUser.access_token);
        yield put(showMessage('success', 'translate.successfullySaved', null, true));
        yield put(translateSavedSuccess(true));
    } catch (error) {
        yield put(translateSavedSuccess(false));
        yield put(getActionForError(error));
    }
}

function* deleteTranslateSaga({ payload }) {
    const { id }  = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.deleteTranslate, id, authUser.access_token);
        yield put(showMessage('success', 'translate.successfullyDelete', null, true));
        yield put(translateSavedSuccess(true));
    } catch (error) {
        yield put(translateSavedSuccess(false));
        yield put(getActionForError(error));
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(TRANSLATE_FETCH_LIST, fetchTranslateListSaga),
        takeLatest(TRANSLATE_FETCH_SERVICE_LIST, fetchTranslateServiceListSaga),
        takeLatest(TRANSLATE_FETCH_WITH_ID, fetchTranslateWhithIDSaga),
        takeLatest(TRANSLATE_CREATE, createTranslateSaga),
        takeLatest(TRANSLATE_EDIT, editTranslateSaga),
        takeLatest(TRANSLATE_DELETE, deleteTranslateSaga)
    ]);
}
