import axios from 'axios';
import cookie from 'react-cookies';
import { cookieOption } from 'constants/Config';

const instance = axios.create({ timeout: '15000' });

export const setAuthToken = (access_token) => {
    if (access_token) {
        // Apply to every request
        instance.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
    } else {
        // 'Delete auth header'
        delete instance.defaults.headers.common.Authorization;
    }
};

instance.defaults.headers = {
    ...instance.defaults.headers,
    uk: 'flagman',
    service: 'zkhu',
    //uk_name: 'flagman',
    //account_number: 0
};

export const setUKHeaders = (uk) => {
    // if (uk) {
    //     const uk_name = uk.uk_name;
    //     instance.defaults.headers = {
    //         ...instance.defaults.headers,
    //         uk: uk_name
    //     };
    // }
    /*const needRefresh = cookie.load('needRefresh');
    cookie.save(
        'currentUK',
        uk,
        {
            expires: new Date(parseInt(needRefresh)),
            ...cookieOption
        }
    );*/
    if (Object.keys(uk).length > 0) {
        // Apply to every request
        instance.defaults.headers.common['uk'] = uk.uk_name;
        instance.defaults.headers.common['account_number'] = uk.account_number;
    }
};

export default instance;
