import addErrorHandling from 'util/errors/error.handling';
import urls from './api.urls';
import instance from '../axios.config';

const ApiAppealMethods = {
    fetchAddress: async (params) => {
        const response = await instance.get(urls.fetchAddress(), { params });
        return response?.data?.data || [];
    }
};

export default addErrorHandling(ApiAppealMethods);