import {
    FETCH_VOTES_LIST,
    LOAD_VOTE_WITH_ID,
    VOTES_LIST_LOADED_SUCCESS,
    VOTES_LIST_LOADED_SUCCESS_STATUS,
    SAVE_CURRENT_VOTE,
    SET_VOTE_ERROR,
    CREATE_VOTE,
    CHANGE_VOTE_STATUS,
    EDIT_VOTE,
    DELETE_VOTE,
    VOTE_SUCCESSFULLY_SAVED,
    FETCH_VOTES_STATUSES,
    VOTES_STATUSES_LOADED,
    FETCH_VOTES_RELATIONS,
    VOTES_RELATIONS_LOADED,
    SAVE_VOTE_RESULT,
    CLEAR_VOTE_LOADED_ID
} from 'constants/ActionTypes';

export const fetchVotesList = (page, limit) => ({
    type: FETCH_VOTES_LIST,
    payload: { page, limit }
});

export const fetchVoteWithId = (id) => ({
    type: LOAD_VOTE_WITH_ID,
    payload: { id }
});

export const votesListLoaded = (list) => ({
    type: VOTES_LIST_LOADED_SUCCESS,
    payload: list
});

export const votesListLoadedStatus = (bool = false) => ({
    type: VOTES_LIST_LOADED_SUCCESS_STATUS,
    payload: bool
});

export const voteLoaded = (vote) => ({
    type: SAVE_CURRENT_VOTE,
    payload: vote
});

export const setError = (bool) => ({
    type: SET_VOTE_ERROR,
    payload: bool
});

export const fetchStatuses = () => ({
    type: FETCH_VOTES_STATUSES
});

export const fetchVotesRelations = () => ({
    type: FETCH_VOTES_RELATIONS
});

export const statusesLoaded = (statuses) => ({
    type: VOTES_STATUSES_LOADED,
    payload: statuses
});

export const relationsLoaded = (relations) => ({
    type: VOTES_RELATIONS_LOADED,
    payload: relations
});

export const createVote = (data) => ({
    type: CREATE_VOTE,
    payload: { data }
});

export const editVote = (id, data) => ({
    type: EDIT_VOTE,
    payload: { id, data }
});

export const changeVoteStatus = (id, status) => ({
    type: CHANGE_VOTE_STATUS,
    payload: { id, status }
});

export const deleteVote = (id) => ({
    type: DELETE_VOTE,
    payload: { id }
});

export const voteSavedSuccess = (bool = false, action = true) => ({
    type: VOTE_SUCCESSFULLY_SAVED,
    payload: { bool, action }
});

export const saveVoteResult = (data) => ({
    type: SAVE_VOTE_RESULT,
    payload: data
});

export const clearVoteLoadedId = () => ({
    type: CLEAR_VOTE_LOADED_ID
});
