import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchLandingMenu,
    menuSuccesfullySaved,
} from 'actions/Menu';

const useMenuList = (scope) => {
    const dispatch = useDispatch();
    const {
        landingMenu, menuSaved
    } = useSelector(({ menu }) => menu);

    useEffect(() => {
        if (scope) {
            dispatch(fetchLandingMenu(scope));
        }
    }, [scope, dispatch]);

    useEffect(() => {
        if (menuSaved) {
            dispatch(menuSuccesfullySaved());
            dispatch(fetchLandingMenu(scope));
        }
    }, [scope, menuSaved, dispatch]);

    return landingMenu;
};

export default useMenuList;
