import addErrorHandling from 'util/errors/error.handling';
import Urls from './api.urls';
import instance from './axios.config';

// const instance = axios.create({
//     // Reject only if the status code is greater than or equal to 500
//     // validateStatus: status => status < 500,
// });

const ApiAppealMethods = {
    getAppealList: async (page, limit, data, access_token) => {
        const response = await instance.get(Urls.getAppealList(page, limit),
            {
                params: data,
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    getAppealStatuses: async (access_token) => {
        const response = await instance.get(Urls.getAppealStatuses(),
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    getAppealTypes: async (access_token) => {
        const response = await instance.get(Urls.getAppealTypes(),
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    getAppealWithId: async (id, access_token) => {
        const response = await instance.get(Urls.getAppealWithId(id),
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    createAppeal: async (data, access_token) => {
        const response = await instance.post(Urls.createAppeal(),
            data,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    editAppeal: async (id, data, access_token) => {
        const response = await instance.put(Urls.editAppeal(id),
            data,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    watchAppeal: async (id, access_token) => {
        const response = await instance.post(Urls.watchAppeal(id),
            {},
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    stopWatchAppeal: async (id, access_token) => {
        const response = await instance.post(Urls.stopWatchAppeal(id),
            {},
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    }
};

export default addErrorHandling(ApiAppealMethods);
