import React from 'react';
import moment from 'moment';
import IntlMessages from 'util/IntlMessages';

const DateTimeInfo = ({ title = '', time, format = 'DD.MM.YYYY' }) => (
    <span>
        <IntlMessages id={title} />
        {' '}
        {moment(time).format(format)}
    </span>
);

export default DateTimeInfo;
