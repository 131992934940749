import apiUrls from './api.urls';
import instance from '../axios.config';
import addErrorHandling from "../../errors/error.handling";

const ApiMethods = {
    fetchList: async (params) => {
        const response = await instance.get(apiUrls.fetchList(), {
            params
        });

        return response?.data;
    },

    setRead: async (user_id, id) => {
        const response = await instance.put(apiUrls.setRead(id), { user_id });

        return response?.data;
    },

    setReadAll: async (user_id) => {
        const response = await instance.put(apiUrls.setReadAll(), { user_id });

        return response?.data;
    },

    fetchCountActive: async (params) => {
        const response = await instance.get(apiUrls.fetchCountActive(), {
            params
        });

        return response?.data;
    },

    loadFreeSpaceServer: async () => {
        const response = await instance.get(apiUrls.loadFreeSpaceServer());
        return response?.data;
    }
};

export default addErrorHandling(ApiMethods);
