import addErrorHandling from 'util/errors/error.handling';
import instance from './axios.config';
import Urls from './api.urls';

// const instance = axios.create({
//     // Reject only if the status code is greater than or equal to 500
//     // validateStatus: status => status < 500,
//     // headers: { 'uk-name': 'kvartal',
//     // 'account-number': 366963 }
// });

const ApiUserMethods = {
    getUser: async (access_token) => {
        const response = await instance.get(Urls.getUserData(), {
            headers: {
                Authorization: `Bearer ${access_token}`
            }
        });
        return response.data.data;
    },

    syncUser: async (account_num, password, uk_name, access_token) => {
        const response = await instance.post(Urls.syncUser(),
            { account_num, password },
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                    uk_name,
                    // account_number: 0
                }
            });
        return response.data;
    },

    changePassword: async (old_password, password, password_confirmation, user) => {
        const { access_token } = user;
        const response = await instance.post(Urls.changePassword(),
            { old_password, password, password_confirmation },
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    }
};

export default addErrorHandling(ApiUserMethods);
