import React from 'react';
import { Typography } from '@material-ui/core';

const LineIndicator = ({
    width, color, value, title, isPercent = false
}) => (
    <div className="jr-line-indi-info">
        <p className="line_indicator_answer-text">
            {title}
        </p>
        <div className="flex-grow-1 ml-2">
            <div
                className="jr-line-indi"
                style={{
                    width: `${Number.parseInt(width)}%`,
                    backgroundColor: color,
                    height: 8,
                    borderRadius: 4
                }}
            />
        </div>

        <span className="jr-line-indi-count ml-2">
            {value}
            {isPercent && (
                <>
                    {'%'}
                </>
            )}
        </span>
    </div>
);

export default LineIndicator;
