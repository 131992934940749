import {
    INIT_URL,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SIGNIN_FACEBOOK_USER,
    SIGNIN_FACEBOOK_USER_SUCCESS,
    SIGNIN_GITHUB_USER,
    SIGNIN_GITHUB_USER_SUCCESS,
    SIGNIN_GOOGLE_USER,
    SIGNIN_GOOGLE_USER_SUCCESS,
    SIGNIN_TWITTER_USER,
    SIGNIN_TWITTER_USER_SUCCESS,
    SIGNIN_USER,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER,
    SIGNOUT_USER_SUCCESS,
    SIGNUP_USER,
    SIGNUP_USER_SUCCESS,
    REFRESH_TOKEN,
    SAVE_REFRESH_TOKEN_TIME,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    SAVE_USER,
    SET_VALIDATION_FIELDS,
    FETCH_SOCNET_LIST,
    SOCNET_LIST_LOAD,
    SOCNET_LIST_LOAD_CLEAR,
    SIGNIN_KRASINFORM,
    SAVE_CURRENT_UK,
    FETCH_UK_LIST,
    UK_LIST_LOADED,
    LOAD_UK_INFO,
    UK_INFO_LOADED,
    SET_CHANGE_CURRENT_UK_STATUS,
    SYNC_TOKEN
} from 'constants/ActionTypes';
import cookie from 'react-cookies';
import { cookieOption } from 'constants/Config';
import { setUKHeaders, setAuthToken } from 'util/api/axios.config';

export const setChangeCurrentUKStatus = (bool = false) => ({
    type: SET_CHANGE_CURRENT_UK_STATUS,
    bool
});

export const setInitUrl = (url) => ({
    type: INIT_URL,
    payload: url
});

//SIGN UP
export const userSignUp = (user) => ({
    type: SIGNUP_USER,
    payload: user
});

export const userSignUpSuccess = () => ({
    type: SIGNUP_USER_SUCCESS
});

export const setValidationFields = (validationMessage) => ({
    type: SET_VALIDATION_FIELDS,
    payload: validationMessage
});

//SIGN IN
export const userSignIn = (user) => ({
    type: SIGNIN_USER,
    payload: user
});

export const userSignInSuccess = (authUser) => ({
    type: SIGNIN_USER_SUCCESS,
    payload: authUser
});
export const saveUserData = (user, remember = true) => {
    setAuthToken(user.access_token); // TODO - this doesn't work
    return ({
        type: SAVE_USER,
        payload: { user, remember }
    });
};
export const userSignInKrasinform = (user) => ({
    type: SIGNIN_KRASINFORM,
    payload: user
});
export const saveCurrentUK = (uk) => {
    setUKHeaders(uk);
    return ({
        type: SAVE_CURRENT_UK,
        uk
    });
};
//SIGN OUT
export const userSignOut = (logoutall) => {
    setUKHeaders({
        uk_name: 'public',
        account_number: 0
    });
    return ({
        type: SIGNOUT_USER,
        payload: { logoutall }
    });
};
export const userSignOutSuccess = () => {
    cookie.remove('authUser', cookieOption);
    cookie.remove('needRefresh', cookieOption);
    cookie.remove('currentUK', cookieOption);
    return ({
        type: SIGNOUT_USER_SUCCESS
    });
};
//REFRESH TOKEN
export const refreshToken = (tokens) => ({
    type: REFRESH_TOKEN,
    payload: tokens
})
export const syncToken = (tokens) => ({
    type: SYNC_TOKEN,
    payload: tokens
});
export const saveRefreshTime = (time) => ({
    type: SAVE_REFRESH_TOKEN_TIME,
    payload: time
});
// RESET PASSWORD
export const resetPassword = (username) => ({
    type: RESET_PASSWORD,
    payload: username
});
export const resetPasswordSuccess = (bool = true) => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: bool
});

// HELPERS
export const showAuthLoader = () => ({
    type: ON_SHOW_LOADER
});
export const hideAuthLoader = () => ({
    type: ON_HIDE_LOADER
});

// SIGN IN WITH SOCIALS
export const userGoogleSignIn = () => ({
    type: SIGNIN_GOOGLE_USER
});
export const userGoogleSignInSuccess = (authUser) => ({
    type: SIGNIN_GOOGLE_USER_SUCCESS,
    payload: authUser
});
export const userFacebookSignIn = () => ({
    type: SIGNIN_FACEBOOK_USER
});
export const userFacebookSignInSuccess = (authUser) => ({
    type: SIGNIN_FACEBOOK_USER_SUCCESS,
    payload: authUser
});

export const userTwitterSignIn = () => ({
    type: SIGNIN_TWITTER_USER
});
export const userTwitterSignInSuccess = (authUser) => ({
    type: SIGNIN_TWITTER_USER_SUCCESS,
    payload: authUser
});
export const userGithubSignIn = () => ({
    type: SIGNIN_GITHUB_USER
});
export const userGithubSignInSuccess = (authUser) => ({
    type: SIGNIN_GITHUB_USER_SUCCESS,
    payload: authUser
});

export const fetchSocnetList = () => ({
    type: FETCH_SOCNET_LIST
});
export const socnetListLoadClear = () => ({
    type: SOCNET_LIST_LOAD_CLEAR
});
export const socnetListLoad = (list) => ({
    type: SOCNET_LIST_LOAD,
    payload: list
});

export const loadUKInfo = (name) => ({
    type: LOAD_UK_INFO,
    payload: { name }
});

export const ukInfoLoaded = (data) => ({
    type: UK_INFO_LOADED,
    payload: data
});

export const fetchUKList = () => ({
    type: FETCH_UK_LIST
});

export const ukListLoaded = (list) => ({
    type: UK_LIST_LOADED,
    payload: list
});
