import React from 'react';
import { List } from '@material-ui/core';
import NavMenuItem from './NavMenuItem';
import NavCollapse from './NavCollapse';
import NavSection from './NavSection';

const NavList = ({ list, component = 'div', className=""}) => (
    <List component={component} className={className} disablePadding>
        {
            list.map((item, index) => {
                switch (item.type) {
                case 'section':
                    return <NavSection {...item} key={index} />;
                case 'collapse':
                    return <NavCollapse {...item} key={index} />;
                case 'item':
                    return <NavMenuItem {...item} key={index} />;
                }
                return null;
            })
        }
    </List>
);

export default NavList;
