import React, { useEffect, useState } from 'react';
import { TextField, FormGroup, InputAdornment, IconButton } from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import CardBox from 'components/Card';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const Search = ({
    search = '',
    onChangeSearch = (value) => {},
    timeout = 800
}) => {
    let timer = null;
    const [closeVisible, setCloseVisible] = useState(false);
    const [searchField, setSearch] = useState(search);

    /*useEffect(() => {
        if (search.length) {
            setSearch(search);
        }
    },[search]);*/

    const changeSearch = (value) => {
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
        timer = setTimeout(() => {
            onChangeSearch(value);
        }, timeout);
    }

    const handleSearchChange = (event) => {
        const { value } = event.target;
        setSearch(value);
        setCloseVisible(value.length > 0);
        changeSearch(value);
    };

    const handleSortReset = () => {
        setSearch('');
        setCloseVisible(false);
        changeSearch('');
    };

    const keyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            const { value } = event.target;
            if (timer) {
                clearTimeout(timer);
                timer = null;
            }
            onChangeSearch(value);
        }
    };

    return (
        <CardBox
            className="d-none"
            title={<IntlMessages id="appModule.filter" />}
        >
            <form className="row" noValidate autoComplete="off" style={{ maxWidth: 500, marginBottom: '-8px' }}>
                <FormGroup className="col-12">
                    <TextField
                        value={searchField}
                        label={<IntlMessages id="appModule.search" />}
                        name="query"
                        onChange={handleSearchChange}
                        onKeyDown={keyDown}
                        type="text"
                        variant="outlined"
                        size="small"
                        className="hide-spin"
                        InputProps={{
                            /*startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                </InputAdornment>
                            ),*/
                            endAdornment: (
                                <>
                                    {closeVisible && (
                                        <InputAdornment position="end">
                                            <IconButton
                                                size="small"
                                                //aria-label="Очистить"
                                                onClick={handleSortReset}
                                                onMouseDown={(event) => { event.preventDefault(); }}
                                                edge="end"
                                            >
                                                <ClearIcon fontSize="small" />
                                            </IconButton>
                                        </InputAdornment>
                                    )}
                                </>
                            )
                        }}
                    />
                </FormGroup>
            </form>
        </CardBox>
    );
};

export default Search;
