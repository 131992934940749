import { all, put, call, takeLatest } from 'redux-saga/effects';
import * as types from 'constants/Notification';
import * as actions from 'actions/Notification';
import api from 'util/api/notifications/api.methods';

// данные
function* loadListSaga({ payload }) {
    const { page } = payload;
    yield put(actions.setLoadingNotification(true));
    const response = yield call(api.fetchList, payload);
    if (response) {
        yield put(actions.loadedListNotification(response, page === 1));
    }
    yield put(actions.setLoadingNotification(false));
}

// загрузка списка для конкретного сервиса
function* loadServiceListSaga({ payload }) {
    const { service } = payload;
    yield put(actions.setLoadingNotification(true));
    const response = yield call(api.fetchList, payload);
    if (response) {
        yield put(actions.loadedServiceListNotification(response, service));
    }
    yield put(actions.setLoadingNotification(false));
}

function* loadCountActiveSaga({ payload }) {
    const response = yield call(api.fetchCountActive, payload);
    if (response) {
        yield put(actions.loadedCountActiveNotification(response));
    }
}

function* changeReadSaga({ payload }) {
    const {
        user_id,
        id,
    } = payload;
    const response = yield call(api.setRead, user_id, id);
    if (response?.success) {
        yield put(actions.setReadNotification(id));
    }
}

function* changeReadAllSaga({ payload }) {
    const user_id = payload;
    const response = yield call(api.setReadAll, user_id);
    if (response?.success) {
        yield put(actions.setReadAllNotification());
    }
}

function* loadFreeSpaceServerSaga({ payload }) {
    const response = yield call(api.loadFreeSpaceServer, payload);
    if (response) {
        yield put(actions.loadedFreeSpaceServer(response.data));
    }
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_LIST, loadListSaga),
        takeLatest(types.LOAD_COUNT_ACTIVE, loadCountActiveSaga),
        takeLatest(types.CHANGE_READ, changeReadSaga),
        takeLatest(types.CHANGE_READ_ALL, changeReadAllSaga),
        takeLatest(types.LOAD_SERVICE_LIST, loadServiceListSaga),
        takeLatest(types.LOAD_FREE_SPACE_SERVER, loadFreeSpaceServerSaga),
    ]);
}
