const languageData = [
    {
        languageId: 'russian',
        locale: 'ru',
        name: 'Русский',
        icon: 'ru'
    },
    {
        languageId: 'english',
        locale: 'en',
        name: 'English',
        icon: 'us'
    }
];
export default languageData;
