import React, { useEffect, useState } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {Route, Switch, useHistory} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from '@material-ui/styles';
import 'assets/vendors/style';
import RTL from 'util/RTL';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
    setInitUrl, refreshToken, resetPasswordSuccess, saveUserData, fetchUKList, ukInfoLoaded
} from 'actions/Auth';
import { hideMessage } from 'actions/Messages';
import IntlMessages from 'util/IntlMessages';
import { wsConnect, wsDisconnect } from 'actions/Websockets';
import { PUBLIC_THEME } from 'constants/ThemeColors';
import publicTheme from './themes/publicTheme';
import getAppLocale from '../lngProvider';
import SignIn from './SignIn';
import SignUp from './SignUp';
import SignOut from './SignOut';
import ForgotPassword from './ForgotPassword';
import AppLayout from './AppLayout';
import { useCheckUserScopes, userScopesList } from '../components/Helpers/userScopes';
// import useWsConnect from '../components/Helpers/ws/useWsConnect';
// import useWs from '../components/Helpers/ws/useWs';

const App = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    // useWsConnect();

    const [timerId, setTimerId] = useState(null);
    const {
        locale, isDirectionRTL
    } = useSelector(({ settings }) => settings);
    const {
        authUser, needRefreshTime, passwordReseted, isAdmin, wsConnected, ukList, currentUK, ukInfo
    } = useSelector(({ auth }) => auth);
    const {
        showMessage, alertMessage
    } = useSelector(({ messages }) => messages);
    const { match } = props;
    const [appLocale, setAppLocale] = useState({ ru: { locale: 'ru-RU', messages: {} } });
    const initURL = '/';
    const isUser = useCheckUserScopes([userScopesList.user]);

    useEffect(() => {
        if (authUser !== null) {
            dispatch(saveUserData(authUser));
        }
        const getLocale = async () => {
            const currentLocale = await getAppLocale();
            setAppLocale(currentLocale);
        };
        getLocale();

        dispatch(fetchUKList());
    }, []);

    useEffect(() => {
        const findUK = ukList.find((el) => el.value === currentUK.uk_name);
        if (findUK && findUK.name !== ukInfo.name) {
            dispatch(ukInfoLoaded({
                logo: findUK.logo,
                name: findUK.name
            }));
        }
    }, [ukList, currentUK, ukInfo]);

    useEffect(() => {
        if (!wsConnected && authUser !== null && authUser.info?.identifier) {
            dispatch(wsConnect(authUser, isAdmin, history));
        }
    }, [wsConnected, authUser, isAdmin, dispatch]);

    useEffect(() => {
        if (showMessage && alertMessage) {
            if (alertMessage.translate) {
                NotificationManager[alertMessage.type](<IntlMessages id={alertMessage.message} />, <IntlMessages id={alertMessage.title} />)
            } else {
                NotificationManager[alertMessage.type](alertMessage.message, alertMessage.title)
            }
            setTimeout(() => {
                dispatch(hideMessage());
            }, 3000);
        }
    // eslint-disable-next-line react/destructuring-assignment
    }, [showMessage]);

    useEffect(() => {
        if (passwordReseted) {
            setTimeout(() => {
                dispatch(resetPasswordSuccess(false));
            }, 100);
            props.history.push('/signin');
        }
    }, [passwordReseted]);

    useEffect(() => {
        if (authUser !== null) {
            if (!showMessage && props.location.pathname.indexOf('signin') >= 0) {
                props.history.push('/');
            }
        }
    }, [authUser]);

    useEffect(() => {
        // если пользователь юзер без ЛС - выводим ему сообщение
        if (isUser) {
            NotificationManager.success('Для привязки лицевого счета обратитесь лично в клиентский отдел', '');
        }
    }, [isUser]);

    useEffect(() => {
        window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
        if (initURL === '') {
            dispatch(setInitUrl(props.history.location.pathname));
        }
    // eslint-disable-next-line react/destructuring-assignment
    }, [dispatch, initURL, props.history.location.pathname, props.location.search]);

    useEffect(() => {
        const { expires_in, refresh_token, access_token } = authUser || {};
        if (refresh_token) {
            let timeToRefresh = expires_in * 0.6 * 1000;
            if (needRefreshTime > Date.now()) {
                timeToRefresh = needRefreshTime - Date.now();
            }
            if (timerId) {
                clearTimeout(timerId);
            }
            setTimerId(setTimeout(() => {
                console.log('Token almost expired. Need to be refreshed');
                dispatch(refreshToken({ refresh_token, access_token }));
            }, Math.min(2147483647, timeToRefresh))); // 2147483647 is a maximum setTimout delay
        }
        return () => clearTimeout(timerId);
    }, [authUser, needRefreshTime]);

    const getColorTheme = (uk) => {
        document.body.classList.remove(PUBLIC_THEME);
        // switch (`custom-${uk}`) {
        // default: {
            document.body.classList.add(PUBLIC_THEME);
            return createMuiTheme(publicTheme);
        // }
        // }
    };

    const applyTheme = getColorTheme(/*currentUK.uk_name*/);

    if (isDirectionRTL) {
        applyTheme.direction = 'rtl';
        document.body.classList.add('rtl');
    } else {
        document.body.classList.remove('rtl');
        applyTheme.direction = 'ltr';
    }
    const currentAppLocale = appLocale[locale.locale];
    return (
        <ThemeProvider theme={applyTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <IntlProvider
                    locale={currentAppLocale.locale}
                    messages={currentAppLocale.messages}
                >
                    <RTL>
                        <div className="app-main">
                            <Switch>
                                <Route exact path="/signin/:uk?" component={SignIn} />
                                <Route exact path="/signup" component={SignUp} />
                                <Route path="/logout" component={SignOut} />
                                <Route path="/token/all" component={SignOut} />
                                <Route exact path="/reset-password" component={ForgotPassword} />
                                <Route
                                    path={`${match.url}`}
                                    component={AppLayout}
                                />
                            </Switch>
                        </div>
                    </RTL>
                    <NotificationContainer />
                </IntlProvider>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    );
};

export default App;
