import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useTypes = (fetchTypes, storeName) => {
    const dispatch = useDispatch();
    const {
        types
    } = useSelector((store) => store[storeName]);

    const [typeList, setTypes] = useState(types);

    useEffect(() => {
        if (types.length === 0) {
            dispatch(fetchTypes());
        } else {
            setTypes(types);
        }
    }, [types, dispatch, fetchTypes]);

    return typeList;
};

export default useTypes;
