import React from 'react';
import {
    MenuItem, MenuList
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Box from '@material-ui/core/Box';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import IntlMessages from 'util/IntlMessages';

export const useStyles = makeStyles(() => ({
    rotate: {
        transform: 'rotate(180deg)'
    }
}));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiBox-root': {
                color: theme.palette.common.white
            },
            '&:hover .MuiBox-root': {
                color: theme.palette.common.black
            },
            '&>.MuiBox-root': {
                width: '100%'
            }
        }
    }
}))(MenuItem);

const SortingComponent = ({
    data = [],
    selectedItem = null,
    direction = 1,
    onChangeSort = () => {},
    handleClose = () => {},
    open
}) => {
    const classes = useStyles();

    const handleSelectSort = (value) => {
        if (selectedItem === value) {
            onChangeSort(value, Number(!direction));
            handleClose();
            return;
        }
        onChangeSort(value, 0);
        handleClose();
    };

    return (
        <MenuList autoFocusItem={open}>
            {data.map((item) => {
                const { value, name, description = null } = item;
                const isSelected = value === selectedItem;
                return (
                    <StyledMenuItem
                        key={value}
                        selected={isSelected}
                        onClick={() => handleSelectSort(value)}
                    >
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Box flexGrow={1} className="mr-1">
                                {description || <IntlMessages id={name} />}
                            </Box>
                            {isSelected && (
                                <Box>
                                    <ArrowDownwardIcon className={direction ? '' : classes.rotate} fontSize="small" />
                                </Box>
                            )}
                        </Box>
                    </StyledMenuItem>
                );
            })}
        </MenuList>
    );
};

export default SortingComponent;
