import {
    all, call, select, put, takeLatest
} from 'redux-saga/effects';

import {
    FETCH_ARTICLE_LIST,
    LOAD_ARTICLE_WITH_ID,
    LOAD_ARTICLE_WITH_SLUG,
    PUBLISH_ARTICLE,
    EDIT_ARTICLE,
    CREATE_ARTICLE,
    DELETE_ARTICLE,
    FETCH_ARTICLES_STATUSES
} from 'constants/ActionTypes';
import {
    articlesLoaded, articleLoaded, setError, articleSavedSuccess, statusesLoaded, setLoading
} from 'actions/Articles';
import { showMessage } from 'actions/Messages';
import { getActionForError } from 'util/errors';
import api from 'util/api/api.articles.methods';

export const stateSelector = (state) => state.articles;
export const stateAuthSelector = (state) => state.auth;

function* loadArticlesListSaga({ payload }) {
    const { page, limit } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.getArticlesList, page, limit, authUser?.access_token);
        yield put(articlesLoaded(response));
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(setLoading(false));
    }
}

function* loadArticlesStatusesSaga() {
    const { authUser } = yield select(stateAuthSelector);
    try {
        const statuses = yield call(api.getStatuses, authUser?.access_token);
        yield put(statusesLoaded(statuses.data));
    } catch (error) {
        yield put(getActionForError(error));
    }
}

function* loadArticleWithSlugSaga({ payload }) {
    const { slug } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.getArticleWithSlug, slug, authUser?.access_token);
        yield put(articleLoaded(response.data));
    } catch (error) {
        yield put(getActionForError(error));
        yield put(setError(true));
    } finally {
        yield put(setLoading(false));
    }
}

function* loadArticleWithIdSaga({ payload }) {
    const { id } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.getArticleWithId, id, authUser?.access_token);
        yield put(articleLoaded(response.data));
    } catch (error) {
        yield put(getActionForError(error));
        yield put(setError(true));
    } finally {
        yield put(setLoading(false));
    }
}

function* createArticleSaga({ payload }) {
    const { data }  = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.createArticle, data, authUser.access_token);
        yield put(showMessage('success', 'article.successfullySaved', null, true));
        yield put(articleSavedSuccess(response.success));
    } catch (error) {
        yield put(articleSavedSuccess(false));
        yield put(getActionForError(error));
    } finally {
        yield put(setLoading(false));
    }
}

function* editArticleSaga({ payload }) {
    const { id, data } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.editArticle, id, data, authUser.access_token);
        yield put(showMessage('success', 'article.successfullySaved', null, true));
        yield put(articleSavedSuccess(response.success));
    } catch (error) {
        yield put(articleSavedSuccess(false));
        yield put(getActionForError(error));
    } finally {
        yield put(setLoading(false));
    }
}

function* publishArticleSaga({ payload }) {
    const { id } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.publishArticle, id, authUser.access_token);
        yield put(showMessage('success', 'article.successfullySaved', null, true));
        yield put(articleSavedSuccess(response.success));
    } catch (error) {
        yield put(articleSavedSuccess(false));
        yield put(getActionForError(error));
    } finally {
        yield put(setLoading(false));
    }
}

function* deleteArticleSaga({ payload }) {
    const { id }  = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.deleteArticle, id, authUser.access_token);
        yield put(showMessage('success', 'article.successfullyDeleted', null, true));
        yield put(articleSavedSuccess(response.success));
    } catch (error) {
        yield put(articleSavedSuccess(false));
        yield put(getActionForError(error));
    } finally {
        yield put(setLoading(false));
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(FETCH_ARTICLE_LIST, loadArticlesListSaga),
        takeLatest(LOAD_ARTICLE_WITH_SLUG, loadArticleWithSlugSaga),
        takeLatest(LOAD_ARTICLE_WITH_ID, loadArticleWithIdSaga),
        takeLatest(CREATE_ARTICLE, createArticleSaga),
        takeLatest(EDIT_ARTICLE, editArticleSaga),
        takeLatest(DELETE_ARTICLE, deleteArticleSaga),
        takeLatest(FETCH_ARTICLES_STATUSES, loadArticlesStatusesSaga),
        takeLatest(PUBLISH_ARTICLE, publishArticleSaga)
    ]);
}
