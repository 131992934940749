import React, { useState } from 'react';
import {
    Link
} from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import { useDispatch } from 'react-redux';
import ModalDialog from 'components/ModalDialog';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ActionConfirmation from 'components/ActionConfirmation';
import CardAvatarWithStatus from 'components/Card/CardAvatarWithStatus';
import titleBg from 'components/Helpers/HeaderBackgroundWithStatus';
import DateTimeInfo from 'components/DateTimeInfo';
import CardBox from 'components/Card';
import CardMenu from 'components/Card/CardDropDownMenu';
import TagBgUK from 'components/Helpers/TagBackgroundWithUK';
import AttachmentView from 'components/AttachmentView';
import { FormHelperText } from '@material-ui/core';

function ListItem(props) {
    const dispatch = useDispatch();
    const {
        statusName, itemStatus, data, text, isAdmin, actions, type, listMode, uk
    } = props;
    const {
        title, published_at, created_at, updated_at, id, status, attach = [], hashtags = []
    } = data;

    const [isDialogOpen, setDialogVisible] = useState(false);
    const [currentMethod, setMethod] = useState();

    const [anchorEl, setAnchorEl] = useState(null);
    const actionsOpened = Boolean(anchorEl);

    const handleActionsClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleActionsClose = () => {
        setAnchorEl(null);
    };

    const handleButtonClick = (methodName) => {
        setMethod(methodName);
        handleActionsClose();
        if (methodName === 'edit') {
            actions[methodName].onClick();
        } else {
            setDialogVisible(true);
        }
    };

    const handleConfirmClick = () => {
        dispatch(actions[currentMethod].onClick(id));
        setMethod();
        setDialogVisible(false);
    };

    const handleCancelClick = () => {
        setMethod();
        setDialogVisible(false);
    };

    const linkTo = type === 'article' ? `/${type}/view/${id}` : `/${type}/${id}`;

    return (
        <>
            {/* confirmation delete etc. */}
            <ModalDialog isOpen={isDialogOpen}>
                <ActionConfirmation
                    onConfirm={handleConfirmClick}
                    onCancel={handleCancelClick}
                    methodText={actions[currentMethod]?.confirmText}
                />
            </ModalDialog>
            <CardBox
                classes="d-flex flex-column"
                avatar={CardAvatarWithStatus(statusName, itemStatus)}
                title={title}
                icon={isAdmin && (
                    <MoreVertIcon />
                )}
                subheader={<DateTimeInfo time={published_at || created_at} />}
                className={`${titleBg(statusName)} card-header_with_status`}
                handleActionsClick={handleActionsClick}
                actions={(
                    <div className="w-100">
                        <div className="d-flex justify-content-between">
                            <TagBgUK uk={uk}/>

                            {listMode && (
                                <Link to={linkTo}><IntlMessages id="aboutUs.readMore" /></Link>
                            )}
                        </div>
                        {type === 'news' && hashtags?.length > 0 && (
                            <FormHelperText>{hashtags?.map(el => <span key={el}>{el}</span>)}</FormHelperText>
                        )}
                        {type === 'news' && (
                            <div className="date_created_edited">
                                {created_at === updated_at
                                    ? <>{'Создано'} {<DateTimeInfo time={created_at} />}</>
                                    : <>{'Отредактировано'} {<DateTimeInfo time={updated_at} />}</>
                                }
                            </div>
                        )}
                    </div>
                )}
                actionsClass="tagUK justify-content-between"
            >
                <div className="text-muted">
                    {text}
                </div>
                <div className="mt-2">
                    {attach.length > 0 && attach.map((attachment, index) => (
                        <AttachmentView key={attachment.url} attachment={attachment} isPreview />
                    ))}
                </div>
            </CardBox>
            {isAdmin && (
                <CardMenu
                    menuState={actionsOpened}
                    anchorEl={anchorEl}
                    handleRequestClose={handleActionsClose}
                    handleButtonClick={handleButtonClick}
                    actions={actions}
                    isAdmin={isAdmin}
                    status={status}
                />
            )}
        </>
    );
}

export default ListItem;
