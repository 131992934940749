import React from 'react';
import FlagIcon from '@material-ui/icons/Flag';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AvatarStatus from 'components/Helpers/AvatarStatus';

const CardAvatarWithStatus = (
    statusName, itemStatus
) => {
    const avatar = (st) => {
        switch (st) {
        case 'wait':
            return {
                bgColor: 'bg-transparent',
                icon: <PauseCircleOutlineIcon style={{ fontSize: 30 }} />
            };
        case 'new':
            return {
                bgColor: 'bg-transparent',
                icon: <FlagIcon style={{ fontSize: 30 }} />
            };
        case 'started':
            return {
                bgColor: 'bg-transparent',
                icon: <PlayCircleOutlineIcon style={{ fontSize: 30 }} />
            };
        case 'published':
            return {
                bgColor: 'bg-transparent',
                icon: <PlayCircleOutlineIcon style={{ fontSize: 30 }} />
            };
        case 'finished':
            return {
                bgColor: 'bg-transparent',
                icon: <span><span className="iconify" data-icon="ion:stop-circle-outline" data-inline="false" style={{ fontSize: 30 }} /></span>
            };
        case 'deleted':
            return {
                bgColor: 'bg-transparent',
                icon: <DeleteForeverIcon style={{ fontSize: 30 }} />
                // icon: <span className="iconify" data-icon="ion:archive" data-inline="false" style={{ fontSize: 25 }} />
            };
        default:
            return {
                bgColor: 'bg-transparent',
                icon: <ThumbUpAltIcon style={{ fontSize: 30 }} />
            };
        }
    };

    return (
        AvatarStatus({
            status: itemStatus,
            avatar: avatar(statusName)
        })
    );
};

export default CardAvatarWithStatus;
