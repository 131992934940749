import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    userSignIn,
    // userSignInKrasinform,
    // setValidationFields,
    // saveCurrentUK
} from 'actions/Auth';
// import { hideMessage } from 'actions/Messages';
import SignIn from 'components/Auth/SignIn';
import {
    // Button,
    Grid
} from '@material-ui/core/';
// import IntlMessages from 'util/IntlMessages';

const AppSignIn = ({ match }) => {
    // const { uk = 'public' } = match.params;
    const dispatch = useDispatch();
    // const { ukList } = useSelector(({ auth }) => auth);
    //const [krasinformForm, setKrasinformForm] = useState(false);
    //const [logo, setLogo] = useState(null);
    //const [name, setName] = useState('');
    const logo = <img src={require('assets/images/logo64.png')} alt="Logo" title="Logo" width="50" />;
    const name = 'УК ФЛАГМАН';

    // useEffect(() => {
    //     const clearErrors = () => {
    //         dispatch(setValidationFields({})); // reset validator at first page load
    //         dispatch(hideMessage()); // reset all error messages
    //     };
    //     clearErrors();
    //     return () => clearErrors();
    // }, [krasinformForm]);

    // useEffect(() => {
    //     if (uk) {
    //         //устанавливаем данные по УК - лого и имя
    //         const findUK = ukList.find((el) => el.value === uk);
    //         if (findUK) {
    //             setLogo(findUK.logo);
    //             setName(findUK.name);
    //             // dispatch(saveCurrentUK({
    //             //     uk_name: findUK.value,
    //             //     account_number: 0
    //             // }));
    //         }
    //     }
    // }, [uk, ukList]);

    // const buttonSwitcher = (
    //     <Button
    //         color="secondary"
    //         onClick={() => {
    //             setKrasinformForm(!krasinformForm);
    //         }}
    //     >
    //         {krasinformForm ? (
    //             name
    //         ) : (
    //             <IntlMessages id="sidebar.dashboard.sync.krasinform" />
    //         )}
    //     </Button>
    //
    // );

    const signAction = (user) => {
        // if (krasinformForm) {
        //     dispatch(userSignInKrasinform(user));
        // } else {
            dispatch(userSignIn(user));
        // }
    };

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
        >
            <Grid item xs={10} md={4}>
                <SignIn
                    logo={logo}
                    title={name}
                    rememberMe
                    resetPassword
                    onClickSignIn={signAction}
                    // buttonSwitcher={buttonSwitcher}
                    //krasinformForm={krasinformForm}
                />
            </Grid>
        </Grid>
    );
};

export default AppSignIn;
