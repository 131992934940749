import React, { useState, useEffect } from 'react';
import {
    TextField, Button, FormGroup, Divider, Grid
} from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import DeleteIcon from '@material-ui/icons/Delete';
import ModalDialog from 'components/ModalDialog';
import ActionConfirmation from 'components/ActionConfirmation';
import ActionButton from 'components/ActionButton';
import AnswerList from './AnswersListEditor';

const QuestionEditor = ({
    question, index, onQuestionChange, onQuestionDelete
}) => {
    const [qData, setQData] = useState(question);
    const [isDialogOpen, setDialogOpen] = useState(false);

    const saveAnswers = (answers) => {
        const newQuestion = {
            ...qData,
            answers
        };
        setQData(newQuestion);
        onQuestionChange(newQuestion);
    };

    const saveQuestionTitle = (e) => {
        const { value } = e.target;
        setQData((state) => ({
            ...state,
            title: value
        }));
    };

    const onBlur = (event) => {
        event.preventDefault();
        const { value } = event.target;
        const newQuestion = {
            ...qData,
            title: value
        };
        setQData(newQuestion);
        onQuestionChange(newQuestion);
    };

    const onKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            const { value } = event.target;
            const newQuestion = {
                ...qData,
                title: value
            };
            setQData(newQuestion);
            onQuestionChange(newQuestion);
        }
        if (event.keyCode === 27) {
            event.preventDefault();
            const newQuestion = {
                ...qData,
                title: question.title
            };
            setQData(newQuestion);
            onQuestionChange(newQuestion);
        }
    };

    return (
        <Grid item key={question.id} classes={{ root: 'mb-2 mt-2 vote_questions' }}>
            <FormGroup row>
                <TextField
                    required
                    multiline
                    // rows={3}
                    className="mb-2 flex-grow-1"
                    label={(
                        <>
                            <IntlMessages id="votes.question" />
                            {' №'}
                            {index + 1}
                        </>
                    )}
                    defaultValue={qData.title}
                    onChange={saveQuestionTitle}
                    error={!qData.title}
                    helperText={!qData.title && <IntlMessages id="appModule.mandatory.field" />}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                    variant="outlined"
                />
                <ActionButton
                    onClick={() => setDialogOpen(true)}
                    title="votes.deleteQuestion"
                    className="text-info"
                    icon={<DeleteIcon />}
                />
            </FormGroup>
            <FormGroup className="pr-3 pl-3 mb-3">
                <AnswerList answers={qData.answers} saveAnswers={saveAnswers} />
            </FormGroup>
            <ModalDialog isOpen={isDialogOpen}>
                <ActionConfirmation
                    onConfirm={() => {
                        onQuestionDelete();
                        setDialogOpen(false);
                    }}
                    onCancel={() => setDialogOpen(false)}
                    methodText="votes.confirmDeleteQuestion"
                />
            </ModalDialog>
        </Grid>
    );
};

export default QuestionEditor;
