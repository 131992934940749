import {
    ADDRESS_LIST_DADATA_LOADED,
    DADATA_LOADING
} from 'constants/ActionTypes/dadata';

const INIT_STATE = {
    list: [],
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
    case ADDRESS_LIST_DADATA_LOADED: {
        return {
            ...state,
            list: action.payload
        };
    }

    case DADATA_LOADING: {
        return {
            ...state,
            loading: action.payload
        };
    }

    default:
        return state;
    }
};
