export const pdf = 'application/pdf';
export const doc = 'application/msword';
export const docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const xls = 'application/vnd.ms-excel';
export const xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const txt = 'text/plain';
export const jpeg = 'image/jpeg';
export const png = 'image/png';
export const gif = 'image/gif';

export const ACCEPTED_FILE_TYPES = [txt, doc, docx, xls, xlsx, pdf, jpeg, png, gif];
export const IMAGES_FILE_TYPES = [jpeg, png, gif];
