const prefix = 'ws';

/**
 * Constants
 * */
export const CONNECT = `${prefix}/CONNECT`;
export const CONNECTED = `${prefix}/CONNECTED`;
export const DISCONNECT = `${prefix}/DISCONNECT`;
export const DISCONNECTED = `${prefix}/DISCONNECTED`;
export const ADD_LISTENER = `${prefix}/ADD_LISTENER`;
export const ADDED_LISTENER = `${prefix}/ADDED_LISTENER`;

export const REMOVE_LISTENER = `${prefix}/REMOVE_LISTENER`;
export const REMOVED_LISTENER = `${prefix}/REMOVED_LISTENER`;
