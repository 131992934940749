import {
    ARTICLE_LIST_LOADED_SUCCESS,
    SAVE_CURRENT_ARTICLE,
    SET_ARTICLE_ERROR,
    ARTICLES_STATUSES_LOADED,
    ARTICLE_SUCCESSFULLY_SAVED,
    SET_ARTICLE_LOADING
} from 'constants/ActionTypes';

const INIT_STATE = {
    list: {
        data: []
    },
    currentArticle: {},
    error: false,
    statuses: [],
    articleSaved: false,
    articleSavedAction: false,
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
    case SET_ARTICLE_LOADING: {
        return {
            ...state,
            loading: action.payload
        };
    }
    case ARTICLE_LIST_LOADED_SUCCESS: {
        return {
            ...state,
            list: action.payload
        };
    }
    case SAVE_CURRENT_ARTICLE: {
        return {
            ...state,
            currentArticle: action.payload
        };
    }
    case ARTICLES_STATUSES_LOADED: {
        return {
            ...state,
            statuses: action.payload
        };
    }
    case SET_ARTICLE_ERROR: {
        return {
            ...state,
            error: action.payload
        };
    }
    case ARTICLE_SUCCESSFULLY_SAVED: {
        return {
            ...state,
            articleSaved: action.payload.bool,
            articleSavedAction: action.payload.action
        };
    }
    default:
        return state;
    }
};
