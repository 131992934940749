import React, { useEffect } from 'react';
import { Collapse, LinearProgress } from '@material-ui/core';
import moment from 'moment';
import styles from './users.module.scss';
import { fetchUserListWithAccountInfo } from '../../../../actions/Admin';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment } from 'react';

const UserInfo = ({ isOpen, user = {}, organization = {} }) => {
    const dispatch = useDispatch();
    const user_id = user.id;
    const { userInfo } = useSelector(({admin}) => admin);

    useEffect(() => {
        dispatch(fetchUserListWithAccountInfo({user_id: [user_id]}));
    }, [user_id, dispatch]);

    return (
       <Collapse in={isOpen} timeout="auto" unmountOnExit className={styles.info}>
            {userInfo[user_id]
                && userInfo[user_id]?.map((data) => {
                    return (data?.account?.length > 0
                        ? data?.account?.map((el) => <Fragment key={data.id}>
                            <div>Лицевой счет: <strong>{el.external_personal_id || 'Не указан'}</strong></div>
                            <div>Квартира: <strong>{el.flat_id || 'Не указана'}</strong></div>
                        </Fragment>)
                        : null
                    );
                })
            }
            {user.email && <div>Email: {user.email}</div>}
            {user.created_at && <div>Создан: {moment(user.created_at).format('LLL')}</div>}
            {user.updated_at && <div>Изменен: {moment(user.updated_at).format('LLL')}</div>}
            {typeof user.is_active === 'boolean' && (
                <div>Пользователь: {user.is_active ? 'активен' : 'не активен'}</div>
            )}
        </Collapse>
    );
};

export default UserInfo;
