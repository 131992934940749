import {
    FETCH_COUNTERS,
    GET_COUNTER_INFO,
    COUNTERS_LOAD_SUCCESS,
    SET_COUNTERS_ERROR,
    SEND_COUNTER_DATA,
    COUNTERS_LOADING,
    COUNTER_LOADING
} from 'constants/ActionTypes';

export const setLoading = (bool) => ({
    type: COUNTERS_LOADING,
    payload: bool
});

export const fetchCounter = () => ({
    type: FETCH_COUNTERS
});

export const getCounterWithId = (id) => ({
    type: GET_COUNTER_INFO,
    payload: { id }
});

export const countersLoaded = (counters) => ({
    type: COUNTERS_LOAD_SUCCESS,
    payload: counters
});

export const setError = (message) => ({
    type: SET_COUNTERS_ERROR,
    payload: message
});

export const sendCounterData = (id, value) => ({
    type: SEND_COUNTER_DATA,
    payload: { id, value }
});

export const counterLoading = (id, bool) => ({
    type: COUNTER_LOADING,
    payload: { [id]: bool }
})
