import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// слушаем изменение проекта и обновляем инфо по юзеру
const useCurrentUser = () => {
    const {
        authUser,
        currentUK
    } = useSelector(({ auth }) => auth);

    return (authUser?.info?.persons || []).find(el => el.id === currentUK.id) || {};
};

export default useCurrentUser;