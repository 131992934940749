import React, { useEffect, useState } from 'react';
import {
    Select, FormControl, InputLabel, Button
} from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import CardBox from 'components/Card';

const Filter = ({
    roles = [],
    currentFilters = {},
    handleClose = () => {},
    onChangeFilter = () => {}
}) => {
    const initialDataFilter = { };
    const [disabled, setDisabled] = useState(true);
    const [dataFilter, setDataFilter] = useState({ ...initialDataFilter, ...currentFilters });

    useEffect(() => {
        if (Object.keys(currentFilters)) {
            setDataFilter({ ...initialDataFilter, ...currentFilters });
        }
    },[currentFilters]);
    const dataWithoutEmpty = (data) => (Object.keys(data).reduce((res, key) => {
        if (data[key] !== '') {
            res[key] = data[key];
        }
        return res;
    }, {}));

    const handleFilterSubmit = () => {
        const newData = dataWithoutEmpty(dataFilter);
        setDisabled(true);
        onChangeFilter(newData);
        handleClose();
    };

    const handleFilterReset = () => {
        setDataFilter(initialDataFilter);
        setDisabled(true);
        onChangeFilter(initialDataFilter);
    };

    const handleFilterChange = (value, name) => {
        setDataFilter({
            ...dataFilter,
            [name]: value
        });
        setDisabled(false);
    };

    return (
        <CardBox
            title={<IntlMessages id="appModule.filter" />}
            actions={(
                <>
                    <Button
                        onClick={handleFilterReset}
                        variant="contained"
                        className="mr-3"
                        disabled={!dataFilter.role_id}
                    >
                        <IntlMessages id="filter.resetFilter" />
                    </Button>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        className="mr-3"
                    >
                        <IntlMessages id="appModule.close" />
                    </Button>

                    <Button
                        onClick={handleFilterSubmit}
                        variant="contained"
                        color="secondary"
                        disabled={disabled}
                    >
                        <IntlMessages id="appModule.apply" />
                    </Button>
                </>
            )}
        >
            <div className="d-flex flex-column">
                <FormControl variant="outlined" className="mb-3">
                    <InputLabel shrink htmlFor="role_list">
                        Роль
                    </InputLabel>
                    <Select
                        notched
                        native
                        value={dataFilter.role_id}
                        onChange={(event) => {
                            const { value, name } = event.target;
                            handleFilterChange(value, name);
                        }}
                        inputProps={{
                            name: 'role_id',
                            id: 'role_list'
                        }}
                        label="Роль"
                    >
                        <option value="" key="empty-type">Выбрать Роль</option>
                        {roles.map((t) => (
                            <option
                                value={t.id}
                                key={t.id}
                            >
                                {t.description}
                            </option>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </CardBox>
    );
};

export default Filter;
