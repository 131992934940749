import axios from 'axios';
import cookie from 'react-cookies';
import { API_STORAGE_URLS } from 'constants/Config';

const instance = axios.create();

const uploadFile = async (file, service = 'zkh') => {
    const authUser = cookie.load('authUser') || null;
    const { access_token } = authUser;
    const formData = new FormData();
    formData.append('doc', file);
    if (service) {
        formData.append('service', service);
    }
    const response = await instance.post(`${API_STORAGE_URLS}/document`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${access_token}`,
                // todo потом убрать (будет глобально)
                uk: 'flagman'
            }
        });
    if (response.data.success) {
        return response.data.data.public_link;
    }
    throw new Error('Загрузка не удалась. Попробуйте еще раз позднее');
};

export default uploadFile;
