import React from 'react';
import cn from 'classnames';
import {
    IconButton, Tooltip
} from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';

const ActionButton = ({
    onClick = () => {},
    title,
    disabled = false,
    className,
    icon,
    titleText //todo временное решение
}) => (
    <Tooltip title={titleText || <IntlMessages id={title} />} arrow>
        {/* span - small hack for hide disabled state from Tooltip */}
        <span className="action-button">
            <IconButton
                aria-label="edit"
                onClick={onClick}
                className={cn(className, { disabled })}
                disabled={disabled}
            >
                {icon}
            </IconButton>
        </span>
    </Tooltip>
);

export default ActionButton;
