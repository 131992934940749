import {
    LOAD_HOUSES,
    HOUSES_LOAD_SUCCESS,
    SEARCH_HOUSES,
    SEARCH_HOUSES_SUCCESS,
    SEARCH_HOUSES_IDS,
    SET_HOUSES_ERROR,
    HOUSES_STATUSES_LOADED,
    SEARCH_HOUSES_STATUSES_LOADED,
    SEARCH_HOUSES_CLEAR_LIST,
} from 'constants/ActionTypes';


export const fetchHousesList = (page, limit) => ({
    type: LOAD_HOUSES,
    payload: { page, limit }
});

export const searchHouses = (address, page, limit) => ({
    type: SEARCH_HOUSES,
    payload: { address, page, limit }
});

export const housesLoaded = (list) => ({
    type: HOUSES_LOAD_SUCCESS,
    payload: list
});

export const statusesLoaded = (bool = false) => ({
    type: HOUSES_STATUSES_LOADED,
    payload: bool
});

export const housesSearchLoaded = (list) => ({
    type: SEARCH_HOUSES_SUCCESS,
    payload: list
});

export const statusesSearchLoaded = (bool = false) => ({
    type: SEARCH_HOUSES_STATUSES_LOADED,
    payload: bool
});

export const clearSearchList = () => ({
    type: SEARCH_HOUSES_CLEAR_LIST
});

export const searchHousesIds = (ids) => ({
    type: SEARCH_HOUSES_IDS,
    payload: { ids }
});

export const setError = (bool) => ({
    type: SET_HOUSES_ERROR,
    payload: bool
});
