import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchAppealList, appealSavedSuccess, editAppeal, createAppeal, fetchStatuses, fetchTypes, appealLoaded,
    fetchAppealWithId, appealWithIdLoaded
} from 'actions/Appeal';
import { setChangeCurrentUKStatus } from 'actions/Auth';
import {
    LinearProgress, Grid
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Pagination from '@material-ui/lab/Pagination';
import EditorForm from 'components/AppealList/Editor';
import IntlMessages from 'util/IntlMessages';
import Hidden from '@material-ui/core/Hidden';
import withWidth from '@material-ui/core/withWidth';
import Sticker from 'components/Sticker';
import PageHeader from 'components/PageHeader';
import ActionButton from 'components/ActionButton';
import useAppealStatuses from 'components/Helpers/useStatuses';
import useAppealTypes from 'components/Helpers/useTypes';
import MenuItemWithPopover from 'components/MenuItemPopover';
import FilterListIcon from '@material-ui/icons/FilterList';
import SortIcon from '@material-ui/icons/Sort';
import SortingComponent from 'components/Sorting';
import AppealItem from './AppealItem';
import CurrentAppeal from './CurrentAppeal';
import AppealFilter from './appealFilter';
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";

const Appeal = ({ match, history }) => {
    const initialState = {
        page: 1,
        limit: 10,
        filter: {},
        sort: { order: 'created_at', direction: 0 },
        isChatOpen: false,
        isEditorOpen: false,
        currentAppeal: {},
        isNew: false
    };
    const parentRef = useRef(null);
    const dispatch = useDispatch();
    const id = parseInt(match?.params?.id);
    const {
        list, error, appealSaved, loading, currentAppeal: cAppeal, loadingCurrent: cAppealLoading
    } = useSelector(({ appeal }) => appeal);

    const { mainScrollbarRef } = useSelector(({ settings }) => settings);

    const { changeCurrentUK, ukList, currentUK, authUser } = useSelector(({ auth }) => auth);

    const [hasAccountNumber, setHasAccountNumber] = useState(false);

    useEffect(() => {
        const addresses = authUser?.info?.newAddressList || [];

        const findAddress = addresses.find((address) => currentUK.account_number === address.account_number);
        if (findAddress) {
            setHasAccountNumber(true);
        } else {
            setHasAccountNumber(false);
        }
    }, [authUser, currentUK.account_number]);

    const statuses = useAppealStatuses(fetchStatuses, 'appeal');
    const types = useAppealTypes(fetchTypes, 'appeal');

    const [page, setPage] = React.useState(initialState.page);
    const [limit, setLimit] = useState(initialState.limit);
    const [filter, setFilter] = useState(initialState.filter);
    const [sort, setSort] = useState(initialState.sort);

    const [isChatOpen, setChatOpen] = useState(initialState.isChatOpen);
    const [isEditorOpen, setEditorOpen] = useState(initialState.isEditorOpen);
    const [currentAppeal, setCurrentAppeal] = useState(initialState.currentAppeal);
    const [isNew, setIsNew] = useState(initialState.isNew);

    const setActiveItem = (appealId) => {
        if (currentAppeal.id !== appealId) {
            const activeAppeal = list.data.find((el) => el.id === appealId) || {};
            setCurrentAppeal(activeAppeal);
        }
    };

    const removeActiveItem = () => {
        setCurrentAppeal({});
    };

    const openChat = () => {
        if (isEditorOpen) {
            setEditorOpen(false);
        }
        setChatOpen(true);
    };

    const closeChat = () => {
        setChatOpen(false);
        removeActiveItem();
        if (id) {
            history.replace('/appeal');
        }
    };

    const openEditor = () => {
        if (isChatOpen) {
            setChatOpen(false);
        }
        setEditorOpen(true);
    };

    const closeEditor = () => {
        setEditorOpen(false);
        removeActiveItem();
    };

    const openCreateNewEditor = () => {
        setIsNew(true);
        openEditor();
    };

    const closeAll = () => {
        removeActiveItem();
        if (isEditorOpen) {
            setEditorOpen(false);
        }
        if (isChatOpen) {
            setChatOpen(false);
        }
    };

    useEffect(() => {
        if (changeCurrentUK) {
            setPage(initialState.page);
            setLimit(initialState.limit);
            setSort(initialState.sort);
            setFilter(initialState.filter);
            setCurrentAppeal(initialState.currentAppeal);
            setIsNew(initialState.isNew);
            setEditorOpen(initialState.isEditorOpen);
            setChatOpen(initialState.isChatOpen);
            dispatch(appealLoaded({ data: [] }));
            dispatch(setChangeCurrentUKStatus());
        } else if (!id) {
            dispatch(fetchAppealList(page, limit, { ...filter, ...sort }));
        }
    }, [page, limit, filter, sort, dispatch, changeCurrentUK, id]);

    // useEffect(() => {
    //     if (Object.keys(cAppeal).length > 0) {
    //         setCurrentAppeal(cAppeal);
    //         // dispatch(appealWithIdLoaded({}));
    //     }
    // }, [cAppeal]);
    // useEffect(() => {
    //     if (/*list.data.length > 0 &&*/ id && !error) {
    //         dispatch(fetchAppealWithId(id));
    //         // setActiveItem(id);
    //         setCurrentAppeal({});
    //         openChat();
    //         // history.replace('/appeal');
    //     }
    // }, [/*list,*/ id, error]);

    useEffect(() => {
        if (id && Object.keys(cAppeal).length > 0 && cAppeal?.id === id) {
            setCurrentAppeal(cAppeal);
            openChat();
        } else if (/*list.data.length > 0 &&*/ id && !error) {
            dispatch(fetchAppealWithId(id));
            // setActiveItem(id);
            setCurrentAppeal({});
            openChat();
        }
    }, [id, error, cAppeal]);

    useEffect(() => {
        if (appealSaved) {
            dispatch(fetchAppealList(page, limit));
            dispatch(appealSavedSuccess());
            closeEditor();
        }
    }, [appealSaved]);

    const handleChange = (event, value) => {
        setPage(value);
    };

    const handleSendRequest = (appeal) => {
        if (!appeal?.id) {
            setIsNew(false);
            const newAppeal = {
                ...appeal,
                uk_name: currentUK.uk_name
            };
            dispatch(createAppeal(newAppeal));
        } else {
            dispatch(editAppeal(appeal.id, appeal));
        }
    };

    const handleChangeFilter = (data) => {
        setFilter(data);
        closeAll();
    };

    const handleChangeSort = (order, direction) => {
        setSort({
            order,
            direction
        });
        closeAll();
    };

    const currentAppealCardBox = isEditorOpen
        ? (
            <EditorForm
                data={Object.keys(currentAppeal).length > 0 && currentAppeal}
                types={types}
                handleSendRequest={handleSendRequest}
                handleClose={closeEditor}
            />
        )
        : Object.keys(currentAppeal).length > 0 && isChatOpen
            && (
                <CurrentAppeal currentAppeal={currentAppeal} handleClose={closeChat} />
            );

    const sortBy = [
        { value: 'id', name: 'filter.byId' },
        { value: 'created_at', name: 'filter.byCreateDate' },
        { value: 'status', name: 'filter.byStatus' },
        { value: 'type', name: 'filter.byType' }
    ];

    const isCurrent = !!id;

    const buttons = isCurrent ? [
        <ActionButton
            title="appModule.goToList"
            onClick={() => {
                history.push('/appeal');
                setCurrentAppeal({});
                setChatOpen(false);
                setEditorOpen(false);
            }}
            className="text-secondary"
            icon={<FormatListBulletedIcon />}
        />
    ] : [
        <ActionButton
            title="appModule.createAppeal"
            onClick={openCreateNewEditor}
            className={hasAccountNumber && "text-secondary"}
            icon={<AddCircleOutlineIcon />}
            disabled={!hasAccountNumber}
        />,
        <MenuItemWithPopover buttonTitle="appModule.filter" icon={<FilterListIcon />}>
            {(handleClose) => <AppealFilter
                currentFilters={filter}
                types={types}
                statuses={statuses}
                onChangeFilter={handleChangeFilter}
                handleClose={handleClose}
            />}
        </MenuItemWithPopover>,
        <MenuItemWithPopover buttonTitle="appModule.sorting" icon={<SortIcon />}>
            {(handleClose) => <SortingComponent
                data={sortBy}
                onChangeSort={handleChangeSort}
                selectedItem={sort.order}
                direction={sort.direction}
                handleClose={handleClose}
            />}
        </MenuItemWithPopover>
    ];

    const currentList = isCurrent ?
        (Object.keys(cAppeal).length ? [cAppeal] : [])
        : list.data;
    return (
        <>
            {/* header */}
            <PageHeader
                title={<IntlMessages id="sidebar.dashboard.appeal" />}
                buttons={buttons}
            />
            {/* 2 column */}
            <Grid container spacing={3}>
                <Hidden mdUp>
                    { isEditorOpen && isNew
                        && (
                            <Grid item xs={12}>
                                {/* right item */}
                                { currentAppealCardBox }
                            </Grid>
                        )}
                </Hidden>
                <Grid item xs={12} md={5}>
                    {loading && <LinearProgress />}
                    {/* list */}
                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="stretch"
                        spacing={3}
                    >
                        {currentList.length === 0 && loading === false && cAppealLoading === false && (
                            <div style={{ marginTop: '1rem', marginLeft: '.5rem' }}>
                                Данных не найдено
                            </div>
                        )}

                        {currentList.map((item) => (
                            <React.Fragment key={item.id || Math.random()}>
                                <Grid
                                    item
                                    xs={12}
                                    className="position-relative"
                                >
                                    <AppealItem
                                        item={item}
                                        history={history}
                                        typeObj={types.find((el) => el.value === item.type) || {}}
                                        itemStatus={statuses.find((el) => el.value === item.status) || {}}
                                        setEditorOpen={() => { openEditor(); setActiveItem(item.id); }}
                                        setChatOpen={() => { openChat(); setActiveItem(item.id); }}
                                        isActive={currentAppeal.id === item.id}
                                        uk={ukList.find((el) => el.value === item.uk_name) || {}}
                                    />
                                </Grid>
                                <Hidden mdUp>
                                    { Object.keys(currentAppeal).length > 0
                                        && currentAppeal.id === item.id
                                        && (
                                            <Grid item xs={12}>
                                                {/* right item */}
                                                <CurrentAppeal
                                                    getCurrentAppealRef={(ref) => {
                                                        if (mainScrollbarRef?.current) {
                                                            mainScrollbarRef.current.scrollTop = ref.offsetTop - 160;
                                                        }
                                                        // ref.scrollIntoView({ behavior: 'smooth' });
                                                    }}
                                                    currentAppeal={currentAppeal}
                                                    handleClose={closeChat}
                                                />
                                            </Grid>
                                        )}
                                </Hidden>
                            </React.Fragment>
                        )
                        )}
                        {isCurrent === false && list.data.length > 0
                        && (
                            <Grid item sm={12}>
                                <Pagination count={list.meta?.last_page} page={page} onChange={handleChange} />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Hidden smDown>
                    <Grid item md={7} className="position-relative" ref={parentRef}>
                        {/* right item */}
                        { (isEditorOpen || isChatOpen)
                            && (
                                <Sticker parentRef={parentRef}>
                                    {currentAppealCardBox}
                                </Sticker>
                            )}
                    </Grid>
                </Hidden>
            </Grid>
        </>
    );
};

export default withWidth()(Appeal);
