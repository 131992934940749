import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {TextField, Button, FormGroup, LinearProgress} from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import ModalDialog from 'components/ModalDialog';
import ActionConfirmation from 'components/ActionConfirmation';
import Typography from "@material-ui/core/Typography";

const initialData = {
    key: '',
    ru: '',
    en: ''
};

const InputField = ({
    className = '',
    isDelete,
    data = initialData,
    handleClose = () => {},
    handleSendRequest = () => {},
    handleDeleteRequest = () => {},
    loading = false,
    keys = [ 'key', 'ru', 'en' ]
}) => {
    const [invalid, setValid] = useState(true);
    const [formChange, setFormChange] = useState(false);
    const [currentData, setCurrentData] = useState(data);
    const [isDialogOpen, setDialogVisible] = useState(false);

    const handeleApply = (e) => {
        if (invalid === false && formChange) {
            handleSendRequest(currentData);
        }
    };

    useEffect(() => {
        if (Object.keys(data).length){
            setCurrentData(data);
        }
    }, [data]);

    useEffect(() => {
        // поля валидны
        const valid = keys.reduce((res, key) => res && currentData[key].length > 2, true);
        setValid(!valid);
    }, [currentData]);

    const handleDelete = () => {
        setDialogVisible(true);
    };

    const handleChangeField = (event) => {
        const { name, value } = event.target;
        setFormChange(true);
        setCurrentData({
            ...currentData,
            [name]: value
        });
    };

    const handleCancelClickModal = () => {
        setDialogVisible(false);
    };
    const handleConfirmClickModal = () => {
        handleDeleteRequest(currentData.id);
        setDialogVisible(false);
    };

    return (
        <>
            {/* confirmation delete etc. */}
            <ModalDialog isOpen={isDialogOpen}>
                <ActionConfirmation
                    onConfirm={handleConfirmClickModal}
                    onCancel={handleCancelClickModal}
                    methodText='translate.deleteConfirm'
                />
            </ModalDialog>

            <form noValidate autoComplete="off" className={`d-flex flex-column pl-3 pr-3 ${className}`}>

                <FormGroup className="mb-3">
                    <Typography className="font-weight-bold">
                        {isDelete ? <IntlMessages id="translate.editTranslate" /> : <IntlMessages id="translate.createTranslate" />}
                    </Typography>
                </FormGroup>


                {keys.map((key) => (
                    <FormGroup
                        key={key}
                        className="mb-2"
                    >
                        <TextField
                            name={key}
                            className="mb-2"
                            label={<IntlMessages id={`translate.label.${key}`} />}
                            defaultValue={currentData[key]}
                            onChange={handleChangeField}
                            error={!currentData[key] || currentData[key].length < 3}
                            variant="outlined"
                            //size="small"
                        />
                    </FormGroup>
                ))}

                {loading && (<LinearProgress className="mb-3"/>)}

                <FormGroup row className="justify-content-end">

                    {isDelete && (
                        <Button onClick={handleDelete} variant="contained" className="mr-3 bg-secondary">
                            <IntlMessages id="appModule.delete" />
                        </Button>
                    )}

                    <Button onClick={handleClose} variant="contained" className="mr-3">
                        <IntlMessages id="appModule.cancel" />
                    </Button>
                    <Button
                        onClick={handeleApply}
                        disabled={invalid || formChange === false}
                        variant="contained"
                        color="primary"
                    >
                        <IntlMessages id="appModule.save" />
                    </Button>
                </FormGroup>
            </form>
        </>
    );
};

export default InputField;
