import React from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import Item from './Item';
import {
    Button, LinearProgress
} from '@material-ui/core';

const CartMini = (props) => {
    const {
        data = {},
        loading,
        onCreateOrder,
        ...prop
    } = props;

    const list = data.items || [];
    const total = data.total || 0;
    // const summa = list.reduce((res, { cost, count }) => res + count * cost, 0);

    return (
        <div>
            { loading && list.length === 0 ? <LinearProgress /> : (
                <>
                    {list.length === 0 ? (
                        <div className="cart-mini-empty text-center">Корзина пуста</div>
                    ) : (
                        <div>
                            <h2 className="text-center pt-1 font-weight-bold">Корзина</h2>

                            <CustomScrollbars className="scrollbar" style={{ height: 300 }}>
                                <div className="list-unstyled">
                                    {list.map((product) => (
                                        <Item
                                            {...prop}
                                            data={product}
                                            key={product.id}
                                        />
                                    ))}
                                </div>
                            </CustomScrollbars>

                            <div className="cart-mini-summa mb-2 text-center">
                                Сумма: {total}₽
                            </div>

                            {loading && <LinearProgress className="mb-1"/>}

                            <div>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    fullWidth={true}
                                    onClick={onCreateOrder}
                                    disabled={loading}
                                >
                                    Оформить заказ
                                </Button>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default CartMini;
