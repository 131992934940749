import React, { useEffect, useState } from 'react';
import { Button, CardActions, CircularProgress, Grid } from '@material-ui/core';
import CategoryList from './CategoryList';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategoryList, fetchCategoryTree, fetchProductList } from 'actions/Market';
import ProductList from './ProductList';
import ListIcon from '@material-ui/icons/List';
import CartMini from '../../../components/CartMini';
import PageHeader from '../../../components/PageHeader';

const MarketPlace = (props) => {
    const dispatch = useDispatch();
    const {loadingCategory, basket, categoryTree = [], products = [], loadingProduct} = useSelector(({ market }) => market);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [selectedProducts, setSelectedProducts] = useState([]);

    // console.log('basket', basket);

    useEffect(() => {
        dispatch(fetchCategoryTree());
    }, []);

    useEffect(() => {
        dispatch(fetchProductList());
    }, []);

    useEffect(() => {
        const filteredProductList = products.filter((product) => product.category_id === selectedCategory.id);
        setSelectedProducts(filteredProductList.length > 0? filteredProductList : products);
    }, [selectedCategory, products]);

    return (
        <>
            <PageHeader
                title="Маркетплейс"
                buttons={[

                    <Button size="small" color="primary" onClick={() => {
                        props.history.push('/marketplace/orders');
                    }}>
                        <ListIcon fontSize="small" className="mr-1"/>
                        список заказов
                    </Button>,

                    <CartMini
                        color="#bc9131"
                        onCreateOrder={() => {
                            props.history.push('/marketplace/create-order');
                        }}
                    />
                ]}
            />

            <Grid container spacing={3} alignItems="stretch" >
                <Grid item xs={12} sm={4}>
                    {loadingCategory && <CircularProgress />}
                    {categoryTree.length > 0
                        ? <CategoryList
                            list={categoryTree}
                            handleClick={setSelectedCategory}
                            selectedCategory={selectedCategory}
                        />
                        : <div>Данных не найдено</div>
                    }
                </Grid>
                <Grid item xs={12} sm={8}>
                    {loadingProduct && <CircularProgress />}
                    {products.length > 0
                        ? <ProductList list={selectedProducts} basketItems={basket.items} />
                        : <div>Данных не найдено</div>
                    }
                </Grid>
            </Grid>
        </>
    );
};

export default MarketPlace;