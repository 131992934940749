import {
    HIDE_MESSAGE,
    SHOW_MESSAGE,
    NOTIFICATION_RECIEVED,
    RESET_UNREAD_MESSAGES
} from 'constants/ActionTypes';

export const showMessage = (type, message, title, translate) => ({
    type: SHOW_MESSAGE,
    payload: {
        type, message, title, translate
    }
});

export const hideMessage = () => ({
    type: HIDE_MESSAGE
});

export const notificationRecieved = (count) => ({
    type: NOTIFICATION_RECIEVED,
    count
});

export const resetUnreadMessages = (relation_name, relation_id) => ({
    type: RESET_UNREAD_MESSAGES,
    payload: { relation_name, relation_id }
});
