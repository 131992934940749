import React from 'react';
import Typography from '@material-ui/core/Typography';

function List(props) {
    const {
        list = [],
        key = 'name',
        className = ''
    } = props;

    return (
        <>
            {list.map((item) => (
                <React.Fragment key={`${item.value}`}>
                    {item.hasOwnProperty(key) && (
                        <div
                            key={`${item.value}`}
                            className={className}
                        >
                            <Typography>
                                {item[key]}
                            </Typography>
                        </div>
                    )}
                </React.Fragment>
            ))}
        </>
    );
}

export default List;
