import React, { useEffect } from 'react';
import { Typography, Button, IconButton } from '@material-ui/core';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import ProductCounter from './ProductCounter';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles({
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        backgroundColor: '#fff',
        padding: '1rem',
        boxShadow: `0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)`,
        borderRadius: '4px',
        height: '100%'
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    imgBlock: {
        textAlign: 'center',
        flex: 1
    },
    img: {
        height: '150px',
        '&:hover': {
            cursor: 'pointer'
        },
    },
    content: {
        flex: 3,
        padding: '1rem 0'
    }
});

const Item = ({
    item,
    inBasket,
    productInBasket,
    onAddToBasket = () => {},
    onChangeCount = () => {},
    className,
}) => {
    const classes = useStyles();

    const image = item.images.length > 0
        ? item.images[0]
        : null // плейсхолдер

    return (
        <div className={className}>
            <div className={classes.card}>
                <NavLink to={`/marketplace/product/${item.id}`} className={classes.imgBlock}>
                    <img alt={item.name} src={image} title={item.name} className={classes.img} />
                </NavLink>
                <div className={classes.content}>
                <Typography gutterBottom variant="h5" component="h2">
                    {item.cost}{' ₽'}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {item.name}
                </Typography>
                </div>
                <div className={classes.buttons}>
                {inBasket
                    ? (
                        <ProductCounter
                            count={productInBasket.count}
                            onChange={(count) => onChangeCount(productInBasket.id, productInBasket.product_id, count)}
                        />
                    )
                    : (<Button size="small" color="primary" onClick={() => onAddToBasket(item)}>
                        В корзину
                    </Button>)
                }
                </div>
            </div>
        </div>
    );
};

export default Item;