import {
    NEWS_LIST_LOADED_SUCCESS,
    SAVE_CURRENT_NEWS,
    SET_NEWS_ERROR,
    NEWS_STATUSES_LOADED,
    NEWS_SUCCESSFULLY_SAVED,
    SET_NEWS_LOADING
} from 'constants/ActionTypes';

const INIT_STATE = {
    list: {
        data: []
    },
    currentNews: {},
    error: false,
    statuses: [],
    newsSaved: false,
    newsSavedAction: false,
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
    case SET_NEWS_LOADING: {
        return {
            ...state,
            loading: action.payload
        };
    }
    case NEWS_LIST_LOADED_SUCCESS: {
        return {
            ...state,
            list: action.payload
        };
    }
    case SAVE_CURRENT_NEWS: {
        return {
            ...state,
            currentNews: action.payload
        };
    }
    case NEWS_STATUSES_LOADED: {
        return {
            ...state,
            statuses: action.payload
        };
    }
    case SET_NEWS_ERROR: {
        return {
            ...state,
            error: action.payload
        };
    }
    case NEWS_SUCCESSFULLY_SAVED: {
        return {
            ...state,
            newsSaved: action.payload.bool,
            newsSavedAction: action.payload.action
        };
    }
    default:
        return state;
    }
};
