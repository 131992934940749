import React, { useEffect } from 'react';
import { FormGroup } from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import {
    DatePicker as DatePickerUI,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';

const localeMap = {
    en: enLocale,
    ru: ruLocale
};

const localeCancelLabelMap = {
    en: 'cancel',
    ru: 'отмена'
};

const DatePicker = ({
    dateFormat = 'YYYY-MM',
    dateFormatField = 'LLLL yyyy',
    to_period = '',
    locale = 'ru',
    views = ['year', 'month'],
    onDateChange
}) => {
    const maxDate = new Date();
    const handleDateChange = (date, key) => {
        onDateChange(moment(date).format(dateFormat), key);
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
            <FormGroup>
                <DatePickerUI
                    views={views}
                    label={<IntlMessages id="debtors.label.period_at" />}
                    value={to_period}
                    onChange={(date) => handleDateChange(date, 'to_period')}
                    cancelLabel={localeCancelLabelMap[locale]}
                    format={dateFormatField}
                    maxDate={maxDate}
                    size="small"
                    inputVariant="outlined"
                    className="mb-3"
                />
            </FormGroup>
        </MuiPickersUtilsProvider>
    );
};

export default DatePicker;
