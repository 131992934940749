import React, { useState, useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { v4 as uuidv4 } from 'uuid';
import QuestionEditor from './QuestionEditor';


const QuestionListEditor = ({ questions = [], saveQuestions }) => {
    const [questionList, setData] = useState(questions);

    const saveQuestion = (question, index) => {
        const newList = [
            ...questionList.slice(0, index),
            question,
            ...questionList.slice(index + 1)
        ];
        setData(newList);
        saveQuestions(newList);
    };

    const handleDeleteQuestion = (index) => {
        const newList = [
            ...questionList.slice(0, index),
            ...questionList.slice(index + 1)
        ];
        setData(newList);
        saveQuestions(newList);
    };

    const onAddQuestion = () => {
        const newList = [
            ...questionList,
            {
                new: true,
                id: uuidv4(),
                title: '',
                answers: [
                    {
                        new: true,
                        id: uuidv4(),
                        title: ''
                    },
                    {
                        new: true,
                        id: uuidv4(),
                        title: ''
                    }
                ]
            }
        ];
        setData(newList);
        saveQuestions(newList);
    };

    useEffect(() => {
        if (questions.length > 0) {
            setData(questions);
        }
    }, [questions]);

    return (
        <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="stretch"
        >
            <h3><IntlMessages id="votes.questions" /></h3>
            {
                questionList.map((question, index) => (
                    <QuestionEditor
                        key={question.id}
                        question={question}
                        index={index}
                        onQuestionChange={(value) => saveQuestion(value, index)}
                        onQuestionDelete={() => handleDeleteQuestion(index)}
                    />
                ))
            }
            <Button
                onClick={onAddQuestion}
                color="secondary"
                endIcon={<AddCircleOutlineIcon />}
                classes={{ root: 'align-self-start' }}
            >
                <IntlMessages id="votes.addQuestion" />
            </Button>
        </Grid>
    );
};

export default QuestionListEditor;
