import React, { useRef, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

const CustomScrollbars = ({ getScrollbarRef = () => {}, ...props }) => {
    const scrollbarRef = useRef(null);

    useEffect(() => {
        getScrollbarRef(scrollbarRef);
    }, [scrollbarRef]);

    return (
        <Scrollbars
            ref={scrollbarRef}
            {...props}
            autoHide
            renderTrackHorizontal={(args) => (
                <div
                    {...args}
                    style={{ display: 'none' }}
                    className="track-horizontal"
                />
            )}
        />
    );
};

export default CustomScrollbars;
