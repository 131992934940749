import React from 'react';
import { List} from '@material-ui/core';
import CategoryItem from './CategoryItem';

const CategoryList = ({ list, handleClick, selectedCategory }) => {
    return (
        <List component="div">
            {list.map((item) => (
                <CategoryItem
                    key={item.id}
                    item={item}
                    handleClick={handleClick}
                    selected={selectedCategory}
                    hasChildren={item.childs?.length > 0}
                />
            ))}
        </List>
    );
};

export default CategoryList;