import {
    DEBTORS_LIST_LOADED,
    DEBTORS_LIST_LOADED_SUCCESS,
    DEBTORS_LIST_LOADED_STATUS_CHANGE,
    SET_DEBTORS_ERROR,
    DEBTORS_COMPANIES_LOADED,
    DEBTORS_COMPANIES_LOADED_SUCCESS,
    DEBTORS_FETCH_USER_BY_UK,
    DEBTORS_FETCH_USER_BY_UK_LOADED,
    DEBTORS_SEND_MESSAGE_TELEGRAM_USER,
    DEBTORS_SEND_MESSAGE_EMAIL_USER,
    DEBTORS_CLEAR_MESSAGES_STATUS,
    DEBTORS_SET_MESSAGES_STATUS,
    DEBTORS_FETCH_USER_BY_UK_LOADED_STARUS,
    DEBTORS_CLEAR_USER
} from 'constants/ActionTypes';


export const setDebtorsMessageStatus = (data) => ({
    type: DEBTORS_SET_MESSAGES_STATUS,
    payload: data
});

export const clearDebtorsMessageStatus = () => ({
    type: DEBTORS_CLEAR_MESSAGES_STATUS
});

export const sendDebtorsMessageTelegramUser = (user_identifier, telegram_id, text) => ({
    type: DEBTORS_SEND_MESSAGE_TELEGRAM_USER,
    payload: {
        user_identifier,
        telegram_id,
        text
    }
});

export const sendDebtorsMessageEmailUser = (user_identifier, emails, subject, body, files=[]) => ({
    type: DEBTORS_SEND_MESSAGE_EMAIL_USER,
    payload: {
        user_identifier,
        emails,
        subject,
        body,
        files
    }
});

export const fetchDebtorsUserByUk = (account_num) => ({
    type: DEBTORS_FETCH_USER_BY_UK,
    payload: account_num
});

export const clearDebtorsUser = () => ({
    type: DEBTORS_CLEAR_USER
});

export const fetchDebtorsUserByUkLoaded = (data) => ({
    type: DEBTORS_FETCH_USER_BY_UK_LOADED,
    payload: data
});

export const setDebtorsUserByUkLoadedStatus = (bool = false) => ({
    type: DEBTORS_FETCH_USER_BY_UK_LOADED_STARUS,
    payload: bool
});

export const fetchDebtorsList = (page, limit, data = {}) => ({
    type: DEBTORS_LIST_LOADED,
    payload: { page, limit, data }
});

export const debtorsLoaded = (data) => ({
    type: DEBTORS_LIST_LOADED_SUCCESS,
    payload: data
});

// fetch companies list
export const fetchDebtorsCompanies = () => ({
    type: DEBTORS_COMPANIES_LOADED
});

// loaded companies list
export const debtorsCompaniesLoaded = (data) => ({
    type: DEBTORS_COMPANIES_LOADED_SUCCESS,
    payload: data
});

export const setError = (bool) => ({
    type: SET_DEBTORS_ERROR,
    payload: bool
});

export const debtorsLoadedStatusChange = (bool = false) => ({
    type: DEBTORS_LIST_LOADED_STATUS_CHANGE,
    payload: bool
});
