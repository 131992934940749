import React from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';

const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: 120,
        maxWidth: '100%'
    }
}));

const BootstrapInput = withStyles((theme) => ({
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color']),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#9011c3',
            borderWidth: '2px',
            padding: '9px 25px 9px 11px',
            backgroundColor: theme.palette.background.white
        }
    }
}))(InputBase);

const StyledInputLabel = withStyles((theme) => ({
    root: {
        background: theme.palette.common.white,
        transform: 'translate(14px, 12px) scale(1)',
        paddingLeft: '5px',
        paddingRight: '5px',
        marginLeft: '-5px'
    }
}))(InputLabel);

const MultiSelect = ({
    values = [],
    data = [],
    handleChange = () => {}
}) => {
    const classes = useStyles();
    const theme = useTheme();

    const getStyles = (item, vals) => {
        return {
            fontWeight:
                vals.indexOf(item) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium
        };
    };

    return (
        <FormControl variant="outlined" className={classes.formControl}>
            <StyledInputLabel id="company">Компании</StyledInputLabel>
            <Select
                className="mt-0 mb-3"
                labelId="company"
                multiple
                value={values}
                onChange={(event) => { handleChange(event.target.value); }}
                input={<BootstrapInput />}
            >
                {data?.map((item, index) => (
                    <MenuItem key={index} value={item} style={getStyles(item, values)}>
                        {item}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default MultiSelect;
