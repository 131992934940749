import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    TextField,
    FormControlLabel,
    Button,
    LinearProgress,
    Checkbox,
    FormGroup
} from '@material-ui/core/';
import IntlMessages from 'util/IntlMessages';


const Form = (props) => {
    const {
        onSubmitForm = () => {}
    } = props;

    //const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [account, setAccount] = useState('');
    const [accountValidator, setAccountValidator] = useState(null);
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const {
        validator, authUser
    } = useSelector(({ auth }) => auth);

    const handleSubmitForm = () => {
        const data = {
            account_num: account,
            password
            //email
        };

        onSubmitForm(data);
    };

    useEffect(() => {
        const fieldsValid = password.length === 0 || account.length === 0;
        const accountReady = (authUser.info?.person?.account || []).findIndex((item) => item.account_number === account) >= 0;

        setButtonDisabled(fieldsValid || accountReady);
        setAccountValidator(accountReady ? <IntlMessages id="syncUser.accountIsAdded" /> : null)
    }, [password, account]);

    return (
        <>
            <FormGroup className="my-sm-3">
                <TextField
                    label={<IntlMessages id="label.client.account.number" />}
                    variant="outlined"
                    fullWidth
                    onChange={(event) => setAccount(event.target.value)}
                    defaultValue={account}
                    margin="dense"
                    className="mt-1 my-sm-3"
                    name="account_num"
                    error={!!validator.account_num || !account || accountValidator}
                    helperText={validator.account_num || accountValidator}
                />

                {/*<TextField*/}
                {/*    label={<IntlMessages id="appModule.email" />}*/}
                {/*    variant="outlined"*/}
                {/*    fullWidth*/}
                {/*    onChange={(event) => setEmail(event.target.value)}*/}
                {/*    defaultValue={email}*/}
                {/*    margin="dense"*/}
                {/*    className="mt-1 my-sm-3"*/}
                {/*    name="email"*/}
                {/*    error={!!validator.username}*/}
                {/*    helperText={validator.username}*/}
                {/*/>*/}

                <TextField
                    type="password"
                    variant="outlined"
                    label={<IntlMessages id="appModule.password" />}
                    fullWidth
                    onChange={(event) => setPassword(event.target.value)}
                    defaultValue={password}
                    name="password"
                    margin="dense"
                    className="mt-1 my-sm-3"
                    error={!!validator.password || !password}
                    helperText={validator.password}
                />
            </FormGroup>
            <FormGroup className="mt-1 my-sm-3">
                <Button
                    disabled={buttonDisabled}
                    onClick={handleSubmitForm}
                    variant="contained"
                    color="primary"
                    fullWidth
                >
                    <IntlMessages id="appModule.synchronize" />
                </Button>
            </FormGroup>
        </>
    );
};

export default Form;
