import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import {
    Button, LinearProgress, Paper
} from '@material-ui/core';
import {
    // fetchPeriodList,
    fetchInvoiceForPeriod,
    // periodsLoaded
} from 'actions/Payments';
import Select from 'components/select';
import AccountInfo from 'components/AccountInfo';
import {
    setChangeCurrentUKStatus
} from 'actions/Auth';
import PageHeader from 'components/PageHeader';

const Invoices = () => {
    const initialState = {
        period: null,
        format: 'pdf'
    };
    const dispatch = useDispatch();
    const { /*periods,*/ error, loading } = useSelector(({ payments }) => payments);
    const {
        changeCurrentUK
    } = useSelector(({ auth }) => auth);
    const [period, setPeriod] = useState(initialState.period);
    const [format, setFormat] = useState(initialState.format);

    const resetState = () => {
        setPeriod(initialState.period);
        setFormat(initialState.format);
    };

    // useEffect(() => {
    //     if (periods.length === 0) {
    //         // dispatch(fetchPeriodList());
    //     }
    // }, []);

    useEffect(() => {
        if (changeCurrentUK) {
            resetState();
            // dispatch(periodsLoaded([]));
            // dispatch(fetchPeriodList());
            dispatch(setChangeCurrentUKStatus());
        }
    }, [changeCurrentUK]);

    // useEffect(() => {
    //     if (periods.length > 0 && !period) {
    //         setPeriod(periods[0].id);
    //     }
    // }, [periods, period]);

    if (error) {
        return (
            <>
                <PageHeader
                    title={<IntlMessages id="label.client.payment.invoice" />}
                />
                <h4 className="error">{error}</h4>
            </>
        );
    }

    const formats = ['pdf', 'png', 'emf'];

    return (
        <>
            <PageHeader
                title={<IntlMessages id="label.client.payment.invoice" />}
            />
            <AccountInfo />
            {loading && <LinearProgress />}
            {
                // periods.length > 0 && period
                // && (
                //     <Paper className="p-3">
                //         <div className="d-flex align-items-center mb-3 mt-3">
                //             <div className="mr-4">
                //                 <div className="mb-2"><IntlMessages id="label.client.payment.сhoose.period" /></div>
                //                 <Select
                //                     extractItemInfo={(item) => ({ id: item.id, name: item.name })}
                //                     selectedItem={period}
                //                     data={periods}
                //                     onChange={(val) => setPeriod(val)}
                //                 />
                //             </div>
                //             <div className="mr-4">
                //                 <div className="mb-2"><IntlMessages id="appModule.choose.file.format" /></div>
                //                 <Select
                //                     extractItemInfo={(item) => ({ id: item, name: item.toUpperCase() })}
                //                     selectedItem={format}
                //                     data={formats}
                //                     onChange={(val) => setFormat(val)}
                //                 />
                //             </div>
                //             <Button
                //                 size="small"
                //                 className="ml-3"
                //                 variant="contained"
                //                 color="secondary"
                //                 onClick={() => dispatch(fetchInvoiceForPeriod(period, format))}
                //                 disabled={loading}
                //             >
                //                 <IntlMessages id="label.client.payment.get.invoice" />
                //             </Button>
                //         </div>
                //     </Paper>
                // )
            }
        </>
    );
};

export default Invoices;
