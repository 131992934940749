import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    FormGroup,
    Grid,
    InputLabel
} from '@material-ui/core';
import {
    fetchTranslateList,
    fetchTranslateWithId,
    translateListLoadedStatus,
    createTranslate,
    editTranslate,
    deleteTranslate,
    translateSavedSuccess,
    fetchTranslateServiceList,
    translateListLoaded
} from 'actions/Translate';
import {
    setChangeCurrentUKStatus
} from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';
import MenuItemWithPopover from 'components/MenuItemPopover';
import PageHeader from 'components/PageHeader';
import PagePagination from 'components/PagePagination';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ActionButton from 'components/ActionButton';
import SearchIcon from '@material-ui/icons/Search';
import Select from 'components/select';
import Form from './form';
import Search from './Search';
import AccordionList from './Accordion/List';
import AccordionCard from './Accordion/Card';

const Translate = () => {
    const initialState = {
        page: 1,
        limit: 10,
        pageLoading: false,
        create: false,
        loading: false,
        expanded: null,
        query: '',
        service: 'kvartal'
    };
    const dispatch = useDispatch();
    const [page, setPage] = useState(initialState.page);
    const [limit, setLimit] = useState(initialState.limit);
    const [pageLoading, setPageLoading] = useState(initialState.pageLoading);
    const [create, setCreate] = useState(initialState.create);
    const [loading, setLoading] = useState(initialState.loading);
    const [expanded, setExpanded] = React.useState(initialState.expanded);
    const [query, setQuery] = React.useState(initialState.query);
    const [service, setService] = React.useState(initialState.service);

    const {
        locale
    } = useSelector(({ settings }) => settings);
    const {
        list,
        listLoaded,
        error,
        savedAction,
        serviceList
    } = useSelector(({ translate }) => translate);
    const {
        changeCurrentUK
    } = useSelector(({ auth }) => auth);

    const resetState = () => {
        setPage(initialState.page);
        setLimit(initialState.limit);
        setPageLoading(initialState.pageLoading);
        setCreate(initialState.create);
        setLoading(initialState.loading);
        setExpanded(initialState.expanded);
        setQuery(initialState.query);
        setService(initialState.service);
    };

    const handleSetExpand = (idTranslate) => {
        if (idTranslate) dispatch(fetchTranslateWithId(idTranslate));
        setExpanded(idTranslate);
        setCreate(false);
    };

    const handleChangePage = (event, index) => {
        setPage(index);
    };

    const handleCreateTranslate = (dataTranslate) => {
        setLoading(true);
        dispatch(createTranslate({
            ...dataTranslate,
            service
        }));
    };

    const handleChangeTranslate = (dataTranslate) => {
        setLoading(true);
        const { id } = dataTranslate;
        dispatch(editTranslate(id, dataTranslate));
    };

    const handleDeleteTranslate = (id) => {
        setLoading(true);
        dispatch(deleteTranslate(id));
    };

    const handleCloseForm = () => {
        setCreate(false);
        setExpanded(null);
        setLoading(false);
    };

    const handleChangeSearch = (newSearch) => {
        setPage(1);
        setQuery(newSearch);
    };

    useEffect(() => {
        if (serviceList.length === 0) {
            dispatch(fetchTranslateServiceList());
        }
    },[]);

    // обновляем список
    useEffect(() => {
        if (changeCurrentUK) {
            resetState();
            dispatch(translateListLoaded({ data: [] }));
            dispatch(setChangeCurrentUKStatus());
        } else {
            setPageLoading(true);
            dispatch(fetchTranslateList(page, limit, service, query));
        }
    }, [changeCurrentUK, page, limit, query, service]);

    // список загружен
    useEffect(() => {
        if (listLoaded) {
            setPageLoading(false);
            dispatch(translateListLoadedStatus());
        }
    }, [listLoaded]);

    // сохраняем
    useEffect(() => {
        if (savedAction) {
            setLoading(initialState.loading);
            setCreate(initialState.create);
            setExpanded(initialState.expanded);
            dispatch(translateSavedSuccess(false));
            dispatch(fetchTranslateList(page, limit, service, query));
        }
    }, [savedAction]);

    return (
        <>
            <PageHeader
                title={<IntlMessages id="sidebar.dashboard.translate" />}
                buttons={[
                    <MenuItemWithPopover loading={loading} buttonTitle="appModule.search" icon={<SearchIcon />}>
                        {(handleClose) => <Search
                            search={query}
                            onChangeSearch={handleChangeSearch}
                            handleClose={handleClose}
                        />}
                    </MenuItemWithPopover>,
                    <ActionButton
                        title="translate.createTranslate"
                        onClick={() => {
                            setCreate(true);
                            setExpanded(initialState.expanded);
                        }}
                        className="text-secondary"
                        icon={<AddCircleOutlineIcon />}
                    />

                ]}
            />

            <FormGroup className="mb-2 d-flex flex-row align-items-center">
                <div className="mr-2">
                    <InputLabel id="label" className="ml-3 color-theme-body" style={{color: '#000'}}>
                        <IntlMessages id="appModule.service" />
                        {':'}
                    </InputLabel>
                </div>
                <div>
                    <Select selectedItem={service} data={serviceList} onChange={(newService) => setService(newService)} />
                </div>
            </FormGroup>

            {error && <h4 className="error">{error}</h4>}

            {create && (
                <div className="bg-white pt-3 pb-2 mb-3">
                    <Form
                        loading={loading}
                        className="mb-3"
                        handleClose={handleCloseForm}
                        handleSendRequest={handleCreateTranslate}
                    />
                </div>
            )}

            {list.data.length ? (
                <Grid item className="mb-4" style={{ padding: 0 }}>
                    <AccordionCard
                        title={['translate.label.key', `translate.label.${locale.locale}`]}
                    >
                        <AccordionList
                            list={list.data}
                            childItem={(
                                <Form
                                    loading={loading}
                                    isDelete
                                    handleClose={handleCloseForm}
                                    handleSendRequest={handleChangeTranslate}
                                    handleDeleteRequest={handleDeleteTranslate}
                                />
                            )}
                            expanded={expanded}
                            onExpanded={handleSetExpand}
                        />
                    </AccordionCard>
                </Grid>
            ) : (
                <div className="h4 pl-4 pr-4">
                    <IntlMessages id="appModule.nothingFound" />
                </div>
            )}

            <PagePagination
                pageCount={list.meta?.last_page}
                page={page}
                loading={pageLoading}
                handleChangePage={handleChangePage}
            />
        </>
    );
};

export default Translate;
