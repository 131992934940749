export const FETCH_BASKET = 'FETCH_BASKET';
export const BASKET_FETCHED = 'BASKET_FETCHED';
export const ADD_TO_BASKET = 'ADD_TO_BASKET';
export const UPDATE_BASKET = 'UPDATE_BASKET';
export const DELETE_FROM_BASKET = 'DELETE_FROM_BASKET';
export const LOADING_IN_BASKET = 'LOADING_IN_BASKET';

export const FETCH_CATEGORY_LIST = 'FETCH_CATEGORY_LIST';
export const CATEGORY_LIST_FETCHED = 'CATEGORY_LIST_FETCHED';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const LOADING_CATEGORY_LIST = 'LOADING_CATEGORY_LIST';
export const FETCH_CATEGORY_TREE = 'FETCH_CATEGORY_TREE';
export const CATEGORY_TREE_FETCHED = 'CATEGORY_TREE_FETCHED';

export const FETCH_PRODUCT_LIST = 'FETCH_PRODUCT_LIST';
export const PRODUCT_LIST_FETCHED = 'PRODUCT_LIST_FETCHED';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const LOADING_PRODUCT = 'LOADING_PRODUCT';

export const FETCH_ORDER_LIST = 'FETCH_ORDER_LIST';
export const ORDER_LIST_FETCHED = 'ORDER_LIST_FETCHED';
export const ORDER_LOADING = 'ORDER_LOADING';
export const CREATE_ORDER = 'CREATE_ORDER';
export const GET_ORDER_STATUSES = 'GET_ORDER_STATUSES';
export const ORDER_STATUSES_LOADED = 'ORDER_STATUSES_LOADED';
export const GET_ORDER_PAYMENT_STATUSES = 'GET_ORDER_PAYMENT_STATUSES';
export const ORDER_PAYMENT_STATUSES_LOADED = 'GET_ORDER_PAYMENT_STATUSES';
export const GET_ORDER_PAYMENT_TYPES = 'GET_ORDER_PAYMENT_TYPES';
export const ORDER_PAYMENT_TYPES_LOADED = 'ORDER_PAYMENT_TYPES_LOADED';
export const ORDER_LOADED = 'ORDER_LOADED';

export const FETCH_ORDERS_LIST = 'FETCH_ORDERS_LIST';
export const ORDERS_LIST_FETCHED = 'ORDERS_LIST_FETCHED';
export const ORDERS_LIST_LOADING = 'ORDERS_LIST_LOADING';

export const CHANGE_ORDER_STATUS = 'CHANGE_ORDER_STATUS';
export const CLOSE_ORDER = 'CLOSE_ORDER';
export const ORDERS_SAVED = 'ORDERS_SAVED';
