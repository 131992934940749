import React from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ActionButton from 'components/ActionButton';

const moveControls = ({ index, lastIndex, changeOrder }) => (
    <div className="d-flex flex-row">
        <ActionButton
            title="menu.changeOrder"
            onClick={() => changeOrder(index, index - 1)}
            className={index === 0 ? 'disabled' : 'text-secondary'}
            icon={<ArrowDropUpIcon />}
            disabled={index === 0}
        />
        <ActionButton
            title="menu.changeOrder"
            onClick={() => changeOrder(index, index + 1)}
            className={index === lastIndex ? 'disabled' : 'text-secondary'}
            icon={<ArrowDropDownIcon />}
            disabled={index === lastIndex}
        />
    </div>
);

export default moveControls;
