export const defaultSortDataLang = [
    {
        value: 'id',
        name: 'filter.byId'
    },
    {
        value: 'amount_payments',
        name: 'filter.byAmountPayments'
    }
];

export const defaultSortSelect = {
    order: 'id',
    direction: 1
};
