import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { COLLAPSED_DRAWER, FIXED_DRAWER, MINI_DRAWER } from 'constants/ActionTypes';
import SearchBox from 'components/SearchBox';
import CardHeader from 'components/dashboard/Common/CardHeader/index';
import { switchLanguage, toggleCollapsedNav, setDrawerType } from 'actions/Setting';
import IntlMessages from 'util/IntlMessages';
import LanguageSwitcher from 'components/LanguageSwitcher/index';
// import CartMini from 'components/CartMini/index';
import AppNotification from 'components/AppNotification';
// import MailNotification from 'components/MailNotification';
import UserInfo from 'components/UserInfo';
import _ from 'lodash';
import { fetchAppealWithId } from 'actions/Appeal';
import { Avatar } from '@material-ui/core';
import Notifications from '../../../../components/Notifications';

const Index = (props) => {
    const dispatch = useDispatch();
    const { drawerType, locale, width } = useSelector(({ settings }) => settings);
    const { unreadMessages } = useSelector(({ messages }) => messages);
    const { list } = useSelector(({ appeal }) => appeal);
    const [langSwitcher, setLangSwitcher] = useState(false);
    //const [mailNotification, setMailNotification] = useState(false);
    const [appNotificationVisible, setAppNotificationVisible] = useState(false);
    const [searchBox, setSearchBox] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [notifications, addNotification] = useState([]);

    const onAppNotificationSelect = () => {
        setAppNotificationVisible(!appNotificationVisible);
    };

    /*const onMailNotificationSelect = () => {
        setMailNotification(!mailNotification);
    };*/
    const onLangSwitcherSelect = () => {
        setLangSwitcher(!langSwitcher);
    };

    const onSearchBoxSelect = () => {
        setSearchBox(!searchBox);
    };

    const handleRequestClose = () => {
        setSearchBox(false);
        setLangSwitcher(false);
        //setMailNotification(false);
        setSearchBox(false);
    };

    const setFixedDrawer = () => {
        dispatch(setDrawerType(FIXED_DRAWER));
    };

    const setCollapsedDrawer = () => {
        dispatch(setDrawerType(COLLAPSED_DRAWER));
    };

    const setMiniDrawer = () => {
        dispatch(setDrawerType(MINI_DRAWER));
    };

    const onToggleCollapsedNav = () => {
        const val = !props.navCollapsed;
        if (drawerType === 'fixed_drawer' && width > 1200) {
            setMiniDrawer();
        } else if (drawerType === 'mini_drawer'){
            setFixedDrawer();
        } else if (width < 1200 && drawerType !== 'collapsible') {
            setCollapsedDrawer();
        }
        dispatch(toggleCollapsedNav(val));
    };

    const updateSearchText = (evt) => {
        setSearchText(evt.target.value);
    };

    const onSwitchLanguage = (lang) => {
        dispatch(switchLanguage(lang));
    };

    // const isMarketPlace =
    //     props.history.location.pathname?.includes('/marketplace') &&
    //     !props.history.location.pathname?.includes('/marketplace/create-order') ||
    //     false;

    useEffect(() => {
        if (Object.keys(unreadMessages).length > 0) {
            _.forEach(unreadMessages, (unreadObj, page) => {
                _.forEach(unreadObj, (count, id) => {
                    const appealItem = list.data.find((el) => el.id == id);
                    const appealIndex = notifications.findIndex(el => el.data.id == id);
                    if (appealIndex < 0) {
                        if (appealItem) {
                            addNotification((state) => ([...state, {
                                count, data: appealItem, action: () => props.history.push(`/${page}/${id}`)
                            }]));
                        } else {
                            dispatch(fetchAppealWithId(id, true));
                        }
                    }
                }
                );
            });
        }
    }, [unreadMessages, list.data]);

    return (
        <AppBar className="app-main-header">
            <Toolbar className="app-toolbar" disableGutters={false}>

                <IconButton
                    className="mr-3 d-block collapse-icon"
                    aria-label="Menu"
                    onClick={onToggleCollapsedNav}
                    size="small"
                >
                    <span
                        className="iconify"
                        data-icon="ion-reorder-three"
                        style={{ fontSize: '2em' }}
                    />
                </IconButton>

                <UserInfo />

                {/*<SearchBox*/}
                {/*    styleName="d-none d-lg-block"*/}
                {/*    placeholder=""*/}
                {/*    onChange={updateSearchText}*/}
                {/*    value={searchText}*/}
                {/*/>*/}

                <ul className="header-notifications list-inline ml-auto">

                    {/*{isMarketPlace === true && (*/}
                    {/*    <li className="list-inline-item">*/}
                    {/*        <CartMini*/}
                    {/*            onCreateOrder={() => {*/}
                    {/*                props.history.push('/marketplace/create-order');*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    </li>*/}
                    {/*)}*/}

                    <li className="list-inline-item">
                        <Dropdown
                            className="quick-menu"
                            isOpen={langSwitcher}
                            toggle={onLangSwitcherSelect}
                        >

                            <DropdownToggle
                                className="d-inline-block"
                                tag="span"
                                data-toggle="dropdown"
                            >
                                <IconButton className="icon-btn">
                                    <i className={`flag flag-24 flag-${locale.icon}`} />
                                </IconButton>
                            </DropdownToggle>

                            <DropdownMenu right className="w-50">
                                <LanguageSwitcher
                                    switchLanguage={onSwitchLanguage}
                                    handleRequestClose={handleRequestClose}
                                />
                            </DropdownMenu>
                        </Dropdown>
                    </li>

                    <li className="list-inline-item app-tour">
                        <Notifications />
                    </li>

                    {/*<li className="list-inline-item app-tour">*/}
                    {/*    <Dropdown*/}
                    {/*        className="quick-menu"*/}
                    {/*        isOpen={appNotificationVisible}*/}
                    {/*        toggle={onAppNotificationSelect}*/}
                    {/*    >*/}

                    {/*        <DropdownToggle*/}
                    {/*            className="d-inline-block"*/}
                    {/*            tag="span"*/}
                    {/*            data-toggle="dropdown"*/}
                    {/*        >*/}
                    {/*            <IconButton className="icon-btn">*/}
                    {/*                <i className={`zmdi zmdi-notifications-none infinite wobble ${notifications.length > 0 ? 'icon-alert animated' : ''}`} />*/}
                    {/*            </IconButton>*/}
                    {/*        </DropdownToggle>*/}

                    {/*        {notifications.length > 0*/}
                    {/*        && (*/}
                    {/*            <DropdownMenu right>*/}
                    {/*                <CardHeader*/}
                    {/*                    styleName="align-items-center"*/}
                    {/*                    heading={<IntlMessages id="appNotification.title" />}*/}
                    {/*                />*/}
                    {/*                <AppNotification data={notifications} onAppNotificationSelect={onAppNotificationSelect} />*/}
                    {/*            </DropdownMenu>*/}
                    {/*        )}*/}
                    {/*    </Dropdown>*/}
                    {/*</li>*/}
                    {/*<li className="list-inline-item mail-tour">
                        <Dropdown
                            className="quick-menu"
                            isOpen={mailNotification}
                            toggle={onMailNotificationSelect}
                        >
                            <DropdownToggle
                                className="d-inline-block"
                                tag="span"
                                data-toggle="dropdown"
                            >

                                <IconButton className="icon-btn">
                                    <i className="zmdi zmdi-comment-alt-text zmdi-hc-fw" />
                                </IconButton>
                            </DropdownToggle>


                            <DropdownMenu right>
                                <CardHeader
                                    styleName="align-items-center"
                                    heading={<IntlMessages id="mailNotification.title" />}
                                />
                                <MailNotification />
                            </DropdownMenu>
                        </Dropdown>
                    </li>*/}

                </ul>

                <div className="ellipse-shape" />
            </Toolbar>
        </AppBar>
    );
};


export default withRouter(Index);
