import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// проверяем роли пользователя
// scopes - массив
const useCheckUserScopes = (scopes) => {
    const {
        auth
    } = useSelector((store)  => store);
    const userScopes = auth?.authUser?.info?.scopes || [];

    const [isScopes, setIsScopes] = useState(false);

    useEffect(() => {
        const checkScopes =
            Array.isArray(scopes)
            ? scopes
            : [scopes];

        const checkedScopes = checkScopes.reduce((res, scope) => {
                const isScope = userScopes.includes(scope);
                return res || isScope;
            },
            false
        );
        setIsScopes(checkedScopes);
    }, [scopes, userScopes]);

    return isScopes;
};

export default useCheckUserScopes;
