import addErrorHandling from 'util/errors/error.handling';
import urls from './api.urls';
import instance from '../axios.config';

const ApiAdminMethods = {
    fetchUserList: async (params) => {
        const response = await instance.get(urls.fetchUserList(), { params });
        return response?.data;
    },

    editUserData: async (data) => {
        const response = await instance.put(urls.editUserData(), data);
        return response.data;
    },

    loadRoles: async (params) => {
        const response = await instance.get(
            urls.loadRoles(),
            {
                params
            }
        );
        return response.data;
    },
    
    
loadUserListWithAccountInfo: async (params) => {
    const response = await instance.get(urls.loadUserListWithAccountInfo(), { params });
    return response.data;
},
};

export default addErrorHandling(ApiAdminMethods);