import React, { useEffect, useState } from 'react';
import {
    Button,
    FormGroup,
    Tooltip,
    LinearProgress
} from '@material-ui/core';
import { Card, CardBody, CardHeader } from 'reactstrap';
import moment from 'moment';
import { userScopesList, useCheckUserScopes } from '../../../../components/Helpers/userScopes';
import OrderMenu from '../OrderMenu';
import ModalDialog from '../../../../components/ModalDialog';
import ProductList from '../ProductList';
import ActionConfirmation from '../../../../components/ActionConfirmation';
import OrderStatusForm from '../OrderStatusForm';
import {useDispatch, useSelector} from 'react-redux';
import {
    changeOrderStatus,
    closeOrder,
    orderSaved,
} from '../../../../actions/Market';


const Order = (props) => {
    const { order } = props;
    const dispatch = useDispatch();
    const { saved }  = useSelector(({ market }) => market);
    const isUser = useCheckUserScopes([userScopesList.user]);
    // modals
    const [showProducts, setShowProductsList] = useState(false);
    const [isCloseOrder, setIsCloseOrder] = useState(false);
    const [isChangeStatus, setIsChangeStatus] = useState(false);
    // loadings
    const [statusLoading, setStatusLoading] = useState(false);
    const [orderLoading, setOrderLoading] = useState(false);

    const handleCloseModal = () => setShowProductsList(false);
    const handleCancelClick = () => setIsCloseOrder(false);
    const handleConfirmClick = () => {
        handleCancelClick();
        setOrderLoading(true);
        dispatch(closeOrder(
            order.id
        ));
    };
    const handleCloseChangeStatus = () => setIsChangeStatus(false);

    const handleChangeOrderStatus = (newStatus) => {
        handleCloseChangeStatus();
        setStatusLoading(true);
        // изменяем статус
        dispatch(changeOrderStatus(
            order.id,
            newStatus
        ));
    };

    useEffect(() => {
        if (saved === true && (
            statusLoading === true
            || orderLoading === true
        )) {
            setStatusLoading(false);
            setOrderLoading(false);
            orderSaved(false);
        }
    }, [saved]);

    const renderAddress = (address) => {
        if (address) {
            const {
                city_name,
                street,
                house
            } = address;
            return (
                <div>
                    <strong>Ваш адрес:</strong> {city_name}, {street}, {house}
                </div>
            );
        }
    };

    return (
        <>
            <Card className="mb-0">
                <CardHeader className="d-flex justify-content-between align-items-center">
                    <div>
                        <strong>
                            Заказ №{order.id} ({order.status_text})
                        </strong>
                        &nbsp;&nbsp;{moment(order.created_at).format('DD.MM.YYYY HH:mm:ss')}
                    </div>

                    {isUser && order.payment_link && order.payment_status === 1 && order.payment_type === 1 ? (
                        <div>
                            <Tooltip title="Перейдите на страницу оплаты заказа">
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="secondary"
                                    onClick={() => {
                                        const link = document.createElement('a');
                                        link.href = order.payment_link;
                                        link.click();
                                    }}
                                >
                                    Оплатить заказ
                                </Button>
                            </Tooltip>
                        </div>
                    ) : ''}

                    <OrderMenu
                        list={[
                            {
                                name: 'Изменить статус',
                                onClick: () => setIsChangeStatus(true)
                            },
                            {
                                name: 'Отменить',
                                onClick: () => setIsCloseOrder(true)
                            }
                        ]}
                    />

                </CardHeader>
                <CardBody>

                    {/*<div className="mb-1">*/}
                    {/*    <strong>Дата и время:</strong> {moment(order.created_at).format('DD.MM.YYYY HH:mm:ss')}*/}
                    {/*</div>*/}

                    <div className="mb-1">
                        <strong>Тип оплаты:</strong> {order.payment_type_text}
                    </div>

                    <div className="mb-1">
                        <strong>Статус оплаты:</strong> {order.payment_status_text}
                    </div>

                    <div className="mb-1">
                        <strong>Комментарий:</strong> {order.comment || 'не указан'}
                    </div>

                    {renderAddress(order.address)}

                    {order.products?.length > 0 && (
                        <div className="mt-3">
                            <Tooltip title="Показать товары в заказе">
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => setShowProductsList(true)}
                                >
                                    список товаров
                                </Button>
                            </Tooltip>
                        </div>
                    )}

                </CardBody>

                {orderLoading && <LinearProgress />}
            </Card>

            {/* список товаров в заказе */}
            <ModalDialog
                isOpen={showProducts}
                handleClose={handleCloseModal}
                titleText={`Список товаров в заказе №${order?.id}`}
            >
                <ProductList
                    list={order?.products || []}
                />

                <FormGroup row className="justify-content-end mt-3 mb-3">
                    <Button onClick={handleCloseModal} variant="contained">
                        Закрыть
                    </Button>
                </FormGroup>
            </ModalDialog>

            <ModalDialog isOpen={isCloseOrder}>
                <ActionConfirmation
                    onConfirm={handleConfirmClick}
                    onCancel={handleCancelClick}
                    text="Вы действительно хотите отменить заказ?"
                />
            </ModalDialog>

            <ModalDialog
                isOpen={isChangeStatus}
                titleText="Изменить статус заказа"
                handleClose={handleCloseChangeStatus}
            >
                <OrderStatusForm
                    data={order.status}
                    onAccept={handleChangeOrderStatus}
                    onClose={handleCloseChangeStatus}
                    loading={statusLoading}
                />
            </ModalDialog>
        </>
    );
};

export default Order;
