import {
    FETCH_NEWS_LIST,
    LOAD_NEWS_WITH_ID,
    NEWS_LIST_LOADED_SUCCESS,
    SAVE_CURRENT_NEWS,
    SET_NEWS_ERROR,
    FETCH_NEWS_STATUSES,
    NEWS_STATUSES_LOADED,
    CREATE_NEWS,
    PUBLISH_NEWS,
    EDIT_NEWS,
    DELETE_NEWS,
    NEWS_SUCCESSFULLY_SAVED,
    SET_NEWS_LOADING
} from 'constants/ActionTypes';

export const setLoading = (bool) => ({
    type: SET_NEWS_LOADING,
    payload: bool
})

export const fetchNewsList = (page, limit, data = {}) => ({
    type: FETCH_NEWS_LIST,
    payload: { page, limit, data }
});

export const fetchNewsWithId = (id) => ({
    type: LOAD_NEWS_WITH_ID,
    payload: { id }
});

export const newsLoaded = (news) => ({
    type: NEWS_LIST_LOADED_SUCCESS,
    payload: news
});

export const newsWithIdLoaded = (news) => ({
    type: SAVE_CURRENT_NEWS,
    payload: news
});

export const setError = (bool) => ({
    type: SET_NEWS_ERROR,
    payload: bool
});

export const fetchStatuses = () => ({
    type: FETCH_NEWS_STATUSES
});

export const statusesLoaded = (statuses) => ({
    type: NEWS_STATUSES_LOADED,
    payload: statuses
});

export const createNews = (data) => ({
    type: CREATE_NEWS,
    payload: { data }
});

export const publishNews = (id) => ({
    type: PUBLISH_NEWS,
    payload: { id }
});

export const editNews = (id, data) => ({
    type: EDIT_NEWS,
    payload: { id, data }
});

export const deleteNews = (id) => ({
    type: DELETE_NEWS,
    payload: { id }
});

export const newsSavedSuccess = (bool = false, action = true) => ({
    type: NEWS_SUCCESSFULLY_SAVED,
    payload: { bool, action }
});
