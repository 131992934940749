import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    stepper: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    actionsContainer: {
        marginTop: theme.spacing(2),
        /*marginBottom: theme.spacing(2),*/
        textAlign: 'center'
    },
    resetContainer: {
        padding: theme.spacing(3)
    }
}));

export default useStyles;
