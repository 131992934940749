import {
    all, call, select, put, takeLatest
} from 'redux-saga/effects';

import {
    DEBTORS_LIST_LOADED,
    DEBTORS_COMPANIES_LOADED,
    DEBTORS_FETCH_USER_BY_UK,
    DEBTORS_SEND_MESSAGE_EMAIL_USER,
    DEBTORS_SEND_MESSAGE_TELEGRAM_USER
} from 'constants/ActionTypes';
import {
    debtorsLoaded,
    setError,
    debtorsLoadedStatusChange,
    debtorsCompaniesLoaded,
    fetchDebtorsUserByUkLoaded,
    setDebtorsMessageStatus,
    setDebtorsUserByUkLoadedStatus
} from 'actions/Debtors';
import { showMessage } from 'actions/Messages';
import { getActionForError } from 'util/errors';
import api from 'util/api/api.debtors.methods';

export const stateSelector = (state) => state.Debt;
export const stateAuthSelector = (state) => state.auth;

function* loadDebtorsListSaga({ payload }) {
    const { page, limit, data } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.getList, page, limit, data, authUser?.access_token);
        yield put(debtorsLoaded(response));
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(debtorsLoadedStatusChange(true));
    }
}

function* loadDebtorsCompaniesListSaga() {
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.getCompaniesList, authUser?.access_token);
        yield put(debtorsCompaniesLoaded(response));
    } catch (error) {
        yield put(getActionForError(error));
    }
}

function* loadDebtorsUserUkSaga({ payload }) {
    const account_num = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.getUserByUk, account_num, authUser?.access_token);
        yield put(fetchDebtorsUserByUkLoaded(response));
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        // finish loaded
        yield put(setDebtorsUserByUkLoadedStatus(true));
    }
}

function* sendMessageEmailUserSaga({ payload }) {
    const data = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.sendMessageEmailUser, data, authUser?.access_token);
        //const { success } = response;
        yield put(showMessage('success', 'Сообщение собственнику отправлено на почту.', null, false));
        yield put(setDebtorsMessageStatus('email'));
    } catch (error) {
        yield put(setDebtorsMessageStatus('email'));
        yield put(getActionForError(error));
    }
}

function* sendMessagTelegramkSaga({ payload }) {
    const data = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.sendMessageTelegramUser, data, authUser?.access_token);
        //const { success } = response;
        yield put(showMessage('success', 'Сообщение собственнику отправлено в telegram.', null, false));
        yield put(setDebtorsMessageStatus('telegram'));
    } catch (error) {
        yield put(setDebtorsMessageStatus('telegram'));
        yield put(getActionForError(error));
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(DEBTORS_LIST_LOADED, loadDebtorsListSaga),
        takeLatest(DEBTORS_COMPANIES_LOADED, loadDebtorsCompaniesListSaga),
        takeLatest(DEBTORS_FETCH_USER_BY_UK, loadDebtorsUserUkSaga),
        takeLatest(DEBTORS_SEND_MESSAGE_EMAIL_USER, sendMessageEmailUserSaga),
        takeLatest(DEBTORS_SEND_MESSAGE_TELEGRAM_USER, sendMessagTelegramkSaga)
    ]);
}
