import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import CardBox from 'components/Card';
import EditIcon from '@material-ui/icons/Edit';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import {
    editAppeal, watchTheAppeal, stopWatchTheAppeal
} from 'actions/Appeal';
import ModalDialog from 'components/ModalDialog';
import ActionConfirmation from 'components/ActionConfirmation';
import CardMenu from 'components/Card/CardDropDownMenu';
import {
    Badge
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import tagBg from 'components/Helpers/TagBackgroundWithStatus';
import DateTimeInfo from 'components/DateTimeInfo';
import CardAvatarWithStatus from 'components/Card/CardAvatarWithStatus';
import titleBg from 'components/Helpers/HeaderBackgroundWithStatus';
import AttachmentView from 'components/AttachmentView';
import TagBgUK from 'components/Helpers/TagBackgroundWithUK';

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -3,
        top: -5,
        padding: '0 4px'
    }
}))(Badge);

const AppealItem = ({
    item, setEditorOpen, setChatOpen, itemStatus, isActive, typeObj, uk
}) => {
    const dispatch = useDispatch();
    const { isAdmin, authUser, isOperator } = useSelector(({ auth }) => auth);
    const [anchorEl, setAnchorEl] = useState(null);
    const actionsOpened = Boolean(anchorEl);
    const [isDialogOpen, setDialogVisible] = useState(false);
    const [currentMethod, setMethod] = useState();
    const { unreadMessages } = useSelector(({ messages }) => messages);
    const unreadCount = unreadMessages?.appeal?.[item.id];

    const handleActionsClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleActionsClose = () => {
        setAnchorEl(null);
    };

    const changeAppealStatus = (status) => {
        const newAppeal = {
            ...item,
            status
        };
        dispatch(editAppeal(item.id, newAppeal));
    };

    const watching = item?.leading_managers?.includes(authUser?.info?.identifier);

    const actions = {
        edit: {
            onClick: setEditorOpen,
            text: 'appModule.edit',
            tooltipTitle: 'appModule.edit',
            className: 'text-secondary',
            icon: <EditIcon />,
            disabled: [],
            admin: true,
            operator: true
        },
        inwork: {
            onClick: () => changeAppealStatus(1),
            text: 'appeal.takeToWork',
            confirmText: 'appeal.confirmToStartWork',
            tooltipTitle: 'appeal.takeToWork',
            className: 'text-info',
            icon: <ThumbUpAltIcon />,
            disabled: [1],
            status: 1,
            admin: true,
            operator: true
        },
        // cancel: {
        //     onClick: () => changeAppealStatus(2),
        //     text: 'appeal.toCancel',
        //     confirmText: 'appeal.confirmToCancel',
        //     tooltipTitle: 'appeal.toCancel',
        //     className: 'text-danger',
        //     icon: <CancelIcon />,
        //     disabled: [2],
        //     status: 2,
        //     admin: true,
        //     operator: true
        // },
        complete: {
            onClick: () => changeAppealStatus(3),
            text: 'appeal.toComplete',
            confirmText: 'appeal.confirmComplete',
            tooltipTitle: 'appeal.toComplete',
            className: 'text-primary',
            icon: <DoneIcon />,
            disabled: [3],
            status: 3,
            admin: true,
            operator: true
        },
        // watch: {
        //     onClick: watching ? () => dispatch(stopWatchTheAppeal(item.id)) : () => dispatch(watchTheAppeal(item.id)),
        //     text: watching ? 'appeal.stopWatchTheAppeal' : 'appeal.watchTheAppeal',
        //     confirmText: watching ? 'appeal.confirmStopWatching' : 'appeal.confirmWatching',
        //     tooltipTitle: watching ? 'appeal.stopWatchTheAppeal' : 'appeal.watchTheAppeal',
        //     className: 'text-primary',
        //     // icon: <DoneIcon />,
        //     disabled: [],
        //     admin: true,
        //     operator: true
        // },
        openChat: {
            onClick: setChatOpen,
            text: 'messages.showMessages',
            disabled: isAdmin && !watching,
            admin: false,
            operator: false
        }
    };

    const handleButtonClick = (methodName) => {
        setMethod(methodName);
        handleActionsClose();
        if (methodName === 'edit' || methodName === 'openChat') {
            actions[methodName].onClick();
        } else {
            setDialogVisible(true);
        }
    };

    const handleConfirmClick = () => {
        actions[currentMethod].onClick();
        setMethod();
        setDialogVisible(false);
    };

    const handleCancelClick = () => {
        setMethod();
        setDialogVisible(false);
    };

    const statusesForHeader = {
        0: 'new', 1: 'started', 3: 'finished', 2: 'deleted'
    };
    const statusName = statusesForHeader[item.status];

    return (
        <>
            {/* confirmation delete etc. */}
            <ModalDialog isOpen={isDialogOpen}>
                <ActionConfirmation
                    onConfirm={handleConfirmClick}
                    onCancel={handleCancelClick}
                    methodText={actions[currentMethod]?.confirmText}
                />
            </ModalDialog>
            <CardBox
                avatar={CardAvatarWithStatus(statusName, itemStatus)}
                icon={(
                    <StyledBadge badgeContent={unreadCount} color="secondary">
                        <MoreVertIcon />
                    </StyledBadge>
                )}
                title={item.title || typeObj.description}
                subheader={<DateTimeInfo time={item.created_at} />}
                className={`${isActive ? 'bg-primary' : titleBg(statusName)} card-header_with_status`}
                handleActionsClick={handleActionsClick}
                actionsClass="tagUK justify-content-between"
                actions={(
                    <>
                        {uk && <TagBgUK uk={uk}/>}

                        <div className={cn('jr-tag', tagBg(item.type))}>{typeObj.description}</div>
                    </>
                )}
            >

                {typeObj?.rules?.map((itemType) => {
                    const {
                        allowed_values = [],
                        field,
                        name,
                        type = '',
                    } = itemType;

                    if (field === 'type') return null;

                    switch (type) {
                        case 'select': {
                            const valueId = item[field] || 0;
                            const value = allowed_values.find(({ value }) => value === valueId);

                            if (valueId) {
                                return (
                                    <div key={field}>
                                        <b>{name}:</b> {value.text || ''}
                                    </div>
                                );
                            }
                            break;
                        }

                        case 'file':

                            if (item[field] && item[field].length > 0) {
                                return (
                                    <div className="mt-2" key={field}>
                                        {item[field].length > 0 && item[field]?.map((attachment, index) => (
                                            <AttachmentView key={`${attachment.url}_${index}`} attachment={attachment}
                                                            isPreview/>
                                        ))}
                                    </div>
                                );
                            }
                            break;

                        default:
                            if (item[field]) {
                                return (
                                    <div key={field}>
                                        <b>{name}:</b> {Array.isArray(item[field]) ? item[field].join('') : item[field] || ''}
                                    </div>
                                );
                            }
                            break;
                    }

                    return null;
                })}

            </CardBox>
            <CardMenu
                menuState={actionsOpened}
                anchorEl={anchorEl}
                handleRequestClose={handleActionsClose}
                handleButtonClick={handleButtonClick}
                actions={actions}
                status={item.status}
                unreadCount={unreadCount}
                isAdmin={isAdmin}
                isOperator={isOperator}
            />
        </>
    );
};

export default AppealItem;
