import React, { useEffect, useState } from 'react';
import { Collapse, List, ListItem } from '@material-ui/core';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import { useHistory } from 'react-router-dom';

import IntlMessages from '../../util/IntlMessages';
import NavList from './NavList';

const NavCollapse = (props) => {
    const history = useHistory();

    const { name, icon, children = [] } = props;
    const isExpandable = children && children.length > 0;
    const [open, setOpen] = useState(false);

    /**
   * Check if the given url can be found
   * in one of the given parent's children
   *
   * @param parent
   * @param url
   * @returns {boolean}
   */
    function isUrlInChildren(parent = {}, url) {
        if (!parent.children) {
            return false;
        }

        for (let i = 0; i < parent.children.length; i++) {
            if (parent.children[i].children) {
                if (isUrlInChildren(parent.children[i], url)) {
                    return true;
                }
            }

            if (parent.children[i].link === url || url.includes(parent.children[i].link)) {
                return true;
            }
        }

        return false;
    }

    useEffect(() => {
        if (isUrlInChildren(props, history.location.pathname)) {
            setOpen(true);
        } else {
            setOpen(false);
        }
        const unlisten = history.listen((location, action) => {
            if (isUrlInChildren(props, location.pathname)) {
                setOpen(true);
            } else {
                setOpen(false);
            }
        });
        unlisten();
        return isUrlInChildren;
    }, []);

    function handleClick() {
        setOpen(!open);
    }

    const MenuCollapse = (
        <ListItem className="nav-collapse-btn" button onClick={handleClick}>
            {/* Display an icon if any */}
            {!!icon && (
                <span>
                    <i className="iconify menu-icon" data-icon={`${icon}`} />
                </span>
                // <i className={`zmdi zmdi-hc-fw  zmdi-${icon}`} />
            )}
            <span className="nav-text">
                {name.title
                    ? <IntlMessages id={name.title} />
                    : name}
            </span>
            {/* Display the expand menu if the item has children */}
            {isExpandable && !open && <IconExpandMore className="nav-arrow" />}
            {isExpandable && open && <IconExpandLess className="nav-arrow" />}
        </ListItem>
    );

    const MenuItemChildren = isExpandable ? (
        <Collapse className="nav-collapse-item" in={open} timeout="auto">
            <NavList list={children} component="div" />
        </Collapse>
    ) : null;

    return (
        <List component="div" className={`nav-collapse ${open ? 'open' : ''}`}>
            {MenuCollapse}
            {MenuItemChildren}
        </List>
    );
};

export default NavCollapse;
