import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ClientCard from 'components/ClientCard';
import IntlMessages from 'util/IntlMessages';
import PageHeader from 'components/PageHeader';
import useCurrentUser from '../../../util/useCurrentUser';

const ClientInfo = () => {
    const {
        authUser,
        currentUK
    } = useSelector(({ auth }) => auth);
    const user = useCurrentUser();

    const name = `${user?.first_name || ''} ${user?.last_name || ''}`;
    const [currentAddress, setCurrentAddress] = useState({});

    useEffect(() => {
        const addresses = authUser?.info?.newAddressList || [];

        const findAddress = addresses.find((address) => currentUK.account_number === address.account_number);
        if (findAddress) {
            setCurrentAddress(findAddress);
        } else {
            setCurrentAddress({});
        }
    }, [authUser, currentUK.account_number]);

    const userData = {
        title: {
            key: 'label.client.account.number',
            txt: currentAddress.account_number,
            ico: ''
        },
        items: [
            {
                key: !!currentAddress.account_number ? 'label.client.owner' : 'label.client.user',
                txt: name,
                ico: 'fa:user'
            },
            {
                key: 'label.client.email',
                txt: authUser?.info?.email,
                ico: 'fa:envelope',
                type: 'link'
            },
            {
                key: 'label.client.address',
                txt: currentAddress.address,
                ico: 'fa:building'
            },
            {
                key: 'label.client.account.balance',
                txt: 0,
                ico: 'fa:money'
            },
            // {
            //     key: 'label.client.account.telegramTokenSync',
            //     txt: authUser?.info?.person?.person_token,
            //     ico: 'fa:telegram',
            //     copy: true
            // }
        ]
    };
    return (
        <>
            <PageHeader
                title={<IntlMessages id="label.user.client.info" />}
            />
            <div className="col-12 col-md-10 col-lg-6">
                <ClientCard userData={userData} />
            </div>
        </>
    );
};

export default ClientInfo;
