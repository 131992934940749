import addErrorHandling from 'util/errors/error.handling';
import Urls from './api.urls';
import instance from './axios.config';

// const instance = axios.create({
//     // Reject only if the status code is greater than or equal to 500
//     // validateStatus: status => status < 500,
// });

const ApiPaymentsMethods = {
    getAccrualsList: async (access_token, params) => {
        const response = await instance.get(Urls.getAccrualsList(),
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                },
                params
            });
        return response.data;
    },

    getInvoiceForPeriod: async (period, format, access_token) => {
        const response = await instance.post(Urls.getInvoiceForPeriod(),
            { period: JSON.stringify(period), format },
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                },
                responseType: 'blob'
            });
        return response.data;
    },

    getPaymentsList: async (page = 0, limit = 10, filter, access_token) => {
        const response = await instance.get(Urls.getPaymentsList(page, limit),
            {
                params: filter,
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    getPaymentStatuses: async (access_token) => {
        const response = await instance.get(Urls.getPaymentStatuses(),
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },
};

export default addErrorHandling(ApiPaymentsMethods);
