import React from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import Navigation from '../../components/Navigation';

const SideBarContent = ({ menu = [] }) => {
    const createMenu = (menus) => menus.map((item) => {
        let { children } = item;
        if (children && children.length > 0) {
            children = createMenu(item.children);
        }
        return ({
            ...item,
            children,
            link: item.link || '',
            type: item.children.length > 0 ? 'collapse' : 'item'
        });
    });

    return (
        <CustomScrollbars className=" scrollbar">
            <Navigation menuItems={createMenu(menu)} />
        </CustomScrollbars>
    );
};

export default SideBarContent;
