import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import {
    fetchNewsList, fetchStatuses, newsSavedSuccess, createNews, setLoading, newsLoaded
} from 'actions/News';
import {
    setChangeCurrentUKStatus
} from 'actions/Auth';
import {
    Grid, LinearProgress
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Pagination from '@material-ui/lab/Pagination';
import EditorForm from 'components/ArticleList/EditorForm';
import PageHeader from 'components/PageHeader';
import ActionButton from 'components/ActionButton';
import useStatuses from 'components/Helpers/useStatuses';
import NewsItem from './NewsItem';
import '../../../styles/pages/_news.scss';
import MenuItemWithPopover from '../../../components/MenuItemPopover';
import SortIcon from '@material-ui/icons/Sort';
import SortingComponent from '../../../components/Sorting';
import SearchIcon from '@material-ui/icons/Search';
import Search from './Search';
import { debounce } from 'lodash';

const News = ({ history }) => {
    const {
        isAdmin,
        currentUK,
        changeCurrentUK,
        isOperator
    } = useSelector(({ auth }) => auth);

    const initialState = {
        page: 1,
        limit: 10,
        isEditorOpen: false,
        sort: {
            order: isAdmin ? 'id' : 'published_at',
            direction: 0
        },
        query: '',
    };
    const dispatch = useDispatch();
    const statuses = useStatuses(fetchStatuses, 'news');
    const {
        list, error, newsSaved, loading
    } = useSelector(({ news }) => news);
    const [isEditorOpen, setEditorOpen] = useState(initialState.isEditorOpen);
    const [page, setPage] = useState(initialState.page);
    const [limit, setLimit] = useState(initialState.limit);
    const [sort, setSort] = useState(initialState.sort);
    const [query, setQuery] = useState(initialState.query);

    const handleChangeSearch = (search) => {
        setQuery(search);
        setPage(1);
    };
    const handleChangeSearchDebounce = debounce(handleChangeSearch, 800);

    const getFilter = {
        ...sort,
        ...(query.length > 0 ? { title: query } : {})
    };

    useEffect(() => {
        if (changeCurrentUK) {
            setPage(initialState.page);
            setLimit(initialState.limit);
            setSort(initialState.sort);
            setQuery(initialState.query);
            setEditorOpen(initialState.isEditorOpen);
            dispatch(newsLoaded({ data: [] }));
            dispatch(setChangeCurrentUKStatus());
        } else {
            dispatch(setLoading(true));
            dispatch(fetchNewsList(page, limit, getFilter));
        }
    }, [page, limit, query, sort, changeCurrentUK]);

    useEffect(() => {
        if (newsSaved) {
            dispatch(newsSavedSuccess(false));
            dispatch(fetchNewsList(page, limit, getFilter));
        }
    }, [newsSaved, page, limit]);

    const handleChange = (event, value) => {
        setPage(value);
    };

    const handleSendRequest = (news) => {
        const newNews = {
            ...news,
            uk_name: currentUK.uk_name
        };
        setEditorOpen(false);
        dispatch(createNews(newNews));
    };

    const handleChangeSort = (order, direction) => {
        setSort({
            order,
            direction
        });
    };

    const sortBy = [
        { value: 'id', name: 'filter.byId', description: 'По ID' },
        { value: 'created_at', name: 'filter.byCreateDate', description: 'По дате создания' },
        { value: 'status', name: 'filter.byStatus', description: 'По статусу' },
        { value: 'published_at', name: 'filter.byPublishedDate', description: 'По дате публикации' }
    ];

    return (
        <div className="news_page">
            <PageHeader
                title={<IntlMessages id="sidebar.dashboard.news" />}
                buttons={[
                    (isAdmin || isOperator) && (
                        <ActionButton
                            title="appModule.createNews"
                            onClick={() => setEditorOpen(true)}
                            className="text-secondary"
                            icon={<AddCircleOutlineIcon />}
                        />
                    ),
                    <MenuItemWithPopover buttonTitleText="Поиск" loading={loading} buttonTitle="appModule.search" icon={<SearchIcon />}>
                        {(handleClose) => <Search
                            search={query}
                            onChangeSearch={handleChangeSearchDebounce}
                            handleClose={handleClose}
                        />}
                    </MenuItemWithPopover>,
                    <MenuItemWithPopover buttonTitle="appModule.sorting" icon={<SortIcon />}>
                        {(handleClose) => <SortingComponent
                            data={sortBy}
                            onChangeSort={handleChangeSort}
                            selectedItem={sort.order}
                            direction={sort.direction}
                            handleClose={handleClose}
                        />}
                    </MenuItemWithPopover>
                ]}
            />
            {loading && <LinearProgress className="mb-1" />}
            <Grid container spacing={3}>
                { isEditorOpen
                    && (
                        <Grid item xs={12}>
                            <EditorForm
                                handleSendRequest={handleSendRequest}
                                handleClose={() => setEditorOpen(false)}
                                type="news"
                            />
                        </Grid>
                    )
                }
                <Grid item xs={12}>
                    <Grid
                        container
                        // direction="column"
                        justify="flex-start"
                        alignItems="stretch"
                        spacing={3}
                    >
                        {list.data.length > 0
                            ? <>
                                {list.data.map((item) => (
                                    <Grid
                                        key={item.id || Math.random()}
                                        item
                                        xs={12}
                                        lg={6}
                                        className="position-relative"
                                    >
                                        <NewsItem
                                            listMode
                                            item={item}
                                            history={history}
                                            itemStatus={statuses.find((el) => el.value === item.status) || {}}
                                            text={item.description}
                                        />
                                    </Grid>
                                ))}
                                <Grid item sm={12}>
                                    <Pagination count={list.meta?.last_page} page={page} onChange={handleChange} />
                                </Grid>
                            </>
                            : !loading && <div>Данные отсутствуют</div>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default News;
