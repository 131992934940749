import React from 'react';
import { FormGroup } from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import {
    MuiPickersUtilsProvider,
    DateTimePicker
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';

const localeMap = {
    en: enLocale,
    ru: ruLocale
};

const localeCancelLabelMap = {
    en: 'cancel',
    ru: 'отмена'
};

const DatePicker = ({
    start_at, expire_at, handleDateChange, locale
}) => (
    <FormGroup className="pl-2 pr-2 mb-3">
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
            <Grid
                container
                direction="column"
                justify="space-between"
                alignItems="stretch"
                spacing={2}
            >
                <DateTimePicker
                    value={start_at}
                    onChange={(date) => handleDateChange(date, 'start_at')}
                    label={<IntlMessages id="votes.voteStartDate" />}
                    format="PPP HH:mm"
                    cancelLabel={localeCancelLabelMap[locale]}
                    ampm={false}
                    classes={{ root: 'mb-3' }}
                    inputVariant="outlined"
                />
                <DateTimePicker
                    value={expire_at}
                    onChange={(date) => handleDateChange(date, 'expire_at')}
                    label={<IntlMessages id="votes.voteExpireDate" />}
                    minDate={start_at}
                    format="PPP HH:mm"
                    cancelLabel={localeCancelLabelMap[locale]}
                    ampm={false}
                    minDateMessage={<IntlMessages id="votes.minDateMessage" />}
                    inputVariant="outlined"
                />
            </Grid>
        </MuiPickersUtilsProvider>
    </FormGroup>
);

export default DatePicker;
