import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    createOrderZkh,
    clearOrderZkh,
} from '../../../actions/Order';
import orderTypes from './const';

// хук создания заказа
const useCreateOrder = (orderType = orderTypes.indebtedness) => {
    const dispatch = useDispatch();

    const {
        order,
    } = useSelector(({ order }) => order);

    const [data, setData] = useState({});

    // обрабатываем изменение заказа
    useEffect(() => {
        if (Object.keys(order).length > 0) {
            setData(order);
            dispatch(clearOrderZkh());
        }
    }, [order]);

    // сам запрос
    const current = (props) => {
        dispatch(createOrderZkh({
            type: orderType,
            ...props,
        }));
    };

    // очистить запрос
    current.clear = () => {
        setData({});
    };

    // данные заказа
    current.order = data;

    return current;
};

export default useCreateOrder;
