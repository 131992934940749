import {
    SET_ACCRUALS_ERROR,
    INVOICE_FOR_PERIOD_LOAD_SUCCESS,
    GET_INVOICE_FOR_PERIOD,
    GET_PAYMENTS_LIST,
    PAYMENTS_LIST_LOAD_SUCCESS,
    SAVE_ACCRUALS_LIST,
    FETCH_ACCRUALS_LIST,
    PAYMENTS_STATUSES_LOADED
} from 'constants/ActionTypes';

const INIT_STATE = {
    accruals: {
        data: []
    },
    invoice: null,
    paymentsList: {
        data: []
    },
    error: null,
    loading: false,
    statuses: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
    case FETCH_ACCRUALS_LIST: {
        return {
            ...state,
            loading: true
        };
    }
    case GET_INVOICE_FOR_PERIOD: {
        return {
            ...state,
            loading: true
        };
    }
    case INVOICE_FOR_PERIOD_LOAD_SUCCESS: {
        return {
            ...state,
            error: null,
            loading: false
        };
    }
    case GET_PAYMENTS_LIST: {
        return {
            ...state,
            loading: true
        };
    }
    case PAYMENTS_LIST_LOAD_SUCCESS: {
        return {
            ...state,
            paymentsList: action.payload,
            error: null,
            loading: false
        };
    }
    case SET_ACCRUALS_ERROR: {
        return {
            ...state,
            error: action.payload,
            loading: false
        };
    }
    case SAVE_ACCRUALS_LIST: {
        return {
            ...state,
            accruals: action.payload,
            loading: false
        };
    }
    case PAYMENTS_STATUSES_LOADED: {
        return {
            ...state,
            statuses: action.payload,
        };
    }
    default:
        return state;
    }
};
