import addErrorHandling from 'util/errors/error.handling';
import Urls from './api.urls';
import instance from './axios.config';

// const instance = axios.create({
//     // Reject only if the status code is greater than or equal to 500
//     // validateStatus: status => status < 500,
// });

const ApiLangMethods = {
    getTranslation: async (language = 'ru') => {
        const response = await instance.get(Urls.getTranslation(),
            { headers: { 'x-accept-language': language } });
        if (Object.keys(response.data.data).length > 0) {
            return response.data.data;
        }
        throw new Error();
    }
};

export default addErrorHandling(ApiLangMethods);
