import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchAppealWithId, getMessageList, messageFetched, createMessage
} from 'actions/Appeal';
import { resetUnreadMessages } from 'actions/Messages';
import ChatBox from 'components/ChatPanel';
import ChatFooter from 'components/ChatPanel/ChatFooter';
import IntlMessages from 'util/IntlMessages';
import CloseIcon from '@material-ui/icons/Close';
import CardBox from 'components/Card';
import {
    LinearProgress
} from '@material-ui/core';

const CurrentAppeal = ({ currentAppeal, handleClose, maxHeightContent, getCurrentAppealRef = () => {} }) => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const {
        error, currentMessages, newMessage, loadingCurrent
    } = useSelector(({ appeal }) => appeal);
    const { authUser } = useSelector(({ auth }) => auth);
    const { id } = currentAppeal;
    const { unreadMessages } = useSelector(({ messages }) => messages);
    const unreadCount = unreadMessages?.appeal?.[id];

    const contentRef = useRef(null);

    useEffect(() => {
        if (id && !error) {
            dispatch(fetchAppealWithId(id));
        }
    }, [id, error, dispatch]);

    useEffect(() => {
        if (Object.keys(unreadMessages).length > 0 && Object.keys(unreadMessages.appeal).length > 0) {
            if (unreadCount) {
                dispatch(resetUnreadMessages('appeal', id));
            }
        }
    }, [unreadMessages, id]);

    useEffect(() => {
        if (id && !error) {
            dispatch(getMessageList(page, limit, { relation_id: id, relation_name: 'appeal' }));
        }
    }, [id, error, page, limit, dispatch]);

    useEffect(() => {
        if (newMessage) {
            dispatch(getMessageList(page, limit, { relation_id: id, relation_name: 'appeal' }));
            dispatch(messageFetched(false));
        }
    }, [newMessage]);

    useEffect(() => {
        if (contentRef?.current) {
            getCurrentAppealRef(contentRef.current);
        }
    }, [contentRef]);

    const createNewMessage = (text, attach) => {
        const msg = {
            ...(text ? { text } : {}),
            relation_name: 'appeal',
            relation_id: id,
            attach
        };
        dispatch(createMessage(msg));
    };

    return (
        <div ref={contentRef}>
            <CardBox
                title={(
                    <>
                        <IntlMessages id="sidebar.appModule.chat" />
                        {': '}
                        {currentAppeal.title}
                    </>
                )}
                className="bg-primary"
                handleActionsClick={handleClose}
                icon={(
                    <CloseIcon />
                )}
                actions={<ChatFooter onSendMessage={createNewMessage} />}
                maxHeightContent={maxHeightContent}
                needScroll={false}
            >
                <>{loadingCurrent && <LinearProgress />}</>
                <ChatBox
                    showFooter={false}
                    authUserId={authUser?.info?.id}
                    messages={currentMessages.data}
                    person={currentAppeal.person}
                />
            </CardBox>
        </div>
    );
};

export default CurrentAppeal;
