import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Drawer } from '@material-ui/core';
import { COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION } from 'constants/ActionTypes';
import { toggleCollapsedNav, updateWindowWidth, setDrawerType } from 'actions/Setting';
import { loadMenu } from 'actions/Menu';
import SideBarContent from './SideBarContent';
import AuthHeader from 'components/Auth/AuthHeader';


const SideBar = (props) => {
    const dispatch = useDispatch();
    const {
        navCollapsed, drawerType, width, navigationStyle
    } = useSelector(({ settings }) => settings);

    const { authUser } = useSelector(({ auth }) => auth);
    const { sideBarMenu } = useSelector(({ menu }) => menu);
    //const { logo, name } = useSelector(({ auth }) => auth.ukInfo);
    const logo = <img src={require('assets/images/logo64.png')} alt="Logo" title="Logo" />;
    const name = 'УК ФЛАГМАН';

    useEffect(() => {
        window.addEventListener('resize', () => {
            dispatch(updateWindowWidth(window.innerWidth));
        });
        return () => window.removeEventListener('resize', () => {});
    }, [dispatch]);


    useEffect(() => {
        const setFixedDrawer = () => {
            dispatch(setDrawerType(FIXED_DRAWER));
        };

        const setCollapsedDrawer = () => {
            dispatch(setDrawerType(COLLAPSED_DRAWER));
        };
        if (width > 1200) {
            setFixedDrawer();
        } else {
            setCollapsedDrawer();
        }
        // if (navCollapsed) {
        //     console.log('navCollapsed', navCollapsed);
        //     dispatch(toggleCollapsedNav(false));
        // }
    }, []);

    useEffect(() => {
        dispatch(loadMenu(authUser));
    }, [authUser, dispatch]);

    const onToggleCollapsedNav = (e) => {
        const val = !navCollapsed;
        dispatch(toggleCollapsedNav(val));
    };

    let drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-xl-flex' : drawerType.includes(COLLAPSED_DRAWER) ? '' : 'd-flex';
    let type = 'permanent';
    if (drawerType.includes(COLLAPSED_DRAWER) || width < 1200) {
        type = 'temporary';
    } else {
        type = 'permanent';
    }

    if (navigationStyle === HORIZONTAL_NAVIGATION) {
        drawerStyle = '';
        type = 'temporary';
    }
    return (
        <div className={`app-sidebar d-none ${drawerStyle}`}>
            <Drawer
                className="app-sidebar-content"
                variant={type}
                open={type.includes('temporary') ? navCollapsed : true}
                onClose={onToggleCollapsedNav}
                classes={{
                    paper: 'side-nav'
                }}
            >
                <div className="sidebar-top d-flex flex-row align-items-center justify-content-start">
                    <AuthHeader logo={logo} title={name} className="logo-sm" />
                </div>
                <SideBarContent menu={sideBarMenu} />
            </Drawer>
        </div>
    );
};


export default withRouter(SideBar);
