import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import {
    changePassword,
    changePasswordSuccess
} from 'actions/User';
import ChangePasswordForm from 'components/Auth/ChangePasswordForm';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import {
    setChangeCurrentUKStatus
} from 'actions/Auth';
import PageHeader from 'components/PageHeader';

const ChangePassword = () => {
    const dispatch = useDispatch();
    const {
        passwordChanged, changeCurrentUK
    } = useSelector(({ auth }) => auth);


    useEffect(() => {
        let timer;
        if (passwordChanged || changeCurrentUK) {
            timer = setTimeout(() => {
                dispatch(changePasswordSuccess(false));
            }, 5000);
            dispatch(setChangeCurrentUKStatus());
        }
        return () => clearTimeout(timer);
    }, [passwordChanged, changeCurrentUK]);

    return (
        <>
            <PageHeader
                title={<IntlMessages id="label.user.password.change" />}
            />
            {passwordChanged
            && (
                <Alert severity="success">
                    <AlertTitle>Пароль успешно изменен</AlertTitle>
                </Alert>
            )}
            <ChangePasswordForm onClick={(data) => dispatch(changePassword(data))} resetForm={passwordChanged || changeCurrentUK} />
        </>
    );
};


export default withRouter(ChangePassword);
