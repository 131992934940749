import * as types from '../constants/Notification';

const initialState = {
    // список
    list: {
        data: [],
    },
    // listLocal: [],
    loading: false,
    // всего не прочитанных
    countActive: 0,
    // последняя страница
    lastPage: 0,
    serviceList: {},
    freeSpaceServer: ''
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        case types.LOADED_LIST: {
            const { replace, data } = payload;
            const newData = data.data || [];

            if (replace) {
                return {
                    ...state,
                    list: {
                        ...data,
                        data: newData,
                    },
                };
            }

            return {
                ...state,
                list: {
                    ...data,
                    data: [
                        ...state.list.data,
                        ...newData
                    ],
                },
            };
        }

        case types.LOADED_COUNT_ACTIVE: {
            const count = payload?.data?.count || 0;
            return {
                ...state,
                countActive: count,
            };
        }

        case types.SET_LOADING: {
            return {
                ...state,
                loading: payload,
            };
        }

        case types.SET_READ: {
            const index = state?.list?.data?.findIndex(({ id }) => id === action.payload);

            if (index >= 0) {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        data: [
                            ...state.list.data.slice(0, index),
                            {
                                ...state.list.data[index],
                                viewed: true
                            },
                            ...state.list.data.slice(index + 1)
                        ],
                    },
                    countActive: Math.max(0, state.countActive - 1)
                };
            }

            return {
                ...state,
                countActive: Math.max(0, state.countActive - 1)
            };
        }

        case types.SET_READ_ALL: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: state.list?.data?.map((item) => ({
                        ...item,
                        viewed: true,
                    })),
                },
                countActive: 0
            };
        }

        case types.SET_LAST_PAGE: {
            return {
                ...state,
                lastPage: payload
            };
        }

        case types.LOADED_SERVICE_LIST: {
            return {
                ...state,
                serviceList: {
                    ...state.serviceList,
                    [payload.service]: payload.data
                }
            };
        }

        case types.LOADED_FREE_SPACE_SERVER: {
            return {
                ...state,
                freeSpaceServer: payload
            };
        }

        case types.CLEAR_LIST: {
            return {
                ...state,
                list: initialState.list,
            };
        }

        default:
            return state;
    }
}
