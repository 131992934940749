import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    List,
    CircularProgress
} from '@material-ui/core/';
import {
    fetchUserList,
    fetchRoles,
} from 'actions/Admin';
import PagePagination from 'components/PagePagination';
import UserItem from './Item';
import PageHeader from 'components/PageHeader';
import MenuItemWithPopover from 'components/MenuItemPopover';
import SearchIcon from "@material-ui/icons/Search";
import Search from './Search';
import { debounce } from 'lodash';
import FilterListIcon from '@material-ui/icons/FilterList';
import Filter from './Filter';
import useStoreProp from '../../../../components/Helpers/useStoreProp';
import useDebounce from '../../../../components/Helpers/useDebounce';

const UserManagement = () => {
    const dispatch = useDispatch();
    const { loading, list } = useSelector(({ admin }) => admin);
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const [filter, setFilter] = useState({});

    // роли
    const roles = useStoreProp(fetchRoles, 'admin', 'roles');

    const fetchList = () => {
        const currentfilter = {
            page,
            limit: 25,
            name: query,
            ...filter,
        };
        const params = Object.keys(currentfilter).reduce((res, key) => {
            if (currentfilter[key]) {
                res[key] = currentfilter[key];
            }
            return res;
        }, {});
        dispatch(fetchUserList(params));
    }
    const fetchListDebonce = useDebounce(fetchList, 100);

    useEffect(() => {
        fetchListDebonce();
    }, [dispatch, page, query, filter]);

    const handleChangeSearch = (search) => {
        setQuery(search);
        setPage(1);
    };
    const handlehCangeSearchDebounce = debounce(handleChangeSearch, 800);

    const handleSetPage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeFilter = (data) => {
        setFilter(data);
        setPage(1);
        // closeAll();
    };

    return (
        <>
            <PageHeader
                title={'Список пользователей'}
                buttons={[
                    <MenuItemWithPopover loading={loading} buttonTitle="appModule.search" icon={<SearchIcon />}>
                        {(handleClose) => <Search
                            search={query}
                            onChangeSearch={handlehCangeSearchDebounce}
                            handleClose={handleClose}
                        />}
                    </MenuItemWithPopover>,
                    <MenuItemWithPopover buttonTitle="appModule.filter" icon={<FilterListIcon />}>
                        {(handleClose) => <Filter
                            currentFilters={filter}
                            roles={roles}
                            onChangeFilter={handleChangeFilter}
                            handleClose={handleClose}
                        />}
                    </MenuItemWithPopover>,
                ]}
            />
            <div className="page_filter">
            {/* <FormControl size="small" variant="outlined">
                    <InputLabel>{titles.SELECT_TYPE}</InputLabel>
                    <Select
                        value={selectedType}
                        onChange={handleChangeType}
                        label={titles.SELECT_TYPE}
                    >
                        {Object.keys(types)?.map((item, index) => (
                            <MenuItem key={index} value={item}>{types[item]}</MenuItem>
                        ))}
                    </Select>
                </FormControl> */}
            </div>

            {loading && <CircularProgress />}
            {list?.data?.length > 0
                ? (<>
                    <List className="list">
                        {list?.data?.map((item) => (
                            <UserItem
                                key={item.id}
                                user={item}
                            />
                        ))}
                    </List>
                    <PagePagination
                        pageCount={list.meta?.last_page}
                        page={list.meta?.current_page}
                        loading={loading}
                        handleChangePage={handleSetPage}
                    />
                </>)
                : (!loading && <div>Данных не найдено</div>)
            }
        </>
    );
};

export default UserManagement;
