import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    LinearProgress, Grid
} from '@material-ui/core';
import {
    fetchCounter, sendCounterData, countersLoaded, setError
} from 'actions/Meters';
import {
    setChangeCurrentUKStatus
} from 'actions/Auth';
import AccountInfo from 'components/AccountInfo';
import MeterCard from 'components/MeterCard';
import IntlMessages from 'util/IntlMessages';
import PageHeader from 'components/PageHeader';
// import Form from './form';

require('moment/locale/ru.js');

const Meters = () => {
    const dispatch = useDispatch();
    const {
        counters, error, loading, counterLoading
    } = useSelector(({ meters }) => meters);
    const {
        changeCurrentUK
    } = useSelector(({ auth }) => auth);
    const {
        fieldsValidation
    } = useSelector(({ validation }) => validation);

    useEffect(() => {
        if (counters.length === 0) {
            dispatch(fetchCounter());
        }
    }, [counters.length, dispatch]);

    useEffect(() => {
        if (changeCurrentUK) {
            dispatch(countersLoaded([]));
            dispatch(fetchCounter());
            dispatch(setChangeCurrentUKStatus());
        }
    }, [changeCurrentUK, dispatch]);

    const handleSendRequest = (counterId, value) => {
        dispatch(sendCounterData(counterId, value));
    };

    return (
        <>
            <PageHeader
                title={<IntlMessages id="label.meter.readings" />}
            />
            <AccountInfo />
            {/* 2 column */}
            <Grid container spacing={3}>
                <Grid item xs={12} md={5}>
                    {loading && <LinearProgress />}
                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="stretch"
                        spacing={3}
                    >
                        {
                            counters.length > 0
                            && (
                                <Grid
                                    item
                                    xs={12}
                                    className="position-relative"
                                >
                                    {counters.map((counter, index) => (
                                        <MeterCard
                                            counter={counter}
                                            key={counter.id}
                                        >
                                            {/* <Form
                                                handleSendRequest={(value) => handleSendRequest(counter.id, value)}
                                                error={fieldsValidation?.[`counters.${index}.value`]}
                                            /> */}
                                            {counterLoading[counter.id] && <LinearProgress />}
                                        </MeterCard>
                                    ))}
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Meters;
