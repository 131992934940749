import React, { useEffect, useState } from 'react';
import {
    Select, FormControl, InputLabel, Button
} from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import CardBox from 'components/Card';
import { useIntl } from 'react-intl';

const AppealFilter = ({
    statuses = [],
    types = [],
    currentFilters = {},
    handleClose = () => {},
    onChangeFilter = () => {}
}) => {
    const initialDataFilter = { type: '', status: '' };
    const intl = useIntl();
    const [disabled, setDisabled] = useState(true);
    const [dataFilter, setDataFilter] = useState({ ...initialDataFilter, ...currentFilters });

    useEffect(() => {
        if (Object.keys(currentFilters)) {
            setDataFilter({ ...initialDataFilter, ...currentFilters });
        }
    },[currentFilters]);
    const dataWithoutEmpty = (data) => (Object.keys(data).reduce((res, key) => {
        if (data[key] !== '') {
            res[key] = data[key];
        }
        return res;
    }, {}));

    const handleFilterSubmit = () => {
        const newData = dataWithoutEmpty(dataFilter);
        setDisabled(true);
        onChangeFilter(newData);
        handleClose();
    };

    const handleFilterReset = () => {
        setDataFilter(initialDataFilter);
        setDisabled(true);
        onChangeFilter(initialDataFilter);
    };

    const handleFilterChange = (value, name) => {
        setDataFilter({
            ...dataFilter,
            [name]: value
        });
        setDisabled(false);
    };

    return (
        <CardBox
            title={<IntlMessages id="appModule.filter" />}
            actions={(
                <>
                    <Button
                        onClick={handleFilterReset}
                        variant="contained"
                        className="mr-3"
                        disabled={!dataFilter.type && !dataFilter.status}
                    >
                        <IntlMessages id="filter.resetFilter" />
                    </Button>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        className="mr-3"
                    >
                        <IntlMessages id="appModule.close" />
                    </Button>

                    <Button
                        onClick={handleFilterSubmit}
                        variant="contained"
                        color="secondary"
                        disabled={disabled}
                    >
                        <IntlMessages id="appModule.apply" />
                    </Button>
                </>
            )}
        >
            <div className="d-flex flex-column">
                <FormControl variant="outlined" className="mb-3">
                    <InputLabel shrink htmlFor="types_list"><IntlMessages id="appeal.typeOfAppeal" /></InputLabel>
                    <Select
                        notched
                        native
                        value={dataFilter.type}
                        onChange={(event) => {
                            const { value, name } = event.target;
                            handleFilterChange(value, name);
                        }}
                        inputProps={{
                            name: 'type',
                            id: 'types_list'
                        }}
                        label={<IntlMessages id="appeal.typeOfAppeal" />}
                    >
                        <option value="" key="empty-type">{intl.messages['appeal.selectAppealType']}</option>
                        {types.map((t) => (<option value={t.value} key={t.key}>{t.description}</option>))}
                    </Select>
                </FormControl>

                <FormControl variant="outlined" className="mb-3">
                    <InputLabel shrink htmlFor="statises_list"><IntlMessages id="appModule.status" /></InputLabel>
                    <Select
                        notched
                        native
                        value={dataFilter.status}
                        onChange={(event) => {
                            const { value, name } = event.target;
                            handleFilterChange(value, name);
                        }}
                        inputProps={{
                            name: 'status',
                            id: 'statises_list'
                        }}
                        label={<IntlMessages id="appModule.status" />}
                    >
                        <option value="" key="empty-type">{intl.messages['appeal.selectAppealStatus']}</option>
                        {statuses.map((t) => (<option value={t.value} key={t.key}>{t.description}</option>))}
                    </Select>
                </FormControl>
            </div>
        </CardBox>
    );
};

export default AppealFilter;
