import Echo from 'laravel-echo';
import blueimpMd5 from 'blueimp-md5';
import { messageFetched, setWsData, newWsAppeal } from 'actions/Appeal';
import { notificationRecieved, showMessage } from 'actions/Messages';
import { changeReadNotification, loadListNotification, loadCountActiveNotification } from 'actions/Notification';
import { wsConnected } from 'actions/Websockets';
import {
    WS_CONNECT, WS_DISCONNECT
} from 'constants/ActionTypes';

import { SOCKET_HOST, SOCKET_AUTH_URL, SOCKET_KEY, UK, SERVICE } from 'constants/Config';

const socketMiddleware = () => {
    window.Pusher = require('pusher-js');
    const onOpen = (store) => () => {
        store.dispatch(wsConnected());
    };

    //   const onClose = store => () => {
    //     store.dispatch(actions.wsDisconnected());
    //   };

    const onMessage = (store) => (event) => {
        // console.log('receiving server message', event);
        store.dispatch(messageFetched(event));
    };

    const onNotification = (store) => (event) => {
        // console.log('receiving server notification', event);
        store.dispatch(notificationRecieved(event.data.count));
    };

    // the middleware part of this function
    return (store) => (next) => (action) => {
        switch (action.type) {
            case WS_CONNECT:
            // connect to the remote host
            window.Echo = new Echo({
                broadcaster: 'pusher',
                key: SOCKET_KEY,
                wsHost: SOCKET_HOST,
                encrypted: true,
                wsPort: 80,
                wssPort: null,
                disableStats: true,
                enabledTransports: ['ws', 'wss'],
                authEndpoint: SOCKET_AUTH_URL,
                auth: {
                    headers: {
                        Authorization: `Bearer ${action.authUser.access_token}`,
                        service: SERVICE,
                        uk: UK
                    }
                }
            });

            if (window.Echo !== null) {
                onOpen(store)();
            }

            // прочитано
            const changeRead = (id) => {
                const { authUser } = store.getState().auth;
                const user_id = authUser?.info?.sub || null;
                store.dispatch(changeReadNotification(user_id, id));
            };

            // обновить список
            const refreshList = () => {
                const { authUser } = store.getState().auth;
                const user_id = authUser?.info?.sub || null;
                // store.dispatch(loadListNotification(user_id, 1, 10));
                store.dispatch(loadCountActiveNotification(user_id));
            };

            // window.Echo.channel(`private-user.${blueimpMd5(action.authUser.info.identifier)}`)
            window.Echo.channel(`private-flagman_user.${action.authUser.info.sub}`)
                .listen('.App\\Events\\Zkh\\MessageSend', (e) => {
                    // console.log('window.Echo channel', e);
                    onMessage(store)(e);
                    refreshList();
                })
                .listen('.App\\Events\\Zkh\\AppealSend', (e) => {
                    // изменение обращения
                    // console.log('private-user AppealSend', e);
                    store.dispatch(setWsData(e));
                    refreshList();
                    // изменение данных
                });

            // const history = action.history;

            if (store.getState().auth.authUser?.info?.scopes?.includes('personal_account')) {
                // новое обращение
                window.Echo.channel(`private-flagman_operator-appeal`)
                    .listen('.App\\Events\\Zkh\\AppealSend', (e) => {
                        // console.log('private-flagman_operator-appeal AppealSend', e);
                        store.dispatch(newWsAppeal(e));
                        refreshList();

                        /*() => {
                            changeRead(e.id);
                            history.push('/appeal');
                        }*/
                        store.dispatch(showMessage('success', 'Добавлено новое обращение', ''));
                        // добавить переход
                    });
            }

            break;
        case WS_DISCONNECT:
            // if (socket !== null) {
            //     socket.close();
            // }
            // socket = null;
            // console.log('websocket closed');
            break;
        default:
            return next(action);
        }
    };
};

export default socketMiddleware();
