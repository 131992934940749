import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

const RestrictedRoute = ({
    component: Component, toDefault = '/', adminOnly = false, ...rest
}) => {
    const {
        authUser, isAdmin
    } = useSelector(({ auth }) => auth);

    const getComponent = (props) => {
        if (authUser && (!adminOnly || (adminOnly && isAdmin))) {
            return <Component {...props} />;
        }
        return (
            <Redirect to={toDefault} />
        );
    };
    return <Route {...rest} render={getComponent} />;
};

export default RestrictedRoute;

