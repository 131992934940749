import {
    COUNTERS_LOAD_SUCCESS,
    SET_COUNTERS_ERROR,
    COUNTERS_LOADING,
    COUNTER_LOADING
} from 'constants/ActionTypes';

const INIT_STATE = {
    counters: [],
    error: null,
    loading: false,
    counterLoading: {}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
    case COUNTERS_LOADING: {
        return {
            ...state,
            loading: action.payload
        };
    }
    case COUNTER_LOADING: {
        return {
            ...state,
            counterLoading: action.payload
        };
    }
    case COUNTERS_LOAD_SUCCESS: {
        return {
            ...state,
            counters: action.payload,
            error: null
        };
    }
    case SET_COUNTERS_ERROR: {
        return {
            ...state,
            error: action.payload
        };
    }
    default:
        return state;
    }
};
