import {
    all, call, select, put, takeLatest
} from 'redux-saga/effects';

import * as types from 'constants/ActionTypes/order';
import * as actions from 'actions/Order';

import { getActionForError } from 'util/errors';
import apiMethods from 'util/api/order/api.order.methods';

function* createOrderZkhSaga({ payload }) {
    yield put(actions.setLoading(true));
    try {
        const response = yield call(apiMethods.createOrder, payload);
        yield put(actions.createdOrderZkh(response.data));
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(actions.setLoading(false));
    }
}

function* checkOrderZkhSaga({ payload }) {
    yield put(actions.setLoading(true));
    try {
        const response = yield call(apiMethods.checkOrder, payload);
        yield put(actions.checkedOrderZkh(response));
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(actions.setLoading(false));
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(types.CREATE_ORDER_ZKH, createOrderZkhSaga),
        takeLatest(types.CHECK_ORDER_ZKH, checkOrderZkhSaga),
    ]);
};
