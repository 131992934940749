import React, { useState } from 'react';
import {
    FormControlLabel, FormGroup, Checkbox
} from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';

const CheckBox = (props) => {
    const {
        label = '',
        checked = false,
        readOnly = false,
        children = {},
        onChangeChecked = () => {}
    } = props;

    const [checkedStatus, setCheckedStatus] = useState(checked);

    const handleChange = (event) => {
        setCheckedStatus(event.target.checked);
        onChangeChecked(event.target.checked);
    };

    return (
        <FormGroup
            className="mb-2"
        >
            <FormControlLabel
                control={(
                    <Checkbox
                        checked={checkedStatus}
                        onChange={handleChange}
                        disabled={readOnly}
                        name="isSahidOnly"
                        color="primary"
                    />
                )}
                label={label}
            />
            {!!checked && (children)}
        </FormGroup>
    );
};

export default CheckBox;
