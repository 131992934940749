import {
    API_MARKET_URL,
} from 'constants/Config';

const apiUrls = {
    basketApi: (id = '') => `${API_MARKET_URL}/basket/${id}`,
    categoryApi: (id = '') => `${API_MARKET_URL}/category/${id}`,
    categoryTree: () => `${API_MARKET_URL}/category/tree`,
    productApi: (id = '') => `${API_MARKET_URL}/product/${id}`,
    orderApi: (id = '') => `${API_MARKET_URL}/order/${id}`,
    orderStatusesApi: () =>  `${API_MARKET_URL}/order/statuses`,
    orderPaymentStatusesApi: () => `${API_MARKET_URL}/order/payment/statuses`,
    orderPaymentTypesApi: () => `${API_MARKET_URL}/order/payment/types`,
    getOrdersList: () => `${API_MARKET_URL}/order`,
    //todo методов нет
    changeOrdersStatus: () => `${API_MARKET_URL}/`,
    closeOrders: () => `${API_MARKET_URL}/`,
};

export default apiUrls;