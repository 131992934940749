import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    userSignOut
} from 'actions/Auth';

const SignOut = ({ match, history }) => {
    const dispatch = useDispatch();
    const { all } = match.params; // в старой версии ссылка для выхода со всех устройств была logout/all

    React.useEffect(() => {
        dispatch(userSignOut(all || match.path.includes('all')));
        history.push('/');
    }, []);

    return null;
};


export default SignOut;
