import React, { useState } from 'react';
import { TextField, Button, FormGroup } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import IntlMessages from 'util/IntlMessages';
import ModalDialog from 'components/ModalDialog';
import ActionConfirmation from 'components/ActionConfirmation';
import ActionButton from 'components/ActionButton';

const AnswerEditor = ({
    answer, index, onAnswerChange, onAnswerDelete
}) => {
    const [data, setData] = useState(answer);
    const [isDialogOpen, setDialogOpen] = useState(false);

    const onBlur = (event) => {
        event.preventDefault();
        const { value } = event.target;
        onAnswerChange(value);
    };

    const onKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            const { value } = event.target;
            onAnswerChange(value);
        }
        if (event.keyCode === 27) {
            event.preventDefault();
            onAnswerChange(answer);
        }
    };

    return (
        <React.Fragment key={answer.id}>
            <FormGroup className="align-items-center" row>
                <TextField
                    required
                    type="text"
                    name="title"
                    className="mb-2"
                    label={<IntlMessages id="votes.answerText" />}
                    defaultValue={data}
                    onChange={(e) => setData(e.target.value)}
                    error={!data}
                    helperText={!data && <IntlMessages id="appModule.mandatory.field" />}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                    size="small"
                    variant="outlined"
                />
                <ActionButton
                    onClick={() => setDialogOpen(true)}
                    title="votes.deleteAnswer"
                    className="text-info"
                    icon={<DeleteIcon />}
                />
            </FormGroup>
            <ModalDialog isOpen={isDialogOpen}>
                <ActionConfirmation
                    onConfirm={onAnswerDelete}
                    onCancel={() => setDialogOpen(false)}
                    methodText="votes.confirmDeleteAnswer"
                />
            </ModalDialog>
        </React.Fragment>
    );
};

export default AnswerEditor;
