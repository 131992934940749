export const checkIsRequired = (value) => {
    return(value ? false : 'Обязательное поле.');
}

export const checkPasswordLength = (value) => (value.length < 6 ? 'Количество символов должно быть не менее 6.' : false);

// eslint-disable-next-line max-len
export const checkIsEmail = (value) => (value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g)
    ? false : 'Поле E-Mail адрес должно быть действительным электронным адресом.');

export const checkIsTrue = (value) => (value ? false : 'Must be true');

export const checkIdentical = (value) => (value ? false : 'Значения полей Подтверждение пароля и Пароль должны совпадать.');

// export const composeValidators = (...validators) => (...arg) => validators.reduce((error, validator) => error || validator(...arg), undefined);
