import {
    all, call, select, put, takeLatest
} from 'redux-saga/effects';

import {
    LOAD_USER_LIST,
    EDIT_USER,
    LOAD_ROLES,
    LOAD_USER_ACCOUNT_INFO,
} from 'constants/ActionTypes/users';

import {
    setUserListLoading,
    setUserLoading,
    userListLoaded,
    fetchUserList,
    userSuccessfulySaved,
    rolesLoaded,
    userListWithAccountInfoLoaded
} from 'actions/Admin';
import { getActionForError } from 'util/errors';
import { showMessage } from 'actions/Messages';
import api from 'util/api/admin/api.admin.methods';

export const stateSelector = (state) => state.news;
export const stateAuthSelector = (state) => state.auth;

function* loadUserListSaga({ payload }) {
    yield put(setUserListLoading(true));
    const params = payload || { page: 1, limit: 25 };
    // params.role_id = [5, 6, 7, 8];
    // 8 - "operator_market" - "Оператор Маркетплэйс",
    // 6 - "user" - "Пользователь без ЛС",
    // 7 - "user_uk" - "Пользователь с лицевым счетом",
    // 5 - "operator_uk" - "Оператор УК",

    // const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.fetchUserList, params);
        yield put(userListLoaded(response));
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(setUserListLoading(false));
    }
}

function* loadUserListWithAccountInfoSaga({ payload }) {
    yield put(setUserLoading(true));
    const params = payload;
    // const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.loadUserListWithAccountInfo, params);
        if (response.data.length > 0) {
            yield put(userListWithAccountInfoLoaded(response.data));
        }
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(setUserLoading(false));
    }
}

function* editUserDataSaga({ payload }) {
    yield put(setUserLoading(true));
    // const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.editUserData, payload);
        // console.log('editUserDataSaga response', response);
        yield put(userSuccessfulySaved(true));
        yield put(showMessage('success', 'Изменения успешно сохранены', null, false));
        yield put(fetchUserList());
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(setUserLoading(false));
    }
}

function* loadRolesSaga({ payload }) {
    try {
        const response = yield call(api.loadRoles, payload);
        yield put(rolesLoaded(response.data));
    } catch (error) {
        yield put(getActionForError(error));
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(LOAD_USER_LIST, loadUserListSaga),
        takeLatest(LOAD_USER_ACCOUNT_INFO, loadUserListWithAccountInfoSaga),
        takeLatest(EDIT_USER, editUserDataSaga),
        takeLatest(LOAD_ROLES, loadRolesSaga),
    ]);
}

