import React, { useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import AnswerEditor from './AnswerEditor';

const AnswerList = ({ answers = [], saveAnswers }) => {
    const [answersList, setData] = useState(answers);

    const handleAnswerTextChange = (value, index) => {
        const newList = [
            ...answersList.slice(0, index),
            {
                ...answersList[index],
                title: value
            },
            ...answersList.slice(index + 1)
        ];
        setData(newList);
        saveAnswers(newList);
    };

    const handleDeleteAnswer = (index) => {
        const newList = [
            ...answersList.slice(0, index),
            ...answersList.slice(index + 1)
        ];
        setData(newList);
        saveAnswers(newList);
    };

    const onAddAnswer = () => {
        const newList = [
            ...answersList,
            {
                new: true,
                id: uuidv4(),
                title: ''
            }
        ];
        setData(newList);
        saveAnswers(newList);
    };

    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
        >
            <h4>Варианты ответов</h4>
            {answersList.map((answer, index) => (
                <AnswerEditor
                    key={answer.id}
                    answer={answer.title}
                    index={index}
                    onAnswerChange={(value) => handleAnswerTextChange(value, index)}
                    onAnswerDelete={() => handleDeleteAnswer(index)}
                />
            ))}
            <Button
                onClick={onAddAnswer}
                color="secondary"
                endIcon={<AddCircleOutlineIcon />}
            >
                <IntlMessages id="votes.addAnswer" />
            </Button>
        </Grid>
    );
};

export default AnswerList;
