import React from 'react';
import {
    LinearProgress, CardActions
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination/Pagination';

const PagePagination = ({ pageCount = 1, page = 1, loading = false, handleChangePage = () => {} }) => (
    <>
        {loading && (<LinearProgress className="mb-2" />)}
        {pageCount > 1 && (
            <CardActions className="mb-3">
                <Pagination count={pageCount} page={page} onChange={handleChangePage} />
            </CardActions>
        )}
    </>
);

export default PagePagination;
