import addErrorHandling from 'util/errors/error.handling';
import Urls from './api.urls';
import instance from './axios.config';

const ApiMethods = {
    fetchList: async (access_token, params) => {
        const response = await instance.get(Urls.fetchListIndebtedness(),
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                },
                params
            });
        return response.data;
    }
};

export default addErrorHandling(ApiMethods);
