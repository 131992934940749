import {
    LOAD_MENU,
    MENU_LOAD_SUCCESS,
    FETCH_LANDING_MENU,
    LANDING_MENU_LOAD_SUCCESS,
    CREATE_MENU_ITEM,
    MENU_SAVED,
    EDIT_MENU_ITEM,
    DELETE_MENU_ITEM,
    FETCH_MENU_SCOPES,
    FETCH_MENU_SCENARIOS,
    MENU_SCOPES_LOADED,
    MENU_SCENARIOS_LOADED,
    MENU_LOADING
} from 'constants/ActionTypes';

export const loadingMenu = (bool = false) => ({
    type: MENU_LOADING,
    payload: bool
});

export const fetchScopes = (scenario) => ({
    type: FETCH_MENU_SCOPES,
    payload: scenario
});

export const scopesLoaded = (scopes) => ({
    type: MENU_SCOPES_LOADED,
    payload: scopes
});

export const fetchScenarios = () => ({
    type: FETCH_MENU_SCENARIOS
});

export const scenariosLoaded = (scenarios) => ({
    type: MENU_SCENARIOS_LOADED,
    payload: scenarios
});

export const loadMenu = () => ({
    type: LOAD_MENU
});

export const menuLoaded = (menu) => ({
    type: MENU_LOAD_SUCCESS,
    payload: menu
});

export const fetchLandingMenu = (scope) => ({
    type: FETCH_LANDING_MENU,
    payload: scope
});

export const landingMenuLoaded = (menu, scope) => ({
    type: LANDING_MENU_LOAD_SUCCESS,
    payload: { menu, scope }
});

export const createMenuItem = (data) => ({
    type: CREATE_MENU_ITEM,
    payload: { data }
});

export const editMenuItem = (id, data) => ({
    type: EDIT_MENU_ITEM,
    payload: { id, data }
});

export const deleteMenuItem = (id) => ({
    type: DELETE_MENU_ITEM,
    payload: { id }
});

export const menuSuccesfullySaved = (bool = false) => ({
    type: MENU_SAVED,
    payload: bool
});
