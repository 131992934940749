import {
    SYNC_USER,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_SYNC_USER_STATUS
} from 'constants/ActionTypes';


export const syncUser = (user) => ({
    type: SYNC_USER,
    payload: user
});

export const changePassword = (passwords) => ({
    type: CHANGE_PASSWORD,
    payload: passwords
});

export const changePasswordSuccess = (bool = true) => ({
    type: CHANGE_PASSWORD_SUCCESS,
    payload: bool
});

export const changeSyncUserStatus = (bool = false) => ({
    type: CHANGE_SYNC_USER_STATUS,
    payload: bool
});
