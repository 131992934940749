import {
    all, call, select, put, takeLatest
} from 'redux-saga/effects';

import {
    FETCH_APPEAL_LIST,
    LOAD_APPEAL_WITH_ID,
    FETCH_APPEAL_STATUSES,
    FETCH_APPEAL_TYPES,
    EDIT_APPEAL,
    CREATE_APPEAL,
    WATCH_THE_APPEAL,
    STOP_WATCH_THE_APPEAL
} from 'constants/ActionTypes';
import {
    appealLoaded,
    appealWithIdLoaded,
    setError,
    statusesLoaded,
    typesLoaded,
    appealSavedSuccess,
    saveAppealForNotification,
    setLoading,
    setCurrentAppealLoading
} from 'actions/Appeal';
import { showMessage } from 'actions/Messages';
import { getActionForError } from 'util/errors';
import api from 'util/api/api.appeal.methods';

export const stateSelector = (state) => state.news;
export const stateAuthSelector = (state) => state.auth;

function* loadAppealListSaga({ payload }) {
    yield put(setLoading(true));
    const { page, limit, data } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.getAppealList, page, limit, data, authUser?.access_token);
        yield put(appealLoaded(response));
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(setLoading(false));
    }
}

function* loadAppealStatusesSaga() {
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.getAppealStatuses, authUser?.access_token);
        yield put(statusesLoaded(response.data));
    } catch (error) {
        yield put(getActionForError(error));
    }
}

function* loadAppealTypesSaga() {
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.getAppealTypes, authUser?.access_token);
        yield put(typesLoaded(response.data));
    } catch (error) {
        yield put(getActionForError(error));
    }
}

function* loadAppealWithIdSaga({ payload }) {
    yield put(setCurrentAppealLoading(true));
    const { id, notification } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.getAppealWithId, id, authUser?.access_token);
        if (notification) {
            yield put(saveAppealForNotification(response.data));
        } else {
            yield put(appealWithIdLoaded(response.data));
        }
    } catch (error) {
        yield put(getActionForError(error));
        yield put(setError(error.status));
    } finally {
        yield put(setCurrentAppealLoading(false));
    }
}

function* createAppealSaga({ payload }) {
    yield put(setCurrentAppealLoading(true));
    const { data }  = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.createAppeal, data, authUser.access_token);
        yield put(showMessage('success', 'appeal.successfullySaved', null, true));
        yield put(appealSavedSuccess(response.success));
    } catch (error) {
        // yield put(appealSavedSuccess(false));
        yield put(getActionForError(error));
    } finally {
        yield put(setCurrentAppealLoading(false));
    }
}

function* editAppealSaga({ payload }) {
    yield put(setCurrentAppealLoading(true));
    const { id, data } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.editAppeal, id, data, authUser.access_token);
        yield put(showMessage('success', 'appeal.successfullySaved', null, true));
        yield put(appealSavedSuccess(response.success));
    } catch (error) {
        // yield put(appealSavedSuccess(false));
        yield put(getActionForError(error));
    } finally {
        yield put(setCurrentAppealLoading(false));
    }
}

function* watchAppealSaga({ payload }) {
    yield put(setCurrentAppealLoading(true));
    const { id } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.watchAppeal, id, authUser.access_token);
        yield put(showMessage('success', 'appeal.successfullySaved', null, true));
        yield put(appealSavedSuccess(response.success));
    } catch (error) {
        // yield put(appealSavedSuccess(false));
        yield put(getActionForError(error));
    } finally {
        yield put(setCurrentAppealLoading(false));
    }
}

function* stopWatchAppealSaga({ payload }) {
    yield put(setCurrentAppealLoading(true));
    const { id } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.stopWatchAppeal, id, authUser.access_token);
        yield put(showMessage('success', 'appeal.successfullySaved', null, true));
        yield put(appealSavedSuccess(response.success));
    } catch (error) {
        // yield put(appealSavedSuccess(false));
        yield put(getActionForError(error));
    } finally {
        yield put(setCurrentAppealLoading(false));
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(FETCH_APPEAL_LIST, loadAppealListSaga),
        takeLatest(FETCH_APPEAL_STATUSES, loadAppealStatusesSaga),
        takeLatest(FETCH_APPEAL_TYPES, loadAppealTypesSaga),
        takeLatest(LOAD_APPEAL_WITH_ID, loadAppealWithIdSaga),
        takeLatest(CREATE_APPEAL, createAppealSaga),
        takeLatest(EDIT_APPEAL, editAppealSaga),
        takeLatest(WATCH_THE_APPEAL, watchAppealSaga),
        takeLatest(STOP_WATCH_THE_APPEAL, stopWatchAppealSaga),
    ]);
}
