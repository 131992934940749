import React, {useEffect, useState} from 'react';
// import IntlMessages from '../../util/IntlMessages';
import {
    Button,
    TextField,
    Tooltip,
    LinearProgress,
    Card,
    CardContent,
    CardHeader,
    MenuItem,
    Grid, Typography
} from '@material-ui/core';
// import { Search } from '@material-ui/icons';
// import IconButton from '@material-ui/core/IconButton';
// import DeleteIcon from '@material-ui/icons/Delete';
// import ActionConfirmation from '../ActionConfirmation';
import ModalDialog from 'components/ModalDialog';
import moment from 'moment';
import DaDataList from 'components/DaData';

const Form = (props) => {
    const {
        loading,
        paymentTypes,
        onSubmitForm
    } = props;

    const [showAddress, setShowAddress] = useState(false);

    const [fields, setFields] = useState({
        // time_at: moment().format('DD.MM.YYYTHH:mm')
    });

    const handleChangeField = (e) => {
        const { target: { name, value }} = e;
        setFields({
            ...fields,
            [name]: name === 'payment_type' ? parseInt(value) : value
        })
    };

    const address = fields.address || null;

    const handleSubmit = () => {
        const newFields = {
            ...fields
        };
        if (newFields.time_at) newFields.time_at = moment(newFields.time_at).format('YYYY-MM-DD HH:mm:ss');

        onSubmitForm(newFields);
    };

    const getField = (fieldKey) => {
        return fields[fieldKey] || '';
    };

    return (
        <Card elevation={3}>
            <CardHeader
                title={'Заполните форму'}
                className="text-center"
            />
            <CardContent>
                <div className="basket-form d-flex">

                    <Grid container spacing={3} alignItems="stretch">
                        <Grid item xs={12} md={6}>
                            <div className="mb-3 d-flex">
                                <TextField
                                    label="Адрес *"
                                    placeholder="Выберите адрес"
                                    value={
                                        address && Object.keys(address).length > 0
                                            ? [
                                                address.city_name,
                                                address.settlement,
                                                address.street,
                                                address.house
                                            ].filter((item) => item)
                                            .join(', ')
                                        : ''
                                    }
                                    name="address"
                                    onChange={handleChangeField}
                                    fullWidth={true}
                                    variant="outlined"
                                    size="small"
                                    // disabled={true}
                                    onKeyDown={(e) => e.preventDefault()}
                                    onClick={() => {
                                        setShowAddress(true);
                                    }}
                                    error={!address}
                                    // InputProps={{
                                    //     startAdornment: <Search/>,
                                    // }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    className="mr-3"
                                />

                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    onClick={() => {
                                        setShowAddress(true);
                                    }}
                                >
                                    Выбрать
                                </Button>

                                <ModalDialog
                                    titleText="Ваш адрес"
                                    isOpen={showAddress}
                                    handleClose={() => setShowAddress(false)}
                                >
                                    <DaDataList
                                        onChange={(newAddress) => {
                                            setFields({
                                                ...fields,
                                                address: newAddress
                                            });
                                            setShowAddress(false);
                                        }}
                                    />
                                </ModalDialog>
                            </div>

                            <div className="mb-3">
                                <TextField
                                    select
                                    label="Тип оплаты *"
                                    value={getField('payment_type')}
                                    name="payment_type"
                                    onChange={handleChangeField}
                                    fullWidth={true}
                                    variant="outlined"
                                    size="small"
                                    error={!getField('payment_type')}
                                >
                                    {Object.keys(paymentTypes).map((paymentKey) => (
                                        <MenuItem key={`payment-type-${paymentKey}`} value={paymentKey}>
                                            {paymentTypes[paymentKey]}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>

                            <div className="mb-3">
                                <TextField
                                    label="Дата и время доставки"
                                    value={getField('time_at')}
                                    name="time_at"
                                    onChange={handleChangeField}
                                    type="datetime-local"
                                    fullWidth={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    className="pointer"
                                    variant="outlined"
                                    size="small"
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <div className="mb-3">
                                <TextField
                                    label="Коментарии к заказу"
                                    value={getField('comment')}
                                    name="comment"
                                    onChange={handleChangeField}
                                    fullWidth={true}
                                    multiline={true}
                                    rows={5}
                                    variant="outlined"
                                    size="small"
                                />
                            </div>

                            <div className="buttons text-right">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    // fullWidth={true}
                                    onClick={handleSubmit}
                                    disabled={loading || !address || !fields.payment_type}
                                >
                                    Оформить заказ
                                </Button>
                            </div>
                        </Grid>

                    </Grid>

                </div>
            </CardContent>

            {loading && <LinearProgress />}
        </Card>
    );
};

export default Form;
