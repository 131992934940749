import {
    UK_FETCH_ADDRESS_LIST,
    UK_ADDRESS_LIST_LOADED,
    UK_ADDRESS_LIST_LOADED_STATUS,
    UK_SET_ERROR,
    UK_FETCH_CITIES_LIST,
    UK_FETCH_STREEST_LIST
} from 'constants/ActionTypes';

export const fetchUKAddressList = (page, limit, city_name = '', street_name = '', query = '') => ({
    type: UK_FETCH_ADDRESS_LIST,
    payload: { page, limit, city_name, street_name, query }
});

export const fetchUKStreetList = (page, limit, city_name = '', street_name = '') => ({
    type: UK_FETCH_STREEST_LIST,
    payload: { page, limit, city_name, street_name }
});

export const fetchUKCitiesList = (page, limit, city_name = '') => ({
    type: UK_FETCH_CITIES_LIST,
    payload: { page, limit, city_name }
});

export const ukAddressListLoaded = (data) => ({
    type: UK_ADDRESS_LIST_LOADED,
    payload: data
});

export const ukAddressListLoadedStatus = (bool = false) => ({
    type: UK_ADDRESS_LIST_LOADED_STATUS,
    payload: bool
});


export const setError = (bool) => ({
    type: UK_SET_ERROR,
    payload: bool
});
