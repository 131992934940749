import _ from 'lodash';
import {
    API_AUTH_URL,
    API_MENU_URLS,
    API_ZHKU_URLS,
    API_STORAGE_URLS,
    API_VOTE_URLS,
    API_PAYMENT_URL
} from 'constants/Config';


export default class Urls {
    //AUTH
    static getToken = () => `${API_AUTH_URL}/login`;

    static authKrasinform = () => `${API_AUTH_URL}/register/krasinform`;

    static refreshToken = () => `${API_AUTH_URL}/token/refresh`;

    static syncToken = () => `${API_AUTH_URL}/token/sync`;

    static registerUser = () => `${API_AUTH_URL}/user/register`;

    static deleteToken = () => `${API_AUTH_URL}/token`;

    static deleteAllTokens = () => `${API_AUTH_URL}/token/all`;

    static logout = () => `${API_AUTH_URL}/user/logout`;

    static logoutAll = () => `${API_AUTH_URL}/user/token/all`;

    static resetPassword = () => `${API_AUTH_URL}/user/reset-password`;

    static changePassword = () => `${API_AUTH_URL}/user/change-password`;

    static socnet = () => `${API_AUTH_URL}/register/socnet`;

    static ukList = () => `${API_ZHKU_URLS}/uk`;

    // LANGS
    static getTranslation = (service = 'kvartal') => `${API_MENU_URLS}/translate/${service}`;

    // MENUS
    static getScenarios = () => `${API_MENU_URLS}/menu/scenario`;

    static getScopes = (scenario = 'default') => `${API_MENU_URLS}/menu/scopes/${scenario}`;

    static getAllMenus = (service = 'zkhu', scenario = 'default') => `${API_MENU_URLS}/menu/${service}/${scenario}`;

    static getMenusForScope = (scope = '*', service = 'zkhu', scenario = 'default') => `${API_MENU_URLS}/menu/${service}/${scenario}/${scope}`;

    static createMenuItem = () => `${API_MENU_URLS}/menu`;

    static editMenuItem = (id) => `${API_MENU_URLS}/menu/${id}`;

    static deleteMenuItem = (id) => `${API_MENU_URLS}/menu/${id}`;

    // USER DATA
    static getUserData = () => `${API_ZHKU_URLS}/user`;

    static syncUser = () => `${API_ZHKU_URLS}/sync/krasinform`;

    // COUNTERS
    static getCounters = () => `${API_ZHKU_URLS}/counter`;

    static getCounterInfo = (id) => `${API_ZHKU_URLS}/counter/${id}`;

    static sendCounterData = () => `${API_ZHKU_URLS}/counter/values`;

    // NEWS
    static getNewsList = (page = 1, limit = 10) => `${API_ZHKU_URLS}/news/list?page=${page}&limit=${limit}`;

    static getNewsWithId = (id) => `${API_ZHKU_URLS}/news/${id}`;

    static editNewsWithId = (id) => `${API_ZHKU_URLS}/news/${id}`;

    static deletetNewsWithId = (id) => `${API_ZHKU_URLS}/news/${id}`;

    static getNewsStatuses = () => `${API_ZHKU_URLS}/news/statuses`;

    static createNews = () => `${API_ZHKU_URLS}/news`;

    static publishNewsWithId = (id) => `${API_ZHKU_URLS}/news/${id}/published`;

    // ARTICLES
    static getArticleStatuses = () => `${API_ZHKU_URLS}/article/statuses`;

    static getArticlesList = (page = 1, limit = 10) => `${API_ZHKU_URLS}/article?page=${page}&limit=${limit}`;

    static getArticleWithSlug = (slug) => `${API_ZHKU_URLS}/article/${slug}`;

    static getArticleWithId = (id) => `${API_ZHKU_URLS}/article/view/${id}`;

    static editArticleWithId = (id) => `${API_ZHKU_URLS}/article/${id}`;

    static deletetArticleWithId = (id) => `${API_ZHKU_URLS}/article/${id}`;

    static publishArticleWithId = (id) => `${API_ZHKU_URLS}/article/publish/${id}`;

    static createArticle = () => `${API_ZHKU_URLS}/article`;

    // Payments
    static getInvoiceForPeriod = () => `${API_ZHKU_URLS}/payment/invoice/`;

    static getPaymentsList = (page = 0, limit = 10) => `${API_ZHKU_URLS}/payment/list?page=${page}&limit=${limit}`;

    static getAccrualsList = () => `${API_ZHKU_URLS}/payment/accrual`;

    static getPaymentStatuses = () => `${API_PAYMENT_URL}/payment/statuses`;

    // VOTES
    static getVotesList = (page, limit) => `${API_VOTE_URLS}/vote/list?page=${page}&limit=${limit}`;

    static getVotesStatuses = () => `${API_VOTE_URLS}/vote/statuses`;

    static getVotesRelations = () => `${API_VOTE_URLS}/vote/relations`;

    static createVote = () => `${API_VOTE_URLS}/vote`;

    static getVoteWithId = (id) => `${API_VOTE_URLS}/vote/${id}`;

    static editVoteWithId = (id) => `${API_VOTE_URLS}/vote/${id}`;

    static deleteVoteWithId = (id) => `${API_VOTE_URLS}/vote/${id}`;

    static changeVoteStatus = (id, status) => `${API_VOTE_URLS}/vote/${id}/${status}`;

    static saveVoteResult = () => `${API_VOTE_URLS}/vote/result`;

    // Messages Appeals
    static getAppealList = (page = 1, limit = 10) => `${API_ZHKU_URLS}/appeal?page=${page}&limit=${limit}`;

    static getAppealStatuses = () => `${API_ZHKU_URLS}/appeal/statuses`;

    static getAppealTypes = () => `${API_ZHKU_URLS}/appeal/types`;

    static getAppealWithId = (id) => `${API_ZHKU_URLS}/appeal/${id}`;

    static createAppeal = () => `${API_ZHKU_URLS}/appeal`;

    static editAppeal = (id) => `${API_ZHKU_URLS}/appeal/${id}`;

    static watchAppeal = (id) => `${API_ZHKU_URLS}/appeal/${id}/see`;

    static stopWatchAppeal = (id) => `${API_ZHKU_URLS}/appeal/${id}/delete/see`;

    static createMessage = () => `${API_ZHKU_URLS}/message`;

    static getMessageList = (page, limit, ...props) => {
        const params = _.reduce(...props, (res, item, key) => {
            if (item !== undefined) {
                res = `${res}${key}=${item}&`;
            }
            return res;
        }, '');
        return `${API_ZHKU_URLS}/message?page=${page}&limit=${limit}&${params}`;
    }

    static resetUnreadMessages = () => `${API_ZHKU_URLS}/message/read`;

    // Houses
    static getHousesList = (page = 1, limit = 10) => `${API_ZHKU_URLS}/house?page=${page}&limit=${limit}`;

    static searchHousesByAddress = (page = 1, limit = 10) => `${API_ZHKU_URLS}/house?page=${page}&limit=${limit}`;

    static searchHousesByIds = () => `${API_ZHKU_URLS}/house`;

    //debt
    static getDebtorsList = (page = 1, limit = 10) => `${API_ZHKU_URLS}/debt?page=${page}&limit=${limit}`;

    static getDebtorsCompaniesList = () => `${API_ZHKU_URLS}/debt/companies`;

    static getDebtorsUserByUk = (account_num = 0) => `${API_ZHKU_URLS}/user/uk/${account_num}`;

    static sendDebtorsMessageEmailUser = () => `${API_ZHKU_URLS}/user/email/send`;

    static sendDebtorsMessageTelegramUser = () => `${API_ZHKU_URLS}/user/tg/send`;

    // UK
    static getUKInfo = (name) => `${API_ZHKU_URLS}/uk/${name}`;

    static getUKList = () => `${API_ZHKU_URLS}/uk`;

    static getUKAddressList = (page = 1, limit = 10, city_name = '', street_name = '', query = '') => `${API_ZHKU_URLS}/uk/addresses?page=${page}&limit=${limit}&city_name=${city_name}&street_name=${street_name}${query ? `&query=${query}` : ''}`;

    static getUKStreetList = (page = 1, limit = 10, city_name = '', street_name = '') => `${API_ZHKU_URLS}/uk/streets?page=${page}&limit=${limit}&city_name=${city_name}&street_name=${street_name}`;

    static getUKCitiesList = (page = 1, limit = 10, city_name = '') => `${API_ZHKU_URLS}/uk/cities?page=${page}&limit=${limit}&city_name=${city_name}`;

    // TRANSLATE
    static getTranslateList = (page = 1, limit = 10, service, query = '') => `${API_MENU_URLS}/translate/list/${service}?page=${page}&limit=${limit}${query ? `&query=${query}` : ''}`;

    static getTranslateServiceList = () => `${API_MENU_URLS}/translate/services`;

    static getTranslateWithId = (id) => `${API_MENU_URLS}/translate/view/${id}`;

    static editTranslateWithId = (id) => `${API_MENU_URLS}/translate/${id}`;

    static deleteTranslateWithId = (id) => `${API_MENU_URLS}/translate/${id}`;

    static createTranslate = () => `${API_MENU_URLS}/translate`;

    static fetchListIndebtedness = () => `${API_ZHKU_URLS}/indebtedness`;
}
