import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    TextField, Button, FormGroup
} from '@material-ui/core';
import { showMessage } from 'actions/Messages';
import IntlMessages from 'util/IntlMessages';
import format from 'date-fns/format';
import { v4 as uuidv4 } from 'uuid';
import { sumBy } from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import Card from 'components/Card';
import HouseSelect from 'components/HouseSelect';
import QuestionListEditor from './QuestionListEditor';
import DatePicker from './DatePicker';
import CheckBox from './CheckBox';

const dataDefault = {
    title: '',
    description: '',
    start_at: format(new Date(), 'Y-MM-dd HH:mm:ssxxx'),
    expire_at: format(new Date(), 'Y-MM-dd HH:mm:ssxxx'),
    new: true,
    is_sahid_only: 0,
    questions: [{
        id: uuidv4(),
        new: true,
        title: '',
        answers: [
            {
                new: true,
                id: uuidv4(),
                title: ''
            },
            {
                new: true,
                id: uuidv4(),
                title: ''
            }
        ]
    }],
    relation_ids: []
};

const EditorForm = ({
    data = dataDefault,
    loading = false,
    handleSendRequest,
    handleClose,
    maxHeightContent
}) => {
    const dispatch = useDispatch();
    const [vote, setVote] = useState({...dataDefault, ...data});
    const [error, setError] = useState(false);
    const {
        locale
    } = useSelector(({ settings }) => settings);

    const onSendClick = () => {
        let isError = false;
        const newVote = {
            ...data,
            ...vote
        };
        if (newVote.new) {
            delete newVote.new;
            delete newVote.id;
        }
        newVote.questions.forEach((question) => {
            if (question.new) {
                delete question.new;
                delete question.id;
            }
            // error validation
            isError = isError ||
                !question.title ||
                sumBy(
                    question.answers,
                    (item) => !!item.title.trim().length
                ) < 2;

            question.answers.forEach((answer) => {
                if (answer.new) {
                    delete answer.new;
                    delete answer.id;
                }
            });
        });

        // validate
        if (!vote.title ||
            !vote.description ||
            new Date(vote.start_at) > new Date(vote.expire_at) ||
            isError ||
            (!!vote.is_sahid_only && !vote.relation_ids.length)
        ) {
            dispatch(showMessage('error', 'votes.fillAllFields', null, true));
            return;
        }

        handleSendRequest(newVote);
    };

    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setVote((state) => ({ ...state, [name]: value }));
    };

    const handleDateChange = (date, type) => {
        if (date !== null && date instanceof Date && !isNaN(date)) {
            setVote((state) => ({
                ...state,
                [type]: format(date, 'Y-MM-dd HH:mm:ssxxx')
            }));
        }
    };

    const saveQuestions = (qData) => {
        setVote((state) => ({
            ...state,
            questions: qData
        }));
    };

    const saveForOwnersOnly = (checked) => {
        setVote((state) => ({
            ...state,
            is_sahid_only: checked ? 1 : 0,
            relation_type: checked ? 2 : null,
            relation_ids: checked ? state.relation_ids : []
        }));
    };

    const saveRelationIds = (ids) => {
        setVote((state) => ({
            ...state,
            relation_ids: ids
        }));
    };

    useEffect(() => {
        if (Object.keys(data).length) {
            setVote(data);
        }
    }, [data]);

    const cardTitle = data.id ? (
        <>
            <IntlMessages id="appModule.editing" />
            {': '}
            {data.title}
        </>
    ) : (<IntlMessages id="votes.createVote" />);

    return (
        <Card
            loading={loading}
            maxHeightContent={maxHeightContent}
            title={cardTitle}
            className="bg-primary"
            handleActionsClick={handleClose}
            icon={(
                <CloseIcon />
            )}
            actions={(
                <div className="mb-2 pl-2 pr-2">
                    <Button onClick={handleClose} variant="contained" className="mr-3">
                        <IntlMessages id="appModule.cancel" />
                    </Button>
                    <Button
                        onClick={onSendClick}
                        disabled={error}
                        variant="contained"
                        color="primary"
                    >
                        <IntlMessages id="appModule.send" />
                    </Button>
                </div>
            )}>

            <div>
                <FormGroup className="mb-3">
                    <TextField
                        required
                        id={`${vote.id}inp1`}
                        type="text"
                        name="title"
                        className="mb-3"
                        label={<IntlMessages id="news.title" />}
                        value={vote.title}
                        onChange={handleTextChange}
                        error={!vote.title}
                        helperText={!vote.title && <IntlMessages id="appModule.mandatory.field" />}
                        variant="outlined"
                    />
                    <TextField
                        required
                        label={<IntlMessages id="news.description" />}
                        multiline
                        name="description"
                        value={vote.description}
                        onChange={handleTextChange}
                        error={!vote.description}
                        helperText={!vote.description && <IntlMessages id="appModule.mandatory.field" />}
                        className="mb-3"
                        variant="outlined"
                    />
                </FormGroup>
                <DatePicker
                    locale={locale.locale}
                    start_at={vote.start_at}
                    expire_at={vote.expire_at}
                    handleDateChange={handleDateChange}
                />

                <CheckBox
                    checked={!!vote.is_sahid_only}
                    readOnly={!!vote.id}
                    onChangeChecked={saveForOwnersOnly}
                    label={<IntlMessages id="votes.forOwnersOnly" />}
                >
                    <HouseSelect
                        relationIds={vote.relation_ids}
                        saveRelationIds={saveRelationIds}
                        requered={!!vote.is_sahid_only}
                    />
                </CheckBox>

                <QuestionListEditor
                    questions={vote.questions}
                    saveQuestions={saveQuestions}
                />

                {/*<FormGroup row className="justify-content-end mt-3 mb-3">*/}
                {/*    <Button onClick={handleClose} variant="contained" className="mr-3">*/}
                {/*        <IntlMessages id="appModule.cancel" />*/}
                {/*    </Button>*/}
                {/*    <Button*/}
                {/*        onClick={onSendClick}*/}
                {/*        disabled={error}*/}
                {/*        variant="contained"*/}
                {/*        color="primary"*/}
                {/*    >*/}
                {/*        <IntlMessages id="appModule.send" />*/}
                {/*    </Button>*/}
                {/*</FormGroup>*/}
            </div>
        </Card>

    );
};

export default EditorForm;
