import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import RestrictedRoute from 'containers/RestrictedRoute';
import ChangePassword from './ChangePassword';
import Meters from './Meters';
import Home from './Home';
import SyncAuth from './SyncAuth';
import News from './News';
import CurrentNews from './News/CurrentNews';
import Accruals from './Accruals';
import Invoice from './Invoice';
import ClientInfo from './ClientInfo';
import Payments from './Payments';
import Votes from './Votes';
import PaymentsDebtors from './PaymentsDebtors';
import AdminArticles from './AdminArticles';
import Articles from './Articles';
import Appeal from './Appeal';
import Error404 from './extraPages/404';
import CurrentArticle from './AdminArticles/CurrentArticle';
import SiteMenu from './SiteMenu';
import Translate from './Translate';
import MarketPlace from './MarketPlace';
import CreateOrder from './CreateOrder';
import PaymentSuccessful from './PaymentSuccessful';
import ProductPage from './MarketPlace/ProductPage';
import OrdersPage from './OrdersPages';
import UserManagement from './Admin/UserManagement';
import Indebtedness from './Indebtedness';
import PaymentSuccessfulZkh from './PaymentSuccessfulZkh';


const Routes = () => (
    <Switch>
        <Route
            exact
            path="/"
            component={Home}
        />
        <RestrictedRoute
            exact
            path="/admin/menu"
            component={SiteMenu}
        />
        <RestrictedRoute
            exact
            path="/signup/krasinform"
            component={SyncAuth}
        />
        <Route
            path="/news/:id/:mode?"
            component={CurrentNews}
        />
        <Route
            path="/news"
            component={News}
        />
        <RestrictedRoute
            exact
            path="/appeal/:id?"
            component={Appeal}
        />
        <RestrictedRoute
            path="/article/:mode/:id"
            component={CurrentArticle}
        />
        <RestrictedRoute
            path="/article"
            component={AdminArticles}
        />
        <RestrictedRoute
            exact
            path="/meters"
            component={Meters}
        />
        <RestrictedRoute
            exact
            path="/accruals"
            component={Accruals}
        />
        <RestrictedRoute
            exact
            path="/invoice"
            component={Invoice}
        />
        <RestrictedRoute
            path="/client-info"
            component={ClientInfo}
        />
        <RestrictedRoute
            path="/indebtedness"
            component={Indebtedness}
        />
        <RestrictedRoute
            path="/payments"
            component={Payments}
        />
        <RestrictedRoute
            path="/votes"
            component={Votes}
        />
        <RestrictedRoute
            adminOnly
            path="/payments-debtors"
            component={PaymentsDebtors}
        />
        <RestrictedRoute
            adminOnly
            path="/translate"
            component={Translate}
        />
        <RestrictedRoute
            exact
            path="/user/change-password"
            component={ChangePassword}
        />
        <RestrictedRoute
            exact
            path="/marketplace"
            component={MarketPlace}
        />
        <RestrictedRoute
            exact
            path="/marketplace/product/:id?"
            component={ProductPage}
        />
        <RestrictedRoute
            exact
            path="/marketplace/create-order"
            component={CreateOrder}
        />
        <RestrictedRoute
            exact
            path="/marketplace/payment-successful"
            component={PaymentSuccessful}
        />
        <RestrictedRoute
            exact
            path="/marketplace/orders"
            component={OrdersPage}
        />
        <RestrictedRoute
            exact
            path="/admin/user-management"
            component={UserManagement}
        />

        <RestrictedRoute
            exact
            path="/zkh/payment-successful"
            component={PaymentSuccessfulZkh}
        />

        <Route path="/error404" component={Error404} />
        <Route
            component={Articles}
        />
    </Switch>
);


export default withRouter(Routes);
