import addErrorHandling from 'util/errors/error.handling';
import Urls from './api.urls';
import instance from './axios.config';

// const instance = axios.create({
//     // Reject only if the status code is greater than or equal to 500
//     // validateStatus: status => status < 500,
// });

const ApiCompanyMethods = {
    getHousesList: async (page, limit, access_token) => {
        const response = await instance.get(Urls.getHousesList(page, limit),
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    searchHousesByAddress: async (page, limit, address, access_token) => {
        const response = await instance.get(Urls.searchHousesByAddress(page, limit),
            {
                params: { query: address },
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    searchHousesByIds: async (ids, access_token) => {
        const response = await instance.get(Urls.searchHousesByIds(),
            {
                params: { external_ids: ids },
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    }
};

export default addErrorHandling(ApiCompanyMethods);
