import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

//editor plugins
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import Autolink from '@ckeditor/ckeditor5-link/src/autolink';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
// import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder';
// import CKFinderUploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter';
import Code from '@ckeditor/ckeditor5-basic-styles/src/code';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor';
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor';
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily';
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Link from '@ckeditor/ckeditor5-link/src/link';
import List from '@ckeditor/ckeditor5-list/src/list';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import FileUpload from '../../assets/vendors/ckeditor5-file-upload/fileupload';
import Emojis from '@harrisonlucas/ckeditor5-emojis/src/emojis';

import CustomUploadAdapterPlugin from 'util/fileUploaderForEditor';

import 'assets/vendors/ckeditor5/build/translations/ru';

const editorConfiguration = {
    plugins: [
        Alignment,
        Autoformat,
        Autolink,
        BlockQuote,
        Bold,
        FileUpload,
        Code,
        Essentials,
        FontBackgroundColor,
        FontColor,
        FontFamily,
        FontSize,
        Heading,
        HorizontalLine,
        Image,
        ImageCaption,
        ImageStyle,
        ImageToolbar,
        ImageUpload,
        Indent,
        Italic,
        Link,
        List,
        MediaEmbed,
        Paragraph,
        PasteFromOffice,
        RemoveFormat,
        SpecialCharacters,
        // SpecialCharactersEssentials,
        Strikethrough,
        Superscript,
        Table,
        TableCellProperties,
        TableProperties,
        TableToolbar,
        TextTransformation,
        Underline,
        Emojis,
    ]
};

const CustomCKEditor = ({ locale, content, onEditorContentChange }) => (
        <CKEditor
        editor={ClassicEditor}
        config={{
            ...editorConfiguration,
            extraPlugins: [CustomUploadAdapterPlugin],
            simpleFileUpload: {
                urlFieldName: 'default',
                fileTypes: [
                    '.pdf',
                    '.doc',
                    '.docx',
                    '.xls',
                    '.xlsx'
                ]
            },
            language: locale.locale,
            toolbar: {
                items: [
                    'ckfinder',
                    'heading',
                    '|',
                    'bold', 'italic', 'underline', 'strikethrough', 'link', 'blockQuote', //subscript', 'superscript',
                    '|',
                    'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor',
                    'alignment', //'indent',
                    '|',
                    'bulletedList', 'numberedList', 'horizontalLine',
                    '|',
                    'imageUpload', 'mediaEmbed', 'fileUpload',
                    '|',
                    'insertTable',
                    '|',
                    'emojis',
                    '|',
                    'removeFormat',
                    'undo', 'redo',
                ]
            },
            fontSize: {
                options: [9, 11, 13, 'default', 17, 19, 21]
            },
            table: {
                contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
            },
            mediaEmbed: {
                previewsInData: true
            },
            image: {
                resizeUnit: 'px',
                toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],
                styles: [
                    'full',
                    'alignLeft',
                    'alignRight'
                ],
                upload: {
                    types: ['jpeg', 'png', 'gif', 'bmp', 'webp', 'tiff']
                }
            },
            link: {
                addTargetToExternalLinks: true
            }
        }}
        data={content}
        onInit={(editor) => {
            // console.log( Array.from( editor.ui.componentFactory.names() ) );
            // You can store the "editor" and use when it is needed.
            // console.log('Editor is ready to use!', editor);
        }}
        onChange={onEditorContentChange}
    />
);

export default CustomCKEditor;
