import {
    all, call, select, put, takeLatest
} from 'redux-saga/effects';

import {
    FETCH_ADDRESS_LIST_DADATA
} from 'constants/ActionTypes/dadata';
import {
    loadingDaData,
    addressListLoaded
} from 'actions/DaData';
import { getActionForError } from 'util/errors';
import api from 'util/api/dadata/api.dadata.methods';

function* fetchAddressSaga({ payload }) {
    const params = {
        ...payload,
        source: 'dadata'
    };
    try {
        yield put(loadingDaData(true));
        const response = yield call(api.fetchAddress, params);
        yield put(addressListLoaded(response));
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(loadingDaData(false));
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(FETCH_ADDRESS_LIST_DADATA, fetchAddressSaga)
    ]);
}
