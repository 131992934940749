import fileUpload from 'util/fileUploader';

class CustomUploadAdapter {
    constructor(loader) {
        // The file loader instance to use during the upload.
        this.loader = loader;
    }

    // Starts the upload process.
    async upload() {
        const file = await this.loader.file;
        const url = await fileUpload(file);
        return { default: url };
    }

    // Aborts the upload process.
    abort() {
        // Reject the promise returned from the upload() method.
        // server.abortUpload();
    }
}

export default function CustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
        // Configure the URL to the upload script in your back-end here!
        new CustomUploadAdapter(loader);
}
