import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ForgotPassword from 'components/Auth/ForgotPassword';
import { Grid } from '@material-ui/core/';
import {
    showAuthLoader,
    resetPassword,
    setValidationFields
} from 'actions/Auth';

const AppForgotPassword = () => {
    const dispatch = useDispatch();
    //const { ukInfo: { logo, name } } = useSelector(({ auth }) => auth);
    const logo = <img src={require('assets/images/logo64.png')} alt="Logo" title="Logo" width="50" />;
    const name = 'УК ФЛАГМАН';

    const reset = (username) => {
        dispatch(showAuthLoader());
        dispatch(setValidationFields({}));
        dispatch(resetPassword(username));
    };

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
        >
            <Grid item xs={10} md={4}>
                <ForgotPassword
                    logo={logo}
                    title={name}
                    onClickReset={reset}
                />
            </Grid>
        </Grid>
    );
};

export default AppForgotPassword;
