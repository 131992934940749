import React from 'react';
import banner from './banner.jpg';


const Home = () => (
    <>
        <img src={banner} title="УК ФЛАГМАН" alt="УК ФЛАГМАН" style={{ maxWidth: '100%' }} />
    </>
);

export default Home;
