import {
    USER_LIST_LOADED,
    USER_LIST_LOADING,
    USER_SAVED_SUCCESS,
    USER_LOADING,
    LOADED_ROLES,
    USER_ACCOUNT_INFO_LOADED,
} from 'constants/ActionTypes/users';

const INIT_STATE = {
    list: { data: [] },
    userInfo: {},
    loading: false,
    userLoading: false,
    successfulySaved: false,
    roles: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
    case USER_LIST_LOADING: {
        return {
            ...state,
            loading: action.payload
        };
    }
    case USER_LOADING: {
        return {
            ...state,
            userLoading: action.payload
        };
    }
    case USER_SAVED_SUCCESS: {
        return {
            ...state,
            successfulySaved: action.payload
        };
    }
    case USER_LIST_LOADED: {
        return {
            ...state,
            list: action.payload
        };
    }
    case LOADED_ROLES: {
        return {
            ...state,
            roles: action.payload
        };
    }
    case USER_ACCOUNT_INFO_LOADED: {
        const userData = action.payload.reduce((res, el) => {
            res[el.user_id] = res[el.user_id] || [];
            res[el.user_id].push(el);
            return res;
        }, {});

        return {
            ...state,
        userInfo: {
            ...state.userInfo,
            ...userData
        }
        };
    }

    default:
        return state;
    }
};
