import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import CartContent from './CartContent';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBasket, updateBasket, deleteFromBasket,  } from '../../actions/Market';
import {
    Badge,
    Tooltip
} from '@material-ui/core';
import './style.scss';
import useDebounce from '../Helpers/useDebounce';

const CartMini = (props) => {
    const { onCreateOrder, color } = props;
    const dispatch = useDispatch();
    const [cartSwitcher, setCartSwitcher] = useState(false);
    const { basket, loadingBasket } = useSelector(({ market }) => market);

    const updateBasketDebounce = useDebounce(({ id, product_id, count }) => {
        dispatch(updateBasket(id, { product_id, count }));
    }, 800);
    const handleChangeCount = (params) => updateBasketDebounce(params);

    const onCartSwitcherSelect = () => {
        setCartSwitcher(!cartSwitcher);
    };

    // общее количество продуктов
    const productItems = basket?.items || [];
    const getProductCount = productItems.reduce((res, { count }) => res + count, 0);

    useEffect(() => {
        dispatch(fetchBasket());
    }, []);

    return (
        <Dropdown
            className="quick-menu"
            isOpen={cartSwitcher}
            toggle={onCartSwitcherSelect}
        >
            <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown"
            >
                <Tooltip title="Корзина">
                    <IconButton className="icon-btn">
                        <Badge badgeContent={getProductCount} color="error">
                            <i className={'zmdi zmdi-shopping-cart infinite wobble'} style={{ color: color || '#fff'}}/>
                        </Badge>
                        {/* animated */}
                    </IconButton>
                </Tooltip>
            </DropdownToggle>

            <DropdownMenu
                right
                //className="w-50"
            >
                <CartContent
                    data={basket}
                    loading={loadingBasket}
                    onCreateOrder={(params) => {
                        onCreateOrder(params);
                        setCartSwitcher(false);
                    }}
                    onChangeCount={handleChangeCount}
                    onDelete={(id) => {
                        dispatch(deleteFromBasket(id));
                    }}
                />
            </DropdownMenu>
        </Dropdown>
    );
}

export default CartMini;