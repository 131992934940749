import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setLoading, fetchNewsWithId, newsSavedSuccess,
    editNews, fetchStatuses
} from 'actions/News';
import {
    LinearProgress
} from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import EditorForm from 'components/ArticleList/EditorForm';
import useStatuses from 'components/Helpers/useStatuses';
import PageHeader from 'components/PageHeader';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import ActionButton from 'components/ActionButton';
import NewsItem from '../NewsItem';

const CurrentNews = ({ match, history }) => {
    const dispatch = useDispatch();
    const statuses = useStatuses(fetchStatuses, 'news');
    const {
        error, currentNews, newsSaved, loading
    } = useSelector(({ news }) => news);
    const { changeCurrentUK } = useSelector(({ auth }) => auth);
    const id = parseInt(match?.params?.id);
    const isEditorMode = match?.params?.mode === 'edit';

    useEffect(() => {
        if (changeCurrentUK) {
            history.push('/news');
        }
    }, [changeCurrentUK]);

    useEffect(() => {
        if (id && !error) {
            dispatch(setLoading(true));
            dispatch(fetchNewsWithId(id));
        }
    }, [id, error, dispatch]);

    const handleSendRequest = (el) => {
        dispatch(editNews(id, el));
    };

    useEffect(() => {
        if (newsSaved) {
            dispatch(newsSavedSuccess(false));
            history.goBack();
            if (id) {
                dispatch(fetchNewsWithId(id));
            }
        }
    }, [newsSaved, id]);

    const createMarkup = (value) => ({ __html: value });

    return (
        <div className="news_page">
            {/* header */}
            <PageHeader
                title={<IntlMessages id="sidebar.dashboard.news" />}
                buttons={[
                    <ActionButton
                        title="appModule.goToList"
                        onClick={() => history.push('/news')}
                        className="text-secondary"
                        icon={<FormatListBulletedIcon />}
                    />
                ]}
            />
            {(loading || currentNews.id !== id) && <LinearProgress className="mb-1" />}
            { currentNews.id === id
                ? (isEditorMode && Object.keys(currentNews).length > 0)
                    ? (
                        <EditorForm
                            data={currentNews}
                            handleSendRequest={handleSendRequest}
                            handleClose={() => history.goBack()}
                            type="news"
                        />
                    )
                    : (
                        <NewsItem
                            item={currentNews}
                            history={history}
                            itemStatus={statuses.find((el) => el.value === currentNews.status) || {}}
                            text={
                                <div className="mt-0 text-sm description" dangerouslySetInnerHTML={createMarkup(currentNews.text)} />
                            }
                        />
                    )
                : null}
        </div>
    );
};

export default CurrentNews;
