import React from 'react';
import {
    Avatar, Tooltip
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.8)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        marginTop: 0
    }
}))(Tooltip);

const AvatarStatus = ({ status = {}, avatar = {} }) => (
    <LightTooltip title={status.description || ''}>
        <Avatar aria-label={status.description} classes={{ root: avatar.bgColor }}>
            {avatar.icon}
        </Avatar>
    </LightTooltip>
);

export default AvatarStatus;
