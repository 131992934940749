import {
    all, call, select, put, takeLatest
} from 'redux-saga/effects';

import {
    ADD_TO_BASKET,
    FETCH_BASKET,
    UPDATE_BASKET,
    DELETE_FROM_BASKET,
    FETCH_CATEGORY_LIST,
    ADD_CATEGORY,
    UPDATE_CATEGORY,
    DELETE_CATEGORY,
    FETCH_CATEGORY_TREE,
    FETCH_PRODUCT_LIST,
    ADD_PRODUCT,
    UPDATE_PRODUCT,
    DELETE_PRODUCT,
    FETCH_ORDER_LIST,
    CREATE_ORDER,
    GET_ORDER_STATUSES,
    GET_ORDER_PAYMENT_STATUSES,
    GET_ORDER_PAYMENT_TYPES,
    FETCH_ORDERS_LIST,
    CHANGE_ORDER_STATUS,
    CLOSE_ORDER,
} from 'constants/ActionTypes/market';
import {
    basketFetched,
    loadingInBasket,
    fetchBasket,
    categoryListFetched,
    loadingCategory,
    fetchCategoryList,
    categoryTreeFetched,
    loadingProduct,
    fetchProductList,
    productListFetched,
    loadingOrder,
    ordertListFetched,
    orderStatusesLoaded,
    orderPaymentStatusesLoaded,
    orderPaymentTypesLoaded,
    orderLoaded,
    ordersListFetched,
    loadingOrdersList,
    orderSaved,
} from 'actions/Market';
import { getActionForError } from 'util/errors';
import api from 'util/api/market/api.market.methods';
import categories from './categories';
import products from './products';
import {showMessage} from "../actions/Messages";

function* fetchBasketSaga({ payload }) {
    try {
        const response = yield call(api.getBasket, payload);
        yield put(basketFetched(response.data));
    } catch (error) {
        yield put(getActionForError(error));
    }
}

function* addToBasketSaga({ payload }) {
    yield put(loadingInBasket(true));
    try {
        const response = yield call(api.addToBasket, payload);
        yield put(fetchBasket());
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(loadingInBasket(false));
    }
}

function* updateBasketSaga({ payload }) {
    yield put(loadingInBasket(true));
    try {
        const response = yield call(api.updateBasket, payload);
        yield put(fetchBasket());
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(loadingInBasket(false));
    }
}

function* deleteFromBasketSaga({ payload }) {
    yield put(loadingInBasket(true));
    try {
        const response = yield call(api.deleteFromBasket, payload);
        yield put(fetchBasket());
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(loadingInBasket(false));
    }
}

// категории
function* fetchCategoriesSaga({ payload }) {
    yield put(loadingCategory(true));
    try {
        const response = yield call(api.getCategoryList, payload);
        yield put(categoryListFetched(response.data));
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(loadingCategory(false));
    }
}

function* fetchCategoryTreeSaga({ payload }) {
    yield put(loadingCategory(true));
    try {
        const response = yield call(api.getCategoryTree, payload);
        yield put(categoryTreeFetched(response.data));
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(loadingCategory(false));
    }
}

function* addCategorySaga({ payload }) {
    yield put(loadingCategory(true));
    try {
        const response = yield call(api.addCategory, payload);
        yield put(fetchCategoryList());
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(loadingCategory(false));
    }
}

function* updateCategorySaga({ payload }) {
    yield put(loadingCategory(true));
    try {
        const response = yield call(api.updateCategory, payload);
        yield put(fetchCategoryList());
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(loadingCategory(false));
    }
}

function* deleteCategorySaga({ payload }) {
    yield put(loadingCategory(true));
    try {
        const response = yield call(api.deleteCategory, payload);
        yield put(fetchCategoryList());
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(loadingCategory(false));
    }
}

// продукты
function* fetchProductListSaga({ payload }) {
    yield put(loadingProduct(true));
    try {
        const response = yield call(api.fetchProductList, payload);
        // const response = products;
        yield put(productListFetched(response.data));
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(loadingProduct(false));
    }
}

function* addProductSaga({ payload }) {
    yield put(loadingProduct(true));
    try {
        const response = yield call(api.addProduct, payload);
        yield put(fetchProductList());
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(loadingProduct(false));
    }
}

function* updateProductSaga({ payload }) {
    yield put(loadingProduct(true));
    try {
        const response = yield call(api.updateProduct, payload);
        yield put(fetchProductList());
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(loadingProduct(false));
    }
}

function* deleteProductSaga({ payload }) {
    yield put(loadingProduct(true));
    try {
        const response = yield call(api.deleteProduct, payload);
        yield put(fetchProductList());
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(loadingProduct(false));
    }
}

// orders
function* fetchOrderListSaga({ payload }) {
    yield put(loadingOrder(true));
    try {
        const response = yield call(api.getOrderList, payload);
        yield put(ordertListFetched(response.data));
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(loadingOrder(false));
    }
}

function* createOrderSaga({ payload }) {
    yield put(loadingOrder(true));
    try {
        const response = yield call(api.addOrder, payload);
        yield put(orderLoaded(response.data));

        // const { payment_link } = response.data;
        // // переходим на оплату
        // setTimeout(() => {
        //     if (payment_link) {
        //         const link = document.createElement('a');
        //         link.href = payment_link;
        //         link.click();
        //     }
        // }, 1000);

        yield put(fetchBasket()); // перезапросить корзину - она уже пустая
        // редирект на оплату
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(loadingOrder(false));
    }
}

function* getOrderStatusesSaga({ payload }) {
    try {
        const response = yield call(api.getOrderStatuses, payload);
        yield put(orderStatusesLoaded(response.data));
    } catch (error) {
        yield put(getActionForError(error));
    }
}

function* getOrderPaymentStatusesSaga({ payload }) {
    try {
        const response = yield call(api.getOrderPaymentStatuses, payload);
        yield put(orderPaymentStatusesLoaded(response));
    } catch (error) {
        yield put(getActionForError(error));
    }
}

function* getOrderPaymentTypesSaga({ payload }) {
    try {
        const response = yield call(api.getOrderPaymentTypes, payload);
        yield put(orderPaymentTypesLoaded(response.data));
    } catch (error) {
        yield put(getActionForError(error));
    }
}

function* getOrdersListSaga({ payload }) {
    yield put(loadingOrdersList(true));
    try {
        const response = yield call(api.getOrdersList, payload);
        yield put(ordersListFetched(response));
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(loadingOrdersList(false));
    }
}

function* changeOrdersStatusSaga({ payload }) {
    yield put(loadingOrder(true));
    try {
        //const response = yield call(api.changeOrdersStatus, payload);
        yield put(showMessage('success', 'Статус заказа успешно изменен', null, false));
        yield put(orderSaved(true));
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(loadingOrder(false));
    }
}

function* closeOrdersSaga({ payload }) {
    yield put(loadingOrder(true));
    try {
        //const response = yield call(api.closeOrders, payload);
        yield put(showMessage('success', 'Заказ успешно отменен', null, false));
        yield put(orderSaved(true));
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(loadingOrder(false));
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(ADD_TO_BASKET, addToBasketSaga),
        takeLatest(FETCH_BASKET, fetchBasketSaga),
        takeLatest(UPDATE_BASKET, updateBasketSaga),
        takeLatest(DELETE_FROM_BASKET, deleteFromBasketSaga),
        takeLatest(FETCH_CATEGORY_LIST, fetchCategoriesSaga),
        takeLatest(FETCH_CATEGORY_TREE, fetchCategoryTreeSaga),
        takeLatest(ADD_CATEGORY, addCategorySaga),
        takeLatest(UPDATE_CATEGORY, updateCategorySaga),
        takeLatest(DELETE_CATEGORY, deleteCategorySaga),
        takeLatest(FETCH_PRODUCT_LIST, fetchProductListSaga),
        takeLatest(ADD_PRODUCT, addProductSaga),
        takeLatest(UPDATE_PRODUCT, updateProductSaga),
        takeLatest(DELETE_PRODUCT, deleteProductSaga),
        takeLatest(FETCH_ORDER_LIST, fetchOrderListSaga),
        takeLatest(CREATE_ORDER, createOrderSaga),
        takeLatest(GET_ORDER_STATUSES, getOrderStatusesSaga),
        takeLatest(GET_ORDER_PAYMENT_STATUSES, getOrderPaymentStatusesSaga),
        takeLatest(GET_ORDER_PAYMENT_TYPES, getOrderPaymentTypesSaga),
        takeLatest(FETCH_ORDERS_LIST, getOrdersListSaga),
        takeLatest(CHANGE_ORDER_STATUS, changeOrdersStatusSaga),
        takeLatest(CLOSE_ORDER, closeOrdersSaga),
    ]);
}