import {
    FETCH_ARTICLE_LIST,
    LOAD_ARTICLE_WITH_ID,
    LOAD_ARTICLE_WITH_SLUG,
    ARTICLE_LIST_LOADED_SUCCESS,
    SAVE_CURRENT_ARTICLE,
    SET_ARTICLE_ERROR,
    CREATE_ARTICLE,
    PUBLISH_ARTICLE,
    EDIT_ARTICLE,
    DELETE_ARTICLE,
    ARTICLE_SUCCESSFULLY_SAVED,
    FETCH_ARTICLES_STATUSES,
    ARTICLES_STATUSES_LOADED,
    ARTICLES_SHOW_LOADER,
    ARTICLES_HIDE_LOADER,
    SET_ARTICLE_LOADING
} from 'constants/ActionTypes';

export const setLoading = (bool) => ({
    type: SET_ARTICLE_LOADING,
    payload: bool
});

export const fetchArticlesList = (page, limit) => ({
    type: FETCH_ARTICLE_LIST,
    payload: { page, limit }
});

export const fetchArticleWithSlug = (slug) => ({
    type: LOAD_ARTICLE_WITH_SLUG,
    payload: { slug }
});

export const fetchArticleWithId = (id) => ({
    type: LOAD_ARTICLE_WITH_ID,
    payload: { id }
});

export const articlesLoaded = (article) => ({
    type: ARTICLE_LIST_LOADED_SUCCESS,
    payload: article
});

export const articleLoaded = (article) => ({
    type: SAVE_CURRENT_ARTICLE,
    payload: article
});

export const setError = (bool) => ({
    type: SET_ARTICLE_ERROR,
    payload: bool
});

export const fetchStatuses = () => ({
    type: FETCH_ARTICLES_STATUSES
});

export const statusesLoaded = (statuses) => ({
    type: ARTICLES_STATUSES_LOADED,
    payload: statuses
});

export const createArticle = (data) => ({
    type: CREATE_ARTICLE,
    payload: { data }
});

export const editArticle = (id, data) => ({
    type: EDIT_ARTICLE,
    payload: { id, data }
});

export const publishArticle = (id) => ({
    type: PUBLISH_ARTICLE,
    payload: { id }
});

export const deleteArticle = (id) => ({
    type: DELETE_ARTICLE,
    payload: { id }
});

export const articleSavedSuccess = (bool = false, action = true) => ({
    type: ARTICLE_SUCCESSFULLY_SAVED,
    payload: { bool, action }
});
