import React, {useEffect, useState} from 'react';
import { Card, CardContent, CardHeader, CircularProgress, Typography } from '@material-ui/core';
import Form from './Form';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteFromBasket,
    getOrderPaymentTypes,
    createOrder,
    fetchBasket,
    updateBasket,
    orderLoaded
} from 'actions/Market';
import Item from './Item';
import useDebounce from '../../../components/Helpers/useDebounce';
import './style.scss';
import usePrevious from '../../../components/Helpers/usePrevious';
import Pay from './Pay';

const CreateOrder = (props) => {
    const dispatch = useDispatch();
    const { basket, loadingBasket, order, loadingOrders, paymentTypes } = useSelector(({ market }) => market);

    const updateBasketDebounce = useDebounce(({ id, product_id, count }) => {
        dispatch(updateBasket(id, { product_id, count }));
    }, 800);
    const handleChangeCount = (params) => updateBasketDebounce(params);

    const loadingOrdersPrev = usePrevious(loadingOrders);

    const [isOrderCreated, setIsOrderCreated] = useState(false);

    useEffect(() => {
        if (Object.keys(paymentTypes).length === 0) {
            dispatch(getOrderPaymentTypes());
        }
        const count = basket.items?.length || 0;
        if (count === 0) {
            dispatch(fetchBasket());
        }
        return () => {
            dispatch(orderLoaded({}));
        };
    }, []);

    useEffect(() => {
        if (
            loadingOrdersPrev === true &&
            loadingOrders === false &&
            Object.keys(order).length
        ) {
            setIsOrderCreated(true);
        }
    }, [order, loadingOrders]);

    useEffect(() => {
        // если корзина пустая и не создан заказ - редиректим
        if (
            basket.items?.length === 0 &&
            isOrderCreated === false
        ) {
            props.history.push('/marketplace');
        }
    }, [basket]);

    const productCount = basket.items?.length || 0;

    const handleCreateOrder = (formData) => {
        const products = basket.items?.map(({ product_id, count }) => ({ id: product_id, count }));
        // console.log('formData', formData, products);
        dispatch(createOrder({
            products,
            ...formData
        }));
    };

    const total = basket.total || 0;
    const products = basket.items || [];

    return (
        <>
            {loadingBasket && productCount === 0 ? <CircularProgress /> : (
                <div className="backet">
                    <h1>Оформление заказа</h1>

                    {isOrderCreated === false ? (
                        <Card elevation={3} className="mb-4">
                            <CardHeader
                                title={products.length > 0 ? 'Товары' : ''}
                                className="basket-products"
                            />
                            <CardContent>

                                <div className="list-unstyled">
                                    {products.length > 0
                                        ? products.map((product) => (
                                            <Item
                                                data={product}
                                                key={product.id}
                                                onChangeCount={handleChangeCount}
                                                onDelete={(id) => {
                                                    dispatch(deleteFromBasket(id));
                                                }}
                                            />
                                        ))
                                        : (
                                            <Typography variant="h5" align="center" className="mb-4">
                                                Корзина пустая
                                            </Typography>
                                        )
                                    }
                                </div>

                                {products.length > 0 ?
                                    <Typography variant="subtitle1" align="right">
                                        Сумма: {total} руб.
                                    </Typography>
                                : ''}
                            </CardContent>
                        </Card>
                    ) : ''}

                    {/* форма */}

                    {isOrderCreated === false ? (
                        <Form
                            paymentTypes={paymentTypes}
                            loading={loadingBasket || loadingOrders}
                            onSubmitForm={handleCreateOrder}
                        />
                    ) : (
                        <Pay
                            url={order.payment_link || null}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default CreateOrder;
