import React, { useEffect, useState } from 'react';
import { TextField, FormGroup, Button } from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import moment from 'moment';
import { useSelector } from 'react-redux';
import CardBox from 'components/Card';
import HouseSelect from 'components/HouseSelect';
import MultiSelect from './MultiSelect';
import PricePicker from './PricePicker';
import DatePicker from './DatePicker';

const currentDate = new Date();
const initialDataFilter = {
    to_period: moment(currentDate).format('YYYY-MM'),
    from_sum: '',
    to_sum: '',
    account_uk_identifier: '',
    house_external_id: [],
    company: []
};

const Filter = ({
    companies = [],
    data,
    onChangeFilter = () => {},
    handleClose = () => {}
}) => {
    const [disabled, setDisabled] = useState(true);
    const [dataFilter, setDataFilter] = useState({ ...initialDataFilter, ...data });

    const {
        locale
    } = useSelector(({ settings }) => settings);

    useEffect(() => {
        if (Object.keys(data).length) {
            setDataFilter({ ...initialDataFilter, ...data });
        }
    },[data]);

    const handleFilterChange = (value, name) => {
        setDataFilter({
            ...dataFilter,
            [name]: value
        });
        setDisabled(false);
    };

    const handleFilterSubmit = () => {
        setDisabled(true);
        onChangeFilter(dataFilter);
        handleClose();
    };

    const handleFilterReset = () => {
        setDataFilter(initialDataFilter);
        setDisabled(true);
        onChangeFilter(initialDataFilter);
    };

    return (
        <CardBox
            title={<IntlMessages id="appModule.filter" />}
            actions={(
                <>
                    <Button
                        onClick={handleFilterReset}
                        variant="contained"
                        className="mr-3"
                    >
                        <IntlMessages id="filter.resetFilter" />
                    </Button>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        className="mr-3"
                    >
                        <IntlMessages id="appModule.close" />
                    </Button>

                    <Button
                        onClick={handleFilterSubmit}
                        variant="contained"
                        color="secondary"
                        disabled={disabled}
                    >
                        <IntlMessages id="appModule.apply" />
                    </Button>
                </>
            )}
        >
            <form className="row" noValidate autoComplete="off" style={{ maxWidth: 500 }}>
                <FormGroup className="col-md-6 col-12">
                    <TextField
                        value={dataFilter.account_uk_identifier}
                        label={<IntlMessages id="debtors.accountNumber" />}
                        name="account_uk_identifier"
                        onChange={(event) => {
                            const { value, name } = event.target;
                            handleFilterChange(value, name);
                        }}
                        type="number"
                        variant="outlined"
                        size="small"
                        className="mb-3 hide-spin"
                    />
                </FormGroup>

                <FormGroup className="col-md-6 col-12">
                    <DatePicker
                        dateFormat="YYYY-MM"
                        locale={locale.locale}
                        to_period={dataFilter.to_period}
                        onDateChange={handleFilterChange}
                    />
                </FormGroup>

                <FormGroup className="col-md-6 col-12">
                    <MultiSelect
                        values={dataFilter.company}
                        data={companies}
                        handleChange={(items) => handleFilterChange(items, 'company')}
                    />
                </FormGroup>

                <PricePicker
                    from={{
                        value: dataFilter.from_sum,
                        label: <IntlMessages id="debtors.amountFrom" />,
                        name: 'from_sum'
                    }}
                    to={{
                        value: dataFilter.to_sum,
                        label: <IntlMessages id="debtors.amountTo" />,
                        name: 'to_sum'
                    }}
                    onPriceChange={handleFilterChange}
                />

                <div className="col-12">
                    <HouseSelect
                        relationIds={dataFilter.house_external_id}
                        saveRelationIds={(ids) => handleFilterChange(ids, 'house_external_id')}
                    />
                </div>
            </form>
        </CardBox>
    );
};

export default Filter;
