import api from 'util/api/api.lang.methods';
import messagesEn from '../locales/en_US.json';

const enLang = async () => {
    try {
        const enMessages = await api.getTranslation('en');
        return {
            messages: {
                ...enMessages
            },
            locale: 'en-US'
        };
    } catch (e) {
        return {
            messages: {
                ...messagesEn
            },
            locale: 'en-US'
        };
    }
};
export default enLang;
