import {
    API_ADMIN_URL, API_ZHKU_URLS
} from 'constants/Config';

const apiUrls = {
    fetchUserList: () => `${API_ADMIN_URL}/user`,
    loadUserListWithAccountInfo: () => `${API_ZHKU_URLS}/user/list`,
    loadRoles: () => `${API_ADMIN_URL}/role`,
    editUserData: () => `${API_ZHKU_URLS}/user/account`
};

export default apiUrls;