import React, {useState} from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Detail = ({
    children = null,
    list = [],
    titles = [],
    labels = {},
    button = {},
    defaultExpanded = false
}) => {
    const createBody = (item) => Object.keys(labels).map((key) => {
        if (item[key]) {
            const label = typeof labels[key] === "string" ? labels[key] : labels[key].label;
            const value = item[key];
            const valueFunc = labels[key]?.format ? labels[key]?.format(value) : value;

            return (
                <p key={key}>
                    <strong>{label}</strong>
                    :
                    {' '}
                    {valueFunc} {(key === 'amount' || key === 'sum') && 'руб.'}
                </p>
            );
        } else {
            return null;
        }
    });

    const createHeader = (item) => titles.map((key, index) => {
        const width = 100 / titles.length;
        return (
            <span key={key} style={{ width: `${width}%`, textAlign: `${index > 0 ? 'right' : 'left'}`}}>
                {item[key]} {(key === 'amount' || key === 'sum') && 'руб.'}
            </span>
        );
    });

    return (
        <>
            {list?.map((item, index) => (
                <Accordion defaultExpanded={defaultExpanded} key={`pn${index}`}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        classes={{ content: 'justify-content-between mr-3' }}
                    >
                        {createHeader(item)}
                    </AccordionSummary>
                    <AccordionDetails classes={{ root: 'border-top flex-column' }}>
                        {createBody(item)}
                        {Object.keys(button).length > 0 &&
                            <Button
                                disabled={button.disabled || false}
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={(e) => button.onClick(e, item)}
                                style={{alignSelf: 'self-start'}}
                            >
                                {button.title}
                            </Button>
                        }
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    );
};

export default Detail;
