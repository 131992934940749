import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import {
    fetchArticlesList, articleSavedSuccess, createArticle,
    fetchStatuses, setLoading, articlesLoaded
} from 'actions/Articles';
import { setChangeCurrentUKStatus } from 'actions/Auth';

import {
    LinearProgress, Grid
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Pagination from '@material-ui/lab/Pagination';
import EditorForm from 'components/ArticleList/EditorForm';
import PageHeader from 'components/PageHeader';
import ActionButton from 'components/ActionButton';
import useStatuses from 'components/Helpers/useStatuses';
import ChopLines from 'chop-lines';
import ArticleItem from './ArticleItem';
import { createMenuItem } from '../../../actions/Menu';

const Articles = ({ match, history }) => {
    const initialState = {
        page: 1,
        limit: 10
    };
    const dispatch = useDispatch();
    const { isAdmin, currentUK, changeCurrentUK } = useSelector(({ auth }) => auth);
    const [isEditorOpen, setEditorOpen] = useState(false);
    const statuses = useStatuses(fetchStatuses, 'articles');
    const {
        list, articleSaved, loading
    } = useSelector(({ articles }) => articles);
    const [page, setPage] = React.useState(initialState.page);
    const [limit, setLimit] = useState(initialState.limit);


    useEffect(() => {
        if (changeCurrentUK) {
            setPage(initialState.page);
            setLimit(initialState.limit);
            dispatch(articlesLoaded({ data: [] }));
            dispatch(setChangeCurrentUKStatus());
        } else {
            dispatch(setLoading(true));
            dispatch(fetchArticlesList(page, limit));
        }
    }, [page, limit, changeCurrentUK]);

    useEffect(() => {
        if (articleSaved) {
            dispatch(articleSavedSuccess(false));
            dispatch(fetchArticlesList(page, limit));
        }
    }, [articleSaved, page, limit]);

    const handleSendRequest = (article) => {
        const newArticle = {
            ...article,
            uk_name: currentUK.uk_name
        };
        setEditorOpen(false);
        dispatch(setLoading(true));
        dispatch(createArticle(newArticle));
    };

    const handleSaveMenu = (menu) => {
        const newMenu = {
            ...menu,
            attribute: currentUK.uk_name,
            service: 'zkhu',
            scenario: 'default',
            icon: '',
            is_external: false,
            is_hidden: false,
            parent_id: null,
        };
        dispatch(createMenuItem(newMenu));
    };

    const handleChange = (event, value) => {
        setPage(value);
    };

    const createMarkup = (value) => ({ __html: value });
    return (
        <>
            <PageHeader
                title={<IntlMessages id="label.user.article" />}
                buttons={[
                    isAdmin && (
                        <ActionButton
                            title="appModule.createArticle"
                            onClick={() => setEditorOpen(true)}
                            className="text-secondary"
                            icon={<AddCircleOutlineIcon />}
                        />
                    )
                ]}
            />
            {loading && <LinearProgress className="mb-1" />}
            <Grid container spacing={3}>
                { isEditorOpen
                        && (
                            <Grid item xs={12}>
                                <EditorForm
                                    onClickCreateMenu={handleSaveMenu}
                                    handleSendRequest={handleSendRequest}
                                    handleClose={() => setEditorOpen(false)}
                                    type="article"
                                />
                            </Grid>
                        )
                }
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="stretch"
                        spacing={3}
                    >

                        {list.data.map((item) => (
                            <Grid
                                key={item.id || Math.random()}
                                item
                                xs={12}
                                className="position-relative"
                            >
                                <ArticleItem
                                    listMode
                                    item={item}
                                    history={history}
                                    itemStatus={statuses.find((el) => el.value === item.status) || {}}
                                    text={(
                                        <ChopLines
                                            lines={3}
                                            lineHeight={20}
                                        >
                                            <div className="mt-0 text-sm" dangerouslySetInnerHTML={createMarkup(item.text)} />
                                        </ChopLines>
                                    )}
                                />
                            </Grid>
                        )
                        )}
                        <Grid item sm={12}>
                            <Pagination count={list.meta?.last_page} page={page} onChange={handleChange} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Articles;
