import React, { useState } from 'react';
import { FormGroup } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ModalDialog from 'components/ModalDialog';
import ActionConfirmation from 'components/ActionConfirmation';
import ActionButton from 'components/ActionButton';

const DeletedItem = ({
    onDelete, tooltipTitle, confirmationText, children
}) => {
    const [isDialogOpen, setDialogOpen] = useState(false);

    return (
        <>
            <FormGroup className="align-items-center" row>
                <div>{children}</div>
                <ActionButton
                    onClick={() => setDialogOpen(true)}
                    title={tooltipTitle}
                    className="text-info"
                    icon={<DeleteIcon />}
                />
            </FormGroup>
            <ModalDialog isOpen={isDialogOpen}>
                <ActionConfirmation
                    onConfirm={onDelete}
                    onCancel={() => setDialogOpen(false)}
                    methodText={confirmationText}
                />
            </ModalDialog>
        </>
    );
};

export default DeletedItem;
