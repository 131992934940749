import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Button, LinearProgress
} from '@material-ui/core';
import {
    withStyles
} from '@material-ui/core/styles';
import { showMessage } from 'actions/Messages';
import IntlMessages from 'util/IntlMessages';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import uploadFile from 'util/fileUploader';
import { useDropzone } from 'react-dropzone';

const UploadButton = withStyles({
    root: {
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px'
    }
})(Button);

// 2 мега

const FileUploader = ({ onFileUpload }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const onDrop = useCallback((acceptedFiles) => {
        // console.log('acceptedFiles', acceptedFiles);
        // if (acceptedFiles[0].size < (2 * 1024 * 1024)) {
        setLoading(true);
        uploadFile(acceptedFiles[0])
            .then((url) => onFileUpload(url, acceptedFiles[0].name, acceptedFiles[0].type))
            .catch((error) => {
                throw error;
            })
            .finally(() => setLoading(false));
        // } else {
        //     dispatch(showMessage('error', 'appModule.fileMaxSize2Mb', null, true));
        // }
    }, [onFileUpload, dispatch]);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div {...getRootProps()}>
            {loading && <LinearProgress />}
            <input {...getInputProps()} />
            <UploadButton
                color="secondary"
                startIcon={<AttachFileIcon />}
                size="small"
                className={`file-uploaded ${isDragActive ? 'drop' : ''}`}
            >
                Перетащите или выберите файл
                {/*<IntlMessages id="appModule.attachFile" />*/}
            </UploadButton>
        </div>
    );
};

export default FileUploader;
