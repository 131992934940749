// import { config } from '../../config';
import { API_ZHKU_URLS } from "../../../constants/Config";

const apiNotificationsUrl = () => API_ZHKU_URLS;

const apiUrls = {
    fetchList: () => `${apiNotificationsUrl()}/notification`,

    setRead: (id) => `${apiNotificationsUrl()}/notification/${id}`,

    setReadAll: () => `${apiNotificationsUrl()}/notification/all`,

    fetchCountActive: () => `${apiNotificationsUrl()}/notification/count`,

    loadFreeSpaceServer: () =>  `${apiNotificationsUrl()}/server/free-space`
};

export default apiUrls;
