import React, { useState, useEffect } from 'react';
import {
    FormGroup, CardActions, LinearProgress, TextField, Grid, Button,
} from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import Pagination from '@material-ui/lab/Pagination/Pagination';
import {
    fetchHousesList, statusesLoaded, searchHouses, statusesSearchLoaded, clearSearchList
} from 'actions/Company';
import { useDispatch, useSelector } from 'react-redux';
import List from './List';


const PaginationList = ({
    selected = [], closeModal, onAddItem
}) => {
    const timeout = 1200;
    let timeId = null;

    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const [loader, setLoader] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [isSearchList, setIsSearchList] = useState(false);

    const {
        list, searchList, listLoaded, searchListLoaded
    } = useSelector(({ company }) => company);

    useEffect(() => {
        setLoader(true);
        if (isSearchList) {
            dispatch(searchHouses(searchText, page, limit));
            return;
        }
        dispatch(fetchHousesList(page, limit));
        dispatch(clearSearchList());
    }, [isSearchList, page, limit]);

    useEffect(() => {
        if (listLoaded || searchListLoaded) {
            setLoader(false);
            listLoaded && dispatch(statusesLoaded());
            searchListLoaded && dispatch(statusesSearchLoaded());
        }
    }, [listLoaded, searchListLoaded]);

    useEffect(() => {
        if (searchList.data?.length){
            dispatch(clearSearchList());
        }
    }, []);

    const handleChangePage = (event, index) => {
        setLoader(true);
        setPage(index);
    };

    const handleChangeSearch = (e) => {
        const { value } = e.target;
        if (timeId) {
            clearTimeout(timeId);
            timeId = null;
        }
        setSearchText(value);

        if (value.length > 2) {
            timeId = setTimeout(() => {
                timeId = null;
                setPage(1);
                if (isSearchList) {
                    setLoader(true);
                    dispatch(searchHouses(value, page, limit));
                }
                setIsSearchList(true);
            }, timeout);
            return;
        }

        if (isSearchList) {
            timeId = setTimeout(() => {
                timeId = null;
                setPage(1);
                setIsSearchList(false);
            }, timeout);
        }
    };

    const dataList = isSearchList ? searchList : list;

    return (
        <>
            <FormGroup className="mb-2">
                <TextField
                    size="small"
                    id="searchId"
                    type="text"
                    name="searchId"
                    className="mb-3"
                    label={<IntlMessages id="votes.addressSearchTitle" />}
                    value={searchText}
                    onChange={handleChangeSearch}
                    error={false}
                    variant="outlined"
                />
            </FormGroup>

            {loader && <LinearProgress className="mb-3"/>}

            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                className="mb-3"
            >
                <List
                    selected={selected}
                    list={dataList}
                    onAddItem={onAddItem}
                    loader={loader}
                />
            </Grid>

            {dataList.meta?.last_page > 1 && (
                <CardActions className="mb-3">
                    <Pagination count={dataList.meta?.last_page} page={page} onChange={handleChangePage} />
                </CardActions>
            )}

            <FormGroup row className="justify-content-end mt-3 mb-3">
                <Button onClick={closeModal} variant="contained" className="mr-3">
                    <IntlMessages id="appModule.close" />
                </Button>
            </FormGroup>

        </>
    );
};

export default PaginationList;
