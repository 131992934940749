import {
    VOTES_LIST_LOADED_SUCCESS,
    VOTES_LIST_LOADED_SUCCESS_STATUS,
    SAVE_CURRENT_VOTE,
    SET_VOTE_ERROR,
    VOTES_STATUSES_LOADED,
    VOTES_RELATIONS_LOADED,
    VOTE_SUCCESSFULLY_SAVED,
    CLEAR_VOTE_LOADED_ID
} from 'constants/ActionTypes';

const INIT_STATE = {
    list: {
        data: []
    },
    listLoaded: false,
    loadedId: false,
    error: false,
    statuses: [],
    voteSaved: false,
    voteSavedAction: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
    case VOTES_LIST_LOADED_SUCCESS: {
        return {
            ...state,
            list: action.payload
        };
    }
    case VOTES_LIST_LOADED_SUCCESS_STATUS: {
        return {
            ...state,
            listLoaded: action.payload
        };
    }
    case SAVE_CURRENT_VOTE: {
        const currentIndex = state.list.data.findIndex((el) => el.id === action.payload.id);
        if (currentIndex < 0) {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: [
                        ...state.list.data,
                        action.payload
                    ]
                },
                loadedId: true
            };
        }
        return {
            ...state,
            list: {
                ...state.list,
                data: [
                    ...state.list.data.slice(0, currentIndex),
                    action.payload,
                    ...state.list.data.slice(currentIndex + 1)
                ]
            },
            loadedId: true
        };
    }
    case VOTES_STATUSES_LOADED: {
        return {
            ...state,
            statuses: action.payload
        };
    }
    case SET_VOTE_ERROR: {
        return {
            ...state,
            error: action.payload
        };
    }
    case VOTE_SUCCESSFULLY_SAVED: {
        return {
            ...state,
            voteSaved: action.payload.bool,
            voteSavedAction: action.payload.action
        };
    }
    case CLEAR_VOTE_LOADED_ID: {
        return {
            ...state,
            loadedId: false
        };
    }
    default:
        return state;
    }
};
