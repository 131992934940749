import cn from "classnames";
import React from "react";

const tagBackgroundWithUK = ({
    className = '',
    uk = {}
}) => {
    return (
        <>
            {uk && (
                <div className={cn('jr-tag', `custom-${uk.value} tagBG`)}>
                    {uk.name}
                </div>
            )}
        </>
    );
};

export default tagBackgroundWithUK;
