import {
    FETCH_APPEAL_LIST,
    APPEAL_LIST_LOADED_SUCCESS,
    LOAD_APPEAL_WITH_ID,
    SAVE_CURRENT_APPEAL,
    SET_APPEAL_ERROR,
    CREATE_APPEAL,
    EDIT_APPEAL,
    APPEAL_SUCCESSFULLY_SAVED,
    FETCH_APPEAL_STATUSES,
    APPEAL_STATUSES_LOADED,
    FETCH_APPEAL_TYPES,
    APPEAL_TYPES_LOADED,
    CREATE_MESSAGE,
    GET_MESSAGE_LIST,
    MESSAGE_LIST_LOADED,
    NEW_MESSAGE,
    APPEAL_WITH_ID_LOADED,
    SET_APPEAL_LOADING,
    SET_CURRENT_APPEAL_LOADING,
    WATCH_THE_APPEAL,
    STOP_WATCH_THE_APPEAL,
    APPEAL_SET_WS_DATA,
    NEW_WS_APPEAL
} from 'constants/ActionTypes';

export const setLoading = (bool) => ({
    type: SET_APPEAL_LOADING,
    payload: bool
});

export const setCurrentAppealLoading = (bool) => ({
    type: SET_CURRENT_APPEAL_LOADING,
    payload: bool
});

export const fetchAppealList = (page, limit, data) => ({
    type: FETCH_APPEAL_LIST,
    payload: {
        page, limit, data
    }
});

export const fetchAppealWithId = (id, notification) => ({
    type: LOAD_APPEAL_WITH_ID,
    payload: { id, notification }
});

export const appealLoaded = (appeal) => ({
    type: APPEAL_LIST_LOADED_SUCCESS,
    payload: appeal
});

export const appealWithIdLoaded = (appeal) => ({
    type: SAVE_CURRENT_APPEAL,
    payload: appeal
});

export const saveAppealForNotification = (appeal) => ({
    type: APPEAL_WITH_ID_LOADED,
    payload: appeal
});

export const setError = (bool) => ({
    type: SET_APPEAL_ERROR,
    payload: bool
});

export const fetchStatuses = () => ({
    type: FETCH_APPEAL_STATUSES
});

export const statusesLoaded = (statuses) => ({
    type: APPEAL_STATUSES_LOADED,
    payload: statuses
});

export const fetchTypes = () => ({
    type: FETCH_APPEAL_TYPES
});

export const typesLoaded = (types) => ({
    type: APPEAL_TYPES_LOADED,
    payload: types
});

export const createAppeal = (data) => ({
    type: CREATE_APPEAL,
    payload: { data }
});

export const editAppeal = (id, data) => ({
    type: EDIT_APPEAL,
    payload: { id, data }
});

export const watchTheAppeal = (id) => ({
    type: WATCH_THE_APPEAL,
    payload: { id }
});

export const stopWatchTheAppeal = (id) => ({
    type: STOP_WATCH_THE_APPEAL,
    payload: { id }
});

export const appealSavedSuccess = (bool = false) => ({
    type: APPEAL_SUCCESSFULLY_SAVED,
    payload: bool
});

export const createMessage = (data) => ({
    type: CREATE_MESSAGE,
    payload: { data }
});

export const getMessageList = (page, limit, props) => ({
    type: GET_MESSAGE_LIST,
    payload: { page, limit, data: props }
});

export const messagesLoaded = (data) => ({
    type: MESSAGE_LIST_LOADED,
    payload: data
});

export const messageFetched = (data) => ({
    type: NEW_MESSAGE,
    payload: data
});

export const setWsData = (event) => ({
    type: APPEAL_SET_WS_DATA,
    payload: event
});

export const newWsAppeal = (event) => ({
    type: NEW_WS_APPEAL,
    payload: event
});

