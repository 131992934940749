import React  from 'react';
import InfoIcon from '@material-ui/icons/Info';
import MailIcon from '@material-ui/icons/Mail';
import CardBox from 'components/Card';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import IntlMessages from 'util/IntlMessages';
import ItemProp from './ItemProp';
import { useStyles } from './Accordion/List/style';

const FlatLItem = (props) => {
    const {
        data = {},
        title,
        boby,
        loading = false,
        onLoadUserInfo = () => {}
    } = props;
    const classes = useStyles();
    const [anchorOb, setAnchorOb] = React.useState({});

    const handlePopoverOpen = (event, keys) => {
        setAnchorOb({
            anchor: event.currentTarget,
            keys
        });
    };

    const handlePopoverClose = () => {
        setAnchorOb({});
    };

    const lastTitleKey = Object.keys(title).reverse()[0];

    const open = Boolean(anchorOb?.anchor);

    return (
        <>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper
                }}
                open={open}
                anchorEl={anchorOb?.anchor || null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <div className="ml-1 mr-1 mb-1 mt-1">
                    {anchorOb?.keys && anchorOb.keys.map((keyProp) => (
                        <Typography key={keyProp}>
                            <IntlMessages id={`debtors.label.${keyProp}`} />
                            {': '}
                            {data[keyProp]}
                        </Typography>
                    ))}
                </div>
            </Popover>

            <>
            {/*<Grid item xs={12} md={6} className="mb-3">*/}
                <CardBox
                    classes={classes.fullWidth}
                    loading={loading}
                    className="bg-primary"
                    title={(
                        <div className="d-flex">
                            {Object.keys(title).map((key) => {
                                if (title[key] && title[key].length) {
                                    return (
                                        <span className="d-flex" key={key}>
                                            <Typography
                                                className="mr-2"
                                                style={{ fontWeight: 700, cursor: 'pointer' }}
                                                aria-haspopup="true"
                                                onMouseEnter={(event) => { handlePopoverOpen(event, title[key]); }}
                                                onMouseLeave={() => { handlePopoverClose(); }}
                                            >
                                                <IntlMessages id={`debtors.label.${key}`} />
                                                {`: ${key === 'flat' ? '№' : ''}`}
                                                {data[key]}
                                                <InfoIcon fontSize="small" className="ml-1" />
                                                {key !== lastTitleKey && ','}
                                            </Typography>
                                        </span>
                                    );
                                }
                                return (
                                    <span className="d-flex" key={key}>
                                        <Typography className="mr-2" style={{ fontWeight: 700 }}>
                                            <IntlMessages id={`debtors.label.${key}`} />
                                            {`: ${key === 'flat' ? '№' : ''}`}
                                            {data[key]}
                                            {key !== lastTitleKey && ', '}
                                        </Typography>
                                    </span>
                                );
                            })}
                        </div>
                    )}
                    icon={<MailIcon />}
                    handleActionsClick={() => {
                        const { house, flat, account_uk_identifier, end_debit } = data;
                        onLoadUserInfo({ house, flat, account_uk_identifier, end_debit });
                    }}
                >
                    {boby.map((bodyItem) => (
                        <ItemProp
                            className="row mb-1"
                            key={bodyItem.title}
                            title={<IntlMessages id={`debtors.label.${bodyItem.title}`} />}
                            negativeValue={data[bodyItem.negative]}
                            positiveValue={data[bodyItem.positive]}
                            modal={bodyItem.modal || false}
                            handlePopoverOpen={(event) => handlePopoverOpen(event, bodyItem.modal)}
                            handlePopoverClose={handlePopoverClose}
                        />
                    ))}
                </CardBox>
            {/*</Grid>*/}
            </>
        </>
    );
};

export default FlatLItem;
