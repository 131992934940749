import React from 'react';
import { List } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import IntlMessages from '../../util/IntlMessages';

const NavMenuItem = ({ name, icon, link }) => {
    const children = (
        <>
            {!!icon && (
                <span>
                    <i className="iconify menu-icon" data-icon={`${icon}`} />
                </span>
            )}
            <span className="nav-text">
                {name.title
                    ? <IntlMessages id={name.title} />
                    : name}
            </span>
        </>
    );
    return (
        <List component="div" className="nav-menu-item">
            {/^https?:\/\//.test(link)
                ? (
                    <a href={link} className="prepend-icon nav-menu-link">
                        {children}
                    </a>
                )
                : (
                    <NavLink
                        className="prepend-icon nav-menu-link"
                        exact={link.indexOf('news') < 0 && link.indexOf('article') < 0 && link.indexOf('votes') < 0}
                        to={link}
                    >
                        {children}
                    </NavLink>
                )}
        </List>
    );
};

export default NavMenuItem;
