import React, {useEffect} from 'react';
import queryString from 'query-string';
import {useDispatch, useSelector} from 'react-redux';
import {
    fetchOrdertList,
    ordertListFetched
} from '../../../actions/Market';
import {
    Button,
    LinearProgress,
    Typography
} from '@material-ui/core';

const PaymentSuccessful = ({ history, match, location }) => {
    //todo нужно получать заказ по его номеру и выводить нужные данные

    const params = queryString.parse(location.search);
    console.log('params', params);
    const dispatch = useDispatch();
    // const { orders, loadingOrders } = useSelector(({ market }) => market);

    useEffect(() => {
        // todo когда будет апи - передать на бэк сберовский orderId для оплаченного заказа
        // dispatch(fetchOrdertList({
        //     id: params.orderId
        // }));
        dispatch(fetchOrdertList());
    }, []);

    // const currentOrder = orders;

    return (
        <>
            <Typography variant="h5" className="mb-3">
                Ваш заказ успешно оплачен
            </Typography>

            <div>
                <Button
                    variant="outlined"
                    size="medium"
                    color="primary"
                    onClick={() => {
                        history.push('/marketplace/orders');
                    }}
                    className="mb-3"
                >
                    Перейти к списку заказов
                </Button>
            </div>

            <div>
                <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={() => {
                        history.push('/marketplace');
                    }}
                >
                    Вернуться в маркетплейс
                </Button>
            </div>

            {/*{loadingOrders ? <LinearProgress /> : (*/}
            {/*    <>*/}
            {/*        */}
            {/*    </>*/}
            {/*)}*/}
            {/*{props.match?.params?.orderId}*/}
        </>
    );
};

export default PaymentSuccessful;
