import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    FormGroup, Button, LinearProgress
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
    searchHousesIds, statusesSearchLoaded, clearSearchList, setError
} from 'actions/Company';
import IntlMessages from 'util/IntlMessages';
import ModalDialog from 'components/ModalDialog';
import PaginationList from './PaginationList';
import SelectedList from './SelectedList';

const HouseSelect = (props) => {
    const {
        relationIds = [],
        saveRelationIds,
        requered = false
    } = props;

    const dispatch = useDispatch();
    const [selected, setSelected] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [loader, setLoader] = useState(false);

    const {
        error, searchList, searchListLoaded
    } = useSelector(({ company }) => company);

    useEffect(() => {
        if (error) {
            dispatch(setError(false));
        }
    }, [error]);

    useEffect(() => {
        if (searchListLoaded && !selected.length) {
            if (!error && searchList.data) {
                setSelected(searchList.data?.map((item) => { return { id: item.external_id, address: item.address } }));
            }
            dispatch(statusesSearchLoaded());
            setLoader(false);
        }
    }, [searchListLoaded]);

    useEffect(() => {
        if (relationIds.length && !selected.length) {
            setLoader(true);
            dispatch(searchHousesIds(relationIds));
        }
    }, [relationIds]);

    const handleShowDialog = () => {
        setIsDialogOpen(true);
    };

    const handleDeleteItem = (id) => {
        const currentIndex = selected.findIndex((el) => el.id === id);
        const newSelected = [
            ...selected.slice(0, currentIndex),
            ...selected.slice(currentIndex + 1)
        ];
        setSelected(newSelected);
        saveRelationIds(newSelected.map((item) => item.id));
    };

    const handleAddItem = (newItem) => {
        const newSelected = [
            ...selected,
            newItem
        ];
        setSelected(newSelected);
        saveRelationIds(newSelected.map((item) => item.id));
    };

    return (
        <FormGroup
            className="mb-2"
        >

            {loader && <LinearProgress className="mb-3"/>}

            <SelectedList
                items={selected}
                onDeleteItem={handleDeleteItem}
                errorText={requered && !relationIds.length}
            />

            <FormGroup row className="justify-content-start mt-2 mb-3">
                <Button
                    size="small"
                    onClick={handleShowDialog}
                    disabled={false}
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                >
                    <IntlMessages id="appModule.add" />
                </Button>
            </FormGroup>

            <ModalDialog isOpen={isDialogOpen} title="votes.addAddress" fullWidth={true}>
                <PaginationList
                    selected={selected}
                    closeModal={() => setIsDialogOpen(false)}
                    onAddItem={handleAddItem}
                />
            </ModalDialog>

        </FormGroup>
    );
};

export default HouseSelect;
