import React, { useEffect, useState } from 'react';
import {Button, CircularProgress} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductList } from 'actions/Market';
import ProductWrapper from './ProductWrapper';
import ProductPageItem from './ProductPageItem';
import PageHeader from '../../../components/PageHeader';
import ListIcon from '@material-ui/icons/List';
import CartMini from '../../../components/CartMini';

const ProductPage = ({ match, history }) => {
    const dispatch = useDispatch();
    const productId = parseInt(match?.params?.id);
    const { basket, products = [], loadingProduct } = useSelector(({ market }) => market);
    const [item, setItem] = useState({});
    const productInBasket = basket?.items?.find((el) => el.product_id === item.id);

    useEffect(() => {
        if (productId && Object.keys(item).length === 0) {
            // найти в списке products или запросить 1шт. по ид
            const currentItem = products.find((el) => el.id === productId);
            if (currentItem) {
                setItem(currentItem);
            } else {
                dispatch(fetchProductList({id: productId}))
            }
        }
    }, [productId, products, item, dispatch]);

    return (
        <>
            <PageHeader
                title="Маркетплейс"
                buttons={[

                    <Button size="small" color="primary" onClick={() => {
                        history.push('/marketplace/orders');
                    }}>
                        <ListIcon fontSize="small" className="mr-1"/>
                        список заказов
                    </Button>,

                    <CartMini
                        color="#bc9131"
                        onCreateOrder={() => {
                            history.push('/marketplace/create-order');
                        }}
                    />
                ]}
            />

            {loadingProduct && <CircularProgress />}
            <ProductWrapper>
                <ProductPageItem
                        item={item}
                        inBasket={!!productInBasket}
                        productInBasket={productInBasket}
                />
            </ProductWrapper>
        </>
    );
};

export default ProductPage;