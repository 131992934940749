import {
    all, call, put, takeLatest, select
} from 'redux-saga/effects';

import {
    FETCH_COUNTERS,
    GET_COUNTER_INFO,
    SEND_COUNTER_DATA
} from 'constants/ActionTypes';
import {
    countersLoaded, setError, setLoading, counterLoading
} from 'actions/Meters';
import { showMessage } from 'actions/Messages';
import { getActionForError } from 'util/errors';
import api from '../util/api/api.counters.methods';

export const stateSelector = (state) => state.auth;

function* fetchUsersCountersSaga({ payload }) {
    yield put(setLoading(true));
    const { authUser } = yield select(stateSelector);
    const { access_token } = authUser;
    try {
        const counter = yield call(api.getCounters, access_token);
        // console.log('counter', counter);
        yield put(countersLoaded(counter.data));
    } catch (error) {
        yield put(getActionForError(error));
        // yield put(setError(error.message));
    } finally {
        yield put(setLoading(false));
    }
}

function* getCounterWithIdSaga({ payload }) {
    const { id } = payload;
    yield put(counterLoading({[id]: true}));
    const { authUser } = yield select(stateSelector);
    const { access_token } = authUser;
    try {
        const counter = yield call(api.getCounterInfo, access_token, id);
        // console.log('counter', counter);
        // yield put(countersLoaded(counter.data));
    } catch (error) {
        yield put(getActionForError(error));
        // yield put(setError(error.message));
    } finally {
        yield put(counterLoading({}));
    }
}

function* sendCounterDataSaga({ payload }) {
    const { id, value } = payload;
    yield put(counterLoading({[id]: true}));
    const { authUser } = yield select(stateSelector);
    const { access_token } = authUser;
    try {
        const counter = yield call(api.sendCounterData, access_token, id, value);
        yield put(showMessage('success', 'label.meter.success', null, true));
    } catch (error) {
        yield put(getActionForError(error));
        // yield put(setError(error.message));
    } finally {
        yield put(counterLoading({}));
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(FETCH_COUNTERS, fetchUsersCountersSaga),
        takeLatest(GET_COUNTER_INFO, getCounterWithIdSaga),
        takeLatest(SEND_COUNTER_DATA, sendCounterDataSaga)
    ]);
}
