import React, { useState } from 'react';
import IntlMessages from 'util/IntlMessages';
import {
    IconButton, FormGroup, TextField
} from '@material-ui/core';
import Uploader from 'components/Uploader';
import DeletedItem from 'components/DeletedItem';
import AttachmentView from 'components/AttachmentView';

const ChatFooter = ({ onSendMessage }) => {
    const [message, changeMessage] = useState('');
    const [attaches, addAttach] = useState([]);

    const submitMessage = (msg) => {
        onSendMessage(msg || message, attaches);
        changeMessage('');
        addAttach([]);
    };

    const onKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            const { value } = event.target;
            submitMessage(value);
        }
    };

    const addAttachment = (url, name, type) => {
        addAttach((state) => (
            [
                ...state,
                { url, name, type }
            ]
        ));
    };

    const deleteAttachment = (index) => {
        addAttach((state) => ([
            ...state.slice(0, index),
            ...state.slice(index + 1)
        ]));
    };

    return (
        <div className="chat-main-footer todo-main-footer">
            <div className="d-flex flex-row align-items-center">
                <div className="flex-grow-1">
                    <FormGroup className="pr-3">
                        <TextField
                            label={<IntlMessages id="chat.sendMessage" /> || 'отправить сообщение'}
                            multiline
                            rowsMax={2}
                            name="description"
                            onKeyDown={onKeyDown}
                            onChange={(e) => changeMessage(e.target.value)}
                            value={message}
                            variant="outlined"
                            className="chat-textarea"
                        />
                    </FormGroup>
                </div>
                <div className="chat-sent">
                    <IconButton
                        onClick={() => submitMessage()}
                        aria-label={<IntlMessages id="chat.sendMessage" />}
                    >
                        <i className="zmdi  zmdi-mail-send" />
                    </IconButton>
                </div>
            </div>
            <div className="d-flex flex-column align-items-center mt-2">
                <div className="d-flex flex-row align-items-center mb-2">
                    {attaches.map((attachment, index) => (
                        <DeletedItem
                            key={attachment.url}
                            onDelete={() => deleteAttachment(index)}
                            tooltipTitle="appModule.deleteAttachment"
                            confirmationText="appModule.confirmDeleteAttachment"
                        >
                            <AttachmentView isPreview attachment={attachment} />
                        </DeletedItem>
                    ))}
                </div>
                <Uploader onFileUpload={addAttachment} />
            </div>
        </div>
    );
};

export default ChatFooter;
