import {
    INDEBTEDNESS_SAVE_LIST,
    INDEBTEDNESS_GET_LIST,
    INDEBTEDNESS_SET_ERROR
} from 'constants/ActionTypes';

export const fetchList = (page = 1, limit = 10) => ({
    type: INDEBTEDNESS_GET_LIST,
    payload: {
        page,
        limit
    }
});

export const listLoaded = (list) => ({
    type: INDEBTEDNESS_SAVE_LIST,
    payload: list
});

export const setError = (message) => ({
    type: INDEBTEDNESS_SET_ERROR,
    payload: message
});
