import React from 'react';
import {
    Card, CardHeader, CardActions, CardContent
} from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';

const AccordeonCard = ({ title = [], actions = null, children, className = 'bg-transparent' }) => {
    //col-md-6 col-12
    const header = title.map(((el) =>
        <span key={el} style={document.body.clientWidth > 600 ? { width: `${100 / title.length}%` } : {}}>
            <IntlMessages id={`debtors.label.${el}`} />
        </span>
    ));
    return (
        <Card classes={{ root: className }} elevation={0}>
            {(title.length > 0) && (
                <CardHeader
                    title={header}
                    classes={{ title: 'd-flex justify-content-between jr-fs-lg ml-1' }}
                    style={{ marginRight: '7rem' }}
                />
            )}
            <CardContent classes={{ root: 'p-0' }}>
                {children}
            </CardContent>
            {actions && (
                <CardActions classes={{ root: 'justify-content-center' }}>
                    {actions}
                </CardActions>
            )}
        </Card>
    );
};

export default AccordeonCard;
