import React, { useEffect, useState } from 'react';
import { Typography, Button, Breadcrumbs } from '@material-ui/core';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import ProductCounter from './ProductCounter';
import { NavLink } from 'react-router-dom';
import ProductWrapper from './ProductWrapper';

const useStyles = makeStyles({
    wrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        backgroundColor: '#fff',
        padding: '1rem',
        boxShadow: `0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)`,
        borderRadius: '4px',
    },
    buttons: {
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center'
    },
    imgBlock: {
        textAlign: 'center',
        flex: 1
    },
    img: {
        maxHeight: '350px'
    },
    content: {
        flex: 3,
        padding: '1rem 0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    breadcrumb: {
        marginBottom: '1rem'
    }
});

const ProductPageItem = ({
    item = {},
    inBasket = false,
    onAddToBasket = () => {},
    onChangeCount = () => {},
    productInBasket = {}
}) => {
    const classes = useStyles();

    console.log('item', item);
    const image = item.images?.length > 0
        ? item.images[0]
        : null // плейсхолдер

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
                <NavLink to={`/marketplace`}>
                    {'<< В маркетплейс'}
                </NavLink>
            </Breadcrumbs>
            <div className={cn(classes.wrap)}>
                <div className={classes.imgBlock}>
                    <img alt={item.name} src={image} title={item.name} className={classes.img} />
                </div>
                <div className={classes.content}>
                    <Typography gutterBottom variant="h3" component="h1">
                        {item.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {item.description}
                    </Typography>
                    <Typography gutterBottom variant="h4" component="h2">
                        {item.cost}{' ₽'}
                    </Typography>
                    <div className={classes.buttons}>
                    {inBasket
                        ? (<>
                            <Typography variant="h5" component="h3" color="primary">
                                В корзине:
                            </Typography>
                            <ProductCounter
                                size="medium"
                                count={productInBasket.count}
                                onChange={(count) => onChangeCount(productInBasket.id, productInBasket.product_id, count)}
                            />
                            </>
                        )
                        : (<Button size="medium" variant="contained" color="primary" onClick={() => onAddToBasket(item)}>
                            В корзину
                        </Button>)
                    }
                    </div>
                </div>
            </div>
            </>
    );
};

export default ProductPageItem;