import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import {
    LinearProgress, IconButton, Grid, Button
} from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import ModalDialog from 'components/ModalDialog';
import ActionConfirmation from 'components/ActionConfirmation';
import DateTimeInfo from 'components/DateTimeInfo';
import CardMenu from 'components/Card/CardDropDownMenu';
import titleBg from 'components/Helpers/HeaderBackgroundWithStatus';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CardBox from 'components/Card';
import CardAvatarWithStatus from 'components/Card/CardAvatarWithStatus';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Hidden from '@material-ui/core/Hidden';
import EditorForm from './Editor/EditorForm';
import QuetionList from './QuetionList';
import TagBgUK from 'components/Helpers/TagBackgroundWithUK';


const useStyles = makeStyles((theme) => ({
    expand: {
        transform: 'rotate(0deg)',
        // marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: 'rotate(180deg)'
    },
    expandDesctop: {
        transform: 'rotate(-90deg)'
    }
}));

const ListItem = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isDialogOpen, setDialogVisible] = useState(false);
    const [currentMethod, setMethod] = useState();
    const {
        data,
        statuses,
        isAdmin,
        actions,
        saved,
        showQuestions,
        isActive = false,
        mobile: {
            loadingData,
            visible,
            showCurrent,
            currentData,
            saveLoader,
            handleClose,
            sendVoteResults,
            handleSendRequest
        }
    } = props;
    const {
        description, title, start_at, expire_at, id, status, questions = [], is_passed, uk
    } = data;
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const actionsOpened = Boolean(anchorEl);

    const handleActionsClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleActionsClose = () => {
        setAnchorEl(null);
    };

    const handleExpandClick = () => {
        showQuestions(id);
    };

    const handleButtonClick = (methodName) => {
        setMethod(methodName);
        handleActionsClose();
        if (methodName === 'edit') {
            //actions[methodName].func(id);
            actions[methodName].func(id);
        } else {
            setLoading(true);
            setDialogVisible(true);
        }
    };

    useEffect(() => {
        if (saved || questions.length > 0) {
            setLoading(false);
        }
    }, [saved, questions]);

    const handleConfirmClick = () => {
        dispatch(actions[currentMethod].func(id, actions[currentMethod].status));
        setMethod();
        setDialogVisible(false);
    };

    const handleCancelClick = () => {
        setMethod();
        setLoading(false);
        setDialogVisible(false);
    };

    const itemStatus = statuses.find((el) => el.value === status) || {};
    const statusesForHeader = {
        1: 'wait', 2: 'started', 3: 'finished', 4: 'deleted'
    };

    const statusName = statusesForHeader[status];

    const showQuestionsTitle = (isAdmin || is_passed) ? (<IntlMessages id="votes.viewResults" />) : (<IntlMessages id="votes.takePart" />);

    return (
        <>
            {/* confirmation delete etc. */}
            <ModalDialog isOpen={isDialogOpen}>
                <ActionConfirmation
                    onConfirm={handleConfirmClick}
                    onCancel={handleCancelClick}
                    methodText={actions[currentMethod]?.confirmText}
                />
            </ModalDialog>

            <Grid
                item
                className="mb-3"
            >
                <CardBox
                    loading={loading}
                    // возможно выводить другие статусы?
                    avatar={CardAvatarWithStatus(statusName, itemStatus)}
                    icon={isAdmin && (<MoreVertIcon />)}
                    title={title}
                    subheader={(
                        <>
                            <DateTimeInfo title="appModule.start" time={start_at} />
                            {' - '}
                            <DateTimeInfo title="appModule.finish" time={expire_at} />
                        </>
                    )}
                    className={`${isActive ? 'bg-primary' : titleBg(statusName)} card-header_with_status`}
                    handleActionsClick={handleActionsClick}
                    actionsClass="tagUK justify-content-between"
                    actions={(
                        <>
                            <TagBgUK uk={uk}/>

                            <Button
                                size="small"
                                //variant="outlined"
                                color="secondary"
                                className="mb-2 mt-2"
                                onClick={handleExpandClick}
                                endIcon={(
                                    <>
                                        <Hidden mdUp>
                                            <ExpandMoreIcon />
                                        </Hidden>
                                        <Hidden smDown>
                                            <ExpandMoreIcon className={cn(classes.expandDesctop)} />
                                        </Hidden>
                                    </>
                                )}
                            >
                                {showQuestionsTitle}
                            </Button>
                            {/*<IconButton*/}
                            {/*    className={cn(classes.expand, {*/}
                            {/*        [classes.expandOpen]: expanded*/}
                            {/*    })}*/}
                            {/*    onClick={handleExpandClick}*/}
                            {/*    aria-expanded={expanded}*/}
                            {/*    aria-label="show more"*/}
                            {/*>*/}
                            {/*    <Hidden mdUp>*/}
                            {/*        <ExpandMoreIcon />*/}
                            {/*    </Hidden>*/}
                            {/*    <Hidden smDown>*/}
                            {/*        <ExpandMoreIcon className={cn(classes.expandDesctop)} />*/}
                            {/*    </Hidden>*/}
                            {/*</IconButton>*/}
                        </>
                    )}
                >
                    <div>
                        {description}
                    </div>
                </CardBox>

                <CardMenu
                    menuState={actionsOpened}
                    anchorEl={anchorEl}
                    handleRequestClose={handleActionsClose}
                    handleButtonClick={handleButtonClick}
                    actions={actions}
                    status={status}
                    isAdmin={isAdmin}
                />
            </Grid>

            {/* mobile form */}
            <Hidden mdUp>
                {showCurrent.id === data.id && (
                    <Grid item xs={12} className="mb-3 position-relative vote-right-container">
                        {loadingData && <LinearProgress className="mb-1" />}
                        {visible && showCurrent.component === 'form' && (
                            <EditorForm
                                data={currentData}
                                loading={saveLoader}
                                handleSendRequest={handleSendRequest}
                                handleClose={handleClose}
                            />
                        )}
                        {visible && showCurrent.component === 'questions' && (
                            <QuetionList
                                data={currentData}
                                loading={saveLoader}
                                onSendQuestions={sendVoteResults}
                                handleClose={handleClose}
                                isAdmin={isAdmin}
                            />
                        )}
                    </Grid>
                )}
            </Hidden>
        </>
    );
};

export default ListItem;
