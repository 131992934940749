import React, { Component, useEffect, useState } from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {
    addressListLoaded,
    fetchAddressList
} from '../../actions/DaData';
import {
    TextField,
    LinearProgress, Typography, Grid, Button
} from '@material-ui/core';
import useDebounce from "../Helpers/useDebounce";
import usePrevious from "../Helpers/usePrevious";

const DaDataList = (props) => {
    const { onChange = () => {} } = props;
    const dispatch = useDispatch();
    const { list, loading } = useSelector(({ dadata }) => dadata);

    const loadingPrev = usePrevious(loading);

    const [city, setCity] = useState('');
    const [text, setText] = useState('');
    const [error, setError] = useState(false);
    const [showList, setShowList] = useState(false);

    const fetchListAway = useDebounce(() => {
        dispatch(fetchAddressList(`${city} ${text}`));
    }, 800);

    useEffect(() => {
        return () => {
            dispatch(addressListLoaded());
        };
    }, []);

    useEffect(() => {
        if (city.length > 3 && text.length > 3) {
            fetchListAway();
            setShowList(true);
        } else {
            fetchListAway(null, false);
            dispatch(addressListLoaded());
            setShowList(false);
        }
    }, [city, text]);

    useEffect(() => {
        if (
            loading === false &&
            loadingPrev === true
        ) {
            setError(isListError());
        }
    }, [loading]);

    const handleAccept = (item) => {
        if (Object.keys(item).length > 0) {
            onChange(item);
        }
    };

    const isListError = () => {
        return list.length === 0
            || (
                list.length === 1
                && !list[0].city_name
                && !list[0].street
            );
    };

    return (
        <div style={{ overflow: 'visible' }}>
            <div>
                <TextField
                    label="Введите город"
                    value={city}
                    name="city"
                    onChange={({target: { value }}) => setCity(value)}
                    fullWidth={true}
                    variant="outlined"
                    size="small"
                    className="mb-3"
                />

                <TextField
                    label="Введите улицу, номер дома"
                    value={text}
                    name="city"
                    onChange={({target: { value }}) => setText(value)}
                    fullWidth={true}
                    variant="outlined"
                    size="small"
                    className="mb-4"
                />

                {loading && (<LinearProgress className="mb-3" />)}
            </div>

            {error && (
                <Typography variant="subtitle1" color="error">
                    Ввведенный адрес не найден.
                </Typography>
            )}

            {(list.length > 0 && isListError() === false) && (
                <div>
                    <Typography variant="subtitle1" className="mb-2">
                        <strong>
                            Выберите адрес из списка
                        </strong>
                    </Typography>

                    <div className="">
                        {list.map((listItem) => (
                            <div
                                key={listItem.fias + listItem.kladr_id}
                                onClick={() => handleAccept(listItem)}
                                className="mb-1 pointer"
                            >
                                <Button
                                    size="small"
                                >
                                    {[
                                        listItem.city_name,
                                        listItem.settlement,
                                        listItem.street,
                                        listItem.house
                                    ]
                                        .reduce((res, item) => item ? [...res, item] : res, [])
                                        .join(', ') || ''
                                    }
                                </Button>


                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default DaDataList;