import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextField, LinearProgress, FormGroup } from '@material-ui/core';
// import { NotificationManager } from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import {
    setValidationFields
} from 'actions/Auth';
import {
    Card, CardHeader, CardFooter, CardBody, CardTitle
} from 'reactstrap';
import AuthHeader from './AuthHeader';

const ForgotPassword = (props) => {
    const {
        logo,
        title,
        onClickReset
    } = props;

    const [username, setEmail] = useState('');
    const dispatch = useDispatch();
    const {
        loader, validator
    } = useSelector(({ auth }) => auth);

    useEffect(() => {
        dispatch(setValidationFields({})); // reset validator at first page load
    }, []);

    const keyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            onClickReset(event.target.value);
        }
    };

    return (
        <Card>
            <CardHeader>
                <AuthHeader logo={logo} title={title} />
            </CardHeader>
            <CardBody>
                {loader && <LinearProgress />}
                <CardTitle>
                    <IntlMessages id="appModule.forgotPassword" />
                </CardTitle>
                <FormGroup>
                    <TextField
                        type="email"
                        required
                        label={<IntlMessages id="appModule.email" />}
                        fullWidth
                        margin="dense"
                        className="mt-0 mb-4"
                        variant="outlined"
                        onChange={(event) => setEmail(event.target.value)}
                        onKeyDown={keyDown}
                        defaultValue={username}
                        error={!!validator.username}
                        helperText={validator.username}
                    />
                </FormGroup>

                <Button
                    variant="contained"
                    color="primary"
                    className="text-white"
                    onClick={() => onClickReset(username)}
                    fullWidth
                >
                    <IntlMessages id="appModule.resetPassword" />
                </Button>

            </CardBody>
            <CardFooter className="d-flex justify-content-center align-items-center">
                <Link to="/signin">
                    <IntlMessages id="appModule.cancel" />
                </Link>
            </CardFooter>
        </Card>
    );
};

export default withRouter(ForgotPassword);
