import React, { useState, useEffect } from 'react';
import {
    TextField, FormGroup
} from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import ActionButton from 'components/ActionButton';

const MenuEditor = ({
    data = {},
    handleSendRequest,
    handleClose
}) => {
    const [menuItem, setMenuItem] = useState({
        label: '', link: ''
    });

    const onSendClick = () => {
        const newMenu = {
            ...data,
            ...menuItem
        };

        handleSendRequest(newMenu);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setMenuItem((state) => ({
            ...state,
            [name]: value
        }));
    };

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            setMenuItem({
                label: data.label,
                link: data.link
            });
        }
    }, [data]);

    return (
        <FormGroup className="d-flex flex-column flex-md-row justify-content-between align-items-stretch align-items-md-start mb-3">
            <TextField
                required
                label={<IntlMessages id="menu.name" />}
                type="text"
                name="label"
                value={menuItem.label}
                onChange={handleChange}
                error={!menuItem.label}
                helperText={!menuItem.label && <IntlMessages id="appModule.mandatory.field" />}
                variant="outlined"
                className="mb-3 mb-md-0 flex-md-grow-1 mr-md-4"
                size="small"
            />
            <TextField
                required
                label={<IntlMessages id="menu.link" />}
                type="text"
                name="link"
                value={menuItem.link}
                onChange={handleChange}
                error={!menuItem.link}
                helperText={!menuItem.link && <IntlMessages id="appModule.mandatory.field" />}
                variant="outlined"
                className="mb-3 mb-md-0 flex-md-grow-1 mr-md-4"
                size="small"
            />
            <div>
                <ActionButton
                    title="appModule.cancel"
                    onClick={handleClose}
                    className="text-info"
                    icon={<CloseIcon />}
                />
                <ActionButton
                    title="appModule.save"
                    onClick={onSendClick}
                    className={(!menuItem.label || !menuItem.link) ? 'text-muted' : 'text-secondary'}
                    disabled={!menuItem.label || !menuItem.link}
                    icon={<DoneIcon />}
                />
            </div>
        </FormGroup>
    );
};

export default MenuEditor;
