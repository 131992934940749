import React from 'react';
import { Badge, Link } from '@material-ui/core';
import ChatIcon from '@material-ui/icons/Chat';
import moment from 'moment';

const NotificationItem = ({ notification, onClick }) => {
    // todo
    const {
        data: {
            title,
            text,
            // description,
            // typeText,
            event_text
        },
        viewed,
        created_at,
    } = notification;

    return (
        <li className="d-flex justify-content-between align-items-center pointer" onClick={() => {
            onClick(notification);
        }}>
            <div>
                <Link
                    href="#"
                    // onClick={() => {
                    //     onClick(notification);
                    // }}
                    component="div"
                    variant="body2"
                >
                    <p className="mb-0">{event_text || title || text}</p>
                </Link>
                <p className="sub-heading" style={{ marginTop: '.2rem' }}>{moment(created_at).format('DD.MM.YYYY HH:mm:ss')}</p>
                {/*<Badge badgeContent={10} color="secondary">*/}
                {/*    {' '}*/}
                {/*</Badge>*/}
            </div>

            {!viewed && (
                <i className="is-read"></i>
            )}

        </li>
    );
};

export default NotificationItem;
