import React from 'react';
import IntlMessages from 'util/IntlMessages';
import { Grid } from '@material-ui/core';
import FlatItem from './FlatItem';
// import { List, AutoSizer } from 'react-virtualized';
import { VirtuosoGrid } from 'util/react-virtuoso';
import styled from '@emotion/styled';

const ItemContainer = styled.div`
  width: 50%;
  display: flex;
  flex: none;
  align-content: stretch;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 30px;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const ItemWrapper = styled.div`
    flex: 1;
    text-align: center;
    /*font-size: 80%;*/
    /*padding: 2rem;*/
    box-shadow: 0 5px 6px -6px #777;
    background: white;
    margin-bottom: 2rem;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;


const FlatList = ({ list = [], selectDataModal = false, ...props }) => {
    const title = {
        flat: [],
        account_uk_identifier: ['square', 'count_people']
    };
    const body = [
        {
            title: 'input_saldo',
            negative: 'start_debit',
            positive: 'start_credit'
        },
        {
            title: 'accrual',
            negative: 'accrual',
            modal: ['accrual_amount', 'withdrawals', 'accruals_minus_benefits', 'lost_revenue']
        },
        /*{
            title: 'accrual_amount',
            negative: 'accrual_amount',
            modal: ['accrual', 'withdrawals', 'accruals_minus_benefits', 'lost_revenue']
        },*/
        {
            title: 'amount_payments',
            positive: 'amount_payments'
        },
        {
            title: 'output_saldo',
            negative: 'end_debit',
            positive: 'end_credit'
        }
    ];

    if (!Object.keys(list)) {
        return (
            <Grid item>
                <div className="h4">
                    <IntlMessages id="appModule.nothingFound" />
                </div>
            </Grid>
        );
    }

    const rowRenderer = (index) => {
        const item = list[index];
        return (
            <FlatItem
                key={`${item.account_uk_identifier}${item.period}`}
                data={item}
                title={title}
                boby={body}
                loading={selectDataModal === item.account_uk_identifier}
                {...props}
            />
        );
    };

    return (
        <VirtuosoGrid
            totalCount={list.length}
            overscan={200}
            ItemContainer={ItemContainer}
            ListContainer={ListContainer}
            item={rowRenderer}
            style={{ height: '600px', width: '100%' }}
        />
    );
};

export default FlatList;
