import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import {
    CardActions,
    FormGroup,
    Grid,
    InputAdornment,
    TextField,
    LinearProgress
} from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import SearchIcon from '@material-ui/icons/Search';
import Pagination from '@material-ui/lab/Pagination/Pagination';
import { useSelector } from 'react-redux';
import cyrillicToTranslit from 'cyrillic-to-translit-js';
import FindItem from './FindItem';

const FindField = (props) => {
    const {
        onChangeField = () => {},
        onSelectedItem = () => {},
        name = '',
        label = '',
        loading = false
    } = props;

    const {
        addressList
    } = useSelector(({ uk }) => uk);

    const [currentValue, setCurrentValue] = React.useState('');
    const [fieldValue, setFieldValue] = React.useState('');
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [isStart, setIsStart] = React.useState(true);

    const handleSelect = (data, dataItem) => {
        onSelectedItem(data, dataItem);
    };

    const handleTextChange = (event) => {
        const { name, value } = event.target;
        setFieldValue(value);

        if (value.length > 2 || parseInt(value) > 0) {
            setCurrentValue(value);
        } else if (currentValue.length > 0) {
            setCurrentValue('');
        }
    };

    const onKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();

            setCurrentValue(fieldValue);
        }
    };

    const handleChangePage = (event, index) => {
        setIsStart(true);
        setPage(index);
    };

    useEffect(() => {
        setIsStart(true);
        setPage(1);
        setCurrentValue('');
        setFieldValue('');
    }, [name]);

    useEffect(() => {
        onChangeField({
            page,
            limit,
            value: currentValue
        }, isStart);

        setIsStart(false);
    }, [page, limit, currentValue]);

    const list = addressList;

    return (
        <>
            <FormGroup>
                <TextField
                    type="text"
                    name={name}
                    className="mb-3"
                    label={label}
                    defaultValue={fieldValue}
                    onChange={handleTextChange}
                    onKeyDown={onKeyDown}
                    //error={!value}
                    //helperText={!vote.title && <IntlMessages id="appModule.mandatory.field" />}
                    variant="outlined"
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon fontSize="small" />
                            </InputAdornment>
                        )
                    }}
                />
            </FormGroup>

            {loading && <LinearProgress className="mb-3" />}

            {loading === false && (
                <>
                    {list.data?.length > 0 ? (
                        <div className="list">

                            <Grid container className="position-relative mb-1">
                                {list.data?.map((item, index) => {
                                    const itemValue = item[name];
                                    const key = cyrillicToTranslit().transform(itemValue, '_');
                                    if (!!itemValue) {
                                        return (
                                            <FindItem
                                                key={`${key}${index}`}
                                                value={itemValue}
                                                onClick={() => handleSelect({
                                                    name,
                                                    value: itemValue
                                                }, item)}
                                            />

                                        )
                                    }})}
                            </Grid>

                            {list.meta?.last_page > 1 && (
                                <CardActions className="mb-3">
                                    <Pagination count={list.meta?.last_page} page={page} onChange={handleChangePage} />
                                </CardActions>
                            )}

                        </div>
                    ) : (
                        <Typography>
                            <IntlMessages id="appModule.nothingFound" />
                        </Typography>
                    )}
                </>
            )}
        </>
    );
};

export default FindField;
