import {
    all, call, select, put, takeLatest
} from 'redux-saga/effects';

import {
    FETCH_VOTES_LIST,
    LOAD_VOTE_WITH_ID,
    EDIT_VOTE,
    CREATE_VOTE,
    DELETE_VOTE,
    FETCH_VOTES_STATUSES,
    FETCH_VOTES_RELATIONS,
    CHANGE_VOTE_STATUS,
    SAVE_VOTE_RESULT
} from 'constants/ActionTypes';
import {
    votesListLoaded, voteLoaded, setError, voteSavedSuccess, statusesLoaded, relationsLoaded, votesListLoadedStatus
} from 'actions/Votes';
import { showMessage } from 'actions/Messages';
import { getActionForError } from 'util/errors';
import api from 'util/api/api.votes.methods';

export const stateAuthSelector = (state) => state.auth;

function* loadVotesListSaga({ payload }) {
    const { page, limit } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.getVotesList, page, limit, authUser?.access_token);
        yield put(votesListLoaded(response));
        yield put(votesListLoadedStatus(true));
    } catch (error) {
        yield put(votesListLoadedStatus(true));
        yield put(getActionForError(error));
    }
}

function* loadVotesStatusesSaga() {
    const { authUser } = yield select(stateAuthSelector);
    try {
        const statuses = yield call(api.getVotesStatuses, authUser?.access_token);
        yield put(statusesLoaded(statuses.data));
    } catch (error) {
        yield put(getActionForError(error));
    }
}

function* loadVotesRelationsSaga() {
    const { authUser } = yield select(stateAuthSelector);
    try {
        const relations = yield call(api.getVotesRelations, authUser?.access_token);
        yield put(relationsLoaded(relations.data));
    } catch (error) {
        yield put(getActionForError(error));
    }
}

function* loadVoteWithIdSaga({ payload }) {
    const { id } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.getVoteWithId, id, authUser?.access_token);
        yield put(voteLoaded(response.data));
    } catch (error) {
        yield put(getActionForError(error));
        yield put(setError(true));
    }
}

function* createVoteSaga({ payload }) {
    const { data } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.createVote, data, authUser.access_token);
        yield put(showMessage('success', 'votes.successfullySaved', null, true));
        yield put(voteSavedSuccess(response.success));
    } catch (error) {
        yield put(voteSavedSuccess(false));
        yield put(getActionForError(error));
    }
}

function* editVoteSaga({ payload }) {
    const { id, data } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.editVoteWithId, id, data, authUser.access_token);
        yield put(showMessage('success', 'votes.successfullySaved', null, true));
        yield put(voteSavedSuccess(response.success));
    } catch (error) {
        yield put(voteSavedSuccess(false));
        yield put(getActionForError(error));
    }
}

function* changeVoteStatusSaga({ payload }) {
    const { id, status } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.changeVoteStatus, id, status, authUser.access_token);
        yield put(showMessage('success', 'votes.successfullySaved', null, true));
        yield put(voteSavedSuccess(response.success));
    } catch (error) {
        yield put(voteSavedSuccess(false));
        yield put(getActionForError(error));
    }
}


function* saveVoteResultSaga({ payload }) {
    const { vote_id, results } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.saveVoteResult, vote_id, results, authUser.access_token);
        yield put(showMessage('success', 'votes.successfullySaved', null, true));
        yield put(voteSavedSuccess(response.success));
    } catch (error) {
        yield put(voteSavedSuccess(false));
        yield put(getActionForError(error));
    }
}

function* deleteVoteSaga({ payload }) {
    const { id }  = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.deleteVoteWithId, id, authUser.access_token);
        yield put(showMessage('success', 'votes.successfullyDeleted', null, true));
        yield put(voteSavedSuccess(response.success));
    } catch (error) {
        yield put(voteSavedSuccess(false));
        yield put(getActionForError(error));
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(FETCH_VOTES_LIST, loadVotesListSaga),
        takeLatest(LOAD_VOTE_WITH_ID, loadVoteWithIdSaga),
        takeLatest(FETCH_VOTES_STATUSES, loadVotesStatusesSaga),
        takeLatest(FETCH_VOTES_RELATIONS, loadVotesRelationsSaga),
        takeLatest(CREATE_VOTE, createVoteSaga),
        takeLatest(EDIT_VOTE, editVoteSaga),
        takeLatest(DELETE_VOTE, deleteVoteSaga),
        takeLatest(CHANGE_VOTE_STATUS, changeVoteStatusSaga),
        takeLatest(SAVE_VOTE_RESULT, saveVoteResultSaga)
    ]);
}
