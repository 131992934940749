import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setLoading, fetchArticleWithId, setError, articleSavedSuccess,
    editArticle, fetchStatuses
} from 'actions/Articles';
import {
    createMenuItem
} from 'actions/Menu';
import {
    LinearProgress
} from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import EditorForm from 'components/ArticleList/EditorForm';
import useStatuses from 'components/Helpers/useStatuses';
import PageHeader from 'components/PageHeader';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import ActionButton from 'components/ActionButton';
import ArticleItem from '../ArticleItem';

const CurrentArticle = ({ match, history }) => {
    const dispatch = useDispatch();
    const statuses = useStatuses(fetchStatuses, 'articles');
    const {
        error, currentArticle, articleSaved, loading
    } = useSelector(({ articles }) => articles);
    const { changeCurrentUK, currentUK } = useSelector(({ auth }) => auth);
    const id = parseInt(match?.params?.id);
    const isEditorMode = match?.params?.mode === 'edit';

    useEffect(() => {
        if (changeCurrentUK) {
            history.push('/article');
        }
    }, [changeCurrentUK]);

    useEffect(() => {
        if (id && !error) {
            dispatch(setLoading(true));
            dispatch(fetchArticleWithId(id));
        }
    }, [id, error, dispatch]);

    const handleSendRequest = (el) => {
        dispatch(setLoading(true));
        dispatch(editArticle(id, el));
    };

    useEffect(() => {
        if (articleSaved) {
            dispatch(articleSavedSuccess(false));
            history.goBack();
            if (id) {
                dispatch(fetchArticleWithId(id));
            }
        }
    }, [articleSaved, id]);

    const createMarkup = (value) => ({ __html: value });

    const handleSaveMenu = (menu) => {
        const newMenu = {
            ...menu,
            attribute: currentUK.uk_name,
            service: 'zkhu',
            scenario: 'default',
            icon: '',
            is_external: false,
            is_hidden: false,
            parent_id: null,
        };
        dispatch(createMenuItem(newMenu));
    };

    return (
        <>
            {/* header */}
            <PageHeader
                title={<IntlMessages id="label.user.article" />}
                buttons={[
                    <ActionButton
                        title="appModule.goToList"
                        onClick={() => history.push('/article')}
                        className="text-secondary"
                        icon={<FormatListBulletedIcon />}
                    />
                ]}
            />
            {(loading || currentArticle.id !== id) && <LinearProgress className="mb-1" />}
            {currentArticle.id === id
                ? (isEditorMode && Object.keys(currentArticle).length > 0)
                    ? (
                        <EditorForm
                            data={currentArticle}
                            handleSendRequest={handleSendRequest}
                            handleClose={() => history.goBack()}
                            type="article"
                            onClickCreateMenu={handleSaveMenu}
                        />
                    )
                    : (
                        <ArticleItem
                            item={currentArticle}
                            history={history}
                            itemStatus={statuses.find((el) => el.value === currentArticle.status) || {}}
                            text={
                                <div className="mt-0 text-sm" dangerouslySetInnerHTML={createMarkup(currentArticle.text)} />
                            }
                        />
                    )
                : null}
        </>
    );
};

export default CurrentArticle;
