import React, {
    useRef, useState, useEffect, Children, isValidElement, cloneElement
} from 'react';
import {
    Card, CardContent, CardActions, CardHeader, IconButton, LinearProgress
} from '@material-ui/core';

const CardBox = ({
    classes = '',
    title,
    avatar,
    subheader,
    className,
    contentClassName,
    handleActionsClick,
    icon = null,
    actions,
    children,
    maxHeightContent = 0,
    needScroll = true,
    loading = false,
    actionsClass
}) => {
    const headerRef = useRef(null);
    const actionsRef = useRef(null);
    const [maxHeight, setMaxHeight] = useState(0);

    useEffect(() => {
        if (maxHeightContent) {
            const headerHeight = headerRef?.current?.getBoundingClientRect().height || 0;
            const actionsHeight = actionsRef?.current?.getBoundingClientRect().height || 0;
            const height = maxHeightContent - headerHeight - actionsHeight;
            setMaxHeight(height);
        }
    }, [maxHeightContent]);

    const childrenWithProps = Children.map(children, (child) => {
        if (isValidElement(child)) {
            return cloneElement(child, { maxHeight });
        }
        return child;
    });

    return (
        <Card
            elevation={3}
            classes={{ root: classes }}
        >
            <CardHeader
                ref={headerRef}
                avatar={avatar}
                title={title}
                subheader={subheader}
                titleTypographyProps={{ variant: 'body1' }}
                subheaderTypographyProps={{ color: 'inherit' }}
                classes={{ root: className }}
                action={icon && (
                    <IconButton onClick={handleActionsClick} classes={{ root: 'text-white' }}>
                        {icon}
                    </IconButton>
                )}
            />
            <CardContent classes={{ root: contentClassName }} style={maxHeight ? { maxHeight, overflowY: `${needScroll ? 'auto' : 'hidden'}` } : {}}>
                {needScroll ? children : childrenWithProps}
            </CardContent>
            {actions && (
                <CardActions ref={actionsRef} disableSpacing classes={{ root: actionsClass || 'justify-content-end' }}>
                    {actions}
                </CardActions>
            )}
            {loading && <LinearProgress />}
        </Card>
    );
};

export default CardBox;
