class ValidationError extends Error {
    constructor({ message, validationData = {} }) {
        super(message);
        this.className = 'ValidationError';
        const validationMessage = Object.keys(validationData).reduce((result, key) => {
            result[key] = validationData[key].map((msg) => msg).join(' ');
            return result;
        }, {});
        this.validationMessage = validationMessage;
        if (Error.captureStackTrace instanceof Function) {
            Error.captureStackTrace(this, ValidationError);
        }
    }
}

export { ValidationError };
