import * as types from 'constants/ActionTypes';

export const fetchAccrualsList = (page = 1, limit = 10, date_at) => ({
    type: types.FETCH_ACCRUALS_LIST,
    payload: {
        page, limit, date_at
    }
});

export const saveAccrualsList = (data) => ({
    type: types.SAVE_ACCRUALS_LIST,
    payload: data
});

export const fetchInvoiceForPeriod = (period, format) => ({
    type: types.GET_INVOICE_FOR_PERIOD,
    payload: { period, format }
});

export const invoiceForPeriodLoaded = (data) => ({
    type: types.INVOICE_FOR_PERIOD_LOAD_SUCCESS,
    payload: data
});

export const fetchPaymentList = (page, limit, filter) => ({
    type: types.GET_PAYMENTS_LIST,
    payload: {
        page,
        limit,
        filter
    }
});

export const paymentListLoaded = (data) => ({
    type: types.PAYMENTS_LIST_LOAD_SUCCESS,
    payload: data
});

export const fetchPaymentStatuses = () => ({
    type: types.GET_PAYMENTS_STATUSES,
});

export const paymentStatusesLoaded = (data) => ({
    type: types.PAYMENTS_STATUSES_LOADED,
    payload: data
});

export const setError = (message) => ({
    type: types.SET_ACCRUALS_ERROR,
    payload: message
});
