import React from 'react';
import moment from 'moment';
import {
    Card, CardHeader, CardFooter, CardBody,
    CardTitle, CardSubtitle
} from 'reactstrap';
import {
    Chip
} from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';

const MeterCard = ({ counter, children }) => (
    <Card>
        <CardHeader className="bg-primary">
            {counter.counter_type_text || ''}
            {' '}
            {counter.model || ''}
            {' '}
            {counter.tariff || ''}
        </CardHeader>
        <CardBody>
            <CardTitle>
                <IntlMessages id="label.meter.device" />
                {' № '}
                {counter.factory_number}
            </CardTitle>
            {counter.last_value_at && counter.last_value ? (
                <CardSubtitle className="d-flex align-items-center">
                    <div>
                        <IntlMessages id="label.meter.last.readings.of" />
                        {' '}
                        {(moment(counter.last_value_at).locale('ru').format('LL'))}
                        {' '}
                    </div>
                    <Chip size="small" label={counter.last_value} classes={{ root: 'rounded ml-2' }} />
                </CardSubtitle>
            ) : null}
            {children}
        </CardBody>
        {/*<CardFooter className="d-flex flex-md-row flex-column align-items-center">*/}
        {/*    <span>*/}
        {/*        <IntlMessages id="label.meter.date.nextCalibration" />*/}
        {/*        {': '}*/}
        {/*    </span>*/}
        {/*    <span>{(moment(counter.ipu_date).locale('ru').format('LL'))}</span>*/}
        {/*</CardFooter>*/}
    </Card>

);

export default MeterCard;
