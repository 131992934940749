import {
    RESET_PASSWORD,
    INIT_URL,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SIGNIN_FACEBOOK_USER_SUCCESS,
    SIGNIN_GITHUB_USER_SUCCESS,
    SIGNIN_GOOGLE_USER_SUCCESS,
    SIGNIN_TWITTER_USER_SUCCESS,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    SIGNUP_USER_SUCCESS,
    SAVE_REFRESH_TOKEN_TIME,
    RESET_PASSWORD_SUCCESS,
    SET_VALIDATION_FIELDS,
    SYNC_USER_SUCCESS,
    CHANGE_PASSWORD_SUCCESS,
    SOCNET_LIST_LOAD_CLEAR,
    SOCNET_LIST_LOAD,
    WS_CONNECTED,
    SAVE_CURRENT_UK,
    UK_INFO_LOADED,
    UK_LIST_LOADED,
    SET_CHANGE_CURRENT_UK_STATUS,
    CHANGE_SYNC_USER_STATUS
} from 'constants/ActionTypes';
import cookie from 'react-cookies';
import { setUKHeaders } from 'util/api/axios.config';
import { isEqual } from 'lodash';
import jwtDecode from "jwt-decode";
import { setAuthToken } from 'util/api/axios.config';

let user = cookie.load('authUser') || null;
const currentUK = cookie.load('currentUK') || {
    uk_name: 'flagman',
    // account_number: 0
};

if (user) {
    setUKHeaders(currentUK);
    const { access_token } = user;
    setAuthToken(access_token);
    const decoded = jwtDecode(access_token);
    user = {
        ...user,
        info: {
            ...decoded,
            scopes: decoded.access?.zkhu?.find(({ uk }) => uk === currentUK?.uk_name)?.permissions || []
        }
    };
}
const adminTypes = ['zkh-superuser', 'zkh-admin'];
const superAdminTypes = ['zkh-superuser'];
const operatorTypes = ['personal_account'];

const testIfAdmin = (authUser, currentUK, types = adminTypes) => {
    const ukName = currentUK?.uk_name || 'flagman';
    return types.reduce((res, type) => {
        const permissionUk = authUser?.info?.access?.zkhu?.find(({ uk }) => uk === ukName)?.permissions || [];
        if (permissionUk.includes(type)) {
            res = true;
        }
        return res;
    }, false);
};

const testIfSuperAdmin = (authUser, currentUK) => testIfAdmin(authUser, currentUK, superAdminTypes);
const testIfOperator = (authUser, currentUK) => testIfAdmin(authUser, currentUK, operatorTypes);

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    passwordReseted: false,
    initURL: '',
    authUser: user,
    needRefreshTime: cookie.load('needRefresh') || 0,
    validator: {},
    isSignUp: false,
    passwordChanged: false,
    isAdmin: testIfAdmin(user, currentUK),
    isSuperAdmin: testIfSuperAdmin(user, currentUK),
    isOperator: testIfOperator(user, currentUK),
    wsConnected: false,
    socknetList: {
        data: {},
        loaded: false
    },
    currentUK,
    changeCurrentUK: false,
    ukInfo: {},
    ukList: [],
    syncUserStatus: false
};


export default (state = INIT_STATE, action) => {
    // console.log('REDUCERS', state);
    switch (action.type) {
    case SIGNUP_USER_SUCCESS: {
        return {
            ...state,
            loader: false,
            isSignUp: true,
            validator: {}
        };
    }
    case SET_VALIDATION_FIELDS: {
        return {
            ...state,
            loader: false,
            validator: action.payload.validation || {}
        };
    }
    case SIGNIN_USER_SUCCESS: {
        return {
            ...state,
            loader: false,
            authUser: action.payload,
            isAdmin: testIfAdmin(action.payload, state.currentUK),
            isSuperAdmin: testIfSuperAdmin(action.payload, state.currentUK),
            isOperator: testIfOperator(action.payload, state.currentUK),
            validator: {}
        };
    }
    case SAVE_CURRENT_UK: {
        const changeCurrentUK = !isEqual(state.currentUK, action.uk);
        return {
            ...state,
            currentUK: action.uk,
            changeCurrentUK,
            isAdmin: testIfAdmin(state.authUser, action.uk),
            isSuperAdmin: testIfSuperAdmin(state.authUser, action.uk),
            isOperator: testIfOperator(state.authUser, action.uk),
        };
    }
    case SET_CHANGE_CURRENT_UK_STATUS: {
        return {
            ...state,
            changeCurrentUK: action.bool
        };
    }
    case RESET_PASSWORD: {
        return {
            ...state,
            loader: true
        };
    }
    case RESET_PASSWORD_SUCCESS: {
        return {
            ...state,
            loader: false,
            passwordReseted: action.payload
        };
    }
    case CHANGE_PASSWORD_SUCCESS: {
        return {
            ...state,
            loader: false,
            passwordChanged: action.payload
        };
    }
    case SAVE_REFRESH_TOKEN_TIME: {
        return {
            ...state,
            loader: false,
            needRefreshTime: action.payload
        };
    }
    case INIT_URL: {
        return {
            ...state,
            initURL: action.payload
        };
    }
    case SIGNOUT_USER_SUCCESS: {
        return {
            ...state,
            authUser: null,
            loader: false,
            needRefreshTime: 0,
            isAdmin: false,
            isSuperAdmin: false,
            isOperator: false,
            /*currentUK: {
                uk_name: 'public',
                account_number: 0
            }*/
        };
    }

    case SIGNIN_GOOGLE_USER_SUCCESS: {
        return {
            ...state,
            loader: false,
            authUser: action.payload,
        };
    }
    case SIGNIN_FACEBOOK_USER_SUCCESS: {
        return {
            ...state,
            loader: false,
            authUser: action.payload
        };
    }
    case SIGNIN_TWITTER_USER_SUCCESS: {
        return {
            ...state,
            loader: false,
            authUser: action.payload
        };
    }
    case SIGNIN_GITHUB_USER_SUCCESS: {
        return {
            ...state,
            loader: false,
            authUser: action.payload
        };
    }
    case ON_SHOW_LOADER: {
        return {
            ...state,
            loader: true
        };
    }
    case ON_HIDE_LOADER: {
        return {
            ...state,
            loader: false
        };
    }
    case WS_CONNECTED: {
        return {
            ...state,
            wsConnected: true
        };
    }
    case SOCNET_LIST_LOAD: {
        return {
            ...state,
            socknetList: {
                data: action.payload,
                loaded: true
            }
        };
    }
    case SOCNET_LIST_LOAD_CLEAR: {
        return {
            ...state,
            socknetList: {
                ...state.socknetList,
                loaded: false
            }
        };
    }
    case UK_INFO_LOADED: {
        return {
            ...state,
            ukInfo: action.payload
        };
    }
    case UK_LIST_LOADED: {
        return {
            ...state,
            ukList: action.payload
        };
    }
    case CHANGE_SYNC_USER_STATUS: {
        return {
            ...state,
            syncUserStatus: action.payload
        };
    }
    default:
        return state;
    }
};
