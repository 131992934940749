import React from 'react';
import {
    Dialog, DialogTitle, DialogContent
} from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';

const ModalDialog = ({
    isOpen = false, handleClose = () => {}, children, title = '', titleText = null, fullWidth = false, headerClasses = ''
}) => (
    <Dialog open={isOpen} onClose={handleClose} fullWidth={fullWidth}>
        <DialogTitle className={headerClasses}>
            {titleText || <IntlMessages id={title} />}
        </DialogTitle>
        <DialogContent>
            {children}
        </DialogContent>
    </Dialog>
);

export default ModalDialog;
