import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import {
    checkOrderZkh,
    clearCheckOrderZkh,
} from '../../../actions/Order';
import {
    Button,
    LinearProgress,
    Typography
} from '@material-ui/core';

// оплатили задолженность
const PaymentSuccessfulZkh = ({ history, match, location }) => {
    const params = queryString.parse(location.search);
    const dispatch = useDispatch();

    const {
        loading,
        checked,
    } = useSelector(({ order }) => order);

    useEffect(() => {
        if (params?.orderId) {
            // проверяем заказ
            dispatch(checkOrderZkh(params?.orderId));

            return () => {
                // чистим при закрытии
                dispatch(clearCheckOrderZkh());
            };
        }
    }, []);

    return (
        <>
            {loading ? (
                <LinearProgress className="mb-3"/>
            ) : (
                <Typography variant="h5" className="mb-3">
                    {checked || 'Информация по оплате недоступна'}
                </Typography>
            )}

            <div>
                <Button
                    variant="outlined"
                    size="medium"
                    color="primary"
                    onClick={() => {
                        history.push('/indebtedness');
                    }}
                    className="mb-3"
                >
                    Перейти к списку задолженностей
                </Button>
            </div>
        </>
    );
};

export default PaymentSuccessfulZkh;
