import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import DebtList from 'components/DebtorsList';
import {
    fetchDebtorsList,
    debtorsLoadedStatusChange,
    fetchDebtorsCompanies,
    fetchDebtorsUserByUk,
    setDebtorsUserByUkLoadedStatus,
    debtorsLoaded
} from 'actions/Debtors';
import {
    setChangeCurrentUKStatus
} from 'actions/Auth';
import {
    LinearProgress, Grid, CardActions, Button
} from '@material-ui/core';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import moment from 'moment';
import Filter from 'components/DebtorsList/Filter';
import PersonalInfo from './PersonalInfo';
import {
    defaultSortDataLang,
    defaultSortSelect
} from 'constants/defaultLang';
import PageHeader from 'components/PageHeader';
import SortingComponent from 'components/Sorting';
import MenuItemWithPopover from 'components/MenuItemPopover';
import FilterListIcon from '@material-ui/icons/FilterList';
import SortIcon from '@material-ui/icons/Sort';

const currentData = new Date();

const Debtors = ({ match, history }) => {
    const initialState = {
        dataLoader: false,
        selectDataModal: false,
        userLoader: false,
        page: 1,
        limit: 10,
        visibleNextPage: true,
        filter: {
            from_period: moment(currentData).format('YYYY-MM'),
            to_period: moment(currentData).format('YYYY-MM')
        },
        sort: defaultSortSelect
    };
    const dispatch = useDispatch();
    const { changeCurrentUK } = useSelector(({ auth }) => auth);
    const [dataLoader, setDataLoader] = useState(initialState.dataLoader);
    const [selectDataModal, setSelectDataModal] = useState(initialState.selectDataModal);
    const [userLoader, setUserLoader] = useState(initialState.userLoader);
    const [page, setPage] = useState(initialState.page);
    const [limit, setLimit] = useState(initialState.limit);
    const [visibleNextPage, setVisibleNextPage] = useState(initialState.visibleNextPage);
    const [filter, setFilter] = useState(initialState.filter);
    const [sort, setSort] = useState(initialState.sort);

    const {
        list,
        listLoaded,
        companies,
        userLoaded
    } = useSelector(({ debtors }) => debtors);

    useEffect(() => {
        dispatch(fetchDebtorsCompanies());
    }, []);

    // data list loaded
    useEffect(() => {
        if (listLoaded) {
            if (!Object.keys(list.data).length) {
                setVisibleNextPage(false);
            }

            setDataLoader(false);
            dispatch(debtorsLoadedStatusChange());
        }
    }, [listLoaded]);

    useEffect(() => {
        if (changeCurrentUK) {
            setDataLoader(initialState.dataLoader);
            setSelectDataModal(initialState.selectDataModal);
            setUserLoader(initialState.userLoader);
            setPage(initialState.page);
            setLimit(initialState.limit);
            setVisibleNextPage(initialState.visibleNextPage);
            setFilter(initialState.filter);
            setSort(initialState.sort);
            dispatch(debtorsLoaded({ data: [] }));
            dispatch(setChangeCurrentUKStatus());
        } else {
            setDataLoader(true);
            dispatch(fetchDebtorsList(page, limit, { ...filter, ...sort }));
        }
    }, [page, limit, sort, filter, changeCurrentUK]);

    // finish user loaded
    useEffect(() => {
        if (selectDataModal && userLoaded) {
            setUserLoader(false);
            dispatch(setDebtorsUserByUkLoadedStatus());
        }
    }, [userLoaded]);


    const handleNextPage = () => {
        const currentPage = page;
        setPage(currentPage + 1);
    };

    const handlePrevPage = () => {
        const currentPage = page;
        if (currentPage > 1) {
            setVisibleNextPage(true);
            setPage(currentPage - 1);
        }
    };

    const handleChangeFilter = (newFilter) => {
        /*if (newFilter?.account_uk_identifier) {
            newFilter = {
                account_uk_identifier: newFilter.account_uk_identifier
            };
        }*/

        if (newFilter?.to_period) {
            newFilter = {
                ...newFilter,
                from_period: newFilter.to_period
            };
        }

        setPage(1);
        setFilter(newFilter);
    };

    const handleChangeSort = (order, direction) => {
        setSort({
            order,
            direction
        });
    };

    return (
        <>
            <PageHeader
                title={<IntlMessages id="debtors.payments" />}
                buttons={[
                    <MenuItemWithPopover loading={dataLoader} buttonTitle="appModule.filter" icon={<FilterListIcon />}>
                        {(handleClose) => <Filter
                            data={filter}
                            companies={companies}
                            onChangeFilter={handleChangeFilter}
                            handleClose={handleClose}
                        />}
                    </MenuItemWithPopover>,
                    <MenuItemWithPopover buttonTitle="appModule.sorting" icon={<SortIcon />}>
                        {(handleClose) => <SortingComponent
                            data={defaultSortDataLang}
                            onChangeSort={handleChangeSort}
                            selectedItem={sort.order}
                            direction={sort.direction}
                            handleClose={handleClose}
                        />}
                    </MenuItemWithPopover>
                ]}
            />

            <Grid container className="mb-3">
                <Grid
                    item
                    xs={12}
                    // md={6}
                >
                    <DebtList
                        list={list.data}
                        dataLoading={dataLoader}
                        selectDataModal={userLoader}
                        onLoadUserInfo={(dataItem) => {
                            const { account_uk_identifier } = dataItem;
                            setSelectDataModal(dataItem);
                            setUserLoader(account_uk_identifier);
                            dispatch(fetchDebtorsUserByUk(account_uk_identifier));
                        }}
                    />

                    {dataLoader && <LinearProgress />}

                    {(!filter?.account_uk_identifier && !dataLoader) && (
                        <CardActions classes={{ root: 'justify-content-center' }}>
                            {page > 1 && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className="btn"
                                    startIcon={<NavigateBefore />}
                                    onClick={handlePrevPage}
                                    disabled={dataLoader}
                                >
                                    <IntlMessages id="appModule.previousPage" />
                                </Button>
                            )}
                            {visibleNextPage && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className="btn"
                                    endIcon={<NavigateNext />}
                                    onClick={handleNextPage}
                                    disabled={dataLoader}
                                >
                                    <IntlMessages id="appModule.nextPage" />
                                </Button>
                            )}
                        </CardActions>
                    )}
                </Grid>
            </Grid>

            <PersonalInfo selectedData={selectDataModal} />
        </>
    );
};

export default Debtors;
