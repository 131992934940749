import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalDialog from 'components/ModalDialog';
import ActionConfirmation from 'components/ActionConfirmation';
import IntlMessages from 'util/IntlMessages';
import {
    Hidden, Button, LinearProgress
} from '@material-ui/core';
import {
    createMenuItem, deleteMenuItem, editMenuItem
} from 'actions/Menu';
import EditorForm from './EditorForm';
import SiteMenuItem from './MenuItem';
import MoveControls from './MoveControls';

const SiteMenuList = ({ menuList = [], currentScope }) => {
    const dispatch = useDispatch();
    const {
        loading, menuSaved
    } = useSelector(({ menu }) => menu);
    const [isEditorOpen, setEditorOpen] = useState(false);
    const [isDialogOpen, setDialogVisible] = useState(false);
    const [deletedId, setDeletedId] = useState(null);
    const { currentUK } = useSelector(({ auth }) => auth);

    const handleDelete = (id) => {
        setDeletedId(id);
        setDialogVisible(true);
    };

    const handleConfirmClick = () => {
        dispatch(deleteMenuItem(deletedId));
        setDeletedId(null);
        setDialogVisible(false);
    };

    const handleCancelClick = () => {
        setDeletedId(null);
        setDialogVisible(false);
    };

    const handleSendRequest = (menu) => {
        const newMenu = {
            ...menu,
            uk_name: currentUK.uk_name,
            service: 'zkhu',
            scenario: 'landing',
            icon: '',
            scopes: currentScope,
            is_external: false,
            is_hidden: false,
            parent_id: null,
            sorting: menuList.length
        };
        dispatch(createMenuItem(newMenu));
        setEditorOpen(false);
    };

    const addMenuItem = () => {
        setEditorOpen(true);
    };

    const changeOrder = (fromIndex, toIndex) => {
        const movedElement = {
            ...menuList[fromIndex],
            sorting: menuList[toIndex].sorting
        };
        const changedElement = {
            ...menuList[toIndex],
            sorting: menuList[fromIndex].sorting
        };
        dispatch(editMenuItem(movedElement.id, movedElement));
        dispatch(editMenuItem(changedElement.id, changedElement));
    };

    menuList.sort((a, b) => {
        if (a.sorting < b.sorting) {
            return -1;
        }
        if (a.sorting > b.sorting) {
            return 1;
        }
        return 0;
    });

    return (
        <div className="d-flex flex-column justify-content-start align-items-stretch">
            <ModalDialog isOpen={isDialogOpen}>
                <ActionConfirmation
                    onConfirm={handleConfirmClick}
                    onCancel={handleCancelClick}
                    methodText="menu.confirmDelete"
                />
            </ModalDialog>
            <Hidden smDown>
                {menuList.length > 0
                && (
                    <h3 className="d-flex justify-content-between align-items-center mb-3">
                        <span>
                            <IntlMessages id="menu.name" />
                        </span>
                        <span>
                            <IntlMessages id="menu.link" />
                        </span>
                        <span>
                            <IntlMessages id="menu.actions" />
                        </span>
                    </h3>
                )}
            </Hidden>
            {menuList.map((item, index) => (
                <SiteMenuItem
                    key={item.id}
                    id={item.id}
                    index={index}
                    item={item}
                    onHandleDelete={() => handleDelete(item.id)}
                    moveControls={(
                        <MoveControls
                            changeOrder={changeOrder}
                            index={index}
                            lastIndex={menuList.length - 1}
                        />
                    )}
                />
            ))}

            {loading && (deletedId || !menuSaved) && <LinearProgress />}

            {isEditorOpen
                ? (
                    <EditorForm
                        handleSendRequest={handleSendRequest}
                        handleClose={() => setEditorOpen(false)}
                    />
                )
                : (
                    <Button
                        onClick={addMenuItem}
                        color="primary"
                        className="align-self-start"
                    >
                        <IntlMessages id="appModule.add" />
                    </Button>
                )}
        </div>
    );
};

export default SiteMenuList;
