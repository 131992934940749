// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const CHANGE_DIRECTION = 'change-direction';
export const SET_MAIN_SCROLLBAR_REF = 'SET_MAIN_SCROLLBAR_REF';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

//Contact Module const

export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';

export const FETCH_ALL_CONTACT = 'fetch_all_contact';
export const FETCH_ALL_CONTACT_SUCCESS = 'fetch_all_contact_success';
export const ADD_FAVOURITE = 'add_favourite';
export const ON_CONTACT_SELECT = 'on_contact_select';
export const ON_ADD_CONTACT = 'on_add_contact';
export const ON_CONTACT_CLOSE = 'on_contact_close';
export const ON_FILTER_OPTION_SELECT = 'on_filter_option_select';
export const ON_SAVE_CONTACT = 'on_save_contact';
export const ON_DELETE_CONTACT = 'on_delete_contact';
export const ON_DELETE_SELECTED_CONTACT = 'on_delete_selected_contact';
export const FILTER_CONTACT = 'filter_contact';
export const GET_ALL_CONTACT = 'get_all_contact';
export const GET_UNSELECTED_ALL_CONTACT = 'get_unselected_all_contact';
export const ON_ALL_CONTACT_SELECT = 'on_all_contact_select';
export const UPDATE_SEARCH_USER = 'update_search_user';
export const ON_TOGGLE_DRAWER = 'on_toggle_drawer';
export const HANDLE_REQUEST_CLOSE = 'handle_request_close';
export const HIDE_CONTACT_LOADER = 'hide_contact_loader';

//Auth const
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
export const SIGNIN_GOOGLE_USER = 'signin_google_user';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER = 'signin_facebook_user';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER = 'signin_twitter_user';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER = 'signin_github_user';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const SAVE_REFRESH_TOKEN_TIME = 'SAVE_REFRESH_TOKEN_TIME';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const SAVE_USER = 'SAVE_USER';
export const SET_VALIDATION_FIELDS = 'SET_VALIDATION_FIELDS';
export const FETCH_SOCNET_LIST = 'FETCH_SOCNET_LIST';
export const SOCNET_LIST_LOAD = 'SOCNET_LIST_LOAD';
export const SOCNET_LIST_LOAD_CLEAR = 'SOCNET_LIST_LOAD_CLEAR';
export const SIGNIN_KRASINFORM = 'SIGNIN_KRASINFORM';
export const SAVE_CURRENT_UK = 'SAVE_CURRENT_UK';
export const FETCH_UK_LIST = 'FETCH_UK_LIST';
export const UK_LIST_LOADED = 'UK_LIST_LOADED';
export const SET_CHANGE_CURRENT_UK_STATUS = 'SET_CHANGE_CURRENT_UK_STATUS';
export const CHANGE_SYNC_USER_STATUS = 'CHANGE_SYNC_USER_STATUS';
export const SYNC_TOKEN = 'SYNC_TOKEN';

// Chat Module const
export const FETCH_ALL_CHAT_USER = 'fetch_all_chat_user';
export const FETCH_ALL_CHAT_USER_CONVERSATION = 'fetch_all_chat_user_conversation';
export const FETCH_ALL_CHAT_USER_SUCCESS = 'fetch_all_chat_user_success';
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS = 'fetch_all_chat_user_conversation_success';
export const FILTER_USERS = 'filter_users';
export const ON_SELECT_USER = 'on_select_user';
export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';
export const USER_INFO_STATE = 'user_info_state';
export const SUBMIT_COMMENT = 'submit_comment';
export const UPDATE_MESSAGE_VALUE = 'update_message_value';
export const UPDATE_SEARCH_CHAT_USER = 'update_search_chat_user';

//// Mail Module const
export const ADD_LABEL = 'ADD_LABEL';
export const GET_ALL_MAIL = 'get_all_mail';
export const FETCH_ALL_MAIL = 'fetch_all_mail';
export const FETCH_ALL_MAIL_SUCCESS = 'fetch_all_mail_success';
export const GET_IMPORTANT_MAIL = 'get_important_mail';
export const GET_NAV_FILTERS = 'get_nav_filters';
export const GET_NAV_LABELS = 'get_nav_labels';
export const GET_NAV_FOLDER = 'get_nav_folder';
export const GET_READ_MAIL = 'get_read_mail';
export const GET_STARRED_MAIL = 'get_starred_mail';
export const GET_UNIMPORTANT_MAIL = 'get_unimportant_mail';
export const GET_UNREAD_MAIL = 'get_unread_mail';
export const GET_UNSELECTED_ALL_MAIL = 'get_unselected_all_mail';
export const GET_UNSTARRED_MAIL = 'get_unstarred_mail';
export const ON_ALL_MAIL_SELECT = 'on_all_mail_select';
export const ON_FOLDER_MENU_ITEM_SELECT = 'on_folder_menu_item_select';
export const ON_FOLDER_SELECT = 'on_folder_select';
export const ON_IMPORTANT_SELECT = 'on_important_select';
export const ON_LABEL_MENU_ITEM_SELECT = 'on_label_menu_item_select';
export const GET_MARK_AS_START = 'get_mark_as_start';
export const ON_LABEL_SELECT = 'on_label_select';
export const ON_MAIL_CHECKED = 'on_mail_checked';
export const ON_MAIL_SELECT = 'on_mail_select';
export const ON_MAIL_SEND = 'on_mail_send';
export const ON_OPTION_MENU_ITEM_SELECT = 'on_option_menu_item_select';
export const ON_OPTION_MENU_SELECT = 'on_option_menu_select';
export const ON_START_SELECT = 'on_start_select';
export const SEARCH_MAIL = 'search_mail';
export const ON_DELETE_MAIL = 'on_delete_mail';
export const SET_CURRENT_MAIL_NULL = 'set_current_mail_null';
export const ON_COMPOSE_MAIL = 'on_compose_mail';

//// TO-DO Module const

export const ON_SORTEND = 'on_sortend';
export const FETCH_ALL_TODO = 'fetch_all_todo';
export const FETCH_ALL_TODO_SUCCESS = 'fetch_all_todo-success';
export const FETCH_ALL_TODO_CONVERSATION = 'fetch_all_todo_conversation';
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS = 'fetch_all_todo_conversation_success';
export const SELECT_ALL_TODO = 'select_all_todo';
export const GET_ALL_TODO = 'get_all_todo';
export const GET_UNSELECTED_ALL_TODO = 'get_unselected_all_todo';
export const GET_STARRED_TODO = 'get_starred_todo';
export const GET_UNSTARRED_TODO = 'get_unstarred_todo';
export const GET_IMPORTANT_TODO = 'get_important_todo';
export const GET_UNIMPORTANT_TODO = 'get_unimportant_todo';
export const ON_LABEL_UPDATE = 'on_label_update';
export const ON_TODO_UPDATE = 'on_todo_update';
export const ON_DELETE_TODO = 'on_delete_todo';
export const SEARCH_TODO = 'search_todo';
export const SHOW_TODOS = 'show_todos';
export const GET_TODO_CONVERSATION = 'get_todo_conversation';
export const ON_TODO_CHECKED = 'on_todo_checked';
export const ON_TODO_ADD = 'on_todo_add';
export const ON_TODO_SELECT = 'on_todo_select';
export const SET_CURRENT_TODO_NULL = 'set_current_todo_null';
export const REMOVE_LABEL = 'remove_label';
export const UPDATE_SEARCH = 'update_search';

//// Menues module
export const LOAD_MENU = 'LOAD_MENU';
export const MENU_LOAD_SUCCESS = 'MENU_LOAD_SUCCESS';
export const FETCH_LANDING_MENU = 'FETCH_LANDING_MENU';
export const LANDING_MENU_LOAD_SUCCESS = 'LANDING_MENU_LOAD_SUCCESS';
export const MENU_SAVED = 'MENU_SAVED';
export const CREATE_MENU_ITEM = 'CREATE_MENU_ITEM';
export const EDIT_MENU_ITEM = 'EDIT_MENU_ITEM';
export const DELETE_MENU_ITEM = 'DELETE_MENU_ITEM';
export const FETCH_MENU_SCOPES = 'FETCH_MENU_SCOPES';
export const MENU_SCOPES_LOADED = 'MENU_SCOPES_LOADED';
export const FETCH_MENU_SCENARIOS = 'FETCH_MENU_SCENARIOS';
export const MENU_SCENARIOS_LOADED = 'MENU_SCENARIOS_LOADED';
export const MENU_LOADING = 'MENU_LOADING';

//// User
export const FETCH_USER_DATA = 'FETCH_USER_DATA';
export const SAVE_USER_DATA = 'SAVE_USER_DATA';
export const SYNC_USER = 'SYNC_USER';
export const SYNC_USER_SUCCESS = 'SYNC_USER_SUCCESS';
export const SYNC_USER_ERROR = 'SYNC_USER_ERROR';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';

//// Counters
export const FETCH_COUNTERS = 'FETCH_COUNTERS';
export const GET_COUNTER_INFO = 'GET_COUNTER_INFO';
export const COUNTERS_LOAD_SUCCESS = 'COUNTERS_LOAD_SUCCESS';
export const SET_COUNTERS_ERROR = 'SET_COUNTERS_ERROR';
export const SEND_COUNTER_DATA = 'SEND_COUNTER_DATA';
export const COUNTERS_LOADING = 'COUNTERS_LOADING';
export const COUNTER_LOADING = 'COUNTER_LOADING';

//// News
export const FETCH_NEWS_LIST = 'FETCH_NEWS_LIST';
export const LOAD_NEWS_WITH_ID = 'LOAD_NEWS_WITH_ID';
export const NEWS_LIST_LOADED_SUCCESS = 'NEWS_LIST_LOADED_SUCCESS';
export const SAVE_CURRENT_NEWS = 'SAVE_CURRENT_NEWS';
export const SET_NEWS_ERROR = 'SET_NEWS_ERROR';
export const CREATE_NEWS = 'CREATE_NEWS';
export const PUBLISH_NEWS = 'PUBLISH_NEWS';
export const EDIT_NEWS = 'EDIT_NEWS';
export const DELETE_NEWS = 'DELETE_NEWS';
export const NEWS_SUCCESSFULLY_SAVED = 'NEWS_SUCCESSFULLY_SAVED';
export const FETCH_NEWS_STATUSES = 'FETCH_NEWS_STATUSES';
export const NEWS_STATUSES_LOADED = 'NEWS_STATUSES_LOADED';
export const SET_NEWS_LOADING = 'SET_NEWS_LOADING';

//// Appeal
export const FETCH_APPEAL_LIST = 'FETCH_APPEAL_LIST';
export const APPEAL_LIST_LOADED_SUCCESS = 'APPEAL_LIST_LOADED_SUCCESS';
export const LOAD_APPEAL_WITH_ID = 'LOAD_APPEAL_WITH_ID';
export const APPEAL_WITH_ID_LOADED = 'APPEAL_WITH_ID_LOADED';
export const SAVE_CURRENT_APPEAL = 'SAVE_CURRENT_APPEAL';
export const SET_APPEAL_ERROR = 'SET_APPEAL_ERROR';
export const CREATE_APPEAL = 'CREATE_APPEAL';
export const CHANGE_APPEAL_STATUS = 'CHANGE_APPEAL_STATUS';
export const EDIT_APPEAL = 'EDIT_APPEAL';
export const APPEAL_SUCCESSFULLY_SAVED = 'APPEAL_SUCCESSFULLY_SAVED';
export const FETCH_APPEAL_STATUSES = 'FETCH_APPEAL_STATUSES';
export const APPEAL_STATUSES_LOADED = 'APPEAL_STATUSES_LOADED';
export const FETCH_APPEAL_TYPES = 'FETCH_APPEAL_TYPES';
export const APPEAL_TYPES_LOADED = 'APPEAL_TYPES_LOADED';
export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const GET_MESSAGE_LIST = 'GET_MESSAGE_LIST';
export const MESSAGE_LIST_LOADED = 'MESSAGE_LIST_LOADED';
export const NEW_MESSAGE = 'NEW_MESSAGE';
export const SET_APPEAL_LOADING = 'SET_APPEAL_LOADING';
export const SET_CURRENT_APPEAL_LOADING = 'SET_CURRENT_APPEAL_LOADING';
export const WATCH_THE_APPEAL = 'WATCH_THE_APPEAL';
export const STOP_WATCH_THE_APPEAL = 'STOP_WATCH_THE_APPEAL';

//// Articles
export const FETCH_ARTICLE_LIST = 'FETCH_ARTICLE_LIST';
export const LOAD_ARTICLE_WITH_ID = 'LOAD_ARTICLE_WITH_ID';
export const LOAD_ARTICLE_WITH_SLUG = 'LOAD_ARTICLE_WITH_SLUG';
export const ARTICLE_LIST_LOADED_SUCCESS = 'ARTICLE_LIST_LOADED_SUCCESS';
export const SAVE_CURRENT_ARTICLE = 'SAVE_CURRENT_ARTICLE';
export const SET_ARTICLE_ERROR = 'SET_ARTICLE_ERROR';
export const CREATE_ARTICLE = 'CREATE_ARTICLE';
export const PUBLISH_ARTICLE = 'PUBLISH_ARTICLE';
export const EDIT_ARTICLE = 'EDIT_ARTICLE';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';
export const ARTICLE_SUCCESSFULLY_SAVED = 'ARTICLE_SUCCESSFULLY_SAVED';
export const FETCH_ARTICLES_STATUSES = 'FETCH_ARTICLES_STATUSES';
export const ARTICLES_STATUSES_LOADED = 'ARTICLES_STATUSES_LOADED';
export const SET_ARTICLE_LOADING = 'SET_ARTICLE_LOADING';

// Payments
export const SET_ACCRUALS_ERROR = 'SET_ACCRUALS_ERROR';
export const GET_INVOICE_FOR_PERIOD = 'GET_INVOICE_FOR_PERIOD';
export const INVOICE_FOR_PERIOD_LOAD_SUCCESS = 'INVOICE_FOR_PERIOD_LOAD_SUCCESS';
export const GET_PAYMENTS_LIST = 'GET_PAYMENTS_LIST';
export const PAYMENTS_LIST_LOAD_SUCCESS = 'PAYMENTS_LIST_LOAD_SUCCESS';
export const FETCH_ACCRUALS_LIST = 'FETCH_ACCRUALS_LIST';
export const SAVE_ACCRUALS_LIST = 'SAVE_ACCRUALS_LIST';
export const GET_PAYMENTS_STATUSES = 'GET_PAYMENTS_STATUSES';
export const PAYMENTS_STATUSES_LOADED = 'PAYMENTS_STATUSES_LOADED';

// Votes
export const FETCH_VOTES_LIST = 'FETCH_VOTES_LIST';
export const LOAD_VOTE_WITH_ID = 'LOAD_VOTE_WITH_ID';
export const VOTES_LIST_LOADED_SUCCESS = 'VOTES_LIST_LOADED_SUCCESS';
export const VOTES_LIST_LOADED_SUCCESS_STATUS = 'VOTES_LIST_LOADED_SUCCESS_STATUS';
export const SAVE_CURRENT_VOTE = 'SAVE_CURRENT_VOTE';
export const SET_VOTE_ERROR = 'SET_VOTE_ERROR';
export const CREATE_VOTE = 'CREATE_VOTE';
export const CHANGE_VOTE_STATUS = 'CHANGE_VOTE_STATUS';
export const EDIT_VOTE = 'EDIT_VOTE';
export const DELETE_VOTE = 'DELETE_VOTE';
export const VOTE_SUCCESSFULLY_SAVED = 'VOTE_SUCCESSFULLY_SAVED';
export const FETCH_VOTES_STATUSES = 'FETCH_VOTES_STATUSES';
export const VOTES_STATUSES_LOADED = 'VOTES_STATUSES_LOADED';
export const FETCH_VOTES_RELATIONS = 'FETCH_VOTES_RELATIONS';
export const VOTES_RELATIONS_LOADED = 'VOTES_RELATIONS_LOADED';
export const SAVE_VOTE_RESULT = 'SAVE_VOTE_RESULT';
export const CLEAR_VOTE_LOADED_ID = 'CLEAR_VOTE_LOADED_ID';

// Company data
export const LOAD_HOUSES = 'LOAD_HOUSES';
export const HOUSES_LOAD_SUCCESS = 'HOUSES_LOAD_SUCCESS';
export const SEARCH_HOUSES = 'SEARCH_HOUSES';
export const SEARCH_HOUSES_SUCCESS = 'SEARCH_HOUSES_SUCCESS';
export const SEARCH_HOUSES_IDS = 'SEARCH_HOUSES_IDS';
export const SET_HOUSES_ERROR = 'SET_HOUSES_ERROR';
export const HOUSES_STATUSES_LOADED = 'HOUSES_STATUSES_LOADED';
export const SEARCH_HOUSES_STATUSES_LOADED = 'SEARCH_HOUSES_STATUSES_LOADED';
export const SEARCH_HOUSES_CLEAR_LIST = 'SEARCH_HOUSES_CLEAR_LIST';
export const LOAD_UK_INFO = 'LOAD_UK_INFO';
export const UK_INFO_LOADED = 'UK_INFO_LOADED';

// Messages
export const NOTIFICATION_RECIEVED = 'NOTIFICATION_RECIEVED';
export const RESET_UNREAD_MESSAGES = 'RESET_UNREAD_MESSAGES';

// Websocket
export const WS_CONNECT = 'WS_CONNECT';
export const WS_CONNECTED = 'WS_CONNECTED';
export const WS_DISCONNECT = 'WS_DISCONNECT';
export const WS_DISCONNECTED = 'WS_DISCONNECTED';

// Debt
export const DEBTORS_LIST_LOADED = 'DEBT_LIST_LOADED';
export const DEBTORS_LIST_LOADED_SUCCESS = 'DEBT_LIST_LOADED_SUCCESS';
export const DEBTORS_COMPANIES_LOADED = 'DEBTORS_COMPANIES_LOADED';
export const DEBTORS_COMPANIES_LOADED_SUCCESS = 'DEBTORS_COMPANIES_LOADED_SUCCESS';
export const DEBTORS_LIST_LOADED_STATUS_CHANGE = 'DEBTORS_LIST_LOADED_STATUS_CHANGE';
export const SET_DEBTORS_ERROR = 'SET_DEBT_ERROR';
export const DEBTORS_FETCH_USER_BY_UK = 'DEBTORS_FETCH_USER_BY_UK';
export const DEBTORS_FETCH_USER_BY_UK_LOADED = 'DEBTORS_FETCH_USER_BY_UK_LOADED';
export const DEBTORS_SEND_MESSAGE_TELEGRAM_USER = 'DEBTORS_SEND_MESSAGE_TELEGRAM_USER';
export const DEBTORS_SEND_MESSAGE_EMAIL_USER = 'DEBTORS_SEND_MESSAGE_EMAIL_USER';
export const DEBTORS_SET_MESSAGES_STATUS = 'DEBTORS_SET_MESSAGES_STATUS';
export const DEBTORS_CLEAR_MESSAGES_STATUS = 'DEBTORS_CLEAR_MESSAGES_STATUS';
export const DEBTORS_FETCH_USER_BY_UK_LOADED_STARUS = 'DEBTORS_FETCH_USER_BY_UK_LOADED_STARUS';
export const DEBTORS_CLEAR_USER = 'DEBTORS_CLEAR_USER';

// Translate
export const TRANSLATE_FETCH_LIST = 'TRANSLATE_FETCH_LIST';
export const TRANSLATE_FETCH_WITH_ID = 'TRANSLATE_FETCH_WITH_ID';
export const TRANSLATE_LIST_LOADED = 'TRANSLATE_LIST_LOADED';
export const TRANSLATE_LIST_LOADED_STATUS = 'TRANSLATE_LIST_LOADED_STATUS';
export const TRANSLATE_SET_ERROR = 'TRANSLATE_SET_ERROR';
export const TRANSLATE_CREATE = 'TRANSLATE_CREATE';
export const TRANSLATE_EDIT = 'TRANSLATE_EDIT';
export const TRANSLATE_DELETE = 'TRANSLATE_DELETE';
export const TRANSLATE_SAVED = 'TRANSLATE_SAVED';
export const TRANSLATE_WHITH_ID_LOADED = 'TRANSLATE_WHITH_ID_LOADED';
export const TRANSLATE_FETCH_SERVICE_LIST = 'TRANSLATE_FETCH_SERVICE_LIST';
export const TRANSLATE_SERVICE_LIST_LOADED = 'TRANSLATE_SERVICE_LIST_LOADED';

// Uk
export const UK_FETCH_ADDRESS_LIST = 'UK_FETCH_ADDRESS_LIST';
export const UK_ADDRESS_LIST_LOADED = 'UK_ADDRESS_LIST_LOADED';
export const UK_ADDRESS_LIST_LOADED_STATUS = 'UK_ADDRESS_LIST_LOADED_STATUS';
export const UK_SET_ERROR = 'UK_SET_ERROR';
export const UK_FETCH_STREEST_LIST = 'UK_FETCH_STREEST_LIST';
export const UK_FETCH_CITIES_LIST = 'UK_FETCH_CITIES_LIST';

export const INDEBTEDNESS_GET_LIST = 'INDEBTEDNESS_GET_LIST';
export const INDEBTEDNESS_SAVE_LIST = 'INDEBTEDNESS_SAVE_LIST';
export const INDEBTEDNESS_SET_ERROR = 'INDEBTEDNESS_SET_ERROR';

export const APPEAL_SET_WS_DATA = 'APPEAL_SET_WS_DATA';
export const NEW_WS_APPEAL = 'NEW_WS_APPEAL';
