import {
    LOAD_USER_LIST,
    USER_LIST_LOADING,
    USER_LIST_LOADED,
    EDIT_USER,
    USER_LOADING,
    USER_SAVED_SUCCESS,
    LOAD_ROLES,
    LOADED_ROLES,
    LOAD_USER_ACCOUNT_INFO,
    USER_ACCOUNT_INFO_LOADED,
} from 'constants/ActionTypes/users';

export const setUserListLoading = (bool) => ({
    type: USER_LIST_LOADING,
    payload: bool
});

export const setUserLoading = (bool) => ({
    type: USER_LOADING,
    payload: bool
});

export const fetchUserList = (data) => ({
    type: LOAD_USER_LIST,
    payload: data
});

export const userListLoaded = (data) => ({
    type: USER_LIST_LOADED,
    payload: data
});

export const fetchUserListWithAccountInfo = (data) => ({
    type: LOAD_USER_ACCOUNT_INFO,
    payload: data
});

export const userListWithAccountInfoLoaded = (data) => ({
    type: USER_ACCOUNT_INFO_LOADED,
    payload: data
});

export const editUser = (data) => ({
    type: EDIT_USER,
    payload: data
});

export const userSuccessfulySaved = (bool) => ({
    type: USER_SAVED_SUCCESS,
    payload: bool
});

export const fetchRoles = (serviceName = 'zkhu') => ({
    type: LOAD_ROLES,
    payload: {
        serviceName
    }
});

export const rolesLoaded = (data) => ({
    type: LOADED_ROLES,
    payload: data,
});
