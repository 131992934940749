import React from 'react';
import {
    FormGroup, Button, Grid
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import ActionButton from 'components/ActionButton';

const ListItem = ({
    data = {}, onAddItem, disabled = false
}) => {
    const {external_id, address} = data;

    const handleAdd = () => {
        !disabled && onAddItem({id: external_id, address});
    };

    return (
        <Grid item xs={12} sm={6} className="position-relative">
            <FormGroup className="align-items-center" row>
                <ActionButton
                    disabled={disabled}
                    onClick={handleAdd}
                    title='votes.addAddress'
                    className='text-secondary'
                    icon={disabled ? <CheckIcon /> : <AddIcon />}
                />
                <div dangerouslySetInnerHTML={{ __html: address }}></div>
            </FormGroup>
        </Grid>
    );
};

export default ListItem;
