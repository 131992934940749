import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const ExpansionAccordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0
        },
        '&:before': {
            display: 'none'
        },
        '&$expanded': {
            margin: 'auto'
        }
    },
    expanded: {}
})(Accordion);

export const ExpansionAccordionSummary = withStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
            borderColor: 'rgba(0, 0, 0, .03)'
        },
        '& .MuiTouchRipple-root': {
            display: 'none'
        }
    },
    content: {
        '&$expanded': {
            margin: '12px 0'
        }
    },
    expanded: {
        '& .MuiExpansionPanelSummary-expandIcon': {
            transform: 'none !important'
        }
    }
}))(AccordionSummary);

export const ExpansionAccordionDetails = withStyles((theme) => ({
    root: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        minHeight: 500
    }
}))(AccordionDetails);

export const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none'
    },
    paper: {
        padding: theme.spacing(1)
    },
    fullWidth: {
        width: '100%'
    }
}));
