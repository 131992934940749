import React from 'react';
import './style.scss';
import CustomScrollbars from 'util/CustomScrollbars';
import NotificationItem from './NotificationItem';
import {LinearProgress} from "@material-ui/core";

const AppNotification = ({ data = [], onClick, loading, onUpdate }) => {
    const handleScrollUpdate = (values) => {
        if (values.top > 0.88) {
            onUpdate();
        }
    };

    if (data?.length === 0 && loading === false) {
        return (<div>Данных не найдено</div>);
    }

    return (
        <CustomScrollbars className="messages-list scrollbar notifications" style={{ height: 280 }} onUpdate={handleScrollUpdate}>
            <ul className="list-unstyled">
                {data.map((notification) => (
                    <NotificationItem
                        key={notification.id || Math.random()}
                        notification={notification}
                        onClick={onClick}
                    />
                ))}
            </ul>

            {loading && <LinearProgress />}

        </CustomScrollbars>
    );
};

export default AppNotification;
