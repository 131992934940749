import React, { useEffect, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import IconButton from "@material-ui/core/IconButton";
import CardHeader from "../dashboard/Common/CardHeader";
import IntlMessages from "../../util/IntlMessages";
import AppNotification from "../AppNotification";
// import * as actions from '../../actions/Notification';
import { useDispatch } from "react-redux";
// import { useSelector } from 'react-redux';
import useNotifications from '../../components/Helpers/useNotifications';
import {useHistory} from "react-router";
import {Button, Tooltip} from "@material-ui/core";
import * as actionsAppeal from '../../actions/Appeal';
import {appealWithIdLoaded} from "../../actions/Appeal";


const Notification = (props) => {
    const dispatch = useDispatch();
    const notifications = useNotifications();
    const histrory = useHistory();
    const [visible, setVisible] = useState(false);
    const limit = 50;

    // const {
    //     project: { code: projectCode = null }
    // } = useSelector(({ auth }) => auth);

    const handleClickItem = (data) => {
        const {
            id,
            channels,
            data: itemData
        } = data;
        notifications.changeRead(id);
        const firstChannel = channels[0];
        const service = firstChannel.replaceAll(`private-flagman_`, '') || null;

        // вызываем функцию обработки
        if (service) {

            // const extendsProps = {};
            // if (itemData.type_device) extendsProps.type_device = itemData.type_device;
            // if (itemData.idDtp) extendsProps.id = itemData.idDtp;
            // if (itemData.camera_id) extendsProps.id = itemData.camera_id;

            // wsCallBacks(service)({
            //     ...itemData,
            //     ...extendsProps,
            // }, history, dispatch);

            // onClose();

            switch (service) {
                case 'operator-appeal': {
                    dispatch(actionsAppeal.appealWithIdLoaded(!itemData?.relation_id ? itemData || {} : {}));
                    const relation_id = itemData?.relation_id || itemData?.id || null;
                    histrory.push(`/appeal${relation_id ? `/${relation_id}` : ''}`);
                    // histrory.push('/appeal');
                    break;
                }
                // default: {
                //     console.log('no event');
                // }
            }

            const userChannel = service.split('.');
            if (userChannel.length && userChannel[0] === 'user') {
                // сообщения
                dispatch(actionsAppeal.appealWithIdLoaded(!itemData?.relation_id ? itemData || {} : {}));
                const relation_id = itemData?.relation_id || itemData?.id || null;
                histrory.push(`/appeal${relation_id ? `/${relation_id}` : ''}`);
            }
        }
        setVisible(false);
    };

    const handleLoadNextPage = () => {
        if (
            !notifications.loading
            && notifications.lastPage < notifications.list?.meta?.last_page
        ) {
            notifications.loadList(notifications.lastPage + 1, limit);
        }
    };

    const onAppNotificationSelect = () => {
        setVisible(!visible);
    };

    // const notifications = [];

    useEffect(() => {
        notifications.loadCountActive();
        // notifications.loadList(1, limit)
    }, []);

    useEffect(() => {
        if (visible) {
            notifications.loadList(1, limit);
        } else {
            notifications.clearList();
        }
    }, [visible]);

    const handleReadAll = () => {
        notifications.loading === false && notifications.changeReadAll();
    };

    return (
        <Dropdown
            className="quick-menu"
            isOpen={visible}
            toggle={onAppNotificationSelect}
        >

            <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown"
            >
                <IconButton className="icon-btn">
                    <i className={`zmdi zmdi-notifications-none infinite wobble ${notifications?.countActive > 0 ? 'icon-alert animated' : ''}`} />
                </IconButton>
            </DropdownToggle>

            {/*{notifications.countActive > 0*/}
            {/*    && (*/}
                <DropdownMenu right>
                    <CardHeader
                        styleName="align-items-center"
                        heading={<IntlMessages id="appNotification.title" />}
                    />
                    <AppNotification
                        data={notifications?.list?.data}
                        onClick={handleClickItem}
                        loading={notifications.loading}
                        onUpdate={handleLoadNextPage}
                    />

                    {notifications.countActive > 0 && (
                        <div className="mt-3">
                            <Button
                                fullWidth
                                size="small"
                                // variant="outlined"
                                variant="text"
                                color="primary"
                                onClick={handleReadAll}
                            >
                                Отметить все как прочитанные
                            </Button>
                        </div>
                    )}

                </DropdownMenu>
            {/*)}*/}
        </Dropdown>

    );
};

export default Notification;
