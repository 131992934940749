import React from 'react';
import Item from './ProductItem';
import { makeStyles } from '@material-ui/core/styles';
import ProductWrapper from './ProductWrapper';


const useStyles = makeStyles({
    list: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        flexWrap: 'wrap',
        margin: '0 -.5rem'
    },
    item: {
        flex: 1,
        minWidth: '33.333%',
        maxWidth: '33.333%',
        margin: '0 0 1rem 0',
        padding: '0 .5rem 0 .5rem'
    }
});

const ProductList = ({ list = [], basketItems = [] }) => {
    const classes = useStyles();

    return (
        <div className={classes.list}>
            {list.map((item) => {
                    const inBasket = basketItems.find((el) => el.product_id === item.id);
                    return (
                        <ProductWrapper key={item.id}>
                            <Item
                                className={classes.item}
                                item={item}
                                productInBasket={inBasket}
                                inBasket={!!inBasket}
                                // onAddToBasket={() => handleAddToBasket(item)}
                                // onChangeCount={(count) => handleChangeCount(inBasket.id, inBasket.product_id, count)}
                            />
                        </ProductWrapper>
                    )
            })}
        </div>
    );


};

export default ProductList;