import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import { Avatar, Tooltip } from '@material-ui/core';
import AttachmentView from 'components/AttachmentView';

const ReceivedMessageCell = ({ message, myMessage, onImageLoaded = () => {} }) => {
    const avatarName = `${message.person?.first_name?.[0] || ''}${message.person?.last_name?.[0] || ''}`;
    const avatarFullName = `${message.person?.first_name || ''} ${message.person?.last_name || ''}`;
    const adminTypes = ['zkh-superuser', 'zkh-admin'];
    const isAdmin = adminTypes.reduce((res, type) => {
        if (message.scopes?.includes(type)) {
            res = true;
        }
        return res;
    }, false);

    return (
        <div className={cn('d-flex flex-nowrap chat-item', { 'flex-row-reverse': myMessage })}>
            <div className="chat-avatar">
                <Tooltip title={avatarFullName}>
                    <Avatar
                        className={cn('chat-user text-white', {
                            'bg-secondary': isAdmin,
                            'bg-primary': !isAdmin
                        })}
                    >
                        {avatarName}
                    </Avatar>
                </Tooltip>
            </div>

            <div className={cn('bubble', { 'jambo-card': myMessage })}>
                <div className="message">{message.text}</div>
                {message.attach.map((attachment, index) => (
                    <AttachmentView attachment={attachment} key={attachment.url} onImageLoaded={onImageLoaded} />
                ))}
                <div className="time text-muted text-right mt-2">{moment(message.created_at).format('LLL')}</div>
            </div>

        </div>
    );
};

export default ReceivedMessageCell;
