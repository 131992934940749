import {
    SET_VALIDATION_FIELDS,
    CLEAR_VALIDATION_FIELDS
} from 'constants/ActionTypes/validation';

const INIT_STATE = {
    fieldsValidation: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
    case SET_VALIDATION_FIELDS: {
        return {
            ...state,
            fieldsValidation: action.payload.validation || {}
        };
    }
    case CLEAR_VALIDATION_FIELDS: {
        return {
            ...state,
            fieldsValidation: INIT_STATE.fieldsValidation
        };
    }
    default:
        return state;
    }
};
