import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { SENTRY_DSN } from 'constants/Config';

const rootEl = document.getElementById('app-site');
if (SENTRY_DSN) {
    Sentry.init({ dsn: SENTRY_DSN });
}

// Create a reusable render method that we can call more than once
const render = () => {
    // Dynamically import our main App component, and render it
    const MainApp = require('./MainApp').default;
    ReactDOM.render(
        <MainApp />,
        rootEl
    );
};

if (module.hot) {
    module.hot.accept('./MainApp', () => {
        const MainApp = require('./MainApp').default;
        render(
            <MainApp />,
            rootEl
        );
    });
}

render();
