import React from 'react';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary
} from './style';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import IntlMessages from 'util/IntlMessages';
import { useSelector } from 'react-redux';

const Detail = ({
    list = {},
    childItem,
    expanded = null,
    onExpanded = () => {}
}) => {
    const {
        locale
    } = useSelector(({ settings }) => settings);

    const handleChange = (panel) => (event, newExpanded) => {
        onExpanded(newExpanded ? panel : false);
    };

    const expandIcon = (isExpanded) => (
        <div style={{ fontSize: 16 }}>
            <span style={{ fontSize: 16 }}>{isExpanded ? <IntlMessages id="appModule.close" /> : <IntlMessages id="button.modify" />}</span>
            {isExpanded ? <RemoveIcon fontSize="small" /> : <AddIcon fontSize="small" />}
        </div>
    );
    const titles = {
        key: 'font-weight-bold',
        [locale.locale]: ''
    };

    return (
        <>
            {list.map((item) => {
                const { id, key } = item;
                return (
                    <ExpansionPanel
                        key={`${id}${key}`}
                        expanded={expanded === id}
                        onChange={handleChange(id)}
                    >
                        <ExpansionPanelSummary
                            expandIcon={expandIcon(expanded === id)}
                            classes={{ content: 'flex-column flex-lg-row justify-content-start' /*'justify-content-between'*/ }}
                            //expanded={false}
                        >
                            {Object.keys(titles).map((titleKey)=>(
                                <div
                                    key={`${id}-${titleKey}`}
                                    className="d-flex mr-3"
                                    style={document.body.clientWidth > 959 ? { width: `${100 / Object.keys(titles).length}%` } : {}}
                                >
                                    <Typography className={titles[titleKey]}>
                                        <Hidden mdUp>
                                            <span className="font-weight-bold">
                                                <IntlMessages id={`translate.label.${titleKey}`} />
                                                {': '}
                                            </span>
                                        </Hidden>
                                        {item[titleKey]}
                                    </Typography>
                                </div>
                            ))}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails classes={{ root: 'border-top flex-column' }}>
                            {expanded === id && (
                                <>
                                    {React.cloneElement(childItem, {
                                        data: item
                                    })}
                                </>
                            )}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                )}
            )}
        </>
    );
};

export default Detail;
