const COOKIES_DOMAIN = process.env.REACT_APP_COOKIES_DOMAIN;
export const cookieOption = {
    path: '/',
    domain: COOKIES_DOMAIN
};

// urls
export const API_AUTH_URL = process.env.REACT_APP_API_AUTH_URL;
export const API_MENU_URLS = process.env.REACT_APP_API_MENU_URLS;
export const API_ZHKU_URLS = process.env.REACT_APP_API_ZHKU_URLS;
export const API_ZHKU_SBERBANK_URLS = process.env.REACT_APP_API_ZHKU_SBERBANK_URLS;
export const API_STORAGE_URLS = process.env.REACT_APP_API_STORAGE_URLS;
export const API_VOTE_URLS = process.env.REACT_APP_API_VOTE_URLS;
export const API_MARKET_URL = process.env.REACT_APP_API_MARKET;
export const API_PAYMENT_URL = process.env.REACT_APP_API_PAYMENT;
export const API_DADATA_URL = process.env.REACT_APP_API_DADATA_URL;
export const API_ADMIN_URL = process.env.REACT_APP_API_AUTH_URL;

// socket
export const SOCKET_HOST = process.env.REACT_APP_SOCKET_HOST;
export const SOCKET_AUTH_URL = process.env.REACT_APP_SOCKET_AUTH_URL;
export const SOCKET_KEY = process.env.REACT_APP_SOCKET_KEY;
export const UK = process.env.REACT_APP_SOCKET_UK;
export const SERVICE = process.env.REACT_APP_SOCKET_SERVICE;

// sentry
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
