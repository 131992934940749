import React from 'react';
import MessageCell from './MessageCell';

const Conversation = ({ messages, authUserId, onImageLoaded = () => {} }) => {
    //const scrollbarsRef = useRef();
    // const scrollToBottom = () => {
    //     scrollbarsRef.current.scrollIntoView({ behavior: 'smooth' });
    // };
    /*useEffect(() => {
        if (scrollbarsRef.current) {
            scrollToBottom();
        }
    }, [messages]);
    const scrollToBottom = () => {
        //sroll to the last message
        const { scrollHeight } = scrollbarsRef.current;
        const height = scrollbarsRef.current.clientHeight;
        const maxScrollTop = scrollHeight - height;
        scrollbarsRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }*/

    return (
        <div className="chat-main-content">
            {messages.map((message) => (
                <MessageCell
                    key={message.id}
                    message={message}
                    myMessage={message.created_by === authUserId}
                    onImageLoaded={onImageLoaded}
                />
            )
            )}
        </div>
    );
};
export default Conversation;
