class HttpError extends Error {
    constructor(message, status) {
        super(message);
        this.className = 'HttpError';
        this.status = status;
        Error.captureStackTrace(this, HttpError);
    }
}

export { HttpError };
