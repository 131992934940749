import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    FormControl,
    InputLabel,
    OutlinedInput,
} from '@material-ui/core/';
import {
    userSuccessfulySaved
} from 'actions/Admin';
import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';

const useStyles = makeStyles({
    root: {
      width: '100%'
    },
    buttons: {
        marginTop: '1rem'
    }
  });

const Form = ({ data = {}, onSuccess, onClose }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { successfulySaved } = useSelector(({ admin }) => admin);
    const [personal_account, setPersonalAccount] = useState(data.personal_account || '');

    const handleChange = (event) => {
        event.preventDefault();
        const { value } = event.target;
        setPersonalAccount(value);
    };

    useEffect(() => {
        if (successfulySaved) {
            onClose();
            dispatch(userSuccessfulySaved());
        }
    },[dispatch, onClose, successfulySaved]);

    const saveUser = () => {
        console.log('personal_account', personal_account);
        onSuccess(personal_account);
    };

    return (
        <>
            <FormControl size="small" variant={'outlined'} classes={{root: classes.root}}>
                <InputLabel>Номер лицевого счета</InputLabel>
                <OutlinedInput
                    type="text"
                    value={personal_account}
                    name="personal_account"
                    onChange={handleChange}
                    label="Номер лицевого счета"
                />
                {/* <FormHelperText>{validation[name]}</FormHelperText> */}
            </FormControl>
            <div className={cn(classes.buttons, 'text-right')}>
                <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    // fullWidth={true}
                    onClick={saveUser}
                >
                    Сохранить
                </Button>
            </div>
        </>
    );
};

export default Form;
