import {
    UK_ADDRESS_LIST_LOADED,
    UK_ADDRESS_LIST_LOADED_STATUS,
    UK_SET_ERROR
} from 'constants/ActionTypes';

const INIT_STATE = {
    addressList: {
        data: []
    },
    addressListLoaded: false,
    error: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
    case UK_ADDRESS_LIST_LOADED: {
        return {
            ...state,
            addressList: action.payload
        };
    }

    case UK_ADDRESS_LIST_LOADED_STATUS: {
        return {
            ...state,
            addressListLoaded: action.payload
        };
    }
    case UK_SET_ERROR: {
        return {
            ...state,
            error: action.payload
        };
    }
    default:
        return state;
    }
};
