import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    userSignUp
} from 'actions/Auth';
import { Grid } from '@material-ui/core/';
import SignUp from 'components/Auth/SignUp';

const AppSignUp = ({ history }) => {
    const dispatch = useDispatch();
    //const { ukInfo: { logo, name } } = useSelector(({ auth }) => auth);
    const logo = <img src={require('assets/images/logo64.png')} alt="Logo" title="Logo" />;
    const name = 'УК ФЛАГМАН';
    const {
        isSignUp
    } = useSelector(({ auth }) => auth);
    const {
        showMessage
    } = useSelector(({ messages }) => messages);


    useEffect(() => {
        if (isSignUp && !showMessage) {
            history.push('/signin');
        }
    // eslint-disable-next-line react/destructuring-assignment
    }, [showMessage, isSignUp, history]);


    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
        >
            <Grid item xs={10} md={4}>
                <SignUp
                    logo={logo}
                    title={name}
                    onClickSignUp={(user) => dispatch(userSignUp(user))}
                />
            </Grid>
        </Grid>
    );
};


export default AppSignUp;
