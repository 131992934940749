import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

export const ExpansionPanel = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0
        },
        '&:before': {
            display: 'none'
        },
        '&$expanded': {
            margin: '1rem 0',
        }
    },
    expanded: {}
})(MuiExpansionPanel);

export const ExpansionPanelSummary = withStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
            borderColor: 'rgba(0, 0, 0, .03)'
        },
        '& .MuiTouchRipple-root': {
            display: 'none'
        }
    },
    content: {
        '&$expanded': {
            margin: '12px 0'
        }
    },
    expanded: {
        '& .MuiExpansionPanelSummary-expandIcon': {
            transform: 'none !important'
        }
    }
}))(MuiExpansionPanelSummary);

export const ExpansionPanelDetails = withStyles((theme) => ({
    root: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        borderBottom: '1px solid rgba(0, 0, 0, .125)'
        //minHeight: 500
    }
}))(MuiExpansionPanelDetails);

export const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none'
    },
    paper: {
        padding: theme.spacing(1)
    },
    fullWidth: {
        width: '100%'
    }
}));
