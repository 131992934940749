import * as types from 'constants/ActionTypes/order';

const INIT_STATE = {
    loading: false,
    order: {},
    checked: '',
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

    case types.CREATED_ORDER_ZKH: {
        return {
            ...state,
            order: action.payload,
        };
    }

    case types.SET_LOADING_ZKH: {
        return {
            ...state,
            loading: action.payload,
        };
    }

    case types.CHECKED_ORDER_ZKH: {
        return {
            ...state,
            checked: action.payload || '',
        };
    }

    default:
        return state;
    }
};
