import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

const Sticker = ({ children = {}, offsetTop = 164, offsetBottom = 80, minHeight = 200, parentRef = null }) => {
    const currentRef = useRef();
    const { drawerType } = useSelector(({ settings }) => settings);
    const [style, setStyle] = useState({});
    const [maxHeight, setMaxHeight] = useState(0);
    let timerId = null;

    const handleChangeFunc = () => {
        const height = document.body.clientHeight - offsetTop - offsetBottom;
        setMaxHeight(Math.max(minHeight, height));

        if (parentRef && parentRef.current) {
            const parent = parentRef.current;
            const width = parent.getBoundingClientRect().width -
                parseInt(getComputedStyle(parent, true).paddingRight) -
                parseInt(getComputedStyle(parent, true).paddingLeft);
            setStyle({ position: 'fixed', top: offsetTop, width });
            return;
        }

        if (currentRef && currentRef.current) {
            const current = currentRef.current.getBoundingClientRect();
            setStyle({ position: 'fixed', top: offsetTop, width: current.width });
        }
    };

    const handleChange = () => {
        if (timerId) {
            clearTimeout(timerId);
            timerId = null;
        }
        timerId = setTimeout(() => {
            timerId = null;
            handleChangeFunc();
        }, 500);
    };

    useEffect(() => {
        if (currentRef && currentRef.current) {
            handleChangeFunc();
        }
    }, [currentRef, drawerType]);

    useEffect(() => {
        window.addEventListener('resize', handleChange);

        return () => {
            window.removeEventListener('resize', handleChange);
        };
    }, []);

    const childrenWithProp = React.Children.map(children, (child) => {
        if (child) {
            return React.cloneElement(child, {
                maxHeightContent: maxHeight
            });
        }
    });

    return (
        <div
            ref={currentRef}
            style={style}
        >
            {childrenWithProp}
        </div>
    );
};

export default Sticker;
