import * as types from '../constants/Notification';

// прочитано
export const changeReadNotification = (user_id, id) => ({
    type: types.CHANGE_READ,
    payload: {
        user_id,
        id,
    },
});

export const setReadNotification = (id) => ({
    type: types.SET_READ,
    payload: id,
});

// прочитаны все
export const changeReadAllNotification = (user_id) => ({
    type: types.CHANGE_READ_ALL,
    payload: user_id,
});

export const setReadAllNotification = () => ({
    type: types.SET_READ_ALL,
});


export const setLoadingNotification = (bool = false) => ({
    type: types.SET_LOADING,
    payload: bool,
});

// подгрузить список
export const loadListNotification = (user_id, page = 1, limit = 10, params = {}) => ({
    type: types.LOAD_LIST,
    payload: {
        user_id,
        page,
        limit,
        ...params,
    },
});

export const loadedListNotification = (data, replace = false) => ({
    type: types.LOADED_LIST,
    payload: {
        data,
        replace,
    },
});

export const clearListNotification = () => ({
    type: types.CLEAR_LIST,
});
// загружаем события по конкретному сервису
export const loadServiceListNotification = (user_id, page = 1, limit = 10, params = {}) => ({
    type: types.LOAD_SERVICE_LIST,
    payload: {
        user_id,
        page,
        limit,
        ...params,
    },
});

export const loadedServiceListNotification = (data, service) => ({
    type: types.LOADED_SERVICE_LIST,
    payload: {
        data,
        service,
    },
});

// подгрузить количество не прочитанных
export const loadCountActiveNotification = (user_id) => ({
    type: types.LOAD_COUNT_ACTIVE,
    payload: {
        user_id,
    },
});

export const loadedCountActiveNotification = (count) => ({
    type: types.LOADED_COUNT_ACTIVE,
    payload: count,
});

// установить последнюю загруженную страницу
export const setLastPageNotification = (page) => ({
    type: types.SET_LAST_PAGE,
    payload: page,
});

export const loadFreeSpaceServer = () => ({
    type: types.LOAD_FREE_SPACE_SERVER,
});

export const loadedFreeSpaceServer = (data) => ({
    type: types.LOADED_FREE_SPACE_SERVER,
    payload: data
});
