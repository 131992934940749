import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { userScopesList, useCheckUserScopes } from '../../../../components/Helpers/userScopes';

const OrderMenu = (props) => {
    const { list = [] } = props;
    const [showMenu, setShowMenu] = useState(null);
    const isOperator = useCheckUserScopes([userScopesList.operator_market]);

    const handleShowMenu = (e) => setShowMenu(e.currentTarget);
    const handleCloseMenu = () => setShowMenu(null);

    if (isOperator) {
        return (
            <>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleShowMenu}
                >
                    <MoreVertIcon/>
                </IconButton>

                <Menu
                    id="long-menu"
                    anchorEl={showMenu}
                    keepMounted
                    open={Boolean(showMenu)}
                    onClose={handleCloseMenu}
                    // PaperProps={{
                    //     style: {
                    //         maxHeight: ITEM_HEIGHT * 4.5,
                    //         width: '20ch',
                    //     },
                    // }}
                >
                    {list.map(({ onClick, name }, index) => (
                        <MenuItem
                            key={index}
                            onClick={() => {
                                setShowMenu(false);
                                onClick();
                            }}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </Menu>
            </>
        );
    }

    return null;
};

export default OrderMenu;
