import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomScrollbars from '../../../util/CustomScrollbars';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
    list: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        flexDirection: 'column'
    },
    item: {
        marginBottom: '1rem'
    }
});

const ProductList = ({ list = []}) => {
    const classes = useStyles();

    if (list.length === 0) {
        return (
            <Typography variant="subtitle1">
                Список товаров пуст
            </Typography>
        );
    }

    return (
        <CustomScrollbars className="scrollbar" style={{ height: 200, width: 500 }}>
            <div className={classes.list}>
                {list.map((item) => {
                    const {
                        id,
                        product_id,
                        count,
                        cost,
                        product: {
                            images,
                            name
                        }
                    } = item;

                    const image = images[0] || '';

                    return (
                        <div key={`orders_${id}_product${product_id}`} className="item d-flex justify-content-between align-items-center cart-mini-item">
                            <div className="image">
                                <img src={image} title={name} alt={name} />
                            </div>

                            <div className="name">
                                {name}
                            </div>

                            <div className="count">
                                {count}шт
                            </div>

                            <div className="price text-center">
                                {cost * count}₽
                            </div>
                        </div>
                    );
                })}
            </div>
        </CustomScrollbars>
    );


};

export default ProductList;