import React from 'react';
import { Button, FormGroup } from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';

const ActionConfirmation = ({ onConfirm, onCancel, methodText = null, text }) => (
    <div>
        <p>
            {methodText ? <IntlMessages id={methodText} /> : text}
        </p>
        <FormGroup row className="justify-content-end mt-3 mb-3">
            <Button onClick={onCancel} variant="contained" className="mr-3">
                <IntlMessages id="appModule.cancel" />
            </Button>
            <Button
                onClick={onConfirm}
                variant="contained"
                color="primary"
            >
                <IntlMessages id="button.yes" />
            </Button>
        </FormGroup>
    </div>
);

export default ActionConfirmation;
