import React from 'react';
import { IMAGES_FILE_TYPES } from 'constants/mime-types';
import SaveIcon from '@material-ui/icons/Save';
import CopyToClipboard from 'components/CopyToClipboard';

const AttachmentView = ({
    attachment, isPreview, onImageLoaded = () => {}, textOnly = false
}) => (
    <span className="mr-1">
        {textOnly
            ? (
                <CopyToClipboard
                    text={attachment.url}
                />
            )
            : IMAGES_FILE_TYPES.includes(attachment.type)
                ? (
                    <a
                        href={attachment.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        alt="link"
                    >
                        <img
                            onLoad={onImageLoaded}
                            src={attachment.url}
                            alt={attachment.name}
                            style={isPreview ? { height: '60px' } : { maxWidth: '100%' }}
                        />
                    </a>
                )
                : (
                    <a
                        href={attachment.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        alt="link"
                    >
                        <SaveIcon />
                        {attachment.name}
                    </a>
                )}
    </span>
);

export default AttachmentView;
