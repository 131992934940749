import {
    all, call, select, put, takeLatest
} from 'redux-saga/effects';

import {
    LOAD_MENU,
    FETCH_LANDING_MENU,
    CREATE_MENU_ITEM,
    EDIT_MENU_ITEM,
    DELETE_MENU_ITEM,
    FETCH_MENU_SCOPES,
    FETCH_MENU_SCENARIOS
} from 'constants/ActionTypes';
import {
    menuLoaded, landingMenuLoaded, menuSuccesfullySaved, scopesLoaded,
    scenariosLoaded, loadingMenu
} from 'actions/Menu';
import { showMessage } from 'actions/Messages';
import { userSignOutSuccess } from 'actions/Auth';
import { getActionForError } from 'util/errors';
import api from 'util/api/api.menu.methods';

export const stateAuthSelector = (state) => state.auth;

function* fetchScopesSaga({ payload }) {
    yield put(loadingMenu(true));
    const { authUser } = yield select(stateAuthSelector);
    const scenario = payload;
    try {
        const response = yield call(api.getScopes, scenario, authUser?.access_token);
        yield put(scopesLoaded(response.data));
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(loadingMenu(false));
    }
}

function* fetchScenariosSaga() {
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.getScenarios, authUser?.access_token);
        yield put(scenariosLoaded(response.data));
    } catch (error) {
        yield put(getActionForError(error));
    }
}

function* loadMenuSaga() {
    const { authUser } = yield select(stateAuthSelector);
    try {
        const menu = yield call(api.getMenus, authUser?.access_token);
        const { is_auth } = menu;
        if (!is_auth) {
            yield put(userSignOutSuccess());
        }
        yield put(menuLoaded(menu.data));
    } catch (error) {
        yield put(getActionForError(error));
    }
}

function* fetchLandingMenuSaga({ payload }) {
    yield put(loadingMenu(true));
    const scope = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const menu = yield call(api.getMenusForScope, authUser?.access_token, scope, 'admin', 'default');
        const { is_auth } = menu;
        if (!is_auth) {
            yield put(userSignOutSuccess());
        }
        yield put(landingMenuLoaded(menu.data, scope));
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(loadingMenu(false));
    }
}

function* createMenuItemSaga({ payload }) {
    yield put(loadingMenu(true));
    const { data } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.createMenuItem, data, authUser?.access_token);
        yield put(menuSuccesfullySaved(response.success));
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(loadingMenu(false));
    }
}

function* editMenuItemSaga({ payload }) {
    yield put(loadingMenu(true));
    const { id, data } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.editMenuItem, id, data, authUser?.access_token);
        yield put(menuSuccesfullySaved(response.success));
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(loadingMenu(false));
    }
}

function* deleteMenuItemSaga({ payload }) {
    yield put(loadingMenu(true));
    const { id } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.deleteMenuItem, id, authUser?.access_token);
        yield put(menuSuccesfullySaved(response.success));
        yield put(showMessage('success', 'menu.successfullyDeleted', null, true));
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(loadingMenu(false));
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(LOAD_MENU, loadMenuSaga),
        takeLatest(FETCH_LANDING_MENU, fetchLandingMenuSaga),
        takeLatest(CREATE_MENU_ITEM, createMenuItemSaga),
        takeLatest(EDIT_MENU_ITEM, editMenuItemSaga),
        takeLatest(DELETE_MENU_ITEM, deleteMenuItemSaga),
        takeLatest(FETCH_MENU_SCOPES, fetchScopesSaga),
        takeLatest(FETCH_MENU_SCENARIOS, fetchScenariosSaga)
    ]);
}
