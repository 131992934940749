import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button } from '@material-ui/core/';
import CheckIcon from '@material-ui/icons/Check';
import IntlMessages from 'util/IntlMessages';

const CopyToClipBoard = ({ text }) => {
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        let timer;
        if (copied) {
            timer = setTimeout(() => {
                setCopied(false);
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [copied]);

    return (
        <div className="mb-2 mt-2">
            <span>
                {text}
            </span>
            <CopyToClipboard
                text={text}
                onCopy={() => setCopied(true)}
            >
                <Button
                    size="small"
                    color="secondary"
                >
                    {copied
                        ? (
                            <span>
                                <CheckIcon color="secondary" />
                                {' '}
                                <IntlMessages id="appModule.copied" />
                            </span>
                        )
                        : <IntlMessages id="appModule.copy" />}
                </Button>
            </CopyToClipboard>
        </div>
    );
};

export default CopyToClipBoard;
