import React, {useEffect, useState} from 'react';
import {
    Button,
    TextField,
    LinearProgress,
    FormGroup,
} from '@material-ui/core';
import NumberFormatCustom from './NumberFormatCustom';
import { useDispatch, useSelector } from 'react-redux';
import { clearValidationFields } from '../../../../actions/Validation';

const Form = (props) => {
    const {
        loading = false,
        currentCost = 0,
        onSubmit = () => {},
        onCancel = () => {},
    } = props;

    const dispatch = useDispatch();

    const {
        fieldsValidation
    } = useSelector(({ validation }) => validation);

    const [sum, setSum] = useState(currentCost);
    const [validation, setValidation] = useState({});

    const handleClosePayModal = () => {
        onCancel();
    };

    const handlePayModal = () => {
        onSubmit(sum);
    };

    const handleChaneValue = ({ target: { value } }) => {
        setSum(Math.round( Math.abs(value) * 100 ) / 100);
        const newValidation = validation;
        delete newValidation.cost;
        setValidation({
            ...newValidation
        })
    };

    useEffect(() => {
        if (currentCost) {
            setSum(currentCost);
        }
    }, [currentCost]);

    useEffect(() => {
        if (Object.keys(fieldsValidation).length > 0) {
            setValidation({
                ...fieldsValidation
            });
            dispatch(clearValidationFields());
        }
    }, [dispatch, fieldsValidation]);

    return (
        <div className="form">
            <FormGroup row className="justify-content-end mt-3 mb-3">
                <TextField
                    disabled={loading}
                    label="Сумма (руб.)"
                    value={sum}
                    onChange={handleChaneValue}
                    fullWidth={true}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    className="pointer"
                    variant="outlined"
                    size="small"
                    InputProps={{
                        inputComponent: NumberFormatCustom,
                    }}
                    error={sum < 1 || validation.cost}
                    helperText={validation.cost || ''}
                />
            </FormGroup>

            <FormGroup row className="justify-content-end mt-3 mb-3">
                <Button
                    onClick={handleClosePayModal}
                    variant="contained"
                    disabled={loading}
                    className="mr-3"
                >
                    Отмена
                </Button>

                <Button
                    onClick={handlePayModal}
                    variant="contained"
                    disabled={loading || sum < 1 || validation.cost}
                    color="primary"
                >
                    Оплатить
                </Button>
            </FormGroup>

            {loading && <LinearProgress />}
        </div>
    );
};

export default Form;
