import React, { useEffect } from 'react';
import { fetchArticleWithSlug, setError, articleLoaded } from 'actions/Articles';
import { setChangeCurrentUKStatus } from 'actions/Auth';
import { useDispatch, useSelector } from 'react-redux';

const Other = ({ location, history }) => {
    const dispatch = useDispatch();
    const { currentArticle, error } = useSelector(({ articles }) => articles);
    const { changeCurrentUK } = useSelector(({ auth }) => auth);

    useEffect(() => {
        if (changeCurrentUK) {
            dispatch(articleLoaded({}));
            dispatch(setChangeCurrentUKStatus());
        } else {
            dispatch(fetchArticleWithSlug(location.pathname));
        }
    }, [location.pathname, changeCurrentUK]);

    useEffect(() => {
        if (error) {
            dispatch(setError(false));
            history.push('/error404');
        }
    // eslint-disable-next-line react/destructuring-assignment
    }, [error]);

    const createMarkup = (value) => ({ __html: value });

    return (
        <>
            <h1>{currentArticle.title}</h1>
            <div dangerouslySetInnerHTML={createMarkup(currentArticle.text)} />
        </>
    );
};

export default Other;
