import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    TextField,
    Button,
    FormGroup,
    Select,
    FormControl,
    InputLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    FormHelperText,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment/moment';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import {
    fetchScopes
} from 'actions/Menu';
import CustomCKEditor from 'components/CKEditor';
import useScopes from 'components/Helpers/useScopes';
import useMenuList from 'components/Helpers/useMenuList';
import { useIntl } from 'react-intl';
import MenuEditor from 'app/routes/SiteMenu/EditorForm';
import { Autocomplete } from '@material-ui/lab';

const localeCancelLabelMap = {
    en: 'cancel',
    ru: 'отмена'
};

const localeMap = {
    en: enLocale,
    ru: ruLocale
};

const initialPublished = moment(new Date()).format('YYYY-MM-DD HH:mm:ssZ');


const EditorForm = ({
    data = {}, handleSendRequest, handleClose, type, onClickCreateMenu
}) => {
    const intl = useIntl();
    const {
        text,
        published_at = initialPublished,
        title = '',
        description = '',
        slug = '',
        scopes = '*',
        status,
        send_telegram = true,
        send_instagram = true,
        send_web = true,
        hashtags = [],
    } = data;

    const [content, setContent] = useState(text);
    const [currentSlug, setSlug] = useState(slug);
    const [preview, setPreview] = useState({ published_at, title, description, hashtags });
    const [error, setError] = useState(false);
    const [currentScope, setCurrentScope] = useState(scopes);
    const menuList = useMenuList(currentScope);
    const [isDialogOpen, setDialogOpen] = useState(false);

    // сайт, инстаграм, телеграм
    const [sendTelegram, setSendTelegram] = useState(send_telegram);
    const [sendSite, setSendSite] = useState(send_web);
    const [sendInstagram, setSendInstagram] = useState(send_instagram);

    const scopeList = useScopes(fetchScopes, 'menu', 'default');

    const {
        locale
    } = useSelector(({ settings }) => settings);

    const onEditorContentChange = (event, editor) => {
        const newContent = editor.getData();
        setContent(newContent);
    };

    const handeleButtonClick = (e) => {
        const newData = {
            ...data,
            text: content,
            ...preview
            // attach: attaches
        };
        if (type === 'article') {
            newData.slug = currentSlug;
            newData.scopes = currentScope;
            delete newData.published_at;
        }
        if (type === 'news') {
            newData.send_telegram = sendTelegram;
            newData.send_instagram = sendInstagram;
            newData.send_web = sendSite;
        }
        // handleClose();
        // console.log('newData', newData)
        handleSendRequest(newData);
    };

    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setPreview((state) => ({ ...state, [name]: value }));
    };

    const hashtagsHandler = (_, newValue) => {
        const regexp = /^(?:#)([A-Za-z0-9А-Яа-я_](?:(?:[A-Za-z0-9А-Яа-я_]|(?:(?!))){0,28}(?:[A-Za-z0-9А-Яа-я_]))?)((?: #)([A-Za-z0-9А-Яа-я_](?:(?:[A-Za-z0-9А-Яа-я_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9А-Яа-я_]))?))*$/
        if (newValue.every(el => el.match(regexp))) {
            setPreview({
                ...preview,
                hashtags: newValue
            });
        }
    };

    useEffect(() => {
        setError(
            !preview.title
            || !content
            || (type === 'article' ? !currentSlug : !preview.description)
        );
    }, [preview, content, currentSlug, type]);

    const handleSaveMenu = (menu) => {
        const newMenu = {
            ...menu,
            scopes: currentScope,
            sorting: menuList[currentScope].length
        };
        onClickCreateMenu(newMenu);
        setDialogOpen(false);
    };

    return (
        <div className="mb-3 border-bottom pb-3">
            {data.description && <h3><IntlMessages id="appModule.shortDescription" /></h3>}
            <FormGroup className="bg-light p-3 mb-3">
                {type === 'news' && (
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale.locale]}>
                        <FormGroup>
                            <DateTimePicker
                                ampm={false}
                                label="Дата публикации"
                                // label={<IntlMessages id="news.publishedAt" />}
                                value={preview.published_at}
                                error={!preview.published_at}
                                cancelLabel={localeCancelLabelMap[locale.locale]}
                                onChange={(date) => setPreview((state) => ({
                                    ...state,
                                    published_at: moment(date).format('YYYY-MM-DD HH:mm:ssZ')
                                }))}
                                format="yyyy-MM-dd HH:mm"
                                // size="small"
                                inputVariant="outlined"
                                className="mb-3"
                                disabled={status === 1}
                            />
                        </FormGroup>
                    </MuiPickersUtilsProvider>
                )}
                <FormControl className="mb-3">
                    <TextField
                        required
                        type="text"
                        name="title"
                        className="mb-2"
                        variant="outlined"
                        label={<IntlMessages id="news.title" />}
                        defaultValue={preview.title}
                        onChange={handleTextChange}
                        error={!preview.title}
                        helperText={!preview.title && <IntlMessages id="appModule.mandatory.field" />}
                    />
                </FormControl>
                {type === 'article'
                    ? (
                        <>
                        <FormControl variant="outlined" required className="mb-3">
                            <InputLabel shrink htmlFor="scope_list"><IntlMessages id="article.whoCanSeeThisPage" /></InputLabel>
                            <Select
                                notched
                                native
                                value={currentScope}
                                onChange={(event) => {
                                    const { value } = event.target;
                                    setCurrentScope(value);
                                }}
                                inputProps={{
                                    name: 'scope',
                                    id: 'scope_list'
                                }}
                                label={<IntlMessages id="article.whoCanSeeThisPage" />}
                            >
                                {scopeList.map((t) => (<option value={t.value} key={t.key}>{t.description}</option>))}
                            </Select>
                        </FormControl>
                        {menuList[currentScope].length > 0 && (
                            <FormControl variant="outlined" className="mb-3" required error={!currentSlug}>
                                <InputLabel shrink htmlFor="slug_list"><IntlMessages id="article.menuItem" /></InputLabel>
                                <Select
                                    notched
                                    native
                                    value={currentSlug}
                                    onChange={(event) => {
                                        const { value } = event.target;
                                        setSlug(value);
                                    }}
                                    inputProps={{
                                        name: 'slug',
                                        id: 'slug_list'
                                    }}
                                    label={<IntlMessages id="article.menuItem" />}
                                >
                                    <option value="" key="empty">{intl.messages?.["article.selectMenuItem"]}</option>
                                    {menuList[currentScope].map((item) => (
                                        <option value={item.link} key={item.link}>{item.label}</option>))}
                                </Select>
                                {!currentSlug
                                    && <FormHelperText>
                                        <IntlMessages id="appModule.mandatory.field" />
                                    </FormHelperText>}
                            </FormControl>
                        )}
                            <div>
                                {menuList[currentScope].length === 0
                                    ? (
                                        <IntlMessages id="article.noMenuItemsForThisUserType" />
                                        ) : (
                                            <span>Вы можете добавить новый пункт в меню.</span>
                                    )}
                                <Button
                                    color="secondary"
                                    onClick={() => setDialogOpen(true)}
                                >
                                    <IntlMessages id="appModule.create" />
                                </Button>
                            </div>
                        </>
                    )
                    : (
                        <>
                            <FormControl className="mb-3">
                                <TextField
                                    required
                                    label="Краткое описание"
                                    // label={<IntlMessages id="news.shortDescription" />}
                                    type="text"
                                    name="description"
                                    variant="outlined"
                                    multiline
                                    defaultValue={preview.description}
                                    onChange={handleTextChange}
                                    error={!preview.description}
                                    helperText={!preview.description && <IntlMessages id="appModule.mandatory.field" />}
                                    className="mb-3"
                                />
                            </FormControl>
                            <FormControl className="mb-3">
                                <Autocomplete
                                    multiple
                                    value={preview.hashtags || null}
                                    options={[]}
                                    filterSelectedOptions={true}
                                    freeSolo={true}
                                    onChange={hashtagsHandler}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Хэштеги"
                                            className="mb-3"
                                            helperText="Для ввода хэштегов используйте знак #. После ввода нажмите Enter"
                                        />
                                    )}
                                />
                            </FormControl>

                            <div className="d-flex">
                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            color="primary"
                                            checked={sendTelegram}
                                            onChange={(event) => {
                                                const checked = event.target.checked;
                                                setSendTelegram(checked);
                                            }}
                                        />
                                    )}
                                    label="Телеграмм"
                                    // label={<IntlMessages id="appModule.telegram" />}
                                />

                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            color="primary"
                                            checked={sendInstagram}
                                            onChange={(event) => {
                                                const checked = event.target.checked;
                                                setSendInstagram(checked);
                                            }}
                                        />
                                    )}
                                    label="Инстаграм"
                                    // label={<IntlMessages id="appModule." />}
                                />

                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            color="primary"
                                            checked={sendSite}
                                            onChange={(event) => {
                                                const checked = event.target.checked;
                                                setSendSite(checked);
                                            }}
                                        />
                                    )}
                                    label="Сайт"
                                    // label={<IntlMessages id="appModule.website" />}
                                />
                            </div>

                        </>
                    )}
            </FormGroup>

            <h3><IntlMessages id="appModule.fullText" /></h3>
            <div className="description">
                <CustomCKEditor
                    locale={locale}
                    content={content}
                    onEditorContentChange={onEditorContentChange}
                />
            </div>

            {!content && <div className="error"><IntlMessages id="appModule.mandatory.field" /></div>}

            <div className="d-flex justify-content-end align-items-center">
                <FormGroup row className="justify-content-end mt-3">
                    <Button onClick={handleClose} variant="contained" className="mr-3">
                        <IntlMessages id="appModule.cancel" />
                    </Button>
                    <Button
                        onClick={handeleButtonClick}
                        disabled={error}
                        variant="contained"
                        color="primary"
                    >
                        <IntlMessages id="appModule.send" />
                    </Button>
                </FormGroup>
            </div>

            {/* добавляем пункт меню */}
            <Dialog
                open={isDialogOpen}
                onClose={() => setDialogOpen(false)}
                scroll="paper"
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle><IntlMessages id="menu.siteMenu" /></DialogTitle>
                <DialogContent>
                    <MenuEditor
                        handleSendRequest={handleSaveMenu}
                        handleClose={() => setDialogOpen(false)}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default EditorForm;
