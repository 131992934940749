import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useStatuses = (fetchStatuses, storeName) => {
    const dispatch = useDispatch();
    const {
        statuses
    } = useSelector((store) => store[storeName]);

    useEffect(() => {
        if (statuses.length === 0) {
            dispatch(fetchStatuses());
        }
    }, [statuses, dispatch, fetchStatuses]);

    return statuses;
};

export default useStatuses;
