import { HttpError } from './http.error';
import { HttpAuthError } from './http.auth.error';
import { ValidationError } from './validation.error';

const mapStatusToError = {
    401: () => new HttpAuthError(),
    422: (data) => {
        const message = [data.error_description, data.hint].filter((a) => a).join(': ');
        return new ValidationError({ message, validationData: data.message });
    },
    DEFAULT: (data, status) => new HttpError(data.error_description, status),
};

const createErrorFromAxiosError = (error) => {
    if (error.response) {
        const { data, status } = error.response;
        return (mapStatusToError[status] || mapStatusToError.DEFAULT)(data, status);
    }
    return error;
};

export default createErrorFromAxiosError;
