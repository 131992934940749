import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, Badge } from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -8,
        top: 5,
        padding: '0 4px'
    }
}))(Badge);

const CardMenu = (props) => {
    const {
        actions = {}, menuState, anchorEl, handleRequestClose, handleButtonClick, status, unreadCount, isAdmin, isOperator
    } = props;
    return (
        <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={menuState}
            onClose={handleRequestClose}

            MenuListProps={{
                style: {
                    paddingTop: 0,
                    paddingBottom: 0
                }
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
        >
            {
                Object.keys(actions).map((key) => (
                    (
                        (isAdmin || !actions[key].admin)
                        || (isOperator || !actions[key].operator)
                    )
                    && (
                        <MenuItem
                            key={key}
                            onClick={() => handleButtonClick(key)}
                            disabled={typeof actions[key].disabled === 'boolean'
                                ? actions[key].disabled
                                : status ? actions[key].disabled.includes(status) : false}
                            classes={{ root: 'pr-4' }}
                        >
                            {key === 'openChat'
                                ? (
                                    <StyledBadge badgeContent={unreadCount} color="secondary">
                                        <IntlMessages id={actions[key].text} />
                                    </StyledBadge>
                                )
                                : <IntlMessages id={actions[key].text} />}
                        </MenuItem>
                    )
                ))
            }

        </Menu>
    );
};

export default CardMenu;
