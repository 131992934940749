import React from 'react';
import {
    Grid
} from '@material-ui/core';
import SelectedListItem from './SelectedListItem';
import IntlMessages from 'util/IntlMessages';

const SelectedList = ({
    items = [], onDeleteItem, errorText
}) => {
    if (!items.length) return (
        <div className={errorText ? 'error' : ''}>
            <IntlMessages id="votes.addressNoSelected" />
        </div>
    );

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
        >
            {items.map((item) => (
                <SelectedListItem
                    key={item.id}
                    item={item}
                    onDelete={onDeleteItem}
                />
            ))}
        </Grid>
    );
};

export default SelectedList;
