import {
    HOUSES_LOAD_SUCCESS,
    SEARCH_HOUSES_SUCCESS,
    SET_HOUSES_ERROR,
    HOUSES_STATUSES_LOADED,
    SEARCH_HOUSES_STATUSES_LOADED,
    SEARCH_HOUSES_CLEAR_LIST
} from 'constants/ActionTypes';

const INIT_STATE = {
    list: {
        data: []
    },
    listLoaded: false,
    searchList: {
        data: []
    },
    searchListLoaded: false,
    error: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
    case HOUSES_LOAD_SUCCESS: {
        return {
            ...state,
            list: action.payload,
            listLoaded: true
        };
    }
    case HOUSES_STATUSES_LOADED: {
        return {
            ...state,
            listLoaded: action.payload
        };
    }
    case SEARCH_HOUSES_SUCCESS: {
        return {
            ...state,
            searchList: action.payload,
            searchListLoaded: true
        };
    }
    case SEARCH_HOUSES_CLEAR_LIST: {
        return {
            ...state,
            searchList: INIT_STATE.list,
            searchListLoaded: false
        };
    }
    case SEARCH_HOUSES_STATUSES_LOADED: {
        return {
            ...state,
            searchListLoaded: action.payload
        };
    }
    case SET_HOUSES_ERROR: {
        return {
            ...state,
            error: action.payload
        };
    }
    default:
        return state;
    }
};
