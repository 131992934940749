import addErrorHandling from 'util/errors/error.handling';
import Urls from './api.urls';
import instance from './axios.config';
// const instance = axios.create({
//     // Reject only if the status code is greater than or equal to 500
//     // validateStatus: status => status < 500,
// });

const ApiMessagesMethods = {
    createMessage: async (data, access_token) => {
        const response = await instance.post(Urls.createMessage(),
            data,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    getMessageList: async (page, limit, data, access_token) => {
        const response = await instance.get(Urls.getMessageList(page, limit, data),
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    resetUnreadMessages: async (relation_name, relation_id, access_token) => {
        const response = await instance.post(Urls.resetUnreadMessages(),
            { relation_name, relation_id },
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    }
};

export default addErrorHandling(ApiMessagesMethods);
