import addErrorHandling from 'util/errors/error.handling';
import Urls from './api.urls';
import instance from './axios.config';

// const instance = axios.create({
//     // Reject only if the status code is greater than or equal to 500
//     // validateStatus: status => status < 500,
// });

const ApiVotesMethods = {
    getVotesList: async (page, limit, access_token) => {
        const response = await instance.get(Urls.getVotesList(page, limit),
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    getVotesStatuses: async (access_token) => {
        const response = await instance.get(Urls.getVotesStatuses(),
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    getVotesRelations: async (access_token) => {
        const response = await instance.get(Urls.getVotesRelations(),
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    getVoteWithId: async (id, access_token) => {
        const response = await instance.get(Urls.getVoteWithId(id),
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    createVote: async (data, access_token) => {
        const response = await instance.post(Urls.createVote(),
            data,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    editVoteWithId: async (id, data, access_token) => {
        const response = await instance.put(Urls.editVoteWithId(id),
            data,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    changeVoteStatus: async (id, status, access_token) => {
        const response = await instance.post(Urls.changeVoteStatus(id, status),
            {},
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    saveVoteResult: async (vote_id, results, access_token) => {
        const response = await instance.post(Urls.saveVoteResult(),
            { vote_id, results },
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    deleteVoteWithId: async (id, access_token) => {
        const response = await instance.delete(Urls.deleteVoteWithId(id),
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    }
};

export default addErrorHandling(ApiVotesMethods);
