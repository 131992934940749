import {
    INDEBTEDNESS_GET_LIST,
    INDEBTEDNESS_SAVE_LIST,
    INDEBTEDNESS_SET_ERROR
} from 'constants/ActionTypes';

const INIT_STATE = {
    list: {
        data: []
    },
    error: null,
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
    case INDEBTEDNESS_GET_LIST: {
        return {
            ...state,
            loading: true
        };
    }
    case INDEBTEDNESS_SAVE_LIST: {
        return {
            ...state,
            list: action.payload,
            error: null,
            loading: false
        };
    }
    case INDEBTEDNESS_SET_ERROR: {
        return {
            ...state,
            error: action.payload
        }
    }
    default:
        return state;
    }
};
