import React from 'react';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';

const ItemProp = ({
    title = '',
    negativeValue = 0,
    positiveValue = 0,
    modal = false,
    handlePopoverOpen = () => {},
    handlePopoverClose = () => {},
    ...props
}) => {
    if (!title) return <></>;

    return (
        <div {...props}>
            <div className="col-12 col-md-6">
                {modal ? (
                    <Typography
                        style={{ cursor: 'pointer' }}
                        className="d-flex"
                        aria-haspopup="true"
                        onMouseEnter={(event) => { handlePopoverOpen(event); }}
                        onMouseLeave={handlePopoverClose}
                    >
                        {title}
                        <InfoIcon className="ml-1" fontSize="small" />
                        {': '}
                    </Typography>
                ) : (
                    <Typography className="d-flex">
                        {title}
                        {': '}
                    </Typography>
                )}
            </div>

            {negativeValue > 0 ? (
                <>
                    <div className="col-12 col-md-3">
                        <Typography className="d-block" style={{ color: '#e91e63' }} align="right">
                            {`-${negativeValue}`}
                        </Typography>
                    </div>
                    <div className="col-12 col-md-3"> </div>
                </>
            ) : (
                <>
                    <div className="col-12 col-md-3"> </div>
                    <div className="col-12 col-md-3">
                        <Typography className="d-bock" style={{ color: '#4caf50' }} align="right">
                            {positiveValue > 0 ? `+${positiveValue}` : '0'}
                        </Typography>
                    </div>
                </>
            )}
        </div>
    );
};

export default ItemProp;
