import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    TextField, Button, FormGroup, NativeSelect, FormControl, InputLabel, Grid, Select, MenuItem, FormHelperText
} from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import CloseIcon from '@material-ui/icons/Close';
import CardBox from 'components/Card';
import Uploader from 'components/Uploader';
import DeletedItem from 'components/DeletedItem';
import AttachmentView from 'components/AttachmentView';
import * as actions from '../../../actions/Appeal';
import * as actionsAuth from '../../../actions/Auth';
import useStoreProp from '../../Helpers/useStoreProp';

const EditorCard = ({
    data = {},
    handleSendRequest,
    handleClose,
    types,
    maxHeightContent = 0
}) => {
    const dispatch = useDispatch();

    const typeList = useStoreProp(actions.fetchTypes, 'appeal', 'types');
    const { authUser, validator } = useSelector(({ auth }) => auth);

    const personFullName = authUser?.info?.person
        && `${authUser?.info?.person.first_name} ${authUser?.info?.person.middle_name} ${authUser?.info?.person.last_name}`

    const personAddress = authUser?.info?.newAddressList?.length > 0
        && authUser?.info?.newAddressList[0].name

    const [appeal, setAppeale] = useState({
        type: 1,
        address: personAddress || '',
        fio: personFullName || ''
    });

    const onSendClick = () => {
        const newAppeal = {
            ...data,
            ...appeal
        };

        handleSendRequest(newAppeal);
    };

    const handleChangeType = (event) => {
        const { value } = event.target;
        const { rules = [] } = typeList.find((item) => item.value === value) || {};
        const fieldsList = rules.map(({ field }) => field);

        const filteredFields = (state) => fieldsList.reduce((res, key) => {
            if (state.hasOwnProperty(key)) {
                return {
                    ...res,
                    [key]: state[key],
                };
            }
            return res;
        }, {});

        setAppeale((state) => ({
            ...filteredFields(state),
            type: value
        }));

        dispatch(actionsAuth.setValidationFields({}));
    };

    const deleteValidationKey = (key) => {
        const valid = { ...validator };
        if (key) {
            delete valid[key];
            dispatch(actionsAuth.setValidationFields(valid));
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setAppeale((state) => ({
            ...state,
            [name]: value
        }));
        deleteValidationKey(name);
    };

    useEffect(() => {
        if (data) {
            setAppeale({
                ...data,
            });
        }
    }, [data]);

    const onFileUpload = (fieldName) => (url, name, type) => {
        setAppeale((state) => ({
            ...state,
            [fieldName]: [
                ...(state[fieldName] || []),
                { url, name, type }
            ]
        }));
        deleteValidationKey(fieldName);
    };

    const deleteUrl = (fieldName) => (index) => {
        setAppeale((state) => ({
            ...state,
            [fieldName]: [
                ...state[fieldName].slice(0, index),
                ...state[fieldName].slice(index + 1)
            ]
        }));
        deleteValidationKey(fieldName);
    };

    // проверка зависимости поля
    const isValidWith = (withProp, field) => {
        const result = withProp.reduce((res, item) =>
            Object.keys(item).reduce((acc, key) => {
                return acc || (!!appeal[key] && appeal[key] === item[key]);
            }, res)
        , false);

        // было изменено - удалить
        if (!result && appeal[field]) {
            const fields = {
                ...appeal,
            };
            delete fields[field];
            setAppeale(fields);
        }
        return result;
    };

    const renderFields = () => {
        const { rules = [] } = typeList.find((item) => item.value === appeal.type) || {};

        return (
            <>
                {rules.map(({
                    allowed_values = [],
                    field,
                    name,
                    require = false,
                    // rules,
                    type,
                    with: withProp = [],
                }, index) => {
                    if (
                        field === 'type'
                        || (withProp.length > 0 && !isValidWith(withProp, field))
                    ) return null;

                    switch (type) {
                        case 'file':
                            return (
                                <Grid
                                    key={`${field}_${index}`}
                                    container
                                    direction="column"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                    spacing={2}
                                    className="mb-4 pl-2"
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="flex-start"
                                        className="mb-2"
                                    >
                                        {(appeal[field] || []).map((attachment, index) => (
                                            <DeletedItem
                                                key={`${attachment.url}_${index}`}
                                                onDelete={() => deleteUrl(field)(index)}
                                                tooltipTitle="appModule.deleteAttachment"
                                                confirmationText="appModule.confirmDeleteAttachment"
                                            >
                                                <AttachmentView attachment={attachment} isPreview />
                                            </DeletedItem>
                                        ))}
                                    </Grid>
                                    <Uploader onFileUpload={onFileUpload(field)} />
                                    {(!!validator[field] || (require && !appeal[field])) && (
                                        <FormHelperText style={{ color: '#ff0000' }}>
                                            {validator[field] || <IntlMessages id="appModule.mandatory.field" />}
                                        </FormHelperText>
                                    )}
                                </Grid>
                            );

                        case 'select':
                            if (allowed_values.length > 0) {
                                return (
                                    <FormControl key={`${field}_${index}`} variant="outlined" className="mb-4" size="small">
                                        <InputLabel htmlFor="types_list">{name}</InputLabel>
                                        <Select
                                            required={require}
                                            type={type}
                                            label={name}
                                            name={field}
                                            value={appeal[field] || ''}
                                            variant="outlined"
                                            onChange={handleChange}
                                            error={(require && !appeal[field]) || validator[field]}
                                        >
                                            {allowed_values.map(({ value, text }) => <MenuItem value={value} key={value}>{text}</MenuItem>)}
                                        </Select>

                                        {(!!validator[field] || (require && !appeal[field])) && (
                                            <FormHelperText style={{ color: '#ff0000' }}>
                                                {validator[field] || <IntlMessages id="appModule.mandatory.field" />}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                );
                            }
                            return null;

                        default:
                            return (
                                <TextField
                                    key={`${field}_${index}`}
                                    required={require}
                                    type={type}
                                    name={field}
                                    className="mb-4"
                                    label={name}
                                    value={appeal[field] || ''}
                                    onChange={handleChange}
                                    error={(require && !appeal[field]) || validator[field]}
                                    helperText={validator[field] || (require && !appeal[field] ? <IntlMessages id="appModule.mandatory.field" /> : '')}
                                    variant="outlined"
                                    size="small"
                                />
                            );
                    }
                })}
            </>
        );
    };

    return (
        <CardBox
            title={data.title
                ? (
                    <>
                        <IntlMessages id="appModule.editing" />
                        {': '}
                        {data.title}
                    </>
                )
                : (<IntlMessages id="appModule.createAppeal" />)}
            className="bg-primary"
            handleActionsClick={handleClose}
            icon={(
                <CloseIcon />
            )}
            actions={(
                <>
                    <Button onClick={handleClose} variant="contained" className="mr-3">
                        <IntlMessages id="appModule.cancel" />
                    </Button>
                    <Button
                        onClick={onSendClick}
                        // disabled={error}
                        variant="contained"
                        color="primary"
                    >
                        <IntlMessages id="appModule.send" />
                    </Button>
                </>
            )}
            maxHeightContent={maxHeightContent}
        >

            <FormGroup className="pr-3 pl-3 mb-3">
                <FormControl variant="outlined" className="mb-4" size="small">
                    <InputLabel htmlFor="types_list"><IntlMessages id="appeal.typeOfAppeal" /></InputLabel>
                    <Select
                        value={appeal?.type}
                        name="type"
                        onChange={handleChangeType}
                        label={<IntlMessages id="appeal.typeOfAppeal" />}
                        variant="outlined"
                    >
                        {typeList.map(({ value, description }) => <MenuItem value={value} key={value}>{description}</MenuItem>)}
                    </Select>
                </FormControl>

                {renderFields()}

            </FormGroup>

        </CardBox>
    );
};

export default EditorCard;
