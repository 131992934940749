import React, { useEffect, useState } from 'react';
import IntlMessages from 'util/IntlMessages';
import {
    fetchPaymentList,
    paymentListLoaded
} from 'actions/Payments';
import { useDispatch, useSelector } from 'react-redux';
import AccountInfo from 'components/AccountInfo';
import AccordionCard from 'components/Accordion/Card';
import AccordionList from 'components/Accordion/ListWithLang';
import {
    setChangeCurrentUKStatus
} from 'actions/Auth';
import PageHeader from 'components/PageHeader';
import moment from 'moment';
import Pagination from '@material-ui/lab/Pagination';
import MenuItemWithPopover from 'components/MenuItemPopover';
import FilterListIcon from '@material-ui/icons/FilterList';
import PaymentFilter from './Filter';

const PaymentCredited = () => {
    const initialState = {
        limit: 10,
        page: 1,
        filter: {},
    };
    const dispatch = useDispatch();
    const { paymentsList, error } = useSelector(({ payments }) => payments);
    const {
        changeCurrentUK
    } = useSelector(({ auth }) => auth);
    const [limit, setLimit] = useState(initialState.limit);
    const [page, setPage] = useState(initialState.page);
    const [filter, setFilter] = useState(initialState.filter);


    useEffect(() => {
        const resetState = () => {
            setPage(1);
            setLimit(10);
            setFilter({});
        };
        if (changeCurrentUK) {
            resetState();
            dispatch(paymentListLoaded([]));
            dispatch(setChangeCurrentUKStatus());
        } else {
            dispatch(fetchPaymentList(page, limit, filter));
        }
    }, [page, limit, filter, changeCurrentUK, dispatch]);

    const handleChange = (event, value) => {
        setPage(value);
    };

    const buttons = [
        <MenuItemWithPopover buttonTitle="appModule.filter" icon={<FilterListIcon />}>
            {(handleClose) => <PaymentFilter
                currentFilters={filter}
                onChangeFilter={setFilter}
                handleClose={handleClose}
            />}
        </MenuItemWithPopover>,
    ];

    return (
        <>
            <PageHeader
                title={<IntlMessages id="label.client.payments" />}
                buttons={buttons}
            />
            <AccountInfo />
            <AccordionCard
                title={['label.client.payment.type', "appModule.status", 'label.service.total']}
                actions={(
                    <>
                        {paymentsList?.meta?.last_page > 1 && (
                            <Pagination count={paymentsList?.meta?.last_page} page={page} onChange={handleChange} />
                        )}
                    </>
                )}
            >
                {(paymentsList?.data?.length === 0)
                    ? (
                        <div className="h4">
                            <IntlMessages id="appModule.nothingFound" />
                        </div>
                    )
                    : (
                        <AccordionList
                            list={paymentsList?.data}
                            titles={['typeText', 'statusText', 'amount']}
                            labels={{
                                title: 'Вид платежа',
                                // statusText: 'Статус',
                                // serviceText: 'Сервис',
                                createdAt: {
                                    label: 'Дата создания платежа',
                                    format: (value) => moment(value).format('DD.MM.YYYY')
                                },
                                serviceTypeText: 'Тип сервиса',
                                updatedAt: {
                                    label: 'Дата оплаты',
                                    format: (value) => moment(value).format('DD.MM.YYYY')
                                },
                            }}
                        />
                )}
            </AccordionCard>
        </>
    );
};

export default PaymentCredited;
