import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {
    editMenuItem
} from 'actions/Menu';
import {
    LinearProgress
} from '@material-ui/core';
import ActionButton from 'components/ActionButton';
import EditorForm from './EditorForm';

const SiteMenuItem = ({
    item, onHandleDelete = () => {}, moveControls, deleted
}) => {
    const dispatch = useDispatch();
    const {
        loading, menuSaved
    } = useSelector(({ menu }) => menu);
    const [isEditorOpen, setEditorOpen] = useState(false);
    const [loader, setLoader] = useState(false);

    const handleSave = (menu) => {
        setLoader(true);
        dispatch(editMenuItem(menu.id, menu));
        setEditorOpen(false);
    };

    useEffect(() => {
        if (loader) {
            setLoader(loading && !menuSaved);
        }
    }, [loader, loading, menuSaved]);

    return (
        <>
            { loader && <LinearProgress />}
            {isEditorOpen
                ? (
                    <EditorForm
                        data={Object.keys(item).length > 0 && item}
                        handleSendRequest={handleSave}
                        handleClose={() => setEditorOpen(false)}
                    />
                )
                : (
                    <div
                        className="d-flex flex-column flex-md-row justify-content-between align-items-stretch align-items-md-center mb-3"
                        style={{ background: '#f4f4f7' }}
                    >
                        <div className="mb-2 mb-md-0 mr-md-3 w-md-40 pl-2">{item.label}</div>
                        <div className="mb-3 mb-md-0 mr-md-3 flex-grow-1">{item.link}</div>
                        {/* <div className="mb-2">{item.icon}</div> */}
                        <div className="d-flex flex-column flex-md-row">
                            {moveControls}
                            <ActionButton
                                title="appModule.edit"
                                onClick={() => setEditorOpen(true)}
                                className="text-secondary"
                                icon={<EditIcon />}
                            />
                            <ActionButton
                                title="appModule.delete"
                                onClick={onHandleDelete}
                                className="text-danger"
                                icon={<DeleteForeverIcon />}
                            />
                        </div>
                    </div>
                )}
        </>
    );
};

export default SiteMenuItem;
