import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'constants/ActionTypes';

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar
}));

const PageHeader = ({ title = '', buttons = [] }) => {
    const classes = useStyles();
    const [styles, setStyles] = useState({ width: 'calc(100% - 260px)' });
    const { mainScrollbarRef, drawerType } = useSelector(({ settings }) => settings);
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
        ? 'fixed-drawer'
        : drawerType.includes(COLLAPSED_DRAWER)
            ? 'collapsible-drawer'
            : 'mini-drawer';

    const handleChangeDOM = () => {
        const mainScrool = mainScrollbarRef.current;
        setStyles({
            width: '100%',
            maxWidth: mainScrool.clientWidth
        });
    };

    useEffect(() => {
        if (mainScrollbarRef && mainScrollbarRef.current) {
            handleChangeDOM();
        }
    }, [drawerType]);

    useEffect(() => {
        if (mainScrollbarRef && mainScrollbarRef.current) {
            const mainScrool = mainScrollbarRef.current;
            const observerConfig = {
                attributes: false,
                childList: true,
                subtree: true,
                maxWidth: mainScrool?.clientWidth
            };
            const observer = new MutationObserver(handleChangeDOM);
            observer.observe(mainScrollbarRef.current, observerConfig);

            window.addEventListener('resize', handleChangeDOM);
            return () => {
                observer.disconnect();
                window.removeEventListener('resize', handleChangeDOM);
            };
        }
    }, [mainScrollbarRef]);
    return (
        <>
            <div className={`page-heading__wrapper ${drawerStyle}`} style={styles}>
                <div className="page-heading d-flex justify-content-between align-items-center">
                    <h2 className="title mb-0">{title}</h2>
                    <div className="page-heading__buttons">
                        {buttons.map((button, index) => (
                            <React.Fragment key={index}>
                                {button}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
            <div className={classes.offset} />
        </>
    );
};

export default PageHeader;
