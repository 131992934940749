import React, {useEffect, useState} from 'react';
// import IntlMessages from '../../util/IntlMessages';
import {Grid, TextField, Tooltip, Typography} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
// import ActionConfirmation from '../ActionConfirmation';
// import ModalDialog from '../ModalDialog';

const Item = (props) => {
    const {
        data,
        onChangeCount = () => {},
        onDelete = () => {}
    } = props;

    const {
        id,
        product: {
            name,
            images = [],
            cost,
            description = '',
        },
        count: dataCount,
    } = data;

    const [count, setCount] = useState(parseInt(dataCount));

    useEffect(() => {
        if (dataCount !== count) {
            onChangeCount({
                ...data,
                count
            });
        }
    }, [count]);

    // const [isDelete, setIsDelete] = useState(false);

    const image = images[0] || '';

    return (
        <div className="pointer basket-product-item">
            <Grid container spacing={3} alignItems="center" >
                <Grid item xs={12} md={1}>
                    <div className="image">
                        <img src={image} title={name} alt={name} />
                    </div>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">{name}</Typography>
                    <Typography color="secondary">{description}</Typography>
                </Grid>

                <Grid item xs={12} md={2}>
                    <TextField
                        value={count}
                        variant="outlined"
                        size="small"
                        type="number"
                        onChange={({ target: { value } }) => value > 0 && setCount(parseInt(value))}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        helperText={count > 1 ? `${cost} руб./шт` : ''}
                        className="helper-text-absolute"
                    />
                </Grid>

                <Grid item xs={12} md={2}>
                    <Typography variant="subtitle1" align="center">
                        {cost * count} руб.
                    </Typography>
                </Grid>

                <Grid item xs={12} md={1}>
                    <div className="delete">
                        <Tooltip title="Удалить">
                            <IconButton onClick={() => onDelete(id)} className="icon-btn">
                                <DeleteIcon fontSize="small" color="primary" />
                            </IconButton>
                        </Tooltip>
                    </div>
                </Grid>
            </Grid>














            {/*<ModalDialog isOpen={isDelete}>*/}
            {/*    <ActionConfirmation*/}
            {/*        onConfirm={() => {*/}
            {/*            onDelete(data);*/}
            {/*            setIsDelete(false);*/}
            {/*        }}*/}
            {/*        onCancel={() => setIsDelete(false)}*/}
            {/*        methodText="votes.confirmDeleteAnswer"*/}
            {/*    />*/}
            {/*</ModalDialog>*/}
        </div>

    );
};

export default Item;
