import {
    APPEAL_LIST_LOADED_SUCCESS,
    SAVE_CURRENT_APPEAL,
    SET_APPEAL_ERROR,
    APPEAL_STATUSES_LOADED,
    APPEAL_SUCCESSFULLY_SAVED,
    APPEAL_TYPES_LOADED,
    MESSAGE_LIST_LOADED,
    NEW_MESSAGE,
    APPEAL_WITH_ID_LOADED,
    SET_APPEAL_LOADING,
    SET_CURRENT_APPEAL_LOADING,
    APPEAL_SET_WS_DATA,
    NEW_WS_APPEAL,
} from 'constants/ActionTypes';

const INIT_STATE = {
    list: { data: [] },
    currentAppeal: {},
    currentMessages: { data: [] },
    newMessage: false,
    error: false,
    statuses: [],
    types: [],
    appealSaved: false,
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
    case SET_APPEAL_LOADING: {
        return {
            ...state,
            loading: action.payload
        };
    }
    case SET_CURRENT_APPEAL_LOADING: {
        return {
            ...state,
            loadingCurrent: action.payload
        };
    }
    case APPEAL_LIST_LOADED_SUCCESS: {
        return {
            ...state,
            list: action.payload
        };
    }
    case APPEAL_WITH_ID_LOADED: {
        const appealIndex = state.list.data.findIndex((el) => el.id === action.payload.id);
        if (appealIndex < 0) {
            return {
                ...state,
                list: {
                    data: [
                        ...state.list.data,
                        action.payload
                    ]
                }
            };
        }
        return state;
    }
    case SAVE_CURRENT_APPEAL: {
        return {
            ...state,
            currentAppeal: action.payload
        };
    }
    case MESSAGE_LIST_LOADED: {
        return {
            ...state,
            currentMessages: action.payload
        };
    }
    case NEW_MESSAGE: {
        return {
            ...state,
            newMessage: action.payload
        };
    }
    case APPEAL_STATUSES_LOADED: {
        return {
            ...state,
            statuses: action.payload
        };
    }
    case APPEAL_TYPES_LOADED: {
        return {
            ...state,
            types: action.payload
        };
    }
    case SET_APPEAL_ERROR: {
        return {
            ...state,
            error: action.payload
        };
    }
    case APPEAL_SUCCESSFULLY_SAVED: {
        return {
            ...state,
            appealSaved: action.payload
        };
    }
    case APPEAL_SET_WS_DATA: {
        const item = action.payload;
        // console.log(state.list);
        // console.log(item);

        const appealIndex = state.list?.data?.findIndex((el) => el.id === item.model_id);
        // console.log(appealIndex);
        if (appealIndex >= 0) {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: [
                        ...state.list.data.slice(0, appealIndex),
                        {
                            ...item,
                            id: item.model_id
                        },
                        ...state.list.data.slice(appealIndex + 1),
                    ]
                }
            };
        }
        return state;
    }

    case NEW_WS_APPEAL : {
        const item = action.payload;

        const appealIndex = state.list?.data?.findIndex((el) => el.id === item.model_id);
        if (appealIndex >= 0) {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: [
                        ...state.list.data.slice(0, appealIndex),
                        {
                            ...item,
                            id: item.model_id,
                            status: item.status || 0
                        },
                        ...state.list.data.slice(appealIndex + 1),
                    ]
                }
            };
        }

        return {
            ...state,
            list: {
                ...state.list,
                data: [
                    {
                        ...item,
                        id: item.model_id,
                        status: item.status || 0
                    },
                    ...state.list.data
                ]
            }
        };
    }
    default:
        return state;
    }
};
