import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../actions/Notification';

// хук нотификаций
const useNotifications = (props_query = {}) => {
    const dispatch = useDispatch();

    const { authUser } = useSelector(({ auth }) => auth);
    const notifications = useSelector(({ notifications }) => notifications);

    const user_id = authUser?.info?.sub || null;

    return {
        ...notifications,

        // получить список
        loadList: (page = 1, limit = 10) => {
            if (user_id) {
                dispatch(actions.loadListNotification(user_id, page, limit, props_query));
                dispatch(actions.setLastPageNotification(page));
            }
        },

        // получить список по конкретному сервису
        loadServiceList: (page = 1, limit = 10) => {
            if (user_id) {
                dispatch(actions.loadServiceListNotification(user_id, page, limit, props_query));
            }
        },

        // получить количество активных
        loadCountActive: () => {
            if (user_id) {
                dispatch(actions.loadCountActiveNotification(user_id));
            }
        },

        // прочитано
        changeRead: (id) => {
            if (user_id) {
                dispatch(actions.changeReadNotification(user_id, id));
            }
        },

        // прочитано все
        changeReadAll: () => {
            if (user_id) {
                dispatch(actions.changeReadAllNotification(user_id));
            }
        },

        // установить последнюю загруженную страницу
        setLastPage: (page) => {
            dispatch(actions.setLastPageNotification(page));
        },

        clearList: () => {
            dispatch(actions.setLastPageNotification(0));
            dispatch(actions.clearListNotification());
        }

        // // добавить новое уведомление
        // addNew: (data) => {
        //     dispatch(actions.addNewNotification(data));
        // },
    };
};

export default useNotifications;
