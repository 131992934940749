import addErrorHandling from 'util/errors/error.handling';
import Urls from './api.urls';
import instance from './axios.config';
// const instance = axios.create({
//     // Reject only if the status code is greater than or equal to 500
//     // validateStatus: status => status < 500,
// });

const ApiNewsMethods = {
    getNewsList: async (page = 1, limit = 10, data = {}, access_token) => {
        const response = await instance.get(Urls.getNewsList(page, limit),
            {
                params: data,
                headers: {
                    ...(access_token ? { Authorization: `Bearer ${access_token}` } : {})
                }
            });
        return response.data;
    },

    getNewsWithId: async (id, access_token) => {
        const response = await instance.get(Urls.getNewsWithId(id),
            {
                headers: {
                    ...(access_token ? { Authorization: `Bearer ${access_token}` } : {})
                }
            });
        return response.data;
    },

    getStatuses: async (access_token) => {
        const response = await instance.get(Urls.getNewsStatuses(),
            {
                headers: {
                    ...(access_token ? { Authorization: `Bearer ${access_token}` } : {})
                }
            });
        return response.data;
    },

    createNews: async (data, access_token) => {
        const response = await instance.post(Urls.createNews(),
            data,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    publishNews: async (id, access_token) => {
        const response = await instance.post(Urls.publishNewsWithId(id),
            {},
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    editNews: async (id, data, access_token) => {
        const response = await instance.put(Urls.editNewsWithId(id),
            data,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    deleteNews: async (id, access_token) => {
        const response = await instance.delete(Urls.deletetNewsWithId(id),
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    }
};

export default addErrorHandling(ApiNewsMethods);
