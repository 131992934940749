import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import VoteList from 'components/VoteList';
import {
    fetchVotesList,
    setError,
    voteSavedSuccess,
    fetchStatuses,
    changeVoteStatus,
    clearVoteLoadedId,
    createVote,
    editVote,
    fetchVoteWithId,
    saveVoteResult,
    votesListLoadedStatus,
    votesListLoaded
} from 'actions/Votes';
import { setChangeCurrentUKStatus } from 'actions/Auth';
import {
    Button, LinearProgress, Grid, CardActions
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import EditorForm from 'components/VoteList/Editor/EditorForm';
import QuetionList from 'components/VoteList/QuetionList';
import Sticker from 'components/Sticker';
import Hidden from '@material-ui/core/Hidden';
import PagePagination from 'components/PagePagination';
import ActionButton from 'components/ActionButton';
import PageHeader from 'components/PageHeader';

const Votes = ({ match, history }) => {
    const initialState = {
        showCurrent: { id: 0, component: '' },
        currentData: {},
        closeAfterSave: false,
        loadingData: false,
        pageLoading: false,
        saveLoader: false,
        visible: false,
        page: 1,
        limit: 10
    };
    const parentRef = useRef(null);

    const dispatch = useDispatch();
    const { isAdmin, currentUK, changeCurrentUK } = useSelector(({ auth }) => auth);
    const [showCurrent, setShowCurrent] = useState(initialState.showCurrent);
    const [currentData, setCurrentData] = useState(initialState.currentData);
    const [closeAfterSave, setCloseAfterSave] = useState(initialState.closeAfterSave);
    const [loadingData, setLoadingData] = useState(initialState.loadingData);
    const [pageLoading, setPageLoading] = useState(initialState.pageLoading);
    const [saveLoader, setSaveLoader] = useState(initialState.saveLoader);
    const [visible, setVisible] = useState(initialState.visible);
    const [page, setPage] = useState(initialState.page);
    const [limit, setLimit] = useState(initialState.limit);

    const {
        list, listLoaded, error, voteSaved, statuses, loadedId, voteSavedAction
    } = useSelector(({ votes }) => votes);

    const handleShowComponent = (id = 0, component = '') => {
        setVisible(false);
        setCurrentData({});
        setShowCurrent({
            id, component
        });

        if (id) {
            setLoadingData(true);
            dispatch(fetchVoteWithId(id));
        } else {
            setLoadingData(true);
            setTimeout(() => {
                setLoadingData(false);
                setVisible(true);
            }, 100);
        }
    };

    const sendVoteResults = (res) => {
        setCloseAfterSave(false);
        setSaveLoader(true);
        dispatch(saveVoteResult({
            vote_id: showCurrent.id,
            results: res
        }));
    };

    const handleChangePage = (event, index) => {
        setShowCurrent(initialState.showCurrent);
        setVisible(false);
        setCurrentData({});
        setPage(index);
    };

    const handleSendRequest = (data) => {
        setCloseAfterSave(true);
        setSaveLoader(true);
        if (data.id) {
            // edit vote
            dispatch(editVote(data.id, data));
            return;
        }
        // create new vote
        const newVote = {
            ...data,
            uk_name: currentUK.uk_name
        };
        dispatch(createVote(newVote));
    };

    const handleClose = () => {
        setShowCurrent(initialState.showCurrent);
        setVisible(false);
        setCurrentData({});
    };

    useEffect(() => {
        if (error) {
            dispatch(setError(false));
            //history.push('/votes');
        }
    // eslint-disable-next-line react/destructuring-assignment
    }, [error]);

    useEffect(() => {
        if (listLoaded) {
            setPageLoading(false);
            dispatch(votesListLoadedStatus());
        }
    }, [listLoaded]);

    useEffect(() => {
        if (changeCurrentUK) {
            setShowCurrent(initialState.showCurrent);
            setCurrentData(initialState.currentData);
            setCloseAfterSave(initialState.closeAfterSave);
            setLoadingData(initialState.loadingData);
            setPageLoading(initialState.pageLoading);
            setSaveLoader(initialState.saveLoader);
            setVisible(initialState.visible);
            setPage(initialState.page);
            setLimit(initialState.limit);
            dispatch(votesListLoaded({ data: [] }));
            dispatch(setChangeCurrentUKStatus());
        } else {
            setPageLoading(true);
            dispatch(fetchVotesList(page, limit));
        }
    }, [page, limit, changeCurrentUK]);

    useEffect(() => {
        if (voteSaved && closeAfterSave) {
            setCloseAfterSave(false);
            setShowCurrent(initialState.showCurrent);
            setVisible(false);
            setCurrentData({});
        }
    }, [voteSaved]);

    useEffect(() => {
        if (voteSavedAction) {
            setSaveLoader(false);
            dispatch(voteSavedSuccess(false, false));
            if (showCurrent.id) {
                dispatch(fetchVoteWithId(showCurrent.id));
                return;
            }
            dispatch(fetchVotesList(page, limit));
        }
    }, [voteSavedAction]);

    useEffect(() => {
        if (loadedId) {
            if (showCurrent.id) {
                const findData = list.data.find((el) => el.id === showCurrent.id);
                setCurrentData(findData);
            }
            setLoadingData(false);
            setVisible(true);
            dispatch(clearVoteLoadedId());
        }
    }, [loadedId]);

    useEffect(() => {
        dispatch(fetchStatuses());
    }, []);

    return (
        <>
            <PageHeader
                title={<IntlMessages id="sidebar.dashboard.quiz" />}
                buttons={[
                    isAdmin && (
                        <ActionButton
                            title="votes.createVote"
                            onClick={() => {
                                handleShowComponent(0, 'form');
                            }}
                            className="text-secondary"
                            icon={<AddCircleOutlineIcon />}
                        />
                    )
                ]}
            />

            <Grid container className="position-relative" spacing={3}>

                {/* modile form add new vote */}
                <Hidden mdUp>
                    {(showCurrent.id === 0 && (loadingData || visible)) && (
                        <Grid item xs={12} className="position-relative">
                            {loadingData && <LinearProgress className="mb-1" />}
                            {visible && showCurrent.component === 'form' && (
                                <EditorForm
                                    data={currentData}
                                    loader={saveLoader}
                                    handleSendRequest={handleSendRequest}
                                    handleClose={handleClose}
                                />
                            )}
                        </Grid>
                    )}
                </Hidden>

                <Grid item xs={12} md={5} className="position-relative">

                    <VoteList
                        list={list.data}
                        isAdmin={isAdmin}
                        saved={voteSaved}
                        statuses={statuses}
                        activeId={showCurrent.id}
                        showQuestions={(id) => {
                            handleShowComponent(id, 'questions');
                        }}
                        actions={{
                            edit: {
                                func: (id) => {
                                    handleShowComponent(id, 'form');
                                },
                                text: 'appModule.edit',
                                tooltipTitle: 'appModule.edit',
                                className: 'text-secondary',
                                icon: <EditIcon />,
                                disabled: [],
                                admin: true
                            },
                            await: {
                                func: changeVoteStatus,
                                confirmText: 'votes.confirmPause',
                                text: 'votes.toPause',
                                tooltipTitle: 'votes.toPause',
                                className: 'text-info',
                                icon: <AlarmOnIcon />,
                                disabled: [1],
                                status: 1,
                                admin: true
                            },
                            start: {
                                func: changeVoteStatus,
                                confirmText: 'votes.confirmStart',
                                text: 'votes.toStart',
                                tooltipTitle: 'votes.toStart',
                                className: 'text-info',
                                icon: <PlayCircleOutlineIcon />,
                                disabled: [2, 4],
                                status: 2,
                                admin: true
                            },
                            finish: {
                                func: changeVoteStatus,
                                confirmText: 'votes.confirmStop',
                                text: 'votes.stop',
                                tooltipTitle: 'votes.stop',
                                className: 'text-info',
                                icon: <TimerOffIcon />,
                                disabled: [3, 4],
                                status: 3,
                                admin: true
                            },
                            delete: {
                                func: changeVoteStatus,
                                confirmText: 'votes.confirmArchive',
                                text: 'appModule.toArchive',
                                tooltipTitle: 'appModule.toArchive',
                                className: 'text-danger',
                                icon: <DeleteForeverIcon />,
                                disabled: [2, 3, 4],
                                status: 4,
                                admin: true
                            }
                        }}
                        mobile={{
                            loadingData,
                            visible,
                            showCurrent,
                            currentData,
                            saveLoader,
                            handleClose,
                            sendVoteResults,
                            handleSendRequest
                        }}
                    />

                    <PagePagination
                        pageCount={list.meta?.last_page}
                        page={page}
                        loading={pageLoading}
                        handleChangePage={handleChangePage}
                    />
                </Grid>
                <Hidden smDown>
                    <Grid item xs={12} md={7} className="position-relative" ref={parentRef}>
                        {loadingData && (
                            <Sticker>
                                <LinearProgress className="mb-1" />
                            </Sticker>
                        )}
                        {visible && showCurrent.component === 'form' && (
                            <Sticker parentRef={parentRef}>
                                <EditorForm
                                    data={currentData}
                                    loading={saveLoader}
                                    handleSendRequest={handleSendRequest}
                                    handleClose={handleClose}
                                />
                            </Sticker>
                        )}
                        {visible && showCurrent.component === 'questions' && (
                            <Sticker parentRef={parentRef}>
                                <QuetionList
                                    data={currentData}
                                    loading={saveLoader}
                                    onSendQuestions={sendVoteResults}
                                    handleClose={handleClose}
                                    isAdmin={isAdmin}
                                />
                            </Sticker>
                        )}
                    </Grid>
                </Hidden>

            </Grid>
        </>
    );
};

export default Votes;
