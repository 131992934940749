import React, { useState } from 'react';
import { Button, FormGroup } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ModalDialog from 'components/ModalDialog';
import ActionConfirmation from 'components/ActionConfirmation';
import ActionButton from 'components/ActionButton';

const SelectedListItem = ({
    item, onDelete
}) => {
    const { id, address } = item;
    const [isDialogOpen, setDialogOpen] = useState(false);

    return (
        <React.Fragment key={id}>
            <FormGroup className="align-items-center" row>
                <div>{address}</div>
                <ActionButton
                    onClick={() => setDialogOpen(true)}
                    title="votes.deleteAddress"
                    className="text-info"
                    icon={<DeleteIcon fontSize="small"/>}
                />
            </FormGroup>
            <ModalDialog isOpen={isDialogOpen}>
                <ActionConfirmation
                    onConfirm={() => onDelete(id)}
                    onCancel={() => setDialogOpen(false)}
                    methodText="votes.confirmDeleteAddress"
                />
            </ModalDialog>
        </React.Fragment>
    );
};

export default SelectedListItem;
