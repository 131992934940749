import addErrorHandling from 'util/errors/error.handling';
import Urls from './api.urls';
import instance from './axios.config';
// const instance = axios.create({
//     // Reject only if the status code is greater than or equal to 500
//     // validateStatus: status => status < 500,
// });

const ApiNewsMethods = {
    getUKAddressList: async (page = 1, limit = 10, city_name = '', street_name = '', query = '', access_token) => {
        const response = await instance.get(Urls.getUKAddressList(page, limit, city_name, street_name, query),
            {
                headers: {
                    ...(access_token ? { Authorization: `Bearer ${access_token}` } : {})
                }
            });
        return response.data;
    },

    getUKStreetList: async (page = 1, limit = 10, city_name = '', street_name = '', access_token) => {
        const response = await instance.get(Urls.getUKStreetList(page, limit, city_name, street_name),
            {
                headers: {
                    ...(access_token ? { Authorization: `Bearer ${access_token}` } : {})
                }
            });
        return response.data;
    },

    getUKCitiesList: async (page = 1, limit = 10, city_name = '', access_token) => {
        const response = await instance.get(Urls.getUKCitiesList(page, limit, city_name),
            {
                headers: {
                    ...(access_token ? { Authorization: `Bearer ${access_token}` } : {})
                }
            });
        return response.data;
    }
};

export default addErrorHandling(ApiNewsMethods);
