
const fnv32a = (str) => {
    const FNV1_32A_INIT = 0x811c9dc5;
    let hval = FNV1_32A_INIT;
    for (let i = 0; i < str.length; ++i) {
        hval ^= str.charCodeAt(i);
        hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }
    return hval >>> 0;
};

const generateColor = (data) => {
    const shortHash = fnv32a(data.toString()) % 4096;
    const hueValue = (shortHash / 4096) * 300;
    return `hsla(${hueValue}, 80%, 60%, 1)`;
};

export default generateColor;
