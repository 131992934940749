import { all } from 'redux-saga/effects';
import authSagas from './Auth';
import menuSagas from './MenuSagas';
import metersSagas from './MetersSagas';
import newsSagas from './NewsSagas';
import paymentsSagas from './PaymentsSagas';
import articlesSaga from './ArticlesSagas';
import votesSagas from './VotesSagas';
import companySagas from './CompanySagas';
import appealSagas from './AppealSagas';
import messagesSagas from './MessagesSaga';
import debtorsSagas from './DebtorsSagas';
import translateSagas from './TranslateSagas';
import uksagas from './UKSagas';
import marketSaga from './MarketSaga';
import dadataSaga from './DaDataSaga';
import adminSaga from './AdminSagas';
import indebtednessSagas from './IndebtednessSagas';
import orderSaga from './OrderSaga';
import notificationsSagas from './NotificationsSagas';

export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        menuSagas(),
        metersSagas(),
        newsSagas(),
        paymentsSagas(),
        articlesSaga(),
        votesSagas(),
        companySagas(),
        appealSagas(),
        messagesSagas(),
        debtorsSagas(),
        translateSagas(),
        uksagas(),
        marketSaga(),
        dadataSaga(),
        adminSaga(),
        indebtednessSagas(),
        orderSaga(),
        notificationsSagas(),
    ]);
}
