import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import { loadMenu } from 'actions/Menu';
import {
    saveCurrentUK
} from 'actions/Auth';
import MenuIcon from '@material-ui/icons/Menu';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import MenuItemWithPopover from 'components/MenuItemPopover';
import { useIntl } from 'react-intl';
import List from './List';

const UserSwitcher = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const {
        authUser,
        isAdmin,
        isSuperAdmin,
        ukList,
        currentUK
    } = useSelector(({ auth }) => auth);
    const [selectedItem, setSelectedItem] = useState({ key: 0, name: '' });
    const [menuList, setMenuList] = useState([]);

    const handleChange = (data) => {
        if (!isAdmin || isSuperAdmin) {
            const {
                name, id, account_number, uk_name
            } = data;
            setSelectedItem({
                key: id,
                name
            });

            dispatch(saveCurrentUK({
                account_number: isSuperAdmin ? 0 : account_number,
                uk_name,
                id
            }));
        }
        // загружаем заново меню для новой УК
        dispatch(loadMenu());
    };

    useEffect(() => {
        const {scopes = [], newAddressList = []} = authUser?.info;
        if (scopes.includes('user_uk')) { // если это просто юзер и он имеет УК
            setMenuList(newAddressList);
            if (newAddressList.length > 0) {
                const { id, name } = newAddressList?.[0];
                setSelectedItem({
                    key: id,
                    name
                });
            }
        }
    }, [authUser]);

    useEffect(() => {
        if (ukList.length && isAdmin) { // если юзер админ
            const findUk = ukList.find((item) => item.value === currentUK.uk_name);

            if (findUk) {
                setSelectedItem({
                    key: currentUK.uk_name,
                    name: findUk.name
                });
            }
            setMenuList(ukList);
        }
    }, [authUser, isAdmin, ukList, currentUK.uk_name]);

    const selected = selectedItem.key;
    const title = selectedItem.key
        ? selectedItem.name
        : (
            isAdmin
                ? intl.messages['component.userSwitcher.noSelectedUK']
                : intl.messages['component.userSwitcher.noSelectedAddress']
        );
    const buttonTitle = isAdmin ? 'component.userSwitcher.selectUK' : 'component.userSwitcher.selectAnotherAddress';

    return (
        <div className="user-switcher d-flex">
            <div className="user-detail align-self-center">
                {title
                && (
                    <>
                        {'('}
                        {title}
                        {')'}
                    </>
                )}
            </div>

            {/* //todo список адресов юзера */}
            {((!isSuperAdmin && !isAdmin) && menuList.length > 1) && (
                <MenuItemWithPopover
                    //loading={loading}
                    buttonTitle={buttonTitle}
                    icon={<HomeWorkIcon fontSize="small" />}
                    className="switcher-button"
                    classNameButton="text-white"
                >
                    {(handleClose) => (
                        <List
                        data={menuList}
                        selected={selected}
                        handleChange={handleChange}
                        handleClose={handleClose}
                    />
                    )}
                </MenuItemWithPopover>
            )}
            {/* //todo список управляющих компаний */}
            {/*{((isSuperAdmin || !isAdmin) && menuList.length > 1) && (*/}
            {/*    <MenuItemWithPopover*/}
            {/*        //loading={loading}*/}
            {/*        buttonTitle={buttonTitle}*/}
            {/*        icon={<HomeWorkIcon fontSize="small" />}*/}
            {/*        className="switcher-button"*/}
            {/*        classNameButton="text-white"*/}
            {/*    >*/}
            {/*        {(handleClose) => <List*/}
            {/*            data={menuList}*/}
            {/*            selected={selected}*/}
            {/*            handleChange={handleChange}*/}
                        {/* handleClose={handleClose} */}
            {/*        />}*/}
            {/*    </MenuItemWithPopover>*/}
            {/*)}*/}
        </div>
    );
};

export default UserSwitcher;
