import React from 'react';
import {
    Card, CardHeader, CardActions, CardContent
} from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import Hidden from "@material-ui/core/Hidden";

const AccordeonCard = ({ title = [], actions = null, children, className = 'bg-transparent' }) => {
    //col-md-6 col-12
    const header = title.map(((el) => (
        <span key={el} style={document.body.clientWidth > 959 ? { width: `${100 / title.length}%` } : {}}>
            <IntlMessages id={el} />
        </span>
    )));
    return (
        <Card classes={{ root: className }} elevation={0}>
            {(title.length > 0) && (
                <Hidden smDown>
                    <CardHeader
                        title={header}
                        classes={{ title: 'd-flex justify-content-start jr-fs-lg' }}
                        style={{ marginRight: '7rem' }}
                    />
                </Hidden>
            )}
            <CardContent classes={{ root: 'p-0' }}>
                {children}
            </CardContent>
            {actions && (
                <CardActions classes={{ root: 'justify-content-center' }}>
                    {actions}
                </CardActions>
            )}
        </Card>
    );
};

export default AccordeonCard;
