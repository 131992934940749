import React from 'react';
import { useDispatch } from 'react-redux';
import { addToBasket, deleteFromBasket, updateBasket } from 'actions/Market';

const ProductWrapper = ({ children }) => {
    const dispatch = useDispatch();

    const handleAddToBasket = (item) => {
        const newData = {
            ...item,
            product_id: item.id,
            count: 1
        };
        dispatch(addToBasket(newData));
    }

    const handleChangeCount = (id, product_id, count) => {
        if (count === 0) {
            dispatch(deleteFromBasket(id));
        } else {
            dispatch(updateBasket(id, { product_id, count }));
        }
    }
    return (
        <>
        {React.cloneElement(children, {
            onAddToBasket: handleAddToBasket,
            onChangeCount: handleChangeCount,
        })}
        </>
    )
};

export default ProductWrapper;