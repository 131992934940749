import { useState } from 'react';

// debounce для запросов
const useDebounce = (fn, wait = 1000) => {
    const [time, setTime] = useState(null);

    return (props, doFn = true) => {
        if (time) {
            clearTimeout(time);
            !doFn && setTime(null);
        }
        doFn && setTime(setTimeout(() => {
            if (fn) fn(props);
            setTime(null);
        }, wait));
    };
};

export default useDebounce;
