const tagBackgroundWithStatus = (status) => {
    const tagBg = (t) => {
        switch (t) {
        case 1:
            return 'bg-secondary lighten-1';
        case 2:
            return 'bg-danger lighten-3';
        case 3:
            return 'bg-light-blue lighten-1';
        case 4:
            return 'bg-indigo';
        case 5:
            return 'bg-grey lighten-1';
        case 6:
            return 'bg-green lighten-1';
        default:
            return '';
        }
    };

    return (
        tagBg(status)
    );
};

export default tagBackgroundWithStatus;
