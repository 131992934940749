// import axios from 'axios';
import addErrorHandling from 'util/errors/error.handling';
import Urls from './api.urls';
import instance from './axios.config';

// const instance = axios.create({
//     // Reject only if the status code is greater than or equal to 500
//     // validateStatus: status => status < 500,
// });

const ApiAuthMethods = {
    getToken: async (username, password) => {
        const response = await instance.post(Urls.getToken(), {
            username,
            password
        });
        return response.data;
    },

    authKrasinform: async (account_num, password, email) => {
        const response = await instance.post(Urls.authKrasinform(), {
            account_num,
            password,
            email
        });
        return response.data;
    },

    refreshToken: async (refresh_token, access_token) => {
        const response = await instance.post(Urls.refreshToken(),
            { refresh_token },
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    syncToken: async (refresh_token, access_token) => {
        const response = await instance.post(Urls.syncToken(),
            { refresh_token },
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    logout: async (access_token) => {
        const response = await instance.post(Urls.logout(),
            {},
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    logoutAll: async (access_token) => {
        const response = await instance.delete(Urls.logoutAll(),
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    resetPassword: async (username) => {
        const response = await instance.post(Urls.resetPassword(), {
            username
        });
        return response.data;
    },

    signUp: async (user) => {
        const registeredUser = await instance.post(Urls.registerUser(), user);
        return registeredUser.data;
    },

    socnetList: async () => {
        const socnetList = await instance.get(Urls.socnet(), {});
        return socnetList.data;
    },

    getUKList: async () => {
        const response = await instance.get(Urls.getUKList());
        return response.data;
    },

    getUKInfo: async (name) => {
        const response = await instance.get(Urls.getUKInfo(name));
        return response.data;
    }
};

export default addErrorHandling(ApiAuthMethods);
