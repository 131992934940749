import addErrorHandling from 'util/errors/error.handling';
import Urls from './api.urls';
import instance from './axios.config';

// const instance = axios.create({
//     // Reject only if the status code is greater than or equal to 500
//     // validateStatus: status => status < 500,
// });

const ApiDebtorsMethods = {
    getList: async (page, limit, data, access_token) => {
        const response = await instance.get(Urls.getDebtorsList(page, limit),
            {
                params: data,
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },
    getCompaniesList: async (access_token) => {
        const response = await instance.get(Urls.getDebtorsCompaniesList(),
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },
    getUserByUk: async (account_num, access_token) => {
        const response = await instance.get(Urls.getDebtorsUserByUk(account_num),
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },
    sendMessageEmailUser: async (data, access_token) => {
        const response = await instance.post(Urls.sendDebtorsMessageEmailUser(),
            data,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },
    sendMessageTelegramUser: async (data, access_token) => {
        const response = await instance.post(Urls.sendDebtorsMessageTelegramUser(),
            data,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    }
};

export default addErrorHandling(ApiDebtorsMethods);
