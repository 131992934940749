import React from 'react';
import { MenuItem, MenuList } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            '&': {
                color: theme.palette.common.white
            },
            '&:hover': {
                color: theme.palette.common.black
            }
        }
    }
}))(MenuItem);

const List = (props) => {
    const {
        data = [],
        open = false,
        selected = null,
        handleChange = () => {},
        handleClose = () => {},
        nameKey = 'name',
        valueKey = 'id'
    } = props;

    const handleChangeMenu = (item, isSelected) => {
        if (isSelected === false) {
            handleChange(item);
        }
        handleClose();
    };

    return (
        <MenuList autoFocusItem={open}>
            {data.map((item) => {
                const isSelected = item[valueKey] === selected;
                return (
                    <StyledMenuItem
                        key={item.id}
                        selected={isSelected}
                        onClick={() => handleChangeMenu(item, isSelected)}
                    >
                        {item[nameKey]}
                    </StyledMenuItem>
                );
            })}
        </MenuList>
    );
};

export default List;
