import React, { useState } from 'react';
import Radio from '@material-ui/core/Radio';
import IntlMessages from 'util/IntlMessages';
import {
    RadioGroup, FormControlLabel, FormControl, FormLabel, Button, CardActions, Grid
} from '@material-ui/core';
import LineIndicator from 'components/Helpers/LineIndicator';
import generateColor from 'util/RandomColor';
import _ from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import Card from 'components/Card';

const QuestionsList = ({
    data, handleClose = () => {}, onSendQuestions = () => {}, maxHeightContent = 0, loading = false, isAdmin = false
}) => {
    const { questions, results, is_passed, is_sahid_only } = data;
    const [values, setValues] = useState({});
    const saveAnswer = (qid, event) => {
        setValues((state) => ({
            ...state,
            [qid]: parseInt(event.target.value)
        }));
    };

    const onClickSendButton = () => {
        if (Object.keys(values).length > 0) {
            onSendQuestions(values);
        }
    };

    const cardTitle = (isAdmin || is_passed) ? (<IntlMessages id="votes.results" />) : (<IntlMessages id="votes.takePart" />);
    const buttonCloseTitle = (isAdmin || is_passed) ? (<IntlMessages id="appModule.close" />) : (<IntlMessages id="appModule.cancel" />);

    return (
        <Card
            loading={loading}
            maxHeightContent={maxHeightContent}
            title={(
                <>
                    {cardTitle}
                    {': '}
                    {data.title}
                </>
            )}
            className="bg-primary"
            handleActionsClick={handleClose}
            icon={(
                <CloseIcon />
            )}
            actions={(
                <div className="mb-2 pl-2 pr-2">
                    <Button onClick={handleClose} variant="contained">
                        {buttonCloseTitle}
                    </Button>
                    {!(is_passed || isAdmin) && (
                        <Button
                            onClick={onClickSendButton}
                            disabled={Object.keys(values).length === 0 || is_passed}
                            variant="contained"
                            color="primary"
                            className="ml-3"
                        >
                            <IntlMessages id="appModule.send" />
                        </Button>
                    )}
                </div>
            )}
        >

            <Grid
                container
                direction="column"
                alignItems="stretch"
            >
                {(is_passed && !isAdmin) && <h5 className="error"><IntlMessages id="votes.alreadyVoted" /></h5>}
                {questions?.map((question) => (
                    <Grid item key={question.id} classes={{ root: 'mb-2 vote_questions' }}>
                        <FormControl component="fieldset" fullWidth>
                            <FormLabel component="legend">{question.title}</FormLabel>
                            { (is_passed || isAdmin)
                                ? (
                                    <ul className="jr-line-indicator jr-fs-sm">
                                        {question.answers.map((el) => (
                                            <li key={el.title}>
                                                <LineIndicator
                                                    title={el.title}
                                                    color={generateColor(el.title)}
                                                    value={is_sahid_only ? _.get(results, [question.id, el.id, 'percents'], 0) : _.get(results, [question.id, el.id, 'value'], 0)}
                                                    width={_.get(results, [question.id, el.id, 'percents'], 0)}
                                                    isPercent={!!is_sahid_only}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                )
                                : (
                                    <RadioGroup
                                        aria-label={question.title}
                                        name={question.title}
                                        value={values[question.id] || 0}
                                        onChange={(event) => saveAnswer(question.id, event)}
                                    >
                                        {question.answers.map((el) => (
                                            <FormControlLabel
                                                value={el.id}
                                                control={<Radio />}
                                                label={el.title}
                                                key={el.title}
                                                disabled={is_passed}
                                            />))}
                                    </RadioGroup>
                                )}
                        </FormControl>
                    </Grid>)
                )}
                {/*<CardActions disableSpacing classes={{ root: 'justify-content-end' }}>*/}
                {/*    <Button*/}
                {/*        disabled={Object.keys(values).length === 0}*/}
                {/*        onClick={onClickSendButton}*/}
                {/*    >*/}
                {/*        <IntlMessages id="appModule.send" />*/}
                {/*    </Button>*/}
                {/*</CardActions>*/}
            </Grid>
        </Card>
    );
};

export default QuestionsList;
