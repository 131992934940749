import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    TextField,
    FormControlLabel,
    Button,
    LinearProgress,
    Checkbox,
    FormGroup
} from '@material-ui/core/';
import {
    Card, CardHeader, CardFooter, CardBody, CardTitle
} from 'reactstrap';
import IntlMessages from 'util/IntlMessages';
import {
    showAuthLoader,
} from 'actions/Auth';
import Social from 'components/Social';
import AuthHeader from './AuthHeader';

const SignIn = (props) => {
    const {
        logo,
        title,
        rememberMe,
        resetPassword,
        onClickSignIn,
        //buttonSwitcher,
        //krasinformForm
    } = props;

    const [checked, setChecked] = useState(!!rememberMe);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [account, setAccount] = useState('');
    const dispatch = useDispatch();
    const {
        loader, validator
    } = useSelector(({ auth }) => auth);

    const onClickLogin = () => {
        const data = /*krasinformForm
            ? {
                account_num: account,
                password,
                email,
                remember: checked
            }
            :*/ {
                username: email,
                password,
                remember: checked
            };
        onClickSignIn(data);
    };

    return (
        <Card>
            <CardHeader>
                <AuthHeader logo={logo} title={title} />
            </CardHeader>
            <CardBody>
                {loader && <LinearProgress />}
                <CardTitle>
                    <IntlMessages id={/*krasinformForm ? 'sidebar.dashboard.sync.krasinform' :*/ 'appModule.signin'} />
                </CardTitle>
                <FormGroup>
                    {/*{krasinformForm*/}
                    {/*        && (*/}
                    {/*            <TextField*/}
                    {/*                label={<IntlMessages id="label.client.account.number" />}*/}
                    {/*                variant="outlined"*/}
                    {/*                fullWidth*/}
                    {/*                onChange={(event) => setAccount(event.target.value)}*/}
                    {/*                defaultValue={account}*/}
                    {/*                margin="dense"*/}
                    {/*                className="mt-1 my-sm-3"*/}
                    {/*                name="number"*/}
                    {/*                error={!!validator.account_num}*/}
                    {/*                helperText={validator.account_num}*/}
                    {/*            />*/}
                    {/*        )}*/}
                    <TextField
                        label={<IntlMessages id="appModule.email" />}
                        variant="outlined"
                        fullWidth
                        onChange={(event) => setEmail(event.target.value)}
                        defaultValue={email}
                        margin="dense"
                        className="mt-1 my-sm-3"
                        name="email"
                        error={!!validator.username}
                        helperText={validator.username}
                    />

                    <TextField
                        type="password"
                        variant="outlined"
                        label={<IntlMessages id="appModule.password" />}
                        fullWidth
                        onChange={(event) => setPassword(event.target.value)}
                        defaultValue={password}
                        name="password"
                        margin="dense"
                        className="mt-1 my-sm-3"
                        error={!!validator.password}
                        helperText={validator.password}
                    />
                </FormGroup>
                <div
                    className="mb-3 d-flex flex-column flex-sm-row justify-content-center justify-content-sm-between align-items-center align-items-sm-center"
                >
                    {rememberMe
                        && (
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        color="primary"
                                        checked={checked}
                                        onChange={(event) => setChecked(event.target.checked)}
                                    />
                                )}
                                label={<IntlMessages id="appModule.rememberMe" />}
                            />
                        )}

                    {resetPassword
                        && (
                            <Link
                                to="/reset-password"
                                title="Reset Password"
                            >
                                <IntlMessages
                                    id="appModule.forgotPassword"
                                />
                            </Link>
                        )}
                </div>
                <Button
                    onClick={() => {
                        dispatch(showAuthLoader());
                        // dispatch(setValidationFields({}));
                        onClickLogin();
                    }}
                    variant="contained"
                    color="primary"
                    fullWidth
                >
                    <IntlMessages id="appModule.loginToSystem" />
                </Button>
                {/*<div className="mt-3 d-flex flex-column justify-content-center align-items-center">*/}
                {/*    /!*{buttonSwitcher}*!/*/}
                {/*    /!*{!krasinformForm && <Social />}*!/*/}
                {/*    <Social />*/}
                {/*</div>*/}
            </CardBody>
            {/*{!krasinformForm*/}
            {/*    && (*/}
            {/*        <CardFooter className="d-flex justify-content-center align-items-center">*/}
            {/*            <Link to="/signup">*/}
            {/*                <IntlMessages id="signIn.signUp" />*/}
            {/*            </Link>*/}
            {/*        </CardFooter>*/}
            {/*    )}*/}

            <CardFooter className="d-flex justify-content-center align-items-center">
                <Link to="/signup">
                    <IntlMessages id="signIn.signUp" />
                </Link>
            </CardFooter>
        </Card>
    );
};

export default withRouter(SignIn);
