import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import {
    Button,
    LinearProgress
} from '@material-ui/core';
import {
    fetchAccrualsList
} from 'actions/Payments';
import { setChangeCurrentUKStatus } from 'actions/Auth';
import AccountInfo from 'components/AccountInfo';
import AccordionCard from 'components/Accordion/Card';
import AccordionList from 'components/Accordion/ListWithLang';
import PageHeader from 'components/PageHeader';
import moment from 'moment';
import Pagination from '@material-ui/lab/Pagination';
import DateFnsUtils from "@date-io/date-fns";
import {
    DatePicker as DatePickerUI,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import enLocale from "date-fns/locale/en-US";
import ruLocale from "date-fns/locale/ru";

const localeMap = {
    en: enLocale,
    ru: ruLocale
};

const localeCancelLabelMap = {
    en: 'cancel',
    ru: 'отмена'
};

const Accruals = () => {
    const dateFormat = 'YYYY-MM';
    const initialState = {
        limit: 100,
        page: 1,
        date_at: moment(new Date()).format(dateFormat)
    };
    const dispatch = useDispatch();
    const {
        error,
        loading,
        accruals
    } = useSelector(({ payments }) => payments);
    const { changeCurrentUK } = useSelector(({ auth }) => auth);
    const [limit, setLimit] = useState(initialState.limit);
    const [page, setPage] = useState(initialState.page);
    const [date, setDate] = useState(initialState.date_at);

    const handleChange = (event, value) => {
        dispatch(fetchAccrualsList(value, limit, date));
        setPage(value);
    };

    useEffect(() => {
        if (changeCurrentUK) {
            dispatch(setChangeCurrentUKStatus());
        } else {
            dispatch(fetchAccrualsList(1, limit, date));
        }
    }, [changeCurrentUK]);


    if (error) {
        return (
            <>
                <PageHeader
                    title={<IntlMessages id="label.client.payment.accruals" />}
                />
                <h4 className="error">{error}</h4>
            </>
        );
    }

    const handleDateChange = (date) => {
        setDate(moment(date).format(dateFormat));
    };

    const sum = (accruals?.data || []).reduce((result, { sum }) => {
        if (typeof sum === 'number') {
            return result + sum
        }
    }, 0).toFixed(2);

    return (
        <>
            <PageHeader
                title={<IntlMessages id="label.client.payment.accruals" />}
            />
            <AccountInfo />
            {/*{*/}
            {/*    periods.length > 0 && period*/}
            {/*    && (*/}
            {/*        <div className="d-flex align-items-center mb-3 mt-3">*/}
            {/*            <span className="mr-3"><IntlMessages id="label.client.payment.accruals.period" /></span>*/}
            {/*            <Select selectedItem={period} data={periods} onChange={(val) => setPeriod(val)} />*/}
            {/*        </div>*/}
            {/*    )*/}
            {/*}*/}
            <div className="col-md-6 col-12 d-flex align-items-start">
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap['ru']} className="">
                        <DatePickerUI
                            views={['month', 'year']}
                            label="Период"
                            value={date}
                            onChange={(date) => handleDateChange(date)}
                            cancelLabel={localeCancelLabelMap['ru']}
                            format={'LLLL yyyy'}
                            maxDate={initialState.date_at}
                            size="small"
                            inputVariant="outlined"
                            className="mb-3 col-md-9"
                        />
                </MuiPickersUtilsProvider>
                <Button
                    style={{height: 40}}
                    onClick={() => dispatch(fetchAccrualsList(1, limit, date))}
                    color="primary"
                    variant="contained"
                    className="ml-2 col-md-3"
                >
                    Показать
                </Button>
            </div>
            {loading && <LinearProgress />}
            <h3 className="col-md-6 col-12"><strong>Данные за период: </strong>{moment(date).format('MMMM YYYY')}</h3>
            <h4 className="col-md-6 col-12"><strong>Сумма: </strong>{sum}</h4>
            {accruals.data.length > 0
                ? (
                    <>
                        {/*<h3>*/}
                        {/*    <IntlMessages id="label.service.totalForPeriod" />*/}
                        {/*    /!*{': '}*!/*/}
                        {/*    /!*{selectedPeriodTotal['label.service.total']}*!/*/}
                        {/*</h3>*/}

                        <AccordionCard
                            title={['label.service', 'label.client.payment.category', 'label.service.total']}
                            actions={(
                                <>
                                    {accruals.meta?.last_page > 1 && (
                                        <Pagination count={accruals.meta?.last_page} page={page} onChange={handleChange} />
                                    )}
                                </>
                            )}
                        >
                            <AccordionList
                                list={accruals.data}
                                titles={['service', 'type', 'sum']}
                                labels={{
                                    organization: 'Организация',
                                    personal_account: 'Лицевой счет',
                                    // uk_id: 'Управляющая компания',
                                    month_accruals: {
                                        label: 'Месяц начисления',
                                        format: (value) => moment(value).format('MM.YYYY')
                                    },
                                    contract: 'Договор',
                                    type: 'Вид начисления',
                                    date: {
                                        label: 'Дата начисления/оплаты',
                                        format: (value) => moment(value).format('DD.MM.YYYY')
                                    },
                                }}
                            />
                        </AccordionCard>
                    </>
                )
                : (!loading
                    && <div className="col-md-6 col-12">
                         Ничего не найдено
                    </div>
                )
            }
        </>
    );
};

export default Accruals;
