import React from 'react';
import { List, ListItem } from '@material-ui/core';

import IntlMessages from '../../util/IntlMessages';
import NavList from './NavList';

const NavSection = (props) => {
    const { name, icon, children = [] } = props;
    const isExpandable = children && children.length > 0;

    const MenuCollapse = (
        <List component="div" className="nav-header">
            {/* Display an icon if any */}
            {!!icon && (
                <i className="iconify menu-icon" data-icon={`${icon}`} />
                // <i className={`zmdi zmdi-hc-fw  zmdi-${icon}`} />
            )}
            {name.title
                ? <IntlMessages id={name.title} />
                : name
            }
        </List>
    );

    const MenuItemChildren = isExpandable ? (
        <NavList list={children} component="div" />
    ) : null;

    return (
        <div className="nav-section">
            {MenuCollapse}
            {MenuItemChildren}
        </div>
    );
};

export default NavSection;
