import {
    all, call, select, put, takeLatest
} from 'redux-saga/effects';

import {
    CREATE_MESSAGE,
    GET_MESSAGE_LIST,
    RESET_UNREAD_MESSAGES
} from 'constants/ActionTypes';
import {
    messagesLoaded,
    messageFetched
} from 'actions/Appeal';
import { saveUserData } from 'actions/Auth';
import { getActionForError } from 'util/errors';
import api from 'util/api/api.messages.methods';

export const stateSelector = (state) => state.news;
export const stateAuthSelector = (state) => state.auth;

function* createMessageSaga({ payload }) {
    const { data } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.createMessage, data, authUser.access_token);
        yield put(messageFetched(response.success));
    } catch (error) {
        yield put(getActionForError(error));
    }
}

function* getMessageListSaga({ payload }) {
    const { page, limit, data } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.getMessageList, page, limit, data, authUser.access_token);
        yield put(messagesLoaded(response));
    } catch (error) {
        yield put(getActionForError(error));
    }
}

function* resetUnreadMessagesSaga({ payload }) {
    const { relation_name, relation_id } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.resetUnreadMessages, relation_name, relation_id, authUser.access_token);
        yield put(saveUserData(authUser));
    } catch (error) {
        yield put(getActionForError(error));
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(CREATE_MESSAGE, createMessageSaga),
        takeLatest(GET_MESSAGE_LIST, getMessageListSaga),
        takeLatest(RESET_UNREAD_MESSAGES, resetUnreadMessagesSaga)
    ]);
}
