import React from 'react';

import CustomScrollbars from 'util/CustomScrollbars';
import LanguageItem from './LanguageItem';
import languageData from './data';

const LanguageSwitcher = ({ switchLanguage, handleRequestClose }) => (
    <CustomScrollbars className="messages-list language-list scrollbar" style={{ height: 75 }}>
        <ul className="list-unstyled">
            {languageData.map((language, index) => (
                <LanguageItem
                    key={language.languageId}
                    language={language}
                    handleRequestClose={handleRequestClose}
                    switchLanguage={switchLanguage}
                />
            ))}
        </ul>
    </CustomScrollbars>
);

export default LanguageSwitcher;
