import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

const InjectMessage = (props) => {
    const { id, intl } = props;

    if (intl.messages[id]) {
        return <FormattedMessage {...props} />;
    }
    return null;
};

export default injectIntl(InjectMessage, {
    withRef: false
});
