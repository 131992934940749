import {
    HIDE_MESSAGE,
    SHOW_MESSAGE,
    NOTIFICATION_RECIEVED,
    RESET_UNREAD_MESSAGES
} from 'constants/ActionTypes';
import { SET_VALIDATION_FIELDS } from 'constants/ActionTypes/validation';

const INIT_STATE = {
    alertMessage: {},
    showMessage: false,
    unreadMessages: {}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
    case SET_VALIDATION_FIELDS: {
        if (Object.keys(action.payload).length > 0) {
            return {
                ...state,
                alertMessage: action.payload.message,
                showMessage: true
            };
        }
        return {
            ...state,
            alertMessage: {},
            showMessage: false
        };
    }
    case SHOW_MESSAGE: {
        return {
            ...state,
            alertMessage: action.payload,
            showMessage: true
        };
    }
    case HIDE_MESSAGE: {
        return {
            ...state,
            alertMessage: {},
            showMessage: false
        };
    }
    case NOTIFICATION_RECIEVED: {
        return {
            ...state,
            unreadMessages: action.count
        };
    }
    default:
        return state;
    }
};
