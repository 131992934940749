import {
    all, call, select, put, takeLatest
} from 'redux-saga/effects';

import {
    LOAD_HOUSES, SEARCH_HOUSES, SEARCH_HOUSES_IDS
} from 'constants/ActionTypes';
import { housesLoaded, housesSearchLoaded } from 'actions/Company';
import { getActionForError } from 'util/errors';
import api from 'util/api/api.company.methods';

export const stateAuthSelector = (state) => state.auth;

function* getHousesListSaga({ payload }) {
    const { page, limit }  = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.getHousesList, page, limit, authUser.access_token);
        yield put(housesLoaded(response));
    } catch (error) {
        yield put(getActionForError(error));
    }
}

function* searchHousesSaga({ payload }) {
    const { address, page, limit } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.searchHousesByAddress, page, limit, address, authUser.access_token);
        yield put(housesSearchLoaded(response));
    } catch (error) {
        yield put(getActionForError(error));
    }
}

function* searchHousesIdsSaga({ payload }) {
    const { ids }  = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.searchHousesByIds, ids, authUser.access_token);
        yield put(housesSearchLoaded(response));
    } catch (error) {
        yield put(getActionForError(error));
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(LOAD_HOUSES, getHousesListSaga),
        takeLatest(SEARCH_HOUSES, searchHousesSaga),
        takeLatest(SEARCH_HOUSES_IDS, searchHousesIdsSaga),
    ]);
}
