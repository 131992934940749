import React, { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Footer from 'components/Footer';
import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'constants/ActionTypes';
import { setMainScrollbarRef } from 'actions/Setting';
import { isIOS, isMobile } from 'react-device-detect';
import Header from './Header/index';
import SideBar from '../../SideBar';

const Vertical = ({ children }) => {
    const mainContainerRef = useRef(null);
    const dispatch = useDispatch();

    const { drawerType } = useSelector(({ settings }) => settings);

    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
        document.body.classList.add('ios-mobile-view-height');
    } else if (document.body.classList.contains('ios-mobile-view-height')) {
        document.body.classList.remove('ios-mobile-view-height');
    }

    useEffect(() => {
        if (mainContainerRef?.current) {
            dispatch(setMainScrollbarRef(mainContainerRef));
        }
    }, [mainContainerRef]);

    return (
        <div className={`app-container ${drawerStyle}`}>
            <SideBar />
            <div className="app-main-container">
                <div className="app-header">
                    <Header />
                </div>

                <main ref={mainContainerRef} className="app-main-content-wrapper">
                    <div className="app-main-content">
                        {children}
                    </div>
                    <Footer />
                </main>
            </div>
        </div>
    );
};

export default withRouter(Vertical);
