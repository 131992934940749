import React from 'react';

import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Typography
} from '@material-ui/core';

const Pay = (props) => {
    const {
        url
    } = props;

    return (
        <Card elevation={3}>
            <CardHeader
                title={'Поздравляем'}
                className="text-center"
            />
            <CardContent>
                <div className="text-center">
                    <Typography variant="subtitle1" className="mb-4">
                        Ваш заказ успешно оформлен!
                    </Typography>

                    {url ? (
                        <div className="mb-4">
                            <Button
                                variant="contained"
                                size="medium"
                                color="secondary"
                                onClick={() => {
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.click();
                                }}
                            >
                                Оплатить заказ
                            </Button>
                        </div>
                    ) : ''}

                    <Button
                        variant="outlined"
                        size="medium"
                        color="primary"
                        onClick={() => {
                            const link = document.createElement('a');
                            link.href = '/marketplace/orders';
                            link.click();
                        }}
                        className="mb-3"
                    >
                        Перейти к списку заказов
                    </Button>

                    <div>
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            onClick={() => {
                                const link = document.createElement('a');
                                link.href = '/marketplace';
                                link.click();
                            }}
                        >
                            Вернуться в маркетплейс
                        </Button>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default Pay;
