import React, { useState } from 'react';
import {
     Button,
    FormGroup,
    MenuItem,
    TextField,
    LinearProgress
} from '@material-ui/core';
import { getOrderStatuses } from 'actions/Market';
import useStoreProp from '../../../../components/Helpers/useStoreProp';

const OrderStatusForm = (props) => {
    const {
        data = 0,
        onAccept = () => {},
        onClose,
        loading = false
    } = props;
    const [status, setStatus] = useState(data);

    const handleStatus = (e) => {
        setStatus(e.target.value);
    };

    const orderStatuses = useStoreProp(
        getOrderStatuses,
        'market',
        'orderStatuses'
    );

    const handleAccept = () => {
        onAccept(status);
    };

    return (
        <div>
            <div className="mb-3">
                <TextField
                    select
                    label="Статус заказа *"
                    value={status}
                    name="payment_type"
                    onChange={handleStatus}
                    fullWidth={true}
                    variant="outlined"
                    size="small"
                >
                    {Object.keys(orderStatuses).map((paymentKey) => (
                        <MenuItem key={`payment-type-${paymentKey}`} value={paymentKey}>
                            {orderStatuses[paymentKey]}
                        </MenuItem>
                    ))}
                </TextField>
            </div>

            {loading && <LinearProgress className="mb-2" />}

            <FormGroup row className="justify-content-end mt-3 mb-3">
                <Button
                    onClick={handleAccept}
                    variant="contained"
                    color="primary"
                    className="mr-2"
                    disabled={status === data}
                >
                    Применить
                </Button>
                {onClose && (
                    <Button
                        onClick={onClose}
                        variant="contained"
                    >
                        Закрыть
                    </Button>
                )}
            </FormGroup>
        </div>
    );
};

export default OrderStatusForm;
