import React from 'react';
import IntlMessages from 'util/IntlMessages';

const Footer = () => (
    <footer className="app-footer">
        <div className="d-inline-block">
            <span className="d-block">
                <IntlMessages id="appModule.developed" />
                {' '}
                <IntlMessages id="appModule.f2lab.in" />
            </span>
            <span className="d-block">
                УК Флагман
                {' '}
                &copy; 2020-21
            </span>
        </div>

        {/*<div className="d-inline-block">*/}
        {/*    <a href="https://t.me/KvartalOnlinePlatformBot" target="_blank">*/}
        {/*        <i className="iconify  text-grey zmdi-hc-2x" data-icon="fa:telegram" />*/}
        {/*    </a>*/}
        {/*</div>*/}
    </footer>
);
export default Footer;
