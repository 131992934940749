import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    TextField,
    Button,
    LinearProgress,
    FormGroup
} from '@material-ui/core/';
import {
    Card, CardHeader, CardFooter, CardBody, CardTitle
} from 'reactstrap';
import IntlMessages from 'util/IntlMessages';
import {
    setValidationFields
} from 'actions/Auth';
import { hideMessage } from 'actions/Messages';
import {
    checkIsRequired, checkIsEmail, checkPasswordLength, checkIdentical
} from 'util/validation.forms';
import AuthHeader from './AuthHeader';

const SignUp = (props) => {
    const dispatch = useDispatch();
    const {
        loader, validator
    } = useSelector(({ auth }) => auth);
    const [user, setUser] = useState({
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: ''
    });
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [valiadtionError, setValiadtionError] = useState(validator);
    const [isPasswordsIdentical, setPasswordsIdentical] = useState(true);
    const validation  = {
        first_name: [checkIsRequired],
        middle_name: [],
        last_name: [checkIsRequired],
        email: [checkIsRequired, checkIsEmail],
        password: [checkIsRequired, checkPasswordLength],
        password_confirmation: [checkIsRequired, checkPasswordLength]
    };

    const {
        logo,
        title,
        onClickSignUp
    } = props;

    useEffect(() => {
        setValiadtionError(validator);
    }, [validator]);

    useEffect(() => {
        const clearErrors = () => {
            dispatch(setValidationFields({})); // reset validator at first page load
            dispatch(hideMessage()); // reset all error messages
        };
        clearErrors();
        return () => clearErrors();
    }, []);

    const handleChange = (event) => {
        if (buttonDisabled) {
            setButtonDisabled(false);
        }
        event.preventDefault();
        const { name, value } = event.target;
        if (validator[name]) {
            delete validator[name];
            dispatch(setValidationFields(validator));
        }
        setUser((state) => ({ ...state, [name]: value }));
        setPasswordsIdentical(name === 'password_confirmation' && value === user.password);
    };

    const handleBlur = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const validationArray = validation[name].reduce((res, func) => {
            const message = func(value);
            return [...res, ...(message === false ? [] : [message])];
        }, []);
        if (name === 'password_confirmation') {
            const check = checkIdentical(isPasswordsIdentical);
            if (check) validationArray.push(check);
        }
        setValiadtionError((state) => ({ ...state, [name]: validationArray.join('') }));
    };


    return (
        <Card>
            <CardHeader>
                <AuthHeader logo={logo} title={title} />
            </CardHeader>
            <CardBody>
                {loader && <LinearProgress />}
                <CardTitle>
                    <IntlMessages id="appModule.createAccount" />
                </CardTitle>
                <FormGroup>
                    <TextField
                        type="text"
                        label={<IntlMessages id="appModule.firstName" />}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        defaultValue={user.first_name}
                        name="first_name"
                        margin="dense"
                        className="mt-0 mb-3"
                        variant="outlined"
                        required
                        error={!!valiadtionError.first_name}
                        helperText={valiadtionError.first_name}
                    />
                    <TextField
                        type="text"
                        label={<IntlMessages id="appModule.middleName" />}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        defaultValue={user.middle_name}
                        name="middle_name"
                        margin="dense"
                        className="mt-0 mb-3"
                        variant="outlined"
                        error={!!valiadtionError.middle_name}
                        helperText={valiadtionError.middle_name}
                    />
                    <TextField
                        type="text"
                        label={<IntlMessages id="appModule.lastName" />}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        defaultValue={user.last_name}
                        name="last_name"
                        margin="dense"
                        className="mt-0 mb-3"
                        variant="outlined"
                        required
                        error={!!valiadtionError.last_name}
                        helperText={valiadtionError.last_name}
                    />

                    <TextField
                        type="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label={<IntlMessages id="appModule.email" />}
                        fullWidth
                        defaultValue={user.email}
                        name="email"
                        margin="dense"
                        className="mt-0 mb-3"
                        variant="outlined"
                        required
                        error={!!valiadtionError.email}
                        helperText={valiadtionError.email}
                    />

                    <TextField
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label={<IntlMessages id="appModule.password" />}
                        fullWidth
                        defaultValue={user.password}
                        name="password"
                        margin="dense"
                        className="mt-0 mb-4"
                        variant="outlined"
                        error={!!valiadtionError.password}
                        required
                        helperText={valiadtionError.password}
                    />
                    <TextField
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label={<IntlMessages id="appModule.password.repeat" />}
                        fullWidth
                        defaultValue={user.password_confirmation}
                        name="password_confirmation"
                        margin="dense"
                        className="mt-0 mb-4"
                        variant="outlined"
                        error={!!valiadtionError.password_confirmation}
                        required
                        helperText={valiadtionError.password_confirmation}
                    />
                </FormGroup>

                <Button
                    variant="contained"
                    onClick={() => {
                        setButtonDisabled(true);
                        dispatch(setValidationFields({}));
                        onClickSignUp(user);
                    }}
                    color="primary"
                    disabled={buttonDisabled}
                    fullWidth
                >
                    <IntlMessages id="appModule.register" />
                </Button>
            </CardBody>
            <CardFooter className="d-flex justify-content-center align-items-center">
                <Link to="/signin">
                    <IntlMessages id="signUp.alreadyMember" />
                </Link>
            </CardFooter>
        </Card>
    );
};

export default withRouter(SignUp);
