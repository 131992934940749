import React, { useEffect } from 'react';
import {
    Breadcrumbs,
    CardActions,
    CircularProgress,
    Grid,
    LinearProgress
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrdersList, getOrderStatuses } from 'actions/Market';
import Pagination from '@material-ui/lab/Pagination/Pagination';
import CartMini from '../../../components/CartMini';
import PageHeader from '../../../components/PageHeader';
import { NavLink } from 'react-router-dom';
import Order from './Order';

const OrdersPage = ({ match, history }) => {
    const dispatch = useDispatch();
    const limit = 10;
    const { loadingOrdersList, ordersList } = useSelector(({ market }) => market);
    const list = ordersList.data || [];
    const last_page = ordersList.meta?.last_page || 1;
    const page = ordersList.meta?.current_page || 1;
    const total = ordersList?.meta?.total || 0;

    useEffect(() => {
        if (list.length === 0) {
            dispatch(fetchOrdersList(1, limit));
            dispatch(getOrderStatuses());
        }
    }, []);

    const handleChangePage = (event, index) => {
        dispatch(fetchOrdersList(index, limit));
    };

    return (
        <>
            <PageHeader
                title={`Список заказов ${total ? `(всего: ${total})` : ''}`}
                buttons={[
                    <CartMini
                        color="#bc9131"
                        onCreateOrder={() => {
                            history.push('/marketplace/create-order');
                        }}
                    />
                ]}
            />

            <Breadcrumbs aria-label="breadcrumb" className="mb-4">
                <NavLink to={`/marketplace`}>
                    {'<< В маркетплейс'}
                </NavLink>
            </Breadcrumbs>

            {loadingOrdersList && list.length === 0 && <CircularProgress />}

            {loadingOrdersList === false && list.length === 0 ? (
                <div className="mb-1">
                    Список пуст
                </div>
            ) : (
                <div>
                    <Grid container spacing={3} className="mb-3">
                        {list.map((item) => (
                            <Grid item xs={12} md={6} key={`order_${item.id}`}>
                                <Order order={item} />
                            </Grid>
                        ))}
                    </Grid>

                    {loadingOrdersList && list.length > 0 && <LinearProgress />}

                    {last_page > 1 && (
                        <CardActions className="mb-3">
                            <Pagination count={last_page} page={page} onChange={handleChangePage} />
                        </CardActions>
                    )}
                </div>
            )}
        </>
    );
};

export default OrdersPage;
