import {
    FETCH_BASKET,
    BASKET_FETCHED,
    ADD_TO_BASKET,
    UPDATE_BASKET,
    DELETE_FROM_BASKET,
    LOADING_IN_BASKET,
    FETCH_CATEGORY_LIST,
    CATEGORY_LIST_FETCHED,
    ADD_CATEGORY,
    UPDATE_CATEGORY,
    DELETE_CATEGORY,
    LOADING_CATEGORY_LIST,
    FETCH_CATEGORY_TREE,
    CATEGORY_TREE_FETCHED,
    FETCH_PRODUCT_LIST,
    PRODUCT_LIST_FETCHED,
    ADD_PRODUCT,
    UPDATE_PRODUCT,
    DELETE_PRODUCT,
    LOADING_PRODUCT,
    FETCH_ORDER_LIST,
    ORDER_LIST_FETCHED,
    ORDER_LOADING,
    CREATE_ORDER,
    GET_ORDER_STATUSES,
    GET_ORDER_PAYMENT_STATUSES,
    GET_ORDER_PAYMENT_TYPES,
    ORDER_STATUSES_LOADED,
    ORDER_PAYMENT_STATUSES_LOADED,
    ORDER_PAYMENT_TYPES_LOADED,
    ORDER_LOADED,
    FETCH_ORDERS_LIST,
    ORDERS_LIST_FETCHED,
    ORDERS_LIST_LOADING,
    CHANGE_ORDER_STATUS,
    CLOSE_ORDER,
    ORDERS_SAVED,
} from 'constants/ActionTypes/market';


export const fetchBasket = (params) => ({
    type: FETCH_BASKET,
    payload: params
});

export const basketFetched = (data) => ({
    type: BASKET_FETCHED,
    payload: data
})

export const addToBasket = (data) => {
    console.log('data', data);
    return ({
        type: ADD_TO_BASKET,
        payload: data
    });
};

export const updateBasket = (id, params) => ({  // params: { product_id, count }
    type: UPDATE_BASKET,
    payload: { id, params }
});

export const deleteFromBasket = (id) => ({
    type: DELETE_FROM_BASKET,
    payload: id
});

export const loadingInBasket = (bool = false) => ({
    type: LOADING_IN_BASKET,
    payload: bool
});

// категории
export const fetchCategoryList = (params) => ({
    type: FETCH_CATEGORY_LIST,
    payload: params
});

export const categoryListFetched = (data) => ({
    type: CATEGORY_LIST_FETCHED,
    payload: data
});

export const fetchCategoryTree = (params) => ({
    type: FETCH_CATEGORY_TREE,
    payload: params
});

export const categoryTreeFetched = (data) => ({
    type: CATEGORY_TREE_FETCHED,
    payload: data
});

export const addCategory = (data) => {
    console.log('data', data);
    return ({
        type: ADD_CATEGORY,
        payload: data
    });
};

export const updateCategory = (params) => ({
    type: UPDATE_CATEGORY,
    payload: params
});

export const deleteCategory = (id) => ({
    type: DELETE_CATEGORY,
    payload: id
});

export const loadingCategory = (bool = false) => ({
    type: LOADING_CATEGORY_LIST,
    payload: bool
});

// продукты
export const fetchProductList = (params) => ({
    type: FETCH_PRODUCT_LIST,
    payload: params
});

export const productListFetched = (data) => ({
    type: PRODUCT_LIST_FETCHED,
    payload: data
});

export const addProduct = (data) => {
    console.log('data', data);
    return ({
        type: ADD_PRODUCT,
        payload: data
    });
};

export const updateProduct = (params) => ({
    type: UPDATE_PRODUCT,
    payload: params
});

export const deleteProduct = (id) => ({
    type: DELETE_PRODUCT,
    payload: id
});

export const loadingProduct = (bool = false) => ({
    type: LOADING_PRODUCT,
    payload: bool
});

// order
export const fetchOrdertList = (params) => ({
    type: FETCH_ORDER_LIST,
    payload: params
});
export const ordertListFetched = (params) => ({
    type: ORDER_LIST_FETCHED,
    payload: params
});
export const loadingOrder = (params) => ({
    type: ORDER_LOADING,
    payload: params
});
export const createOrder = (params) => ({
    type: CREATE_ORDER,
    payload: params
});
export const orderLoaded = (params) => ({
    type: ORDER_LOADED,
    payload: params
});
export const getOrderStatuses = (params) => ({
    type: GET_ORDER_STATUSES,
    payload: params
});
export const orderStatusesLoaded = (params) => ({
    type: ORDER_STATUSES_LOADED,
    payload: params
});
export const getOrderPaymentStatuses = (params) => ({
    type: GET_ORDER_PAYMENT_STATUSES,
    payload: params
});
export const orderPaymentStatusesLoaded = (params) => ({
    type: ORDER_PAYMENT_STATUSES_LOADED,
    payload: params
});
export const getOrderPaymentTypes = (params) => ({
    type: GET_ORDER_PAYMENT_TYPES,
    payload: params
});
export const orderPaymentTypesLoaded = (params) => ({
    type: ORDER_PAYMENT_TYPES_LOADED,
    payload: params
});


export const fetchOrdersList = (page = 1, limit = 10) => ({
    type: FETCH_ORDERS_LIST,
    payload: { page, limit }
});
export const ordersListFetched = (params) => ({
    type: ORDERS_LIST_FETCHED,
    payload: params
});
export const loadingOrdersList = (params) => ({
    type: ORDERS_LIST_LOADING,
    payload: params
});

export const changeOrderStatus = (id, status) => ({
    type: CHANGE_ORDER_STATUS,
    payload: {
        id,
        status
    }
});
export const closeOrder = (id) => ({
    type: CLOSE_ORDER,
    payload: id
});
export const orderSaved = (bool = false) => ({
    type: ORDERS_SAVED,
    payload: bool
});
