import * as types from 'constants/ActionTypes/order';

export const createOrderZkh = (params) => ({
    type: types.CREATE_ORDER_ZKH,
    payload: params,
});

// создание заказа
export const createdOrderZkh = (data) => ({
    type: types.CREATED_ORDER_ZKH,
    payload: data,
});

export const clearOrderZkh = () => ({
    type: types.CREATED_ORDER_ZKH,
    payload: {},
});

// лоадер
export const setLoading = (bool = false) => ({
    type: types.SET_LOADING_ZKH,
    payload: bool,
});

// проверка заказа
export const checkOrderZkh = (orderId) => ({
    type: types.CHECK_ORDER_ZKH,
    payload: {
        orderId
    },
});

export const checkedOrderZkh = (data) => ({
    type: types.CHECKED_ORDER_ZKH,
    payload: data,
});

export const clearCheckOrderZkh = () => ({
    type: types.CHECKED_ORDER_ZKH,
    payload: '',
});
