import {
    MENU_LOAD_SUCCESS,
    LANDING_MENU_LOAD_SUCCESS,
    MENU_SAVED,
    MENU_SCOPES_LOADED,
    MENU_SCENARIOS_LOADED,
    MENU_LOADING
} from 'constants/ActionTypes';

const INIT_STATE = {
    sideBarMenu: [],
    landingMenu: {
        'user': [],
        'user_uk': [],
        '*': []
    },
    menuSaved: false,
    scopes: [],
    scenarios: [],
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
    case MENU_LOADING: {
        return {
            ...state,
            loading: action.payload
        };
    }
    case MENU_LOAD_SUCCESS: {
        return {
            ...state,
            sideBarMenu: action.payload
        };
    }
    case LANDING_MENU_LOAD_SUCCESS: {
        return {
            ...state,
            landingMenu: {
                ...state.landingMenu,
                [action.payload.scope]: action.payload.menu
            }
        };
    }
    case MENU_SAVED: {
        return {
            ...state,
            menuSaved: action.payload
        };
    }
    case MENU_SCOPES_LOADED: {
        return {
            ...state,
            scopes: action.payload
        };
    }
    case MENU_SCENARIOS_LOADED: {
        return {
            ...state,
            scenarios: action.payload
        };
    }
    default:
        return state;
    }
};
