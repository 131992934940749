import addErrorHandling from 'util/errors/error.handling';
import Urls from './api.urls';
import instance from './axios.config';

// const instance = axios.create({
//     // Reject only if the status code is greater than or equal to 500
//     // validateStatus: status => status < 500,
// });
const ApiCountersMethods = {
    getCounters: async (access_token) => {
        const response = await instance.get(Urls.getCounters(),
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    getCounterInfo: async (access_token, id) => {
        const response = await instance.get(Urls.getCounterInfo(id),
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    sendCounterData: async (access_token, id, value) => {
        const response = await instance.post(Urls.sendCounterData(),
            {
                counters: [{ id, value }]
            },
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    }
};

export default addErrorHandling(ApiCountersMethods);
