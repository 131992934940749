import React, { useState, useEffect } from 'react';
import IntlMessages from 'util/IntlMessages';
import { useSelector } from 'react-redux';
import {
    Divider
} from '@material-ui/core';

const AccountInfo = () => {
    const {
        authUser,
        currentUK
    } = useSelector(({ auth }) => auth);
    const [currentAddress, setCurrentAddress] = useState({});

    useEffect(() => {
        const addresses = authUser?.info?.newAddressList || [];

        const findAddress = addresses.find((address) => currentUK.account_number === address.account_number);
        if (findAddress) {
            setCurrentAddress(findAddress);
        } else {
            setCurrentAddress({});
        }
    }, [authUser, currentUK.account_number]);

    return (
        <>
            {currentAddress.account_number ? (
                <h3>
                    <IntlMessages id="label.client.account.number" />
                    {': '}
                    {currentAddress.account_number}
                </h3>
            ) : null}
            {currentAddress.address ? (
                <h4>
                    <IntlMessages id="label.client.address" />
                    {': '}
                    {currentAddress.address}
                </h4>
            ) : null}
            {currentAddress.account_number || currentAddress.address ? (
                <Divider className="mt-3 mb-3" />
            ) : null}
        </>
    );
};

export default AccountInfo;
