import {
    BASKET_FETCHED,
    LOADING_IN_BASKET,
    CATEGORY_LIST_FETCHED,
    LOADING_CATEGORY_LIST,
    PRODUCT_LIST_FETCHED,
    LOADING_PRODUCT,
    ORDER_LIST_FETCHED,
    ORDER_LOADING,
    ORDER_STATUSES_LOADED,
    ORDER_PAYMENT_STATUSES_LOADED,
    ORDER_PAYMENT_TYPES_LOADED,
    ORDER_LOADED,
    CATEGORY_TREE_FETCHED,
    ORDERS_LIST_FETCHED,
    ORDERS_LIST_LOADING,
    ORDERS_SAVED,
} from 'constants/ActionTypes/market';

const INIT_STATE = {
    categories: [],
    categoryTree: [],
    products: [],
    basket: {},
    orders: [],
    loadingBasket: false,
    loadingCategory: false,
    loadingProduct: false,
    loadingOrders: false,
    orderStatuses: [],
    paymentStatuses: [],
    paymentTypes: {
        // 1: 'Онлайн картой',
        // 2: 'Наличка'
    },
    order: {},
    ordersList: {},
    loadingOrdersList: false,
    saved: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
    case BASKET_FETCHED: {
        return {
            ...state,
            basket: action.payload
        };
    }
    case LOADING_IN_BASKET: {
        return {
            ...state,
            loadingBasket: action.payload
        };
    }
    case CATEGORY_LIST_FETCHED: {
        return {
            ...state,
            categories: action.payload
        };
    }
    case CATEGORY_TREE_FETCHED: {
        return {
            ...state,
            categoryTree: action.payload
        };
    }
    case LOADING_CATEGORY_LIST: {
        return {
            ...state,
            loadingCategory: action.payload
        };
    }
    case PRODUCT_LIST_FETCHED: {
        return {
            ...state,
            products: action.payload
        }
    }
    case LOADING_PRODUCT: {
        return {
            ...state,
            loadingProduct: action.payload
        };
    }
    case ORDER_LIST_FETCHED: {
        return {
            ...state,
            orders: action.payload
        };
    }
    case ORDER_LOADING: {
        return {
            ...state,
            loadingOrders: action.payload
        };
    }
    case ORDER_STATUSES_LOADED: {
        return {
            ...state,
            orderStatuses: action.payload
        };
    }
    case ORDER_PAYMENT_STATUSES_LOADED: {
        return {
            ...state,
            paymentStatuses: action.payload
        };
    }
    case ORDER_PAYMENT_TYPES_LOADED: {
        return {
            ...state,
            paymentTypes: action.payload
        };
    }
    case ORDER_LOADED: {
        return {
            ...state,
            order: action.payload
        };
    }
    case ORDERS_LIST_FETCHED: {
        return {
            ...state,
            ordersList: action.payload
        };
    }
    case ORDERS_LIST_LOADING: {
        return {
            ...state,
            loadingOrdersList: action.payload
        };
    }
    case ORDERS_SAVED: {
        return {
            ...state,
            saved: action.payload
        };
    }
    default:
        return state;
    }
};
