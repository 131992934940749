import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// получить поле из стора
const useStoreProp = (
    // запрос на получение данных
    fetchAction,
    // раздел стора
    storeName,
    // нужное поле
    storeProp
) => {
    const dispatch = useDispatch();
    const store = useSelector((store) => store[storeName]);
    const storeField = store[storeProp];
    const [data, setData] = useState(storeField);

    useEffect(() => {
        if ((Array.isArray(storeField) &&  storeField.length === 0)
            || (typeof storeField === 'object' && Object.keys(storeField).length === 0)) {
            dispatch(fetchAction());
        } else {
            setData(storeField);
        }
    }, [storeField, dispatch, fetchAction]);

    return data;
};

export default useStoreProp;
