import * as types from '../constants/Ws';

const initialState = {
    // каналы
    channels: [],
    connected: false
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        case types.CONNECTED: {
            return {
                ...state,
                connected: payload
            };
        }

        case types.DISCONNECTED: {
            return {
                ...initialState
            };
        }

        case types.ADDED_LISTENER: {
            const isAdded = state.channels.includes(payload);
            return {
                ...state,
                channels: isAdded
                    ? [ ...state.channels ]
                    : [
                        ...state.channels,
                        payload
                    ]
            };
        }

        case types.REMOVED_LISTENER: {
            const findIndex = state.channels.findIndex((item) => item === payload);
            if (findIndex >= 0) {
                return {
                    ...state,
                    channels: [
                        ...state.channels.slice(0, findIndex),
                        ...state.channels.slice(findIndex + 1)
                    ]
                };
            }
            return state;
        }

        default:
            return state;
    }
}
