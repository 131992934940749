import React from 'react';
import {
    Grid
} from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import cyrillicToTranslit from 'cyrillic-to-translit-js';
import CompanyList from './CompanyList';

const DebtorsList = ({ list, dataLoading = false, ...props }) => {
    if (!Object.keys(list).length && !dataLoading) {
        return (
            <Grid item>
                <div className="h4">
                    <IntlMessages id="appModule.nothingFound" />
                </div>
            </Grid>
        );
    }

    return (
        <>
            {Object.keys(list).map((key) => {
                const keyProp = cyrillicToTranslit().transform(key, '_');
                return (
                    <CompanyList
                        key={keyProp}
                        title={key}
                        data={list[key]}
                        {...props}
                    />
                );
            })}
        </>
    );
};

export default DebtorsList;
