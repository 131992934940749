import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import {
    LinearProgress, Accordion, AccordionDetails, AccordionSummary
} from '@material-ui/core';
import {
    setChangeCurrentUKStatus
} from 'actions/Auth';
import {
    landingMenuLoaded,
    fetchScopes
} from 'actions/Menu';
import PageHeader from 'components/PageHeader';
import useScopes from 'components/Helpers/useScopes';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SiteMenuList from './MenuList';
import useMenuList from 'components/Helpers/useMenuList';

const SiteMenu = () => {
    const initialState = {
        currentScope: null
    };
    const dispatch = useDispatch();
    const {
        loading
    } = useSelector(({ menu }) => menu);
    const { changeCurrentUK, currentUK } = useSelector(({ auth }) => auth);
    const [currentScope, setCurrentScope] = useState(initialState.currentScope);
    const menuList = useMenuList(currentScope);

    const scopes = useScopes(fetchScopes, 'menu', 'landing');
    scopes.sort((a, b) => {
        if (a.value < b.value) {
            return -1;
        }
        if (a.value > b.value) {
            return 1;
        }
        return 0;
    });

    useEffect(() => {
        if (changeCurrentUK) {
            setCurrentScope(initialState.currentScope);
            dispatch(landingMenuLoaded([]));
            dispatch(setChangeCurrentUKStatus());
        }
    }, [changeCurrentUK, dispatch, initialState.currentScope]);

    const onScopeChange = (event, expanded, scope) => {
        const newScope = scope === currentScope ? null : scope;
        setCurrentScope(newScope);
    };

    if (currentUK.uk_name === 'public') {
        return (
            <>
                <PageHeader
                    title={<IntlMessages id="menu.siteMenu" />}
                />
                <IntlMessages id="component.userSwitcher.noSelectedUK" />
            </>
        );
    }

    return (
        <>
            <PageHeader
                title={<IntlMessages id="menu.siteMenu" />}
            />
            {scopes.length > 0
                ? scopes.map((scope) => (
                    <Accordion
                        key={scope.value}
                        expanded={currentScope === scope.value}
                        onChange={(event, expanded) => onScopeChange(event, expanded, scope.value)}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ content: 'text-info' }}>
                            {scope.description}
                        </AccordionSummary>
                        <AccordionDetails classes={{ root: 'border-top flex-column' }}>
                            <SiteMenuList menuList={menuList[scope.value]} currentScope={currentScope} />
                        </AccordionDetails>
                    </Accordion>
                ))
                : loading
                    ? <LinearProgress />
                    : (
                        <div className="h4">
                            <IntlMessages id="appModule.nothingFound" />
                        </div>
                    )}
        </>
    );
};

export default SiteMenu;
