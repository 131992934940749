import {
    ADDRESS_LIST_DADATA_LOADED,
    DADATA_LOADING,
    FETCH_ADDRESS_LIST_DADATA
} from 'constants/ActionTypes/dadata';


export const fetchAddressList = (query, limit = 10) => ({
    type: FETCH_ADDRESS_LIST_DADATA,
    payload: { query, limit }
});

export const addressListLoaded = (data = []) => ({
    type: ADDRESS_LIST_DADATA_LOADED,
    payload: data
});

export const loadingDaData = (bool) => ({
    type: DADATA_LOADING,
    payload: bool
});
