import React, {useEffect, useState} from 'react';
// import IntlMessages from '../../util/IntlMessages';
import {TextField, Tooltip} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
// import ActionConfirmation from '../ActionConfirmation';
// import ModalDialog from '../ModalDialog';

const Item = (props) => {
    const {
        data,
        onChangeCount = () => {},
        onDelete = () => {}
    } = props;
    const {
        id,
        product: {
            name,
            images = [],
            cost
        },
        count,
        product_id,
    } = data;
    const [dataCount, setDataCount] = useState(count);

    const onCountChange = (e) => {
        const { value } = e.target;
        if (value > 0) {
            setDataCount(parseInt(value));
            onChangeCount({id, product_id, count: parseInt(value)})
        }
    }

    // useEffect(() => {
    //     if (dataCount !== count) {
    //         onChangeCount({
    //             ...data,
    //             count
    //         });
    //     }
    // }, [count, dataCount, data, onChangeCount]);

    // const [isDelete, setIsDelete] = useState(false);

    const image = images[0] || '';

    return (
        <div className="pointer d-flex justify-content-between align-items-center cart-mini-item">
            <div className="image">
                <img src={image} title={name} alt={name} />
            </div>

            <div className="name">
                {name}
            </div>

            <div className="count">
                <TextField
                    value={dataCount}
                    type="number"
                    onChange={onCountChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    size="small"
                    variant="outlined"
                />
            </div>

            <div className="price text-center">
                {cost * count}₽
            </div>

            <div className="delete">
                <Tooltip title="Удалить">
                    <IconButton onClick={() => onDelete(id)} className="icon-btn">
                        <DeleteIcon fontSize="small" color="primary" />
                    </IconButton>
                </Tooltip>
            </div>

            {/*<ModalDialog isOpen={isDelete}>*/}
            {/*    <ActionConfirmation*/}
            {/*        onConfirm={() => {*/}
            {/*            onDelete(data);*/}
            {/*            setIsDelete(false);*/}
            {/*        }}*/}
            {/*        onCancel={() => setIsDelete(false)}*/}
            {/*        methodText="votes.confirmDeleteAnswer"*/}
            {/*    />*/}
            {/*</ModalDialog>*/}
        </div>

    );
};

export default Item;
