import React, { useEffect, useState } from 'react';
import {
    Select, FormControl, InputLabel, Button, FormGroup, TextField
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import CardBox from 'components/Card';
import { useIntl } from 'react-intl';
import useStatuses from 'components/Helpers/useStatuses';
import {
    fetchPaymentStatuses
} from 'actions/Payments';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';

const localeCancelLabelMap = {
    en: 'cancel',
    ru: 'отмена'
};

const localeMap = {
    en: enLocale,
    ru: ruLocale
};

const PaymentFilter = ({
    currentFilters = {},
    handleClose = () => {},
    onChangeFilter = () => {}
}) => {
    const initialDataFilter = {
        amount_start: '',
        amount_end: '',
        date_start: null,
        date_end: null,
        status: '',
    };
    const {
        locale
    } = useSelector(({ settings }) => settings);
    const intl = useIntl();
    const [disabled, setDisabled] = useState(true);
    const [dataFilter, setDataFilter] = useState({ ...initialDataFilter, ...currentFilters });

    const statuses = useStatuses(fetchPaymentStatuses, 'payments');

    const dataWithoutEmpty = (data) => (Object.keys(data).reduce((res, key) => {
        if (data[key] !== '') {
            res[key] = data[key];
        }
        return res;
    }, {}));

    const handleFilterSubmit = () => {
        const newData = dataWithoutEmpty(dataFilter);
        setDisabled(true);
        onChangeFilter(newData);
        handleClose();
    };

    const handleFilterReset = () => {
        setDataFilter(initialDataFilter);
        setDisabled(true);
        onChangeFilter({});
    };

    const handleFilterChange = (event) => {
        const { value, name } = event.target;
        setDataFilter({
            ...dataFilter,
            [name]: value
        });
        setDisabled(false);
    };

    const onChangeDate = (name, value) => {
        setDataFilter({
            ...dataFilter,
            [name]: moment(value).format()
        });
        setDisabled(false);
    };

    return (
        <CardBox
            title={<IntlMessages id="appModule.filter" />}
            actions={(
                <>
                    <Button
                        onClick={handleFilterReset}
                        variant="contained"
                        className="mr-3"
                        disabled={Object.keys(dataWithoutEmpty(dataFilter)).length === 0}
                    >
                        <IntlMessages id="filter.resetFilter" />
                    </Button>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        className="mr-3"
                    >
                        <IntlMessages id="appModule.close" />
                    </Button>

                    <Button
                        onClick={handleFilterSubmit}
                        variant="contained"
                        color="secondary"
                        disabled={disabled}
                    >
                        <IntlMessages id="appModule.apply" />
                    </Button>
                </>
            )}
        >
            <div className="d-flex flex-column">
                <h4>Период</h4>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale.locale]}>
                    <div className="d-flex mb-3">
                        <FormGroup>
                            <DateTimePicker
                                ampm={false}
                                label="Начало периода"
                                // label={<IntlMessages id="news.publishedAt" />}
                                value={dataFilter.date_start}
                                cancelLabel={localeCancelLabelMap[locale.locale]}
                                onChange={(date) => onChangeDate('date_start', date)}
                                format="dd.MM.yyyy HH:mm"
                                // size="small"
                                inputVariant="outlined"
                                size='small'
                            />
                        </FormGroup>
                        <FormGroup>
                            <DateTimePicker
                                ampm={false}
                                label="Конец периода"
                                // label={<IntlMessages id="news.publishedAt" />}
                                value={dataFilter.date_end}
                                cancelLabel={localeCancelLabelMap[locale.locale]}
                                onChange={(date) => onChangeDate('date_end', date)}
                                format="dd.MM.yyyy HH:mm"
                                // size="small"
                                inputVariant="outlined"
                                size='small'
                            />
                        </FormGroup>
                    </div>
                </MuiPickersUtilsProvider>
                <h4>Диапазон сумм</h4>
                <div className="d-flex mb-3">
                    <FormControl variant="outlined" size='small'>
                        <TextField
                            value={dataFilter.amount_start}
                            name="amount_start"
                            onChange={handleFilterChange}
                            variant="outlined"
                            size="small"
                            label="Минимум"
                        />
                    </FormControl>
                    <FormControl variant="outlined" size='small'>
                        <TextField
                            value={dataFilter.amount_end}
                            name="amount_end"
                            onChange={handleFilterChange}
                            variant="outlined"
                            size="small"
                            label="Максимум"
                        />
                    </FormControl>
                </div>
                <FormControl variant="outlined" className="mb-3" size='small'>
                    <InputLabel shrink htmlFor="statises_list"><IntlMessages id="appModule.status" /></InputLabel>
                    <Select
                        notched
                        native
                        value={dataFilter.status}
                        onChange={handleFilterChange}
                        inputProps={{
                            name: 'status',
                            id: 'statises_list'
                        }}
                        label={<IntlMessages id="appModule.status" />}
                    >
                        <option value="" key="empty-type">{intl.messages['appModule.status']}</option>
                        {statuses?.map((t) => (<option value={t.value} key={t.key}>{t.description}</option>))}
                    </Select>
                </FormControl>
            </div>
        </CardBox>
    );
};

export default PaymentFilter;
