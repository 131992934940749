import React from 'react';
import ListItem from './ListItem';
import IntlMessages from 'util/IntlMessages';

const List = ({ list = {data:[]}, loader = false, selected, ...props }) => {
        if (!list.data?.length && !loader){
            return (
                <div>
                     <IntlMessages id="votes.addressNoFound" />
                </div>
            );
        }

        return (
            list.data?.map((item) => (
                <ListItem
                    key={item.external_id}
                    data={item}
                    disabled={!!selected.find((element) => element.id === item.external_id)}
                    {...props}
                />))
        );
};

export default List;
