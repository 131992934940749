import React from 'react';

const AuthHeader = ({ logo, title, className = 'logo-lg' }) => (
    <a className={className} href="https://uk-flagman.com/" title={title}>
        {logo}
        <span className="company_name">{title}</span>
    </a>
);

export default AuthHeader;
