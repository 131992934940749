import React, { useEffect, useState } from 'react';
import IntlMessages from 'util/IntlMessages';
import { fetchList } from 'actions/Indebtedness';
import { useDispatch, useSelector } from 'react-redux';
import AccordionCard from 'components/Accordion/Card';
import AccordionList from 'components/Accordion/ListWithLang';
import {
    setChangeCurrentUKStatus
} from 'actions/Auth';
import PageHeader from 'components/PageHeader';
import Pagination from '@material-ui/lab/Pagination';
import AccountInfo from 'components/AccountInfo';
import { Button, FormGroup, LinearProgress} from '@material-ui/core';
import { showMessage } from 'actions/Messages';
import orderTypes from '../../../components/Helpers/order/const';
import {
    goToUrl,
} from '../../../components/Helpers/order/functions';
import useCreateOrder from '../../../components/Helpers/order/useCreateOrder';
import ModalDialog from "../../../components/ModalDialog";
import PayForm from './Form';

const PaymentCredited = () => {
    const initialState = {
        limit: 10,
        page: 1
    };
    const dispatch = useDispatch();

    // хук запроса создания заказа для задолжностей
    const createOrderZkh = useCreateOrder(orderTypes.indebtedness);
    const { order, clear: clearOrder } = createOrderZkh;

    const {
        list,
        loading,
    } = useSelector(({ indebtedness }) => indebtedness);

    const {
        loading: orderLoading,
    } = useSelector(({ order }) => order);

    const {
        changeCurrentUK
    } = useSelector(({ auth }) => auth);
    const [limit, setLimit] = useState(initialState.limit);
    const [page, setPage] = useState(initialState.page);

    const [payModal, setPayModal] = useState(false);
    const [dataModal, setDataModal] = useState({});

    const resetState = () => {
        setPage(initialState.page);
        setLimit(initialState.limit);
    };

    useEffect(() => {
        if (changeCurrentUK) {
            resetState();
            dispatch(setChangeCurrentUKStatus());
        } else {
            dispatch(fetchList(page, limit));
        }
    }, [page, limit, changeCurrentUK]);

    const handleChange = (event, value) => {
        setPage(value);
    };

    useEffect(() => {
        if (Object.keys(order).length > 0) {
            const payment_link = order?.payment_link;
            if (payment_link) {
                goToUrl(payment_link);
            } else {
                dispatch(showMessage(
                    'error',
                    "Сервер временно не доступен! Попробуйте позднее.",
                    null,
                    false
                ));
            }
            setDataModal({});
            setPayModal(false);
            clearOrder();
        }
    }, [order]);

    const handleClosePayModal = () => {
        setPayModal(false);
    };

    const handlePayModal = (sum) => {
        const {
            id,
            period_at,
        } = dataModal;

        createOrderZkh({
            cost: sum,
            comment: `Оплата задолжности по номеру "${id}" за период "${period_at}"`,
            // comment: id ? `Оплата задолжности по номеру "${id}" за период "${period_at}"` : "Внесение аванса",
        });
    };

    const handleCancelModal = () => {
        setPayModal({});
        setPayModal(false);
    };

    return (
        <>
            <ModalDialog
                isOpen={payModal}
                handleClose={handleClosePayModal}
                titleText={`Оплатить сумму задолженности`}
            >
                <PayForm
                    currentCost={dataModal.sum || 0}
                    onSubmit={handlePayModal}
                    onCancel={handleCancelModal}
                    loading={orderLoading}
                />
            </ModalDialog>

            <PageHeader
                title="Задолженности"
                // title={<IntlMessages id="label.client.payments" />}
            />
            <AccountInfo />

            {loading && <LinearProgress />}

            {(list?.data?.length === 0) ? (
                <>
                    <div className="h4">
                        <IntlMessages id="appModule.nothingFound" />
                    </div>
                    {/* <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={(e) => {
                            setPayModal(true);
                        }}
                        style={{alignSelf: 'self-start'}}
                    >
                        Внести аванс
                    </Button> */}
                </>
            ) : (
                <AccordionCard
                    title={['debtors.label.period_at', 'appModule.status']}
                    actions={(
                        <>
                            {list.meta?.last_page > 1 && (
                                <Pagination count={list.meta?.last_page} page={page} onChange={handleChange} />
                            )}
                        </>
                    )}
                >
                    <AccordionList
                        defaultExpanded={true}
                        list={list.data}
                        titles={['period_at', 'status']}
                        labels={{
                            sum: {
                                label: 'Cумма',
                                format: (amount) => Number.parseFloat(amount).toFixed(2)
                            },
                            uk_id: 'Идентификатор УК'
                        }}
                        button={{
                            title: "Оплатить задолженность",
                            onClick: (e, item) => {
                                setPayModal(true);
                                setDataModal(item);
                            },
                            // disabled: orderLoading
                        }}
                    />
                </AccordionCard>
            )}
        </>
    );
};

export default PaymentCredited;
