import React, { useState } from 'react';
import IntlMessages from 'util/IntlMessages';
import Widget from 'components/Widget';
import ClientCardItem from './item';

const ClientCard = ({ userData }) => {
    const [keyCopy, setKeyCopy] = useState(null);
    return (
        <Widget
            styleName="jr-card-profile"
            title={!!userData.title.txt ? (
                <>
                    <IntlMessages id={userData.title.key} />
                    <span className="ml-2 font-weight-bold">{userData.title.txt}</span>
                </>
            ) : null}
        >
            {userData.items.map((item, index) => (
                <React.Fragment key={item.key}>
                    {(item.txt !== undefined || item.text !== null) && (
                        <ClientCardItem
                            item={item}
                            copied={item.key === keyCopy}
                            onCopy={(key) => setKeyCopy(key)}
                        />
                    )}
                </React.Fragment>
            ))}
        </Widget>
    );
}
export default ClientCard;
