import React from 'react';
import IntlMessages from 'util/IntlMessages';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Grid } from '@material-ui/core/';
import CheckIcon from '@material-ui/icons/Check';

const
    ClientCardItem = ({ item, copied = false, onCopy = () => {} }) => (
        <div className="media align-items-center flex-nowrap jr-pro-contact-list">
            <div className="mr-3">
                <i className="iconify text-grey zmdi-hc-2x" data-icon={item.ico} />
            </div>
            <div className="media-body">
                <span className="mb-0 text-grey jr-fs-sm">
                    <IntlMessages id={item.key} />
                </span>
                <p className="mb-0">
                    <span className={`word-break-all${item.type === 'link' ? ' jr-link' : ''}${!!item.copy ? ' d-inline-block mr-3 mb-2' : ''}`}>{item.txt}</span>
                    {!!item.copy && (
                        <CopyToClipboard
                            text={item.txt}
                            onCopy={() => onCopy(item.key)}
                        >
                            <Button
                                disabled={copied}
                                size="small"
                                variant="contained"
                                color="secondary"
                            >
                                {copied === true && <CheckIcon color="secondary" />}
                                <IntlMessages id={copied === true ? 'appModule.copied' : 'appModule.copy'} />
                            </Button>
                        </CopyToClipboard>
                    )}
                </p>
            </div>
        </div>
    );
export default ClientCardItem;
