import { HttpError } from './http.error';

class HttpAuthError extends HttpError {
    constructor() {
        super('Unauthorized', 401);
        this.className = 'HttpAuthError';
        Error.captureStackTrace(this, HttpAuthError);
    }
}

export { HttpAuthError };