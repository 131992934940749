import React from 'react';
import { Avatar } from '@material-ui/core';
import { useSelector } from 'react-redux';
import UserSwitcher from 'components/UserSwitcher';
import useCurrentUser from '../../util/useCurrentUser';


const UserInfo = () => {
    const { authUser } = useSelector(({ auth }) => auth);
    const user = useCurrentUser();
    const name = `${user?.first_name || ''} ${user?.last_name || ''}`;

    return (
        <div className="user-profile d-flex flex-row align-items-center mr-2">
            <div className="mr-2">
                {authUser?.info?.avatar && (
                    <Avatar
                        alt="..."
                        className="user-avatar "
                    />
                )}
                <div className="user-detail">
                    <h4 className="user-name">
                        {authUser ? name : 'Гость'}
                    </h4>
                </div>
            </div>
            {authUser && <UserSwitcher />}
        </div>
    );
};

export default UserInfo;
