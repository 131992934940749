import * as Sentry from '@sentry/react';
import { SENTRY_DSN } from 'constants/Config';
import createErrorFromAxiosError from './create.error';

const addErrorHandling = (entity) => {
    Object.entries(entity).forEach(([key, value]) => {
        if (value instanceof Function) {
            entity[key] = async (...params) => {
                try {
                    return await value(...params);
                } catch (error) {
                    if (SENTRY_DSN) {
                        Sentry.captureException(error);
                    }
                    throw createErrorFromAxiosError(error);
                }
            };
        }
    });
    return entity;
};

export default addErrorHandling;
