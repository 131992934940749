import React from 'react';
import {
    Popover, LinearProgress
} from '@material-ui/core';
import ActionButton from 'components/ActionButton';

const MenuItemWithPopover = ({
    children = () => {}, loading = false, buttonTitle = '', icon, className = '', classNameButton = 'text-secondary', buttonTitleText
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={className}>
            <ActionButton
                title={buttonTitle}
                titleText={buttonTitleText} //todo временное решение
                onClick={handleOpen}
                className={classNameButton}
                icon={icon}
            />

            <Popover
                id="sort"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                keepMounted
                open={open}
                onClose={handleClose}
                getContentAnchorEl={null}
                classes={{ paper: 'with-filter-arrows' }}
            >
                {children(handleClose)}
                {loading && <LinearProgress />}
            </Popover>
        </div>
    );
};

export default MenuItemWithPopover;
