import {
    SET_VALIDATION_FIELDS,
    CLEAR_VALIDATION_FIELDS
} from 'constants/ActionTypes/validation';

export const setValidationFields = (validation, message) => ({
    type: SET_VALIDATION_FIELDS,
    payload: { validation, message }
});

export const clearValidationFields = () => ({
    type: CLEAR_VALIDATION_FIELDS
});
