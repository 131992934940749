import {
    all, call, select, put, takeLatest
} from 'redux-saga/effects';

import {
    FETCH_NEWS_LIST,
    LOAD_NEWS_WITH_ID,
    FETCH_NEWS_STATUSES,
    PUBLISH_NEWS,
    EDIT_NEWS,
    CREATE_NEWS,
    DELETE_NEWS
} from 'constants/ActionTypes';
import {
    newsLoaded, newsWithIdLoaded, setError, statusesLoaded, newsSavedSuccess, setLoading
} from 'actions/News';
import { showMessage } from 'actions/Messages';
import { getActionForError } from 'util/errors';
import api from 'util/api/api.news.methods';

export const stateSelector = (state) => state.news;
export const stateAuthSelector = (state) => state.auth;

function* loadNewsListSaga({ payload }) {
    const { page, limit, data } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.getNewsList, page, limit, data, authUser?.access_token);
        yield put(newsLoaded(response));
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(setLoading(false));
    }
}

function* loadNewsStatusesSaga() {
    const { authUser } = yield select(stateAuthSelector);
    try {
        const statuses = yield call(api.getStatuses, authUser?.access_token);
        yield put(statusesLoaded(statuses.data));
    } catch (error) {
        yield put(getActionForError(error));
    }
}

function* loadNewsWithIdSaga({ payload }) {
    const { id } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.getNewsWithId, id, authUser?.access_token);
        yield put(newsWithIdLoaded(response.data));
    } catch (error) {
        yield put(getActionForError(error));
        yield put(setError(true));
    } finally {
        yield put(setLoading(false));
    }
}

function* createNewsSaga({ payload }) {
    const { data }  = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.createNews, data, authUser.access_token);
        yield put(showMessage('success', 'news.successfullySaved', null, true));
        yield put(newsSavedSuccess(response.success));
    } catch (error) {
        yield put(newsSavedSuccess(false));
        yield put(getActionForError(error));
    } finally {
        yield put(setLoading(false));
    }
}

function* editNewsSaga({ payload }) {
    const { id, data } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.editNews, id, data, authUser.access_token);
        yield put(showMessage('success', 'news.successfullySaved', null, true));
        yield put(newsSavedSuccess(response.success));
    } catch (error) {
        yield put(newsSavedSuccess(false));
        yield put(getActionForError(error));
    } finally {
        yield put(setLoading(false));
    }
}

function* publishNewsSaga({ payload }) {
    const { id } = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.publishNews, id, authUser.access_token);
        yield put(showMessage('success', 'news.successfullySaved', null, true));
        yield put(newsSavedSuccess(response.success));
    } catch (error) {
        yield put(newsSavedSuccess(false));
        yield put(getActionForError(error));
    } finally {
        yield put(setLoading(false));
    }
}

function* deleteNewsSaga({ payload }) {
    const { id }  = payload;
    const { authUser } = yield select(stateAuthSelector);
    try {
        const response = yield call(api.deleteNews, id, authUser.access_token);
        yield put(showMessage('success', 'news.successfullyArchived', null, true));
        yield put(newsSavedSuccess(response.success));
    } catch (error) {
        yield put(newsSavedSuccess(false));
        yield put(getActionForError(error));
    } finally {
        yield put(setLoading(false));
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(FETCH_NEWS_LIST, loadNewsListSaga),
        takeLatest(LOAD_NEWS_WITH_ID, loadNewsWithIdSaga),
        takeLatest(FETCH_NEWS_STATUSES, loadNewsStatusesSaga),
        takeLatest(CREATE_NEWS, createNewsSaga),
        takeLatest(EDIT_NEWS, editNewsSaga),
        takeLatest(PUBLISH_NEWS, publishNewsSaga),
        takeLatest(DELETE_NEWS, deleteNewsSaga)
    ]);
}
