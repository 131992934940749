import {
    DEBTORS_LIST_LOADED_SUCCESS,
    DEBTORS_LIST_LOADED_STATUS_CHANGE,
    SET_DEBTORS_ERROR,
    DEBTORS_COMPANIES_LOADED_SUCCESS,
    DEBTORS_FETCH_USER_BY_UK,
    DEBTORS_FETCH_USER_BY_UK_LOADED,
    DEBTORS_SET_MESSAGES_STATUS,
    DEBTORS_CLEAR_MESSAGES_STATUS,
    DEBTORS_FETCH_USER_BY_UK_LOADED_STARUS,
    DEBTORS_CLEAR_USER
} from 'constants/ActionTypes';

const INIT_STATE = {
    list: {
        data: []
    },
    listLoaded: false,
    companies: [],
    error: false,
    user: {},
    userLoaded: false,
    sendMessageStatus: {
        /*email: false,
        telegram: false*/
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
    case DEBTORS_LIST_LOADED_SUCCESS: {
        return {
            ...state,
            list: action.payload,
        };
    }
    case DEBTORS_LIST_LOADED_STATUS_CHANGE: {
        return {
            ...state,
            listLoaded: action.payload
        };
    }

    case DEBTORS_COMPANIES_LOADED_SUCCESS: {
        return {
            ...state,
            companies: action.payload.data.map((item) => item.company)
        };
    }

    case SET_DEBTORS_ERROR: {
        return {
            ...state,
            error: action.payload
        };
    }

    case DEBTORS_FETCH_USER_BY_UK: {
        return {
            ...state,
            user: {}
        };
    }

    case DEBTORS_FETCH_USER_BY_UK_LOADED: {
        const { data, success } = action.payload;
        return {
            ...state,
            user: success ? data : {}
        };
    }

    case DEBTORS_CLEAR_USER: {
        return {
            ...state,
            user: {}
        };
    }

    case DEBTORS_FETCH_USER_BY_UK_LOADED_STARUS: {
        return {
            ...state,
            userLoaded: action.payload
        };
    }

    case DEBTORS_SET_MESSAGES_STATUS: {
        const newValue = state.sendMessageStatus.hasOwnProperty(action.payload) ? state.sendMessageStatus[action.payload] : 1;
        return {
            ...state,
            sendMessageStatus: {
                ...state.sendMessageStatus,
                [action.payload]: newValue
            }
        };
    }

    case DEBTORS_CLEAR_MESSAGES_STATUS: {
        return {
            ...state,
            sendMessageStatus: {}
        };
    }

    default:
        return state;
    }
};
