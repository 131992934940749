import addErrorHandling from 'util/errors/error.handling';
import Urls from './api.urls';
import instance from './axios.config';
// const instance = axios.create({
//     // Reject only if the status code is greater than or equal to 500
//     // validateStatus: status => status < 500,
// });

const ApiNewsMethods = {
    getTranslateList: async (page = 1, limit = 10, service, query = '', access_token) => {
        const response = await instance.get(Urls.getTranslateList(page, limit, service, query),
            {
                headers: {
                    ...(access_token ? { Authorization: `Bearer ${access_token}` } : {})
                }
            });
        return response.data;
    },

    getTranslateServiceList: async (access_token) => {
        const response = await instance.get(Urls.getTranslateServiceList(),
            {
                headers: {
                    ...(access_token ? { Authorization: `Bearer ${access_token}` } : {})
                }
            });
        return response.data;
    },


    getTranslateWithId: async (id, access_token) => {
        const response = await instance.get(Urls.getTranslateWithId(id),
            {
                headers: {
                    ...(access_token ? { Authorization: `Bearer ${access_token}` } : {})
                }
            });
        return response.data;
    },

    createTranslate: async (data, access_token) => {
        const response = await instance.post(Urls.createTranslate(),
            data,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    editTranslate: async (id, data, access_token) => {
        const response = await instance.put(Urls.editTranslateWithId(id),
            data,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    deleteTranslate: async (id, access_token) => {
        const response = await instance.delete(Urls.deleteTranslateWithId(id),
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    }
};

export default addErrorHandling(ApiNewsMethods);
