import React from 'react';
import { IconButton } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';

const ProductCounter = ({ count, onChange, size = 'small' }) => {
    const fontSize = size === 'large'
        ? '1.5rem'
        : size === 'medium'
            ? '1.3rem'
            : '1 rem';

    return (
        <>
            <IconButton size={size} color="primary" onClick={() => onChange(count - 1)}>
                <Remove size="inherit" />
            </IconButton>
            <div style={{fontSize}}>{count}{' шт.'}</div>
            <IconButton size={size} color="primary" onClick={() => onChange(count + 1)}>
                <Add size="inherit" />
            </IconButton>
        </>
    )
};

export default ProductCounter;