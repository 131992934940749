import addErrorHandling from 'util/errors/error.handling';
import Urls from './api.urls';
import instance from './axios.config';

// const instance = axios.create({
//     // Reject only if the status code is greater than or equal to 500
//     // validateStatus: status => status < 500,
// });

const ApiArticlesMethods = {
    getArticlesList: async (page = 1, limit = 10, access_token) => {
        const response = await instance.get(Urls.getArticlesList(page, limit),
            {
                headers: {
                    ...(access_token ? { Authorization: `Bearer ${access_token}` } : {})
                }
            });
        return response.data;
    },

    getArticleWithSlug: async (slug, access_token) => {
        const slugWithoutSlash = slug.replace(/^\/+/g, '');
        const response = await instance.get(Urls.getArticleWithSlug(slugWithoutSlash),
            {
                headers: {
                    ...(access_token ? { Authorization: `Bearer ${access_token}` } : {})
                }
            });
        return response.data;
    },

    getArticleWithId: async (id, access_token) => {
        const response = await instance.get(Urls.getArticleWithId(id),
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    getStatuses: async (access_token) => {
        const response = await instance.get(Urls.getArticleStatuses(),
            {
                headers: {
                    ...(access_token ? { Authorization: `Bearer ${access_token}` } : {})
                }
            });
        return response.data;
    },

    createArticle: async (data, access_token) => {
        const response = await instance.post(Urls.createArticle(),
            data,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    editArticle: async (id, data, access_token) => {
        const response = await instance.put(Urls.editArticleWithId(id),
            data,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    publishArticle: async (id, access_token) => {
        const response = await instance.post(Urls.publishArticleWithId(id),
            {},
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    deleteArticle: async (id, access_token) => {
        const response = await instance.delete(Urls.deletetArticleWithId(id),
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    }
};

export default addErrorHandling(ApiArticlesMethods);
