import {
    userSignOutSuccess,
} from 'actions/Auth';
import {
    setValidationFields
} from 'actions/Validation';

import { showMessage } from 'actions/Messages';


const getActionForError = (error) => {
    const sagaMap = {
        // ValidationError: (e) => setValidationFields(e.validationMessage),
        ValidationError: (e) => setValidationFields(e.validationMessage, {
            message: e.message,
            type: 'error'
        }),
        HttpAuthError: (e) => userSignOutSuccess(e),
        DEFAULT: (e) => showMessage('error', e.message)
    };
    //const key = Object.keys(sagaMap).find((i) => i === error.constructor.name) || 'DEFAULT';
    const key = Object.keys(sagaMap).find((i) => i === error.className) || 'DEFAULT';
console.log('getActionForError', error)
    return sagaMap[key](error);
};

export default getActionForError;
