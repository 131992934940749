import React from 'react';
import { Grid } from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import AccordionCard from './Accordion/Card';
import AccordionList from './Accordion/List';

const CompanyList = ({
    title = '',
    data = {},
    ...props
}) => {
    if (!Object.keys(data)) {
        return (
            <div className="h4">
                <IntlMessages id="appModule.nothingFound" />
            </div>
        );
    }

    return (
        <div className="mb-2">
            <h4 className="mb-4">{title}</h4>

            <Grid item className="mb-4" style={{padding:0}}>
                <AccordionCard
                    title={['house', 'end_debit', 'end_credit']}
                >
                    <AccordionList
                        list={data}
                        {...props}
                    />
                </AccordionCard>
            </Grid>
        </div>
    );
};

export default CompanyList;
