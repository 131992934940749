import addErrorHandling from 'util/errors/error.handling';
import urls from './api.urls';
import instance from '../axios.config';
// фейковые данные
// import defaultBasket from '../../../components/CartMini/data';

const ApiAppealMethods = {
    getBasket: async (params) => {
        const response = await instance.get(urls.basketApi(), { params });
        // response: {
        //     "total": {
        //         "description": "Oбщая сумма",
        //         "type": "float",
        //         "example": 100.5
        //     },
        //     "items": {
        //         "description": "Позиции в корзине",
        //         "type": "array",
        //         "items": {
        //             "$ref": "#/components/schemas/ProductResource"
        //         }
        //     }
        // }
        //return response.data;
        return response.data;
    },
    addToBasket: async (params) => {
        // "params": {
        //     "id": {
        //         "description": "Идентификатор",
        //         "type": "integer",
        //         "example": 1
        //     },
        //     "product_id": {
        //         "description": "Идентификатор товара",
        //         "type": "integer",
        //         "example": 1
        //     },
        //     "count": {
        //         "description": "Кол-во",
        //         "type": "integer",
        //         "example": 1
        //     },
        //     "cost": {
        //         "description": "Цена",
        //         "type": "float",
        //         "example": 100
        //     },
        //     "created_at": {
        //         "description": "Дата создания",
        //         "type": "string",
        //         "example": "2020-01-01 00:00:00"
        //     },
        //     "product": {
        //         см product
        //     }
        // }
        const response = await instance.post(urls.basketApi(), params);
        return response.data;
    },
    updateBasket: async ({ id, params }) => {
        const response = await instance.put(urls.basketApi(id), params);
        return response.data;
    },
    deleteFromBasket: async (id) => {
        const response = await instance.delete(urls.basketApi(id));
        return response.data;
    },

    getCategoryList: async (params) => {
        // params: id, page, limit, start_date, end_date
        const response = await instance.get(urls.categoryApi(), { params });
        return response.data;
    },
    addCategory: async (params) => {
        const response = await instance.post(urls.categoryApi(), params);
        return response.data;
    },
    updateCategory: async (id) => {
        const response = await instance.put(urls.categoryApi(id));
        return response.data;
    },
    deleteCategory: async (id) => {
        const response = await instance.delete(urls.categoryApi(id));
        return response.data;
    },

    getCategoryTree: async (params) => {
        const response = await instance.get(urls.categoryTree(), { params });
        return response.data;
    },

    fetchProductList: async (params) => {
        // params: id, category_id, page, limit, start_date, end_date
        const response = await instance.get(urls.productApi(), { params });
        return response.data;
    },
    addProduct: async (params) => {
        // "params": {
        //     "id": {
        //         "description": "Идентификатор",
        //         "type": "integer",
        //         "example": 1
        //     },
        //     "category_id": {
        //         "description": "Идентификатор Категории",
        //         "type": "integer",
        //         "example": 1
        //     },
        //     "cost": {
        //         "description": "Цена",
        //         "type": "float",
        //         "example": 1
        //     },
        //     "name": {
        //         "description": "Товар",
        //         "type": "string",
        //         "example": "Ручка"
        //     },
        //     "description": {
        //         "description": "Описание",
        //         "type": "string",
        //         "example": "Описание"
        //     },
        //     "sort": {
        //         "description": "Сортировка",
        //         "type": "integer",
        //         "example": 1
        //     },
        //     "parameters": {
        //         "description": "Параметры",
        //         "type": "object",
        //         "example": []
        //     },
        //     "images": {
        //         "description": "Изображения",
        //         "type": "object",
        //         "example": []
        //     },
        //     "created_at": {
        //         "description": "Дата создания",
        //         "type": "string",
        //         "example": "2020-01-01 00:00:00"
        //     }
        // }
        const response = await instance.post(urls.productApi(), params);
        return response.data;
    },
    updateProduct: async (id) => {
        const response = await instance.put(urls.productApi(id));
        return response.data;
    },
    deleteProduct: async (id) => {
        const response = await instance.delete(urls.productApi(id));
        return response.data;
    },

    // order
    getOrderList: async (params) => {
        const response = await instance.get(urls.orderApi(), { params });
        return response.data;
//      вот сам заказ
// {
//   "success": true,
//   "data": {
//     "id": 1,
//     "cost": 100,----- - общая цена заказа тоже фиксирована и считается по data.products.*.cost
//     "address": {},
//     "time_at": "2020-01-10 14:00:00",
//     "comment": "Хочу быстрее",
//     "status": 1,
//     "payment_type": 1,
//     "payment_status": 1,
//     "status_text": "Новый",
//     "payment_type_text": "Наличными курьеру",
//     "payment_status_text": "Ожидание оплаты",
//     "created_at": "2020-01-01 00:00:00",
//     "products": [
//       {
//         "id": 1,
//         "product_id": 1,
//         "count": 1,
//         "cost": 100,------- - цена товара на момент заказа он закрепиться и не будет меняться больше ни когда
//         "created_at": "2020-01-01 00:00:00",
//         "product": {
//           "id": 1,
//           "category_id": 1,
//           "cost": 1, ---------- цена самого товара она может меняться и может не совпадать с ценой товара в заказе на разных этапах и временых отрезках
//           "name": "Ручка",
//           "description": "Описание",
//           "sort": 1,
//           "parameters": [],
//           "images": [],
//           "created_at": "2020-01-01 00:00:00"
//         }
//       }
//     ]
//   }
// }
    },
    addOrder: async (params) => {
        // params: {
        //     "products": { ------ это товары из корзины - мы по каждому товару берем id и count
        //         "description": "Товары",
        //         "type": "array",
        //         "items": {
        //             "type": "object"
        //         },
        //         "example": [
        //             {
        //                 "id": 1,
        //                 "count": 1
        //             }
        //         ]
        //     },
        //     "address": {
        //         "description": "адрес",
        //         "type": "object",
        //         "example": []
        //     },
        //     "time_at": {
        //         "description": "время доставки",
        //         "type": "string",
        //         "example": "2020-01-10 14:00:00"
        //     },
        //     "comment": {
        //         "description": "Коментарии к заказу",
        //         "type": "string",
        //         "example": "Хочу быстрее"
        //     },
        //     "payment_type": {
        //         "description": "Тип оплаты",
        //         "type": "integer",
        //         "example": 1
        //     }
        // },
        const response = await instance.post(urls.orderApi(), params);
        return response.data;
    },
    getOrderStatuses: async () => {
        const response = await instance.get(urls.orderStatusesApi());
        return response.data;
    },
    getOrderPaymentStatuses: async () => {
        const response = await instance.get(urls.orderPaymentStatusesApi());
        return response.data;
    },
    getOrderPaymentTypes: async () => {
        const response = await instance.get(urls.orderPaymentTypesApi());
        return response.data;
    },
    getOrdersList: async (params) => {
        const response = await instance.get(urls.getOrdersList(), {
            params
        });
        return response.data;
    },
    changeOrdersStatus: async (params) => {
        const response = await instance.put(urls.changeOrdersStatus(), params);
        return response.data;
    },
    closeOrders: async (params) => {
        const response = await instance.put(urls.closeOrders(), params);
        return response.data;
    },
};

export default addErrorHandling(ApiAppealMethods);