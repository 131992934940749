import React from 'react';
import {
    Button,
    FormGroup,
    Grid
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import ActionButton from 'components/ActionButton';

const FindItem = (props) => {
    const {
        value = '',
        onClick
    } = props;

    return (
        <Grid item xs={12} className="position-relative">
            <FormGroup className="align-items-center" row>
                <ActionButton
                    onClick={onClick}
                    title="appModule.select"
                    className="text-secondary"
                    icon={<CheckIcon />}
                    size="small"
                />
                <Button
                    size="small"
                    onClick={onClick}
                >
                    {value}
                </Button>
            </FormGroup>
        </Grid>
    );
};

export default FindItem;
