import React from 'react';
import {
    MenuItem, FormControl, Select
} from '@material-ui/core';

const SimpleSelect = ({
    selectedItem,
    data,
    onChange,
    className='',
    extractItemInfo = (i) => ({ id: i.id, name: i.name })
}) => (
    <FormControl>
        <Select
            value={selectedItem}
            onChange={(event) => onChange(event.target.value)}
            className={className}
        >
            {data.map((item) => {
                const { id, name } = extractItemInfo(item);
                return (<MenuItem value={id} key={id}>{name}</MenuItem>);
            })}
        </Select>
    </FormControl>
);

export default SimpleSelect;
