import {
    all, call, select, put, takeLatest
} from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';

import {
    SIGNIN_USER,
    SIGNOUT_USER,
    SIGNUP_USER,
    REFRESH_TOKEN,
    RESET_PASSWORD,
    SAVE_USER,
    SYNC_USER,
    CHANGE_PASSWORD,
    FETCH_SOCNET_LIST,
    SIGNIN_KRASINFORM,
    LOAD_UK_INFO,
    FETCH_UK_LIST,
    SYNC_TOKEN
} from 'constants/ActionTypes';
import {
    userSignInSuccess,
    userSignOutSuccess,
    userSignUpSuccess,
    saveRefreshTime,
    saveUserData,
    resetPasswordSuccess,
    refreshToken,
    socnetListLoad,
    saveCurrentUK,
    ukInfoLoaded,
    ukListLoaded,
    hideAuthLoader,
    syncToken
} from 'actions/Auth';
import {
    changeSyncUserStatus, changePasswordSuccess
} from 'actions/User';
import { showMessage, notificationRecieved } from 'actions/Messages';
import { getActionForError } from 'util/errors';
import cookie from 'react-cookies';
import { cookieOption } from 'constants/Config';
import api from '../util/api/api.auth.methods';
import userApi from '../util/api/api.user.methods';

export const stateSelector = (state) => state.auth;

function setTimeToRefreshToken(expires_in) {
    const timeToRefresh = expires_in * 0.6 * 1000; // 40% time to token expiration
    const needRefresh = Date.now() + timeToRefresh;
    //localStorage.setItem('needRefresh', needRefresh);
    cookie.save(
        'needRefresh',
        needRefresh,
        {
            expires: new Date(needRefresh),
            //maxAge: expires_in,
            ...cookieOption
        }
    );
    return needRefresh;
}

function setCurrentUK(user, currentUK) {
    const userUk = user.info.access?.zkhu?.find(el => el.uk === currentUK.uk_name);

    if (user.info.scopes?.includes('zkh-admin')) {
            return {
                uk_name: (currentUK.uk_name !== 'public' && userUk) ? currentUK.uk_name : 'public',
                // account_number: 0
            };

    }
    // if (user.info.scopes?.includes('zkh-user')) {
    //     return {
    //         uk_name: 'public',
    //         account_number: 0
    //     };
    // }
    return currentUK;
}

function* createUserWithEmailPasswordSaga({ payload }) {
    try {
        yield call(api.signUp, payload);
        yield put(userSignUpSuccess());
    } catch (error) {
        yield put(getActionForError(error));
    }
}

function* signInUserWithEmailPasswordSaga({ payload }) {
    const { username, password, remember } = payload;
    try {
        const user = yield call(api.getToken, username, password);

        // remember me
        if (remember) {
            const { expires_in } = user;
            const needRefresh = setTimeToRefreshToken(expires_in);
            yield put(saveRefreshTime(needRefresh));
        }
        yield put(saveUserData(user, remember));
    } catch (error) {
        yield put(getActionForError(error));
    }
}

function* signInUserKrasinformSaga({ payload }) {
    const {
        account_num, password, email, remember
    } = payload;
    try {
        const userData = yield call(api.authKrasinform, account_num, password, email);
        const user = userData.data;

        // remember me
        if (remember) {
            const { expires_in } = user;
            const needRefresh = setTimeToRefreshToken(expires_in);
            yield put(saveRefreshTime(needRefresh));
        }

        yield put(saveUserData(user, remember));
    } catch (error) {
        yield put(getActionForError(error));
    }
}

function* syncUserSaga({ payload }) {
    const { account_num, password, uk_name } = payload;
    const { authUser } = yield select(stateSelector);
    const { refresh_token, access_token } = authUser;

    try {
        const user = yield call(userApi.syncUser, account_num, password, uk_name, access_token);
        yield put(changeSyncUserStatus(true));
        yield put(syncToken({ refresh_token, access_token }));
        //yield put(refreshToken({ refresh_token, access_token }));
        yield put(hideAuthLoader());
        yield put(saveCurrentUK({
            uk_name,
            // account_number: account_num
        }));
    } catch (error) {
        yield put(hideAuthLoader());
        yield put(getActionForError(error));
    }
}

function* saveUserDataSaga({ payload: { user, remember } }) {
    const { access_token, expires_in } = user;
    const { currentUK } = yield select(stateSelector);
    const needRefresh = parseInt(cookie.load('needRefresh')) || Date.now() + expires_in * 0.6 * 1000;
    if (remember) {
        cookie.save(
            'authUser',
            user,
            {
                expires: new Date(needRefresh),
                ...cookieOption
            }
        );
    }
    const decoded = jwtDecode(access_token);

    user = {
        ...user,
        info: {
            ...decoded,
            scopes: decoded.access?.zkhu?.find(({ uk }) => uk === currentUK?.uk_name)?.permissions || []
        }
    };
    const newCurrenUK = setCurrentUK(user, currentUK);
    yield put(saveCurrentUK(newCurrenUK));

    try {
        const personal = yield call(userApi.getUser, access_token);
        // ищем аккаунты текущей УК
        // проходим по массиву массивов и делаем один массив
        const currentUKAccounts = (persons) => (persons.reduce((result, person) => (
            result.concat(person?.account || []).reduce((res,el) => {
                if (el.uk_id === currentUK?.uk_name) {
                    res.push(el);
                }
                return res;
            }, [])
        ), []));
        const newAddressList = currentUKAccounts(personal.persons).map((item) => {
            const {
                uk_id: uk_name, external_personal_id: account_number = 0, address_text: address, id
            } = item;
            return {
                id,
                name: address,
                address,
                uk_name,
                account_number
            };
        });
        const currentUserAccount = newAddressList.find(el => el.account_number === currentUK.account_number);
        if (!currentUserAccount && newAddressList.length > 0) {
            const { account_number, uk_name, id } = newAddressList?.[0];
            yield put(saveCurrentUK({
                account_number,
                uk_name,
                id
            }));
        }
        user = {
            ...user,
            info: {
                ...personal,
                ...user.info,
                // person: {
                //     ...personal.person,
                //     first_name: personal.person?.first_name || user.info.first_name || '',
                //     last_name: personal.person?.last_name || user.info.last_name || '',
                // },
                newAddressList
            },
        };
        if (user.info?.person?.notifyCount) {
            yield put(notificationRecieved(user.info.person.notifyCount));
        }

        yield put(userSignInSuccess(user));
    } catch (error) {
        console.log('get user personality error', error);
        yield put(userSignInSuccess(user)); // записываем юзера в любом случае т.к. логин прошел успешно, а ошибка только в получении данных
    }
}

function* signOutSaga({ payload }) {
    const { logoutall } = payload;
    const { authUser } = yield select(stateSelector);
    try {
        const method = logoutall ? api.logoutAll : api.logout;
        yield call(method, authUser?.access_token);
    } catch (error) {
        yield put(getActionForError(error));
    } finally {
        yield put(userSignOutSuccess());
    }
}

function* refreshTokenSaga({ payload }) {
    const { refresh_token, access_token } = payload;
    try {
        const user = yield call(api.refreshToken, refresh_token, access_token);

        const { expires_in } = user;
        const needRefresh = setTimeToRefreshToken(expires_in);
        yield put(saveRefreshTime(needRefresh));

        yield put(saveUserData(user));
    } catch (error) {
        yield put(getActionForError(error));
    }
}

function* syncTokenSaga({ payload }) {
    const { refresh_token, access_token } = payload;
    try {
        const user = yield call(api.syncToken, refresh_token, access_token);

        const { expires_in } = user;
        const needRefresh = setTimeToRefreshToken(expires_in);
        yield put(saveRefreshTime(needRefresh));

        yield put(saveUserData(user));
    } catch (error) {
        yield put(getActionForError(error));
    }
}

function* resetPasswordSaga({ payload }) {
    const username = payload;
    try {
        const response = yield call(api.resetPassword, username);
        if (response.success) {
            yield put(resetPasswordSuccess());
            yield put(showMessage('success', 'Мы отправили вам новый пароль', 'Проверьте свой email'));
        }
    } catch (error) {
        yield put(getActionForError(error));
    }
}

function* changePasswordSaga({ payload }) {
    const { old_password, password, password_confirmation } = payload;
    const { authUser } = yield select(stateSelector);
    try {
        const response = yield call(userApi.changePassword, old_password, password, password_confirmation, authUser);
        if (response.success) {
            yield put(changePasswordSuccess());
        }
    } catch (error) {
        yield put(getActionForError(error));
    }
}

function* fetchSocnetListSaga({ payload }) {
    try {
        const response = yield call(api.socnetList);
        if (response.success) {
            yield put(socnetListLoad(response.data));
        }
    } catch (error) {
        yield put(getActionForError(error));
    }
}

function* getUKInfoSaga({ payload }) {
    const { name }  = payload;
    console.log('getUKInfoSaga UK', name);
    try {
        const response = yield call(api.getUKInfo, name);
        const uk = response.data;
        yield put(ukInfoLoaded({
            logo: uk.logo,
            name: uk.name
        }));
    } catch (error) {
        yield put(getActionForError(error));
    }
}

function* fetchUKListSaga() {
    try {
        const response = yield call(api.getUKList);
        if (response.success) {
            yield put(ukListLoaded(response.data));
        }
    } catch (error) {
        yield put(getActionForError(error));
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(SIGNIN_USER, signInUserWithEmailPasswordSaga),
        takeLatest(SIGNUP_USER, createUserWithEmailPasswordSaga),
        takeLatest(SIGNOUT_USER, signOutSaga),
        takeLatest(REFRESH_TOKEN, refreshTokenSaga),
        takeLatest(RESET_PASSWORD, resetPasswordSaga),
        takeLatest(SAVE_USER, saveUserDataSaga),
        takeLatest(SYNC_USER, syncUserSaga),
        takeLatest(CHANGE_PASSWORD, changePasswordSaga),
        takeLatest(FETCH_SOCNET_LIST, fetchSocnetListSaga),
        takeLatest(SIGNIN_KRASINFORM, signInUserKrasinformSaga),
        takeLatest(LOAD_UK_INFO, getUKInfoSaga),
        takeLatest(FETCH_UK_LIST, fetchUKListSaga),
        takeLatest(SYNC_TOKEN, syncTokenSaga)
    ]);
}
