// Customizer const
export const LOAD_USER_LIST = 'LOAD_USER_LIST';
export const USER_LIST_LOADING = 'USER_LIST_LOADING';
export const USER_LIST_LOADED = 'USER_LIST_LOADED';
export const CREATE_NEW_USER = 'CREATE_NEW_USER';
export const EDIT_USER = 'EDIT_USER';
export const USER_SAVED_SUCCESS = 'USER_SAVED_SUCCESS';
export const USER_LOADING = 'USER_LOADING';
export const LOAD_ROLES = 'LOAD_ROLES';
export const LOADED_ROLES = 'LOADED_ROLES';
export const LOAD_USER_ACCOUNT_INFO = 'LOAD_USER_ACCOUNT_INFO';
export const USER_ACCOUNT_INFO_LOADED = 'USER_ACCOUNT_INFO_LOADED';
