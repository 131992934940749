import React from 'react';
import { FormGroup, TextField } from '@material-ui/core';

const PricePicker = ({
    from = {
        value: '',
        label: '',
        name: 'from'
    },
    to = {
        value: '',
        label: '',
        name: 'to'
    },
    onPriceChange = () => {}
}) => {
    const keyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            const { value, name } = event.target;
            onPriceChange(value, name);
        }
    };

    const handleChange = (event) => {
        const { value, name } = event.target;
        onPriceChange(value, name);
    };

    return (
        <>
            <div className="col-md-3 col-12">
                <FormGroup>
                    <TextField
                        value={from.value}
                        label={from.label}
                        name={from.name}
                        onChange={handleChange}
                        onKeyDown={keyDown}
                        type="number"
                        variant="outlined"
                        size="small"
                        className="mb-3 hide-spin"
                    />
                </FormGroup>
            </div>
            <div className="col-md-3 col-12">
                <FormGroup>
                    <TextField
                        value={to.value}
                        label={to.label}
                        name={to.name}
                        onChange={handleChange}
                        onKeyDown={keyDown}
                        error={to.value < from.value}
                        type="number"
                        variant="outlined"
                        size="small"
                        className="mb-3 hide-spin"
                    />
                </FormGroup>
            </div>
        </>
    );
};

export default PricePicker;
