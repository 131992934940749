import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useScopes = (fetchScopes, storeName, scenario) => {
    const dispatch = useDispatch();
    const {
        scopes
    } = useSelector((store) => store[storeName]);

    const [scopeList, setScopeList] = useState(scopes);

    useEffect(() => {
        if (scopes.length === 0) {
            dispatch(fetchScopes(scenario));
        } else {
            setScopeList(scopes);
        }
    }, [scopes, dispatch, fetchScopes]);

    return scopeList;
};

export default useScopes;
