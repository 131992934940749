import React from 'react';
import {
    Card, CardHeader, CardActions, CardContent
} from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';

const AccordeonCard = ({ title = [], actions = null, children }) => {
    const header = title.map(((el, index) => {
        const width = 100 / title.length;
        return (<span key={el} style={{ width: `${width}%`, textAlign: `${index > 0 ? 'right' : 'left'}`}}>
            <IntlMessages id={el} />
        </span>);
    }));
    return (
        <Card classes={{ root: 'bg-transparent col-md-6 col-12' }} elevation={0}>
            <CardHeader
                title={header}
                classes={{ title: 'd-flex justify-content-between jr-fs-lg mr-5 ml-3' }}
            />
            <CardContent classes={{ root: 'p-0' }}>
                {children}
            </CardContent>
            <CardActions classes={{ root: 'justify-content-center' }}>
                {actions}
            </CardActions>
        </Card>
    );
};

export default AccordeonCard;
