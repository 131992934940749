import {
    all, call, put, takeLatest, select
} from 'redux-saga/effects';

import {
    FETCH_ACCRUALS_LIST,
    GET_INVOICE_FOR_PERIOD,
    GET_PAYMENTS_LIST,
    GET_PAYMENTS_STATUSES
} from 'constants/ActionTypes';
import {
    saveAccrualsList,
    invoiceForPeriodLoaded,
    setError,
    paymentListLoaded,
    paymentStatusesLoaded
} from 'actions/Payments';
import { getActionForError } from 'util/errors';
import api from '../util/api/api.payments.methods';

export const stateSelector = (state) => state.payments;
export const stateAuthSelector = (state) => state.auth;

function* getAccrualsListSaga({ payload: params }) {
    const { authUser } = yield select(stateAuthSelector);
    const { access_token } = authUser;
    try {
        const periods = yield call(api.getAccrualsList, access_token, params);
        yield put(saveAccrualsList(periods));
    } catch (error) {
        yield put(getActionForError(error));
        yield put(setError(error.message));
    }
}

function* getPaymentStatusesSaga() {
    const { authUser } = yield select(stateAuthSelector);
    const { access_token } = authUser;
    try {
        const response = yield call(api.getPaymentStatuses, access_token);
        yield put(paymentStatusesLoaded(response.data));
    } catch (error) {
        yield put(getActionForError(error));
        yield put(setError(error.message));
    }
}

function* getPaymentsListSaga({ payload }) {
    const { page, limit, filter } = payload;
    const { authUser } = yield select(stateAuthSelector);
    const { access_token } = authUser;
    try {
        const response = yield call(api.getPaymentsList, page, limit, filter, access_token);
        yield put(paymentListLoaded(response));
    } catch (error) {
        yield put(getActionForError(error));
        yield put(setError(error.message));
    }
}

function* getInvoiceForPeriodSaga({ payload }) {
    const { period, format } = payload;
    const { authUser } = yield select(stateAuthSelector);
    const { access_token } = authUser;
    try {
        const invoice = yield call(api.getInvoiceForPeriod, period, format, access_token);
        const link = document.createElement('a');
        link.download = `invoice_${period}.${format}`;
        link.href = URL.createObjectURL(invoice);
        link.click();
        URL.revokeObjectURL(link.href);
        yield put(invoiceForPeriodLoaded());
    } catch (error) {
        yield put(getActionForError(error));
        yield put(setError(error.message));
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(GET_INVOICE_FOR_PERIOD, getInvoiceForPeriodSaga),
        takeLatest(GET_PAYMENTS_LIST, getPaymentsListSaga),
        takeLatest(GET_PAYMENTS_STATUSES, getPaymentStatusesSaga),
        takeLatest(FETCH_ACCRUALS_LIST, getAccrualsListSaga),
    ]);
}
