export default {
    palette: {
        primary: {
            light: '#CAAC69',
            main: '#bc9131',
            dark: '#9d6f09',
            contrastText: '#fff'
        },
        secondary: {
            light: '#CAAC69',
            main: '#bc9131',
            dark: '#9d6f09',
            contrastText: '#fff'
        }
    },
    status: {
        danger: 'orange'
    },
    typography: {
        button: {
            fontWeight: 400,
            textAlign: 'capitalize'
        }
    }
};
