import addErrorHandling from 'util/errors/error.handling';
import Urls from './api.urls';
import instance from './axios.config';

// const instance = axios.create({
//     // Reject only if the status code is greater than or equal to 500
//     // validateStatus: status => status < 500,
// });

const ApiMenuMethods = {
    getScenarios: async (access_token) => {
        const response = await instance.get(Urls.getScenarios(),
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    getScopes: async (scenario, access_token) => {
        const response = await instance.get(Urls.getScopes(scenario),
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    getMenus: async (access_token, service) => {
        const response = await instance.get(Urls.getAllMenus(service),
            {
                headers: {
                    ...(access_token ? { Authorization: `Bearer ${access_token}` } : {})
                }
            });
        return response.data;
    },

    getMenusForScope: async (access_token, scope, service, scenario) => {
        const response = await instance.get(Urls.getMenusForScope(scope, service, scenario),
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    createMenuItem: async (data, access_token) => {
        const response = await instance.post(Urls.createMenuItem(),
            data,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    editMenuItem: async (id, data, access_token) => {
        const response = await instance.put(Urls.editMenuItem(id),
            data,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    },

    deleteMenuItem: async (id, access_token) => {
        const response = await instance.delete(Urls.deleteMenuItem(id),
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
        return response.data;
    }
};

export default addErrorHandling(ApiMenuMethods);
