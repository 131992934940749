import {
    TRANSLATE_FETCH_LIST,
    TRANSLATE_LIST_LOADED,
    TRANSLATE_LIST_LOADED_STATUS,
    TRANSLATE_SET_ERROR,
    TRANSLATE_CREATE,
    TRANSLATE_EDIT,
    TRANSLATE_DELETE,
    TRANSLATE_SAVED,
    TRANSLATE_FETCH_WITH_ID,
    TRANSLATE_WHITH_ID_LOADED,
    TRANSLATE_FETCH_SERVICE_LIST,
    TRANSLATE_SERVICE_LIST_LOADED
} from 'constants/ActionTypes';

export const fetchTranslateList = (page, limit, service = 'kvartal', query = '') => ({
    type: TRANSLATE_FETCH_LIST,
    payload: { page, limit, query, service }
});

export const fetchTranslateServiceList = () => ({
    type: TRANSLATE_FETCH_SERVICE_LIST
});

export const translateListLoaded = (data) => ({
    type: TRANSLATE_LIST_LOADED,
    payload: data
});

export const translateServiceListLoaded = (data) => ({
    type: TRANSLATE_SERVICE_LIST_LOADED,
    payload: data
});

export const fetchTranslateWithId = (id) => ({
    type: TRANSLATE_FETCH_WITH_ID,
    payload: { id }
});

export const translateWhithIDLoaded = (data) => ({
    type: TRANSLATE_WHITH_ID_LOADED,
    payload: data
});

export const translateListLoadedStatus = (bool = false) => ({
    type: TRANSLATE_LIST_LOADED_STATUS,
    payload: bool
});

export const setError = (bool) => ({
    type: TRANSLATE_SET_ERROR,
    payload: bool
});

export const createTranslate = (data) => ({
    type: TRANSLATE_CREATE,
    payload: { data }
});

export const editTranslate = (id, data) => ({
    type: TRANSLATE_EDIT,
    payload: { id, data }
});

export const deleteTranslate = (id) => ({
    type: TRANSLATE_DELETE,
    payload: { id }
});

export const translateSavedSuccess = (savedAction = true) => ({
    type: TRANSLATE_SAVED,
    payload: { savedAction }
});
